import React from "react";
import { Grid, makeStyles, Typography, Card, Button } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { Link } from "react-router-dom";
import { Field } from "formik";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { resetOverridesData } from "../cmsInitialValues";
import { EditorState } from "draft-js";
import Dropzone from "react-dropzone";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Permitted from "~/Permissions/Permitted";
import setImagePathByExtension from "../../../../helpers/Images/setImagePathByExtension";

const useStyles = makeStyles((theme) => ({
    tabs: {
        display: "block",
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: "flex-start",
        textTransform: "capitalize",
    },
}));

const Brand = (props) => {
    const {
        setFieldValue,
        brandFiles,
        setBrandFiles,
        previewUpload,
        brandEditorState,
        setBrandEditorState,
        setRenameImage,
        handleOpenImageDialog,
        setRenameSection,
        setRenameRenameFileIndex,
    } = props;

    const classes = useStyles();

    // RESET SINGLE FIELD STATE TO INITIAL
    const resetField = () => {
        setBrandEditorState(
            EditorState.createWithContent(
                convertFromHTML(resetOverridesData.brand.brand)
            )
        );
        setFieldValue("brand.brand", resetOverridesData.brand.brand);
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid item xs sm md lg xl>
                    <Card style={{ borderRadius: 0, padding: "10px 20px" }}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item xs sm md lg xl>
                                <Typography
                                    style={{ fontSize: 14, color: "#3e3f60" }}
                                >
                                    Go to the Logo & Assets page to upload, edit
                                    or delete assets.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link
                                    to={"/brand/category/3"}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button>
                                        <Typography
                                            style={{
                                                color: "#af001e",
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            Upload Assets
                                        </Typography>
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Grid item>
                <Grid item xs sm md lg xl>
                    <Card style={{ borderRadius: 0, padding: "10px 20px" }}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item xs sm md lg xl>
                                <Typography
                                    style={{ fontSize: 14, color: "#3e3f60" }}
                                >
                                    Go to the Documents page to upload, edit or
                                    delete documents.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link
                                    to={"/brand/category/4"}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button>
                                        <Typography
                                            style={{
                                                color: "#af001e",
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            Upload Documents
                                        </Typography>
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Permitted
                some={[
                    "edit_brand_guidelines_content",
                    "upload_delete_brand_guidelines_files",
                ]}
            >
                <Grid item>
                    <Card
                        style={{ borderRadius: 0, minHeight: 500, padding: 30 }}
                    >
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography
                                    style={{
                                        color: "#af001e",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Brand
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={6}
                                        >
                                            <Permitted one="edit_brand_guidelines_content">
                                                <Grid item xs sm md xl lg>
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        spacing={1}
                                                    >
                                                        <Grid item>
                                                            <Typography
                                                                style={{
                                                                    color: "#3e3f60",
                                                                    fontSize: 14,
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Brand Guidelines
                                                            </Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                alignItems="baseline"
                                                                justify="space-between"
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 10,
                                                                            color: "#999bb5",
                                                                        }}
                                                                    >
                                                                        Edit to
                                                                        the
                                                                        below
                                                                        text
                                                                        will
                                                                        only be
                                                                        applied
                                                                        for the
                                                                        selected
                                                                        partner
                                                                        tier.
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Field name="brand">
                                                                {({
                                                                    field,
                                                                }) => (
                                                                    <Editor
                                                                        {...field}
                                                                        editorState={
                                                                            brandEditorState
                                                                        }
                                                                        name="brand"
                                                                        editorStyle={{
                                                                            border: "1px solid #c6c9db",
                                                                            padding: 10,
                                                                            minHeight: 300,
                                                                            maxHeight: 300,
                                                                            overflowY:
                                                                                "auto",
                                                                        }}
                                                                        placeholder="Please add content here"
                                                                        onEditorStateChange={(
                                                                            e
                                                                        ) => {
                                                                            setFieldValue(
                                                                                "brand.brand",
                                                                                convertToHTML(
                                                                                    e.getCurrentContent()
                                                                                )
                                                                            );
                                                                            setBrandEditorState(
                                                                                e
                                                                            );
                                                                        }}
                                                                        toolbar={{
                                                                            options:
                                                                                [
                                                                                    "inline",
                                                                                    "list",
                                                                                ],
                                                                            inline: {
                                                                                options:
                                                                                    [
                                                                                        "bold",
                                                                                        "italic",
                                                                                        "underline",
                                                                                    ],
                                                                            },
                                                                            list: {
                                                                                options:
                                                                                    [
                                                                                        "unordered",
                                                                                    ],
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Permitted>
                                            <Permitted one="upload_delete_brand_guidelines_files">
                                                <Grid
                                                    item
                                                    xs={5}
                                                    sm={5}
                                                    md={5}
                                                    xl={5}
                                                    lg={5}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justify="space-between"
                                                        style={{
                                                            minHeight: "100%",
                                                        }}
                                                    >
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                alignItems="center"
                                                                justify="space-between"
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#3e3f60",
                                                                            fontSize: 14,
                                                                            fontWeight:
                                                                                "bold",
                                                                        }}
                                                                    >
                                                                        Related
                                                                        Documents
                                                                    </Typography>
                                                                    {(!brandFiles ||
                                                                        brandFiles?.length ===
                                                                            0) && (
                                                                        <Typography
                                                                            style={{
                                                                                color: "#999bb5",
                                                                                fontSize: 10,
                                                                            }}
                                                                        >
                                                                            No
                                                                            documents
                                                                            yet.
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                spacing={4}
                                                            >
                                                                {brandFiles &&
                                                                    brandFiles.map(
                                                                        (
                                                                            file,
                                                                            key
                                                                        ) => {
                                                                            const extension =
                                                                                file.type
                                                                                    ? file.type
                                                                                    : file.ext
                                                                                    ? file.ext
                                                                                    : "doc";

                                                                            return (
                                                                                <Grid
                                                                                    item
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                >
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justify="space-between"
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                        >
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                alignItems="baseline"
                                                                                                spacing={
                                                                                                    1
                                                                                                }
                                                                                            >
                                                                                                <Grid
                                                                                                    item
                                                                                                >
                                                                                                    {
                                                                                                        <img
                                                                                                            src={
                                                                                                                setImagePathByExtension(
                                                                                                                    extension
                                                                                                                )
                                                                                                                    .path
                                                                                                            }
                                                                                                            alt={
                                                                                                                setImagePathByExtension(
                                                                                                                    extension
                                                                                                                )
                                                                                                                    .alt
                                                                                                            }
                                                                                                            style={{
                                                                                                                width: 21,
                                                                                                                height: 21,
                                                                                                            }}
                                                                                                        />
                                                                                                    }
                                                                                                </Grid>
                                                                                                <Grid
                                                                                                    item
                                                                                                >
                                                                                                    <Typography
                                                                                                        style={{
                                                                                                            color: "#3e3f60",
                                                                                                            fontSize: 16,
                                                                                                        }}
                                                                                                    >
                                                                                                        {file
                                                                                                            .name
                                                                                                            .length >
                                                                                                        10
                                                                                                            ? file.name.substring(
                                                                                                                  0,
                                                                                                                  10
                                                                                                              ) +
                                                                                                              "..."
                                                                                                            : file.name}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid
                                                                                                    item
                                                                                                >
                                                                                                    <Typography
                                                                                                        style={{
                                                                                                            color: "#d8dae6",
                                                                                                            fontSize: 10,
                                                                                                            fontWeight:
                                                                                                                "bold",
                                                                                                        }}
                                                                                                    >
                                                                                                        {typeof file.size ===
                                                                                                        "number"
                                                                                                            ? file.size >
                                                                                                                  0 &&
                                                                                                              (
                                                                                                                  file.size *
                                                                                                                  0.000001
                                                                                                              ).toFixed(
                                                                                                                  2
                                                                                                              )
                                                                                                            : Number(
                                                                                                                  file.size
                                                                                                              ) >
                                                                                                                  0 &&
                                                                                                              (
                                                                                                                  Number(
                                                                                                                      file.size
                                                                                                                  ) /
                                                                                                                  1000
                                                                                                              ).toFixed(
                                                                                                                  2
                                                                                                              )}
                                                                                                        MB
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            item
                                                                                        >
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                spacing={
                                                                                                    2
                                                                                                }
                                                                                            >
                                                                                                <Grid
                                                                                                    item
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/icons/SVG/View.svg"
                                                                                                        style={{
                                                                                                            width: 21,
                                                                                                            height: 21,
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        alt="view"
                                                                                                        onClick={() =>
                                                                                                            previewUpload(
                                                                                                                file
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid
                                                                                                    item
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/icons/SVG/Edit.svg"
                                                                                                        style={{
                                                                                                            width: 21,
                                                                                                            height: 21,
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        alt="edit"
                                                                                                        onClick={() => {
                                                                                                            setRenameImage(
                                                                                                                file.name
                                                                                                            );
                                                                                                            setRenameSection(
                                                                                                                "brand_files"
                                                                                                            );
                                                                                                            setRenameRenameFileIndex(
                                                                                                                key
                                                                                                            );
                                                                                                            handleOpenImageDialog();
                                                                                                        }}
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid
                                                                                                    item
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/icons/SVG/Close.svg"
                                                                                                        style={{
                                                                                                            width: 21,
                                                                                                            height: 21,
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        alt="image"
                                                                                                        onClick={() => {
                                                                                                            let newFiles =
                                                                                                                brandFiles.filter(
                                                                                                                    (
                                                                                                                        item,
                                                                                                                        iKey
                                                                                                                    ) =>
                                                                                                                        iKey !==
                                                                                                                        key
                                                                                                                );
                                                                                                            setBrandFiles(
                                                                                                                newFiles
                                                                                                            );
                                                                                                            setFieldValue(
                                                                                                                "brand.brand_files",
                                                                                                                newFiles
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            );
                                                                        }
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                spacing={2}
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 12,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            color: "#af001e",
                                                                            marginTop:
                                                                                "3rem",
                                                                        }}
                                                                    >
                                                                        Upload
                                                                        files
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 10,
                                                                            color: "#999bb5",
                                                                        }}
                                                                    >
                                                                        You can
                                                                        upload
                                                                        new
                                                                        files to
                                                                        be
                                                                        uploaded
                                                                        below by
                                                                        either
                                                                        clicking
                                                                        on the
                                                                        button
                                                                        below,
                                                                        or
                                                                        dropping
                                                                        the file
                                                                        on the
                                                                        button
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Dropzone
                                                                        accept=".pdf,.doc,.jpg,.png,.xsl,.zip,.esp"
                                                                        minSize={
                                                                            0
                                                                        }
                                                                        onDrop={(
                                                                            x
                                                                        ) => {
                                                                            let newFiles =
                                                                                [];
                                                                            if (
                                                                                brandFiles &&
                                                                                brandFiles.length >
                                                                                    0
                                                                            ) {
                                                                                newFiles =
                                                                                    [
                                                                                        ...brandFiles,
                                                                                        ...x,
                                                                                    ];
                                                                            } else {
                                                                                newFiles =
                                                                                    [
                                                                                        ...x,
                                                                                    ];
                                                                            }
                                                                            setBrandFiles(
                                                                                newFiles
                                                                            );
                                                                            setFieldValue(
                                                                                "brand.brand_files",
                                                                                newFiles
                                                                            );
                                                                        }}
                                                                    >
                                                                        {({
                                                                            getRootProps,
                                                                            getInputProps,
                                                                            isDragActive,
                                                                            isDragReject,
                                                                        }) => {
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        background:
                                                                                            "#f3f4f8",
                                                                                        border: "3px dashed #d8dae6",
                                                                                        color: "#3e3f60",
                                                                                        padding: 30,
                                                                                        textAlign:
                                                                                            "center",
                                                                                        position:
                                                                                            "relative",
                                                                                    }}
                                                                                    {...getRootProps()}
                                                                                >
                                                                                    <div className="upload-icon">
                                                                                        <img
                                                                                            src="/images/icons/SVG/Upload-red.svg"
                                                                                            alt="upload"
                                                                                        />
                                                                                    </div>
                                                                                    <h4 className="upload-title">
                                                                                        Drag
                                                                                        &
                                                                                        Drop
                                                                                    </h4>
                                                                                    <div className="form-group">
                                                                                        <div className="dropZone">
                                                                                            <input
                                                                                                {...getInputProps()}
                                                                                            />
                                                                                            {!isDragActive && (
                                                                                                <span>
                                                                                                    Or
                                                                                                    you
                                                                                                    can{" "}
                                                                                                    <u>
                                                                                                        browse
                                                                                                    </u>{" "}
                                                                                                    your
                                                                                                    files
                                                                                                </span>
                                                                                            )}
                                                                                            {isDragActive &&
                                                                                                isDragReject &&
                                                                                                "Drop it like it's hot!"}
                                                                                            {isDragActive &&
                                                                                                !isDragReject &&
                                                                                                "File type not accepted, sorry!"}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </Dropzone>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 10,
                                                                            color: "#999bb5",
                                                                        }}
                                                                    >
                                                                        Accepted
                                                                        Formats:
                                                                        pdf,
                                                                        doc,
                                                                        jpg,
                                                                        png,
                                                                        xsl,
                                                                        zip, esp
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Permitted>
                                        </Grid>
                                    </Grid>
                                    <Permitted one="edit_brand_guidelines_content">
                                        <Grid item xs sm md lg xl>
                                            <Button
                                                style={{
                                                    color: "#af001e",
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    textTransform: "uppercase",
                                                }}
                                                onClick={() => resetField()}
                                            >
                                                Reset Overrides
                                            </Button>
                                        </Grid>
                                    </Permitted>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Permitted>
        </Grid>
    );
};

export default Brand;
