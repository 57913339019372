import update from 'immutability-helper';

const initialState = {
    users: {},
    markets: {},
    meta: {},
    roles: {},
    loading: false,
    permissions: [],
    communications: [],
    triggers: [],
    emailTemplate: [],
};

const adminReducer = (state = initialState, action) =>
{
    if (action.type === 'admin/DESTROY') {
        return initialState;
    }

    if (action.type === 'admin/SET_LOADING') {
        return update(state, {
            loading: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_MARKETS') {
        return update(state, {
            markets: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_USERS') {
        return update(state, {
            users: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_ROLES') {
        return update(state, {
            roles: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_PERMISSIONS') {
        return update(state, {
            permissions: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_COMMUNICATIONS') {
        return update(state, {
            communications: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_TRIGGERS') {
        return update(state, {
            triggers: {$set: action.payload},
        });
    }

    if (action.type === 'admin/SET_ALL_EMAIL_TEMPLATES') {
        return update(state, {
            emailTemplate: {$set: action.payload},
        });
    }

    return state;
};

export default adminReducer;
