import React from 'react';

const ImagePreviewIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
                <path fill="#3E3F60" fillRule="evenodd" d="M22.5 1.567v21h-21v-21h21zm-8.252 10.872l-6.19 6.88-1.115-1.004 2.72-3.024L7.5 13.128 3 17.627v3.44h18v-1.11l-6.752-7.518zM21 3.067H3v12.438l4.5-4.499 3.168 3.168 3.583-3.979L21 17.712V3.067zm-3.75.75c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 1.5c-.828 0-1.5.671-1.5 1.5 0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5c0-.829-.672-1.5-1.5-1.5z"/>
            </svg>
        </>
    );
};

export default ImagePreviewIcon;
