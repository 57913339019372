import React from 'react';
import Grid from "@material-ui/core/Grid";
import {CSVReader} from "react-papaparse";
import Typography from "@material-ui/core/Typography";
import UploadButton from "~/Components/UploadButton";
import {connect} from "react-redux";
import Button from "~/Components/Button";

const buttonRef = React.createRef();
const InventoryUpload = (props) => {

    const {
        fileDropped,
        dispatch,
        step,
    } = props;

    const handleOpenDialog = (e) => {
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const deleteFile = (e) => {
        dispatch({type: 'inventory/SET_STAFF', payload: []});
        dispatch({type: 'inventory/SET_PLAYERS', payload: []});
        dispatch({type: 'inventory/SET_ASSETS', payload: []});

        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
        }
    }

    const downloadTemplate = () => {
        if (step === 1) {
            return '/files/inventory/asset_template.csv';
        }

        return '/files/inventory/members_template.csv';
    }

    return (
        <Grid container direction="row" spacing={4}>
            <Grid item md={12}>
                <CSVReader
                    ref={buttonRef}
                    onDrop={fileDropped}
                    onError={() => {
                    }}
                    noClick
                    noDrag
                    config={{header: true}}
                    noProgressBar={true}
                    style={{
                        progressBar: {
                            display: 'none'
                        }
                    }}
                    onRemoveFile={() => {
                    }}
                >
                    {({file}) => (

                        <Grid container direction="row">
                            <Grid item md={7} className="upload-wrapper-left">
                                <Typography className="light-grey-labels">
                                    UPLOAD CSV
                                </Typography>
                                <UploadButton handleOpenDialog={handleOpenDialog}/>
                                <a href={`/files/inventory/${step === 1 ? 'members' : 'assets'}_template.csv`} download className="button download-template">Download template</a>
                            </Grid>
                            <Grid item md={5} className="upload-wrapper-right">
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography className="light-grey-labels">
                                            UPLOADED DOCUMENTS
                                        </Typography>

                                    </Grid>

                                    <Grid item>
                                        <div> {file
                                            ?
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Grid container direction="row" justify="space-between">
                                                        <Grid item>{file.name}</Grid>
                                                        <Grid item><Typography className="delete-row" onClick={deleteFile}>X</Typography></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item><Typography className="progress-bar">&nbsp;</Typography></Grid>
                                            </Grid>


                                            :
                                            <span className="no-uploads">No uploads yet</span>
                                        }
                                        </div>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </CSVReader>
            </Grid>
        </Grid>
    );
};

export default connect(null, null)(InventoryUpload);
