import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import './index.scss';
import Button from "../../Components/Button";
import PartnersAutocomplete from "~/MediaLibrary/Manage/PartnersAutocomplete";

const Dialog = (props) => {
    const [name, setName] = useState('');
    const [selectedPartners, setSelectedPartners] = useState([]);

    useEffect(() => {
        let initName = props.fileName ? props.fileName : '';
        setName(initName);
    }, []);

    const handleInput = (event) => {
        setName(event.target.value)
    };

    const dialogContent = (mode = '') => {
        if (mode === 'edit' || mode === 'create_folder') {
            return (
                <input className="border-input" onChange={handleInput} required="required" value={name} />
            );
        }

        return (
            <span>{name}</span>
        )
    }

    return (
        <section className="dialog">
            <Row>
                <Col lg="12">
                    <div className="edit-container">
                        <Row className="edit-modal">
                            <div className="pt-6">
                                <h2 className="truncate mb-4">{props.dialogTitle}</h2>
                                <div className="mb-4">
                                    {dialogContent(props.mode)}
                                </div>
                                {props.mode === 'create_folder' &&
                                <>
                                    <div>Choose Partners</div>
                                    <div className="mb-4">
                                        <PartnersAutocomplete setFieldValue={(values) => {
                                            setSelectedPartners(values);
                                        }}/>
                                    </div>
                                </>
                                }
                                <div className="flex justify-end">
                                    <Button onClick={() => props.closeModal()} class="noFill download font-size-14 no-radius" text="Cancel" />
                                    <Button
                                        onClick={() => {
                                            if (props.mode === 'create_folder') {
                                                props.handleItem(name, selectedPartners);
                                            } else {
                                                props.handleItem(name);
                                            }
                                        }}
                                        class="font-size-14 color-red download"
                                        text={props.btnTxt} />
                                </div>
                            </div>
                        </Row>

                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Dialog;
