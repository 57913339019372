import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

const UpdateNoticeDialog = (props) => {

    const useStyles = makeStyles((theme) => ({
        submitButton: {
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            color: 'white',
            height: 50,
            paddingLeft: 40,
            paddingRight: 40,
            fontWeight: 'bold',
            borderRadius: 0,
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        cancelButton: {
            fontWeight: 'bold',
            color: '#3e3f60',
            height: 50,
        }
    }));

    const classes = useStyles();

    return (
        <Dialog
            PaperProps={{ style: { minWidth: 390, borderRadius: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: 25, paddingTop: 10 } }}
            open={props.openUpdateNoteDialog}
            keepMounted
            disableBackdropClick
            onClose={props.handleCloseDialog}>
            <DialogTitle>
                <span style={{ fontSize: 30, fontWeight: 'bolder', color: '#3e3f60' }}>Update note</span>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        notice: props.editNoteData ? props.editNoteData.notice : ''
                    }}
                    enableReinitialize
                    validationSchema={
                        Yup.object().shape({
                            notice: Yup.string()
                                .min(5)
                                .max(255)
                                .required('required'),
                        })
                    }
                    onSubmit={(values) => {
                        props.handleEditNote(values);
                    }}>
                    {({ touched, errors, resetForm }) => (
                        <Form>
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <Field
                                        name="notice"
                                        type="text">
                                        {({ field }) => (
                                            <TextField
                                                style={{ borderRadius: 0 }}
                                                {...field}
                                                multiline
                                                fullWidth
                                                placeholder="Add new note"
                                                maxLength="255"
                                                variant="outlined"
                                                error={touched.notice && !!errors.notice ? true : false}
                                                helperText={touched.notice && !!errors.notice ? errors.notice : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Button type="submit" className={classes.submitButton}>Create</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className={classes.cancelButton} onClick={() => { resetForm(), props.handleCloseDialog() }}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default connect()(UpdateNoticeDialog);
