import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography, Divider} from '@material-ui/core';
import { PageMenu, LIONS_TOUR_2025_MENU } from '../../Components/PageMenu';
import EmailCard from './EmailCard';
import { ltau, britishIrishLions, rugbyAustralia } from './emailList.constants';

const KeyContact2025 = (props) => {

    const useStyles = makeStyles((theme) => ({
        pageTitle: {
            textTransform: 'capitalize',
            fontWeight: 600,
            color: '#3e3f60',
            fontSize: 40,
        },
        sectionTitle: {
            color: '#af001e', 
            textTransform: 'uppercase', 
            fontSize: 20, 
            fontWeight: 600, 
            marginTop: '3em', 
            marginBottom: '2em',
        }
    }));  

    const classes = useStyles();

    return (
        <section>
            <Grid container direction="column">
                <Grid item>
                    <h1 className={classes.pageTitle}>
                        Lions Series 2025
                    </h1>
                </Grid>
            </Grid>
            <PageMenu menuItems={LIONS_TOUR_2025_MENU}/>
            <Divider />
            <Typography variant="h5" className={classes.sectionTitle}>
                Key Contacts
            </Typography>
            <Grid container spacing={5}>
                <EmailCard title="LTAU (WASSERMAN)" items={ltau}/>
                <EmailCard title="British & Irish Lions" items={britishIrishLions}/>
                <EmailCard title="RUGBY AUSTRALIA" items={rugbyAustralia}/>               
            </Grid>
        </section>
    );
}

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
});

export default connect(mapStateToProps)(KeyContact2025);
