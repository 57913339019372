import React from 'react';
import Button from "~/Components/Button";
import './FooterCommands.scss';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import PermissionChecker from "~/Permissions/PermissionChecker";


const FooterCommands = (props) => {

    const {
        users,
        selectAll,
        selectedUsers,
        selectAllUsers,
        deselectAllUsers,
        deleteSelectedUsers,
        changeRoleSelectedUsers,
        changePartnerSelectedUsers,
        massDeleteDialog,
        massPartnerDialog,
        massRoleDialog,
        handleToggleMassDeleteDialog,
        handleToggleMassRoleDialog,
        handleToggleMassPartnerDialog,
        selectAllCheckbox,
        roles,
        partners,
        loggedInUser
    } = props;

    const checker = new PermissionChecker(loggedInUser);

    return (
        <>
            <Grid container direction="column" className="users selected-footer">
                <Grid container direction="row" alignItems="center" justify="space-between" spacing={2}>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <Checkbox
                                    indeterminate={Object.keys(selectedUsers).length !== users.length}
                                    checked={selectAllCheckbox && Object.keys(selectedUsers).length === users.length}
                                    color="primary"
                                    onClick={() => selectAll()} />
                            </Grid>
                            <Grid item>
                                {Object.keys(selectedUsers).length} USERS SELECTED
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="select-deselect">
                        <Grid container direction="row" spacing={1} justify="space-between">
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Typography className="footer-labels actions-label fullWidth">QUICK ACTIONS</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Button class="btn-grey footer-downloads noMarg fullWidth" text="DESELECT USERS" onClick={() => { deselectAllUsers() }} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Button class="btn-grey footer-downloads noMarg fullWidth" text="SELECT ALL" onClick={() => { selectAllUsers() }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{ alignSelf: 'baseline' }}>
                        <Grid container direction="row" spacing={1}>
                            {
                                checker.some([
                                    "edit_existing_user",
                                ]) &&
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Button class="btn-grey footer-downloads noMarg reset-padding fullWidth" text="CHANGE ROLE" onClick={() => { handleToggleMassRoleDialog() }} />
                                </Grid>
                            }
                            {
                                checker.some([
                                    "edit_existing_user",
                                ]) &&
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Button class="btn-grey footer-downloads noMarg reset-padding fullWidth" text="CHANGE PARTNER" onClick={() => { handleToggleMassPartnerDialog() }} />
                                </Grid>
                            }
                            {
                                checker.some([
                                    "mass_delete_users"
                                ]) &&
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Button class="noRadius noMarg border-0 delete-selected reset-padding fullWidth" text="DELETE SELECTED USERS" onClick={() => { handleToggleMassDeleteDialog() }} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default FooterCommands;