import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Card,
    CardContent,    
} from '@material-ui/core';
import { PageMenu, PARTNER_INFORMATION_MENU } from '../Components/PageMenu';

const Activation  = (props) => {

    const useStyles = makeStyles((theme) => ({
        section: {
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60
        },
        textPadding: {
            paddingTop: 20
        },
        sectionTitle: {
            color: '#af001e', 
            textTransform: 'uppercase', 
            fontSize: 20, 
            fontWeight: 600,
            paddingBottom: '1em'
        },
        subtitle: {
            color: '#999bb5', 
            textTransform: 'uppercase', 
            fontSize: 14, 
            fontWeight: 'bolder',
            paddingTop: 20,
            paddingBottom: 10,
        },
        phase: {
            '& ol': {
                listStyleType: 'disc',
                margin: 0,
            },

            '& ol li': {
                    paddingTop: 15,
                    fontSize: 16,
            },
                
            '& ol li::marker': {
                color: '#af001e',
                fontWeight: 'bold',
                display: 'block',
                width: '1em',
                marginLeft: '-1em',
            },
                
        }
    }));

    const classes = useStyles();
    
    return (
        <section className={classes.section}>
            <Grid container direction="column">
                <Grid item>
                    <h1 className="content-title">Partner Information</h1>
                </Grid>
            </Grid>                

            <PageMenu menuItems={PARTNER_INFORMATION_MENU}/>
            <Divider />
            <Grid className={classes.gridPadding}>
                <Typography variant="h5"className={classes.sectionTitle}>Activation</Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    If you have the rights to an activation space or product sampling within the stadia 
                    precinct, please discuss the proposed activation with your Partner Manager.
                </Typography>
                <Grid container spacing={2} >
                    <Grid item md={6}>
                        <Typography variant="body1" className={classes.subtitle}>
                            Phase 1
                        </Typography>
                        <Card style={{ borderRadius: 0 }}>
                            <CardContent style={{ padding: 30 }}>
                                <Typography variant="body1">
                                    An application form will be provided on the portal, alongside a deadline for 
                                    submission. The application form will include the following information to be 
                                    completed:
                            </Typography>
                            <Grid item className={classes.phase}>                                    
                                <ol>
                                    <li>Which matches the Partner would like to activate at (if any)</li>
                                    <li>An overview of the proposed activity</li>
                                    <li>Space required to implement the activity</li>
                                </ol>
                            </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="body1" className={classes.subtitle}>
                            Phase 2
                        </Typography>
                        <Card style={{ borderRadius: 0 }}>
                            <CardContent style={{ padding: 30 }}>
                                <Typography variant="body1">
                                    All activities are subject to approval from LTAU, as well as the local councils. 
                                    Following review of the initial application and consultation with LTAU on the 
                                    proposed activation plans, Partners will be required to submit the following further 
                                    information at a later date:
                                </Typography>
                                <Grid item className={classes.phase}>                              
                                    <ol>
                                        <li>Key contacts</li>
                                        <li>Schedule of works including proposed build schedule and de-rig plan</li>
                                        <li>Detailed area plan and material specifications</li>
                                        <li>Confirmation of power requirements</li>
                                        <li>Risk Assessment</li>
                                        <li>Method Statement</li>
                                    </ol>
                                </Grid>
                                <Typography variant="body1" style={{ paddingTop: 40 }}>
                                    LTAU will also require each Partner to complete a code of conduct, to cover all 
                                    subcontracted activity on site.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            
        </section>
    );
}

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
});

export default connect(mapStateToProps)(Activation);
