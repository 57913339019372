import update from 'immutability-helper';

const initialState = {
    view: 'grid',
    perPage: 15,
    orderBy: {title: 'Name', queryVal: 'name'},
    partner: {}
};

const assetsReducer = (state = initialState, action) =>
{
    if (action.type === 'creative_assets/SET_PARTNER') {
        return update(state, {
            partner: {$set: action.payload.data},
        });
    }

    if (action.type === 'creative_assets/SET_VIEW') {
        return update(state, {
            view: {$set: action.view},
        });
    }

    if (action.type === 'creative_assets/SET_PER_PAGE') {
        return update(state, {
            perPage: {$set: action.perPage},
        });
    }

    if (action.type === 'creative_assets/SET_ORDER_BY') {
        return update(state, {
            orderBy: {$set: action.orderBy},
        });
    }

    return state;
};

export default assetsReducer;
