import update from 'immutability-helper';

const initialState = {
    requests: [],
    index: 0,
    noticeboard: [],
    status_counts: {},
    since_last_visit: []
};

const homepageReducer = (state = initialState, action) =>
{
    if (action.type === 'DESTROY') {
        return initialState;
    }

    if (action.type === 'homepage/SET_REQUESTS') {
        return update(state, {
            requests: {$set: action.payload},
        });
    }

    if (action.type === 'homepage/SET_NOTICEBOARD') {
        return update(state, {
            noticeboard: {$set: action.payload},
        });
    }

    if (action.type === 'homepage/SET_REQUESTS_STATUS_COUNT') {
        return update(state, {
            status_counts: {$set: action.payload},
        });
    }

    if (action.type === 'homepage/SET_SINCE_LAST_VISIT') {
        return update(state, {
            since_last_visit: {$set: action.payload},
        });
    }

    return state;
};

export default homepageReducer;
