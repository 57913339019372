import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { setCmsBlocks } from "~/helpers/cmsHelper";
import PermissionChecker from "~/Permissions/PermissionChecker";

import "./AdminPartnersDropdown.scss";
import Button from "~/Components/Button";
import { withRouter } from "react-router";

const AdminPartnersDropdown = ({
    partners,
    user,
    dispatch,
    history,
    tabId,
    subTab,
}) => {
    const [currentPartner, setCurrentPartner] = useState(null);

    let checker = new PermissionChecker(user);

    useEffect(() => {
        if (partners && partners[0]) {
            setCurrentPartner(partners[0]);
        }
    }, [partners]);

    useEffect(() => {
        dispatch({
            type: "user/SET_CMS_BLOCKS",
            payload: setCmsBlocks(currentPartner),
        });
    }, [currentPartner]);

    const setNewCurrentPartner = (partner) => {
        setCurrentPartner(partner);
        dispatch({
            type: "user/SET_CMS_BLOCKS",
            payload: setCmsBlocks(partner),
        });
    };

    const goToContentManagement = () => {
        history.push({
            pathname: "/admin/content-management/" + currentPartner.id,
            state: { tabId, subTab },
        });
    };

    if (!checker.one("partner_management")) {
        return null;
    }

    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            className="admin-partner-dropdown"
            spacing={1}
        >
            <Grid item>
                <Typography className="preview">Preview: </Typography>
            </Grid>
            <Grid item>
                <DropdownButton
                    title={currentPartner?.name || "No Partners Assigned"}
                    className="dropdown-btn"
                >
                    {partners &&
                        partners.map((partner, key) => {
                            return (
                                <Dropdown.Item
                                    key={key}
                                    onClick={() =>
                                        setNewCurrentPartner(partner)
                                    }
                                >
                                    {partner.name}
                                </Dropdown.Item>
                            );
                        })}
                </DropdownButton>
            </Grid>
            <Grid item>
                <Button
                    class="manage-content color-red noMarg noRadius"
                    text="MANAGE CONTENT"
                    onClick={goToContentManagement}
                />
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state) => ({
    partners: state.user.user.partners,
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(AdminPartnersDropdown));
