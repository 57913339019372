import React, {Fragment, useEffect, useState} from "react";
import {DropdownButton, Dropdown, Row, Col} from "react-bootstrap";
import "./index.scss";

import {connect} from "react-redux";
import SearchInput from "~/Components/SearchInput";
import GridIcon from "~/Components/Icons/GridIcon";
import ListIcon from "~/Components/Icons/ListIcon";
import __ from 'lodash';


const Filter = (props) => {
    const [viewActive, setViewActive] = useState();
    const setItemsPerPage = (perPage) => {
        props.dispatch(props.setPerPage(perPage));
    }

    useEffect(() => {
        setViewActive(props.view);
    }, [props.view]);

    const searchHandler = (searchValue) => {
        if (searchValue.length > 1 || searchValue.length === 0) {
            props.setSearchName(searchValue);
        }
    }
    return (
        <div className="filters d-flex">
            <div className="pright-40">
                <SearchInput searchHandler={searchHandler}/>
            </div>
            <div className="pright-40 d-flex">
                <div className="filter-label">SORT:</div>
                <DropdownButton title={props.orderBy.title}>
                    <Dropdown.Item onClick={() => props.dispatch(props.setOrderBy({title: 'Name', queryVal: 'name'}))}>
                        Name
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.dispatch(props.setOrderBy({title: 'Extension', queryVal: 'ext'}))}>
                        Extension
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.dispatch(props.setOrderBy({title: 'Size', queryVal: 'size'}))}>
                        Size
                    </Dropdown.Item>
                </DropdownButton>
            </div>
            <div className="pright-40 d-flex">
                <div className="filter-label">SHOW:</div>
                <DropdownButton title={props.perPage}>
                    <Dropdown.Item onClick={() => setItemsPerPage(5)}>5</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>10</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(15)}>15</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>20</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(25)}>25</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>30</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(35)}>35</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(40)}>40</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(45)}>45</Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(50)}>50</Dropdown.Item>
                </DropdownButton>
            </div>

            {props.showGridToggle &&
                <div className="view-toggle d-flex">
                    <div className="filter-label">VIEW:</div>
                    <div className={`grid-list-icons-wrapper ${viewActive === 'grid' ? ' active' : ''}`} onClick={() => props.dispatch(props.setView('grid'))}><GridIcon/></div>
                    <div className={`grid-list-icons-wrapper ${viewActive === 'list' ? ' active' : ''}`} onClick={() => props.dispatch(props.setView('list'))}><ListIcon /></div>
                </div>
            }

        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(null, mapDispatchToProps)(Filter)
