import React from 'react';
import './index.scss';
import Button from "~/Components/Button";
import {withRouter} from "react-router-dom";

const PageNotFound = ({history}) => {
    return (
        <section className="not-found-wrapper">
            <div className="not-found">
                <div className="text-wrapper">
                    <div className="title">Page Not Found</div>
                    <div className="message">Sorry! It seems the page you are looking for does not exist.</div>
                    <div className="button-wrapper">
                        <Button class="fullWidth color-red" text="Return to Dashboard" onClick={() => history.push('/dashboard')}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withRouter(PageNotFound);
