import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';

import './index.scss';
import Button from "~/Components/Button";
import MaterialIcon from "material-icons-react";
import LibraryApi from "~/services/api/library";
import Upload from "~/Components/Upload";
import PartnersAutocomplete from "~/MediaLibrary/Manage/PartnersAutocomplete";

const Edit = (props) => {
    const {
        file,
        sendFiles,
        setNameShort,
        handleItem,
        fileName,
        closeModal,
        dialogTitle,
        mode,
        btnTxt,
        templateType,
        accept,
    } = props
    const [name, setName] = useState(fileName);
    const [id, setId] = useState();
    const [relatedItems, setRelatedItems] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);

    useEffect(() => {

        let initName = fileName ? fileName : '';
        setName(initName);
        setId(file.id);

        getRelatedItems();


   }, []);
    const handleInput = (event) => {
        setName(event.target.value)
    };

    const deleteRelatedItem = (id, key) => {
        LibraryApi.deleteFile(id).then(() => {
            getRelatedItems();
        });
    }

    const getRelatedItems = () => {
        if (templateType === 'grid_list' || templateType === 'combo') {
            axios.get('/api/uploads/' + file.id).then((resp) => {
                if (file.ext !== 'Folder') {
                    setRelatedItems(resp.data.data.siblings);
                }

                setSelectedPartners(resp.data.data.partners);
            }).catch((error) => {
                console.log(error.response);
            })
        }
    }

    const updateUpload = () => {
        handleItem(name, id, selectedPartners);
    }

    const dialogContent = (mode = '') => {
        if (mode === 'edit' || mode === 'create_folder') {
            return (
                <input className="border-input" onChange={handleInput} required="required" value={name}/>
            );
        }

        return (
            <span>{name}</span>
        )
    }

    return (
        <section className="dialog">
            <Row>
                <Col lg="12">
                    <div className="edit-container">
                        <div className="edit-modal">
                            <div className="pt-6">
                                <h2 className="truncate mb-4">{dialogTitle}</h2>
                                <div className="mb-4">
                                    {dialogContent(mode)}
                                </div>
                                {(templateType === 'grid_list' || templateType === 'combo') &&
                                    <>
                                        <div>Choose Partners</div>
                                        <div className="mb-4">
                                            <PartnersAutocomplete initialValues={selectedPartners}
                                                                  setFieldValue={(values) => {
                                                                      setSelectedPartners(values);
                                                                  }}/>
                                        </div>
                                    </>
                                }
                                {file.ext !== 'Folder' && (templateType === 'grid_list' || templateType === 'combo') &&
                                    <>
                                        <div>Add related files</div>
                                        <div className="mb-4">
                                            <Upload sendFiles={(files) => sendFiles({files}, getRelatedItems)}
                                                    accept={accept}/>
                                        </div>
                                    </>
                                }
                                <div className="related-wrapper mb-4">
                                    {
                                        relatedItems.map((item, key) => {
                                            if (!item.main_asset) {
                                                return (
                                                    <div className="d-flex justify-content-between pt-1 pb-1" key={key}>
                                                        <div title={item.name}>{setNameShort(item.name, 48)}.{item.ext}</div>
                                                        <div>
                                                        <span onClick={() => deleteRelatedItem(item.id, key)}
                                                              className="delete-wrapper">
                                                            <MaterialIcon icon="delete"/>
                                                        </span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="d-flex justify-content-between">
                                    <Button onClick={() => closeModal(true)}
                                            class="noFill mr-3 download noMarg font-size-14 no-radius" text="Cancel"/>
                                    <Button
                                        onClick={updateUpload}
                                        class="font-size-14 color-red noMarg download"
                                        text={btnTxt}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Edit;
