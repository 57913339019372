import React from 'react';
import {Row, Col} from "react-bootstrap";
import DownloadIcon from "~/Components/Icons/DownloadIcon";
import InfoIcon from "~/Components/Icons/InfoIcon";
import CheckBoxIcon from "~/Components/Icons/CheckBoxIcon";
import {setShowInfo} from "~/Media/actions";
import {connect} from "react-redux";

const Commands = ({file, setSelectedDownloadItems, selectedDownloadItem, setLoaded, dispatch}) => {
    return (
        <Row className="commands">
            <div className="overlay"/>
            <Col lg={9} md={9}>
                <CheckBoxIcon
                    setSelectedDownloadItems={setSelectedDownloadItems}
                    selectedDownloadItem={selectedDownloadItem}
                    item={file}
                />
            </Col>
            <Col lg={2} md={2} className="p-0">
                <InfoIcon setShowInfo={() => dispatch(setShowInfo(file.id))}/>
            </Col>
            <Col lg={1} md={1} className="p-0">
                <DownloadIcon file={file} setLoaded={setLoaded}/>
            </Col>
        </Row>
    );
};

export default connect()(Commands);
