export const ltau = [
    {
        name: 'Charlie McEwen',
        title: 'CEO Rugby',
        email: 'charlie.mccewen@teamwass.com',
        phone: '+44 7385 023 611',
    },
    {
        name: 'Jonny Kew',
        title: 'Partnerships Director',
        email: 'jonny.kew@lionstour2025.com',
        phone: '+44 7974 864 901',
    },
    {
        name: 'Jayne Carmichael',
        title: 'Commercial Project & Operations',
        email: 'jayne.carmichael@lionstour2025.com',
        phone: '44 7552 289 245',
    },
    {
        name: 'Meg Batchelor',
        title: 'Partnership Executive',
        email: 'meg.batchelor@lionstour2025.com',
        phone: '+44 7815 028 759',
    }, 
    {
        name: 'Connor Burrows',
        title: 'Partnership & Operations Manager',
        email: 'connor.burrows@lionstour2025.com',
        phone: '+44 7723 986 828',
    }
];   

export const britishIrishLions = [
    {
        name: 'Tom Halsey',
        title: 'Chief Commercial Officer',
        email: 'tom@lionsrugby.com',
    },
    {
        name: 'Emma Rogers',
        title: 'Commercial Operations Manager',
        email: 'emma@lionsrugby.com',
    },
    {
        name: 'Shane Whelan',
        title: 'Director of Digital, Marketing & Communications',
        email: 'shane@lionsrugby.com',
    },
    {
        name: 'Lynn Cleckner',
        title: 'Head of Marketing',
        email: 'lynn@lionsrugby.com',
    },
    {
        name: 'Joe Ogden',
        title: 'Digital Manager',
        email: 'joe@lionsrugby.com',
    },
    {
        name: 'Barry Aherne',
        title: 'Head of Events',
        email: 'barry@lionsrugby.com',
    },
    {
        name: 'Alan O’Connor',
        title: 'Finance Manager',
        email: 'alan@lionsrugby.com',
    },
];

export const rugbyAustralia = [
    {
        name: 'James Durbin',
        title: 'Chief Commercial Officer',
        email: 'james.durbin@rugby.com.au',
    },
    {
        name: 'David Hebblewhite',
        title: 'Head of Commercial',
        email: 'david.hebblewhite@rugby.com.au',
    },
];
