import React from 'react';

const SearchIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11">
                <path fill="#3E3F60" fillRule="evenodd" d="M4.063.063c2.243 0 4.062 1.818 4.062 4.062 0 .896-.29 1.725-.782 2.397l2.664 2.663-.884.884L6.46 7.405c-.672.492-1.501.783-2.397.783C1.818 8.188 0 6.368 0 4.125 0 1.881 1.819.063 4.063.063zm0 1.25c-1.554 0-2.813 1.259-2.813 2.812s1.26 2.813 2.813 2.813c1.553 0 2.812-1.26 2.812-2.813 0-1.553-1.26-2.812-2.813-2.812z"/>
            </svg>
        </>
    );
};

export default SearchIcon;
