import React from 'react';

const RadioSemiActive = () => {
    return (
        <>
            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Semi Active</title>
                <g id="Portal-Design---Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Semi-Active">
                        <circle id="Oval" fill="#AF001E" cx="6.6" cy="6.6" r="2.5"></circle>
                        <path d="M6.6,0 C2.95492065,0 0,2.95492065 0,6.6 C0,10.2450793 2.95492065,13.2 6.6,13.2 C10.2450793,13.2 13.2,10.2450793 13.2,6.6 C13.2,2.95492065 10.2450793,0 6.6,0 Z M6.6,2 C9.14050985,2 11.2,4.05949015 11.2,6.6 C11.2,9.14050985 9.14050985,11.2 6.6,11.2 C4.05949015,11.2 2,9.14050985 2,6.6 C2,4.05949015 4.05949015,2 6.6,2 Z" id="Oval" fill="#D8DAE6" fillRule="nonzero"></path>
                    </g>
                </g>
            </svg>
        </>
    );
};

export default RadioSemiActive;
