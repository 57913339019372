import React from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import Description from "~/BrandApprovalForm/Description";
import BrandForm from "./BrandForm";
import { showSuccessNotification } from '~/Notification/notificationActions';
import { connect } from "react-redux";
import './index.scss';
import Grid from "@material-ui/core/Grid";
import AdminPartnersDropdown from "~/Components/AdminPartnersDropdown/AdminPartnersDropdown";

import Permitted from '~/Permissions/Permitted';

const BrandApprovalForm = props => {

    const handleSubmit = (payload) => {

        return axios
            .post("/api/brand-request", payload)
            .then(res => {

                let id = res.data.data.id;
                props.history.push('/my-requests/' + id);

                props.dispatch(
                    showSuccessNotification(
                        'Your request has been submitted',
                        'We will review your request soon, and in the meantime, you can review your request here.'
                    )
                );

                return res;
            });
    };

    return (
        <>
            <section className="approvals bodyContent">
                <section className="library-header">
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <h1 className="content-title">Approvals</h1>
                        </Grid>
                        <Grid item>
                            <AdminPartnersDropdown tabId="4" subTab="1" />
                        </Grid>
                    </Grid>
                </section>
            </section>
            <section className="approvals-content">
                <div className="row m-0">
                    <div className="col-lg-4 col-md-12">
                        <Description />
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <Permitted one="submit_approval_request">
                            <BrandForm defaultRequest={{}}
                                submitButtonLabel="SUBMIT REQUEST"
                                onSubmit={handleSubmit.bind(this)} />
                        </Permitted>
                    </div>
                </div>
            </section>
        </>
    );
};

export default connect(null, null)(withRouter(BrandApprovalForm));
