import React from 'react';
import {MenuItem, Select} from "@material-ui/core";

const UsersFilter = ({filterCriteria, inputFilter, setFilterCriteria}) => {
    return (
        <Select
            value={filterCriteria}
            style={{
                boxShadow: '0 0 4px 0 rgba(62, 63, 96, 0.15)',
                borderRadius: 0,
                backgroundColor: 'white',
                border: 'none'
            }}
            onChange={(event) => {
                inputFilter(undefined, event.target.value);
                setFilterCriteria(event.target.value);
            }}
            placeholder="Name"
            variant="outlined"
            margin="dense">

            <MenuItem value={'first_name'} selected>First Name</MenuItem>
            <MenuItem value={'last_name'}>Last Name</MenuItem>
            <MenuItem value={'email'}>Email</MenuItem>
            <MenuItem value={'role'}>Role</MenuItem>

        </Select>
    );
};

export default UsersFilter;
