// import { getCommunications } from './commActions';
import { authHeader } from "~/helpers/authHeader";

export const fetchUserList = (page = 1) => {

    return (dispatch) => {
        let pageParam = `?page=${page}`;
        return fetch('/api/users' + pageParam, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'user/SET_USERS',
                    payload: data,
                });
            });
    };
};

export const filterUsersMultiple = (filterParams, page, perPage) => {
    return (dispatch) => {
        return fetch(`/api/users?first_name=${filterParams.firstname}&role=${filterParams.role}&email=${filterParams.email}&last_name=${filterParams.lastname}&user_id=${filterParams.uid}&perPage=${perPage}&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
        .then((res) => res.text())
        .then((text) => text.length ? JSON.parse(text) : [])
        .then((data) => {
                dispatch({
                    type: 'user/SET_USERS',
                    payload: data,
                });
            });
    };
};

export const addUser = (data) => {

    return (dispatch) => {

        return fetch('/api/users', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(fetchUserList())
                        .then(() => {
                            return true;
                        });
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};

export const fetchUserActivity = (id) => {

    return (dispatch) => {

        return fetch(`/api/activity-logs/${id}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader(),
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'user/SET_ACTIVITY_LOGS',
                    payload: data,
                });
            });
    };
};

export const addMultipleUsers = (data) => {

    return (dispatch) => {

        return fetch('/api/users/multi', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(fetchUserList())
                        .then(() => {
                            return true;
                        });
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};

export const updateUserAction = (data) => {

    return (dispatch) => {

        return fetch('/api/users/' + data.id, {
            method: 'PUT',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data.data)
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(fetchUserList())
                        .then(() => {
                            return true;
                        });
                };

                if (response.status === 422) {
                    return response.json()
                        .then((messages) => {
                            throw messages;
                        });
                };

                throw Error('Unknown error!');
            });
    };
};

export const deleteUser = (id) => {

    return (dispatch) => {

        return fetch('/api/users/' + id, {
            method: 'DELETE',
            credentials: 'include',
            headers: authHeader()
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(fetchUserList())
                        .then(() => {
                            return true;
                        });
                };

                throw Error('Unknown error!');
            });
    };
};


export const getRoles = (search, perPage, page) => {
    let searchParam = search ? `${search.criteria}=${search.input}` : '';
    let orderParam = search ? `orderBy=${search.criteria}` : '';
    let pageParam = `?${searchParam}&perPage=${perPage || ''}&page=${page || 1}&${orderParam}`;

    return (dispatch) => {

        return fetch('/api/roles' + pageParam, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'user/SET_ROLES',
                    payload: data,
                });
            });
    };
};

export const checkExistingEmail = (email) => {

    return (dispatch) => {
        return fetch(`/api/users?email=${email}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                return data.data;
            });
    };
};


export const filterUsers = (input, crieteria, perPage, page) => {

    return (dispatch) => {
        return fetch(`/api/users?${crieteria}=${input}&perPage=${perPage}&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'user/SET_USERS',
                    payload: data,
                });
            });
    };
};

export const massDeleteUsers = (payload) => {
    payload.append('_method', 'delete');
    return (dispatch) => {
        return axios.post(`/api/users/mass-delete`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(res => {
                return dispatch(fetchUserList())
                    .then(() => {
                        return true;
                    });
            })
    }
}

export const massAssignUsersToPartner = (partnerList, userList) => {

    return (dispatch) => {

        return fetch(`/api/partners/mass-user-assigment`, {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({
                "user_ids": userList,
                "partner_ids": partnerList
            })
        })
            .then((response) => {

                if (response.ok) {
                    dispatch(fetchUserList());
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};

export const massAssignUsersToRole = (roleId, userList) => {

    return (dispatch) => {

        return fetch(`/api/roles/${roleId}/attach-users`, {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({ "user_ids": userList })
        })
            .then((response) => {

                if (response.ok) {
                    dispatch(fetchUserList());
                    return response.ok;
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};

export const setSelectedUsers = (userList) => {

    return (dispatch) => {
        dispatch({ type: 'user/SET_SELECTED_USERS', payload: { ...userList } });
    };
};
