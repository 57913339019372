import MaterialIcon from "material-icons-react";
import Dropzone from "react-dropzone";
import React from "react";
import './index.scss';

const UploadButton = (props) => {
    return (
        <div className="library-document" onClick={props.handleOpenDialog}>
            <div className="fileupload">
                <div className="upload-icon">
                    <img src="/images/icons/SVG/Upload-red.svg" alt="upload"/>
                </div>
                <h4 className="upload-title">
                    Drag & Drop
                </h4>
                <div className="form-group">

                    <div className="dropZone">
                        <span>Or you can <u>browse</u> your files</span>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default UploadButton;
