import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {connect} from "react-redux";

const useStyle = makeStyles((theme) => ({
    filterInputs: {
        marginLeft: 10,
        '&.MuiOutlinedInput-root': {
            borderRadius: 0,
        }
    },
    inputBorder: {
        borderRadius: 0,
        minWidth: 100,
        '&&&.outlined': {
            borderRadius: 0,
        }
    },
    filterLabel: {
        fontSize: 12,
        fontFamily: theme.bold,
        color: '#999bb5',
        padding: '20px 0',
    }
}));

const InventoryFilter = ({handleFilterChange, selectedPartner, selectedRequestType}) => {
    const classes = useStyle();
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        axios.get('/api/partners').then(res => {
            setPartners(res.data.data);
        });
    },[]);

    return (
        <Grid container direction="row" className="inventory-filters">
           <Grid item>
               <Typography classes={{root: classes.filterLabel}}>FILTER ASSETS</Typography>
           </Grid>
           <Grid item className="inputs-select">
               <Select
                   displayEmpty
                   classes={{root: classes.inputBorder}}
                   variant="outlined"
                   value={selectedPartner || ''}
                   onChange={(event) => handleFilterChange('partner', event.target.value)}
               >
                   <MenuItem value="">All</MenuItem>
                   {partners.map((partner, key) => {
                       return (
                           <MenuItem key={key}
                                     value={partner.id}>{partner.name}</MenuItem>
                       )
                   })
                   }

               </Select>
           </Grid>
           <Grid item className="inputs-select">
               <Select
                   displayEmpty
                   classes={{outlined: classes.inputBorder, root: classes.inputBorder}}
                   variant="outlined"
                   value={selectedRequestType || ''}
                   onChange={(event) => handleFilterChange('requestTyoe', event.target.value)}
               >
                   <MenuItem value="">All</MenuItem>
                   <MenuItem value="commercial appearance">Commercial Appearance</MenuItem>
                   <MenuItem value="partner event">Partner Event</MenuItem>


               </Select>
           </Grid>
        </Grid>
    );
};

export default connect(null, null)(InventoryFilter);
