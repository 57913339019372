import React, { useState } from 'react';
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import {
    makeStyles,
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    Drawer,
    Divider
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { checkExistingEmail } from '../../userActions';
import BeatLoader from 'react-spinners/BeatLoader';
import __ from 'lodash';
import * as Yup from "yup";


const useStyles = makeStyles((theme) => ({
    section: {
        paddingLeft: 43,
    },
    indicator: {
        color: '#af001e'
    },
    tourimg: {
        maxWidth: '-webkit-fill-available'
    },
    table: {
        minWidth: 650,
    },
    gridPadding: {
        paddingTop: 60,
        paddingBottom: 60
    },
    textPadding: {
        paddingTop: 20
    },
    arrowIcon: {
        width: 29,
        height: 29,
        "&:hover": {
            backgroundColor: '#af001eA5',
        }
    },
    tabItem: {
        color: '#af001e',
        fontWeight: 'bold'
    },
    tabLink: {
        borderBottom: '2px solid #af001e',
    },
    tabItemBlack: {
        color: 'black'
    },
    newUserButton: {
        borderRadius: 0,
        backgroundColor: '#af001e',
        color: 'white',
        "&:hover": {
            backgroundColor: '#af001eA5',
        }
    },
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: '#af001e'
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
    },
    activeTab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
        color: '#af001e',
        fontWeight: 'bold'
    },
    titlePadding: {
        paddingTop: '2rem'
    },
    test: {
        width: 500
    },
    createUserPadding: {
        padding: 20,
        minWidth: 400
    },
    closeButton: {
        cursor: 'pointer'
    },
    createUserButton: {
        backgroundColor: '#af001e',
        fontWeight: 600,
        color: 'white',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001eA5',
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: '#af001e80'
        }
    },
    cancelUserButton: {
        backgroundColor: '#d8dae6',
        fontWeight: 600,
        color: '#3e3f60',
        borderRadius: 0
    },
    subtitle: {
        color: '#af001e'
    },
    dividerLink: {
        backgroundColor: '#af001e'
    },
    dialog: {
        borderRadius: 0,
        maxWidth: 490
    },
    timelineItem: {
        '&&&:before': {
            display: 'none',
        },
    },
    activityLabel: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#999bb5',
    },
    activityTime: {
        fontSize: '10px',
        color: '#3e3f60',
    },
    activityText: {
        fontSize: '14px',
        color: '#3e3f60',
    },
    paginationWrapper: {
        marginTop: '20px',
    },
    animatedWrapper: {
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        width: '100%',
    },
}));

const CreateUser = (props) => {

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        role: '',
        partner_ids: []
    };

    const RequestSchema = Yup.object().shape({
        first_name: Yup.string()
            .max(300)
            .required("Required"),
        last_name: Yup.string()
            .max(300)
            .required("Required"),
        email: Yup.string()
            .max(300)
            .email()
            .required("Required"),
        phone: Yup.string()
            .max(255),
        password: Yup.string()
            .max(30)
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
                "Minimum 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
            )
            .required("Required"),
        role: Yup.string()
            .min(1)
            .required("Required"),
        partner_ids: Yup.array()
            .max(3)
            .optional(),
    });

    const classes = useStyles();
    const {
        createUser,
        toggleCreateUser,
        handleSubmit,
        setCurrentSidebar,
        loading,
        partners,
        roles
    } = props;

    const [emailUnique, setEmailUnique] = useState([]);

    const inputFilter = __.debounce((email) => {
        props.dispatch(checkExistingEmail(email)).then((data) => {
            setEmailUnique(data);
        })
    }, 300);

    return (
        <Drawer
            disableBackdropClick
            BackdropProps={{ invisible: true }}
            anchor="right" open={createUser}
            onClose={toggleCreateUser}
            classes={{ paper: classes.createUserPadding }}>
            <Formik
                validationSchema={RequestSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}>
                {({ values, touched, errors, isValid }) => (
                    <Form>
                        <Grid container direction="column" spacing={3} ref={setCurrentSidebar}>
                            <Grid item>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Typography variant="h5">Create new user</Typography>
                                    <Close onClick={toggleCreateUser} className={classes.closeButton} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.subtitle}>User account</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item md>
                                        <Field name="first_name">
                                            {({ field }) => (
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography>First name</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            {...field}
                                                            fullWidth
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: 0,
                                                                    borderColor: '#c6c9db'
                                                                }
                                                            }}
                                                            placeholder="First Name"
                                                            error={touched.first_name && !!errors.first_name ? true : false}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="input-error">
                                                            {touched.first_name ? errors.first_name : null}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item md>
                                        <Field name="last_name">
                                            {({ field }) => (
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography>Last name</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            {...field}
                                                            fullWidth
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: 0,
                                                                    borderColor: '#c6c9db'
                                                                }
                                                            }}
                                                            placeholder="Last Name"
                                                            error={touched.last_name && !!errors.last_name ? true : false}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="input-error">
                                                            {touched.last_name ? errors.last_name : null}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Field name="email">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography>Email</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...field}
                                                    fullWidth
                                                    onBlur={(event) => {
                                                        inputFilter(event.target.value);
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0,
                                                            borderColor: '#c6c9db'
                                                        }
                                                    }}
                                                    placeholder="name.surname@example.com"
                                                    error={(touched.email && !!errors.email) || emailUnique.length > 0 ? true : false}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="space-between" alignItems="center">
                                                    <Grid item>
                                                        {touched.email ? <Typography className="input-error" style={{ fontSize: '0.75rem', color: '#F44336' }}>{errors.email}</Typography> : null}
                                                    </Grid>
                                                    <Grid item>
                                                        {emailUnique.length > 0 ? <Typography className="input-error" style={{ fontSize: '0.75rem', color: '#F44336' }}>Email already taken</Typography> : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Field name="phone">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography>Phone Number</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    placeholder="+44 (0) 7123 456 789"
                                                    {...field}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0,
                                                            borderColor: '#c6c9db'
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    margin="dense" />
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error">
                                                    {touched.phone ? errors.phone : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Field name="password">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography>Password</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="password"
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0,
                                                            borderColor: '#c6c9db'
                                                        }
                                                    }}
                                                    error={touched.password && !!errors.password ? true : false}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error" style={{ maxWidth: 400 }}>
                                                    {touched.password ? errors.password : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Divider className={classes.dividerLink} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.subtitle}>User account</Typography>
                            </Grid>
                            <Grid item>
                                <Field name="role">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography>Role</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...field}
                                                    style={{
                                                        borderRadius: 0,
                                                        borderColor: '#c6c9db'
                                                    }}
                                                    value={values.role.length > 0 ? values.role : false}
                                                    error={touched.role && !!errors.role ? true : false}>
                                                    <MenuItem value={false} selected disabled>Select Role</MenuItem>
                                                    {
                                                        roles && roles.length > 0 && roles.map((role, key) => (
                                                            <MenuItem key={key} value={role.name}>{role.display_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error">
                                                    {touched.role ? errors.role : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Field name="partner_ids">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography>Partner</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    multiple
                                                    style={{
                                                        borderRadius: 0,
                                                        borderColor: '#c6c9db'
                                                    }}
                                                    {...field}
                                                    value={values.partner_ids.length > 0 ? values.partner_ids : []}
                                                    error={touched.partner_ids && !!errors.partner_ids ? true : false}>
                                                    <MenuItem disabled selected>Select Partner</MenuItem>
                                                    {
                                                        partners && partners.length > 0 && partners.map((partner, key) => (
                                                            <MenuItem key={key} value={partner.id}>{partner.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error">
                                                    {touched.partner_ids ? errors.partner_ids : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        {
                                            loading ?
                                                <BeatLoader
                                                    css={{ marginTop: '20px' }}
                                                    color='#af001e'
                                                    loading={loading}
                                                />
                                                :
                                                <Button
                                                    size="large"
                                                    fullWidth
                                                    className={classes.createUserButton}
                                                    disabled={!isValid || emailUnique.length > 0}
                                                    type="submit">
                                                    Create User
                                                </Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="large"
                                            fullWidth
                                            onClick={toggleCreateUser}
                                            className={classes.cancelUserButton}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}

export default connect()(withRouter(CreateUser));
