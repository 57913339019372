import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Divider,
    Checkbox
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const UpdateDeadlineDialog = (props) => {

    const useStyles = makeStyles((theme) => ({
        secondaryCheckboxColor: {
            color: '#af001e',
        },
        fieldBorderRadius: {
            borderRadius: 0
        },
        submitButton: {
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            color: 'white',
            width: 256,
            height: 50,
            fontWeight: 'bold',
            borderRadius: 0,
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        cancelButton: {
            fontWeight: 'bold',
            color: '#3e3f60',
            width: 132,
            height: 50,
        },
    }));

    const classes = useStyles();

    const setStartDate = () => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 2);
        return currentDate.getFullYear() + '-' + Number(currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    }

    return (
        <Dialog
            PaperProps={{ style: { minWidth: 617, borderRadius: 0, paddingLeft: 25, paddingRight: 25, paddingBottom: 24, paddingTop: 15 } }}
            open={props.openUpdateDeadlineDialog}
            keepMounted
            disableBackdropClick
            onClose={props.handleCloseDialog}>
            <DialogTitle>
                <span style={{ fontSize: 30, fontWeight: 'bolder', color: '#3e3f60' }}>Update Deadline</span>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        deadline_date: props.editDeadlineData ? props.editDeadlineData.deadline_date : '',
                        title: props.editDeadlineData ? props.editDeadlineData.title : '',
                        description: props.editDeadlineData ? props.editDeadlineData.description : '',
                        permissions: props.editDeadlineData ? props.editDeadlineData.permissions : ''
                    }}
                    enableReinitialize
                    validationSchema={
                        Yup.object().shape({
                            deadline_date: Yup.string()
                                .required('required'),
                            title: Yup.string()
                                .required('required'),
                            description: Yup.string()
                                .required('required'),
                            permissions: Yup.string()
                                .optional()
                        })
                    }
                    onSubmit={(values) => {
                        props.handleEditDeadline(values);
                    }}>
                    {({ touched, values, errors, setFieldValue, resetForm }) => (
                        <Form>
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#3e3f60' }}>Deadline Date</Typography>
                                    <Field
                                        name="deadline_date"
                                        type="date">
                                        {({ field }) => (
                                            <KeyboardDatePicker
                                                disableToolbar
                                                {...field}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                minDate={setStartDate()}
                                                value={values.deadline_date ? values.deadline_date : null}
                                                onChange={(date) => {
                                                    setFieldValue('deadline_date', moment(date).format(moment.HTML5_FMT.DATE))
                                                }}
                                                placeholder="DD/MM/YYYY"
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        root: classes.fieldBorderRadius,
                                                    }
                                                }}
                                                InputAdornmentProps={{
                                                    position: "end",
                                                    size: '10px'
                                                }}
                                                error={touched.deadline_date && !!errors.deadline_date ? true : false}
                                                helperText={touched.deadline_date && !!errors.deadline_date ? errors.deadline_date : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#3e3f60' }}>Deadline Title</Typography>
                                    <Field
                                        name="title"
                                        type="text">
                                        {({ field }) => (
                                            <TextField
                                                InputProps={{
                                                    classes: {
                                                        root: classes.fieldBorderRadius
                                                    }
                                                }}
                                                {...field}
                                                fullWidth
                                                placeholder="Deadline title"
                                                variant="outlined"
                                                error={touched.title && !!errors.title ? true : false}
                                                helperText={touched.title && !!errors.title ? errors.title : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#3e3f60' }}>Deadline Description</Typography>
                                    <Field
                                        name="description"
                                        type="text">
                                        {({ field }) => (
                                            <TextField
                                                InputProps={{
                                                    classes: {
                                                        root: classes.fieldBorderRadius
                                                    }
                                                }}
                                                {...field}
                                                multiline
                                                fullWidth
                                                rows={3}
                                                placeholder="Write a description here..."
                                                maxLength="255"
                                                variant="outlined"
                                                error={touched.description && !!errors.description ? true : false}
                                                helperText={touched.description && !!errors.description ? errors.description : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                </Grid>
                                <Grid item>
                                    <Field
                                        name="permissions"
                                        type="text">
                                        {({ field }) => (
                                            <>
                                                <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#3e3f60' }}>Permissions</Typography>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            {...field}
                                                            style={{
                                                                color: "#af001e",
                                                                paddingLeft: 0
                                                            }}
                                                            classes={{
                                                                colorSecondary: classes.secondaryCheckboxColor,
                                                                checked: classes.secondaryCheckboxColor
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>Notify all users</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Button type="submit" className={classes.submitButton}>Update Deadline</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className={classes.cancelButton}onClick={() => { resetForm(), props.handleCloseDialog() }}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default connect()(UpdateDeadlineDialog);
