import axios from 'axios';

export default {
    downloadImage(file, setLoaded) {
        if (setLoaded) {
            setLoaded(false);
        }

        axios.get(file.path, {responseType: 'blob'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            if(file.ext) {
                link.setAttribute('download', `${file.name}.${file.ext}`);
            } else {
                link.setAttribute('download', `${file.name}`);
            }
            document.body.appendChild(link);
            link.click();

            if (setLoaded) {
                setLoaded(true);
            }

        }).catch(function (error) {
            console.log(error);

            if (setLoaded) {
                setLoaded(true);
            }
        });
    },
    downloadZip(files, setLoaded, propertyPath, url = '') {

        let fileName = 'download.zip'

        if (files.length === 1) {
            url = files[0][propertyPath];
            fileName = `${files[0].name}.${files[0].ext}`
        } else {
            let fileIds = files.map((file, idx) => {
                return `files[${idx}]=${file.id}`;
            });
            url += fileIds.join('&');
        }
        setLoaded(false);

        axios.get(url, {responseType: 'blob'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            setLoaded(true)
        }).catch(function (error) {
            console.log(error);
            setLoaded(true)
        });
    },
    createFolder(data) {
        return new Promise((resolve, reject) => {
            axios.post("/api/uploads/create-folder", data, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(() => {
                resolve();
            }).catch(function(error) {
                reject(error.response.data);
            });
        });
    },
    postFiles(payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/uploads/file", payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then(() => {
                resolve();
            }).catch(function(error) {
                reject(error.response.data);
            });
        });
    },
    deleteFile(id) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/uploads/" + id, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(() => {
                resolve();
            }).catch(function(error) {
                reject(error.response.data);
            });
        });
    },
    updateFile(id, data) {
        return new Promise((resolve, reject) => {
            axios.put("/api/uploads/" + id, data,{
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(() => {
                resolve();
            }).catch(function(error) {
                reject(error.response.data);
            });
        });
    }
}
