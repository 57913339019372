import React, { useState } from 'react'
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import { Card, TextField, InputAdornment, IconButton, Grid, FormHelperText } from '@material-ui/core';
import { loginUser } from './loginActions';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import AuthContainer from './AuthContainer';
import Button from '../Components/Button';
import BeatLoader from 'react-spinners/BeatLoader';
import * as Yup from "yup";
import './index.scss';

const logoImage = require("./Positive.svg");

const Login = (props) => {

    const [loginErrors, setLoginErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token') !== null ? true : false)
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .max(300)
            .email()
            .required("required"),
        password: Yup.string()
            .max(300)
            .required("required"),
    });

    const handleLoginUser = (data) => {
        setLoading(true);
        setLoginErrors(null)
        props.dispatch(loginUser({
            email: data.email,
            password: data.password
        }))
            .then((response) => {

                response.json()
                    .then((data) => {
                        if (data.error) {
                            setLoading(false);
                            return setLoginErrors(data)
                        }

                        if (response.ok) {
                            props.dispatch({
                                type: 'user/SET_USER',
                                payload: data.user,
                            })
                            localStorage.setItem('token', data.access_token)
                            window.axios.defaults.headers.authorization = 'Bearer ' + data.access_token;
                            props.history.push('/dashboard', { fromLogin: true });
                        }
                    })
            });
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            handleLoginUser();
        }
    };

    return (
        <>
            {token ? <Redirect to='/dashboard' /> :
                < AuthContainer >
                    <Card
                        raised={true}
                        className="card">

                        <div className="row">
                            <div className="col-12 text-center">
                                <img height="100" alt="Lions Logo" src="/images/logo/LionsSeries-Logo-Portrait-RGB-POS_AW.svg" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>Welcome to The British & Irish Lions Tour of Australia 2025 Partner Portal</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-center">
                                <p>Enter your email address and password.</p>
                            </div>
                        </div>

                        <Formik
                            onSubmit={(data) => {
                                handleLoginUser(data)
                            }}
                            onKeyDown={e => handleKeyPress(e)}
                            validationSchema={LoginSchema}>
                            {({ values, touched, errors, isValid }) => (
                                <Form>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Field
                                                name="email">
                                                {({ field }) => (
                                                    <TextField
                                                        label="Email"
                                                        {...field}
                                                        type='text'
                                                        fullWidth
                                                        value={values.email}
                                                        placeholder="example@lionstour.com"
                                                        name="email"
                                                        helperText={(touched.email && !!errors.email) && <span style={{ color: 'red', fontSize: 14 }}>{errors.email}</span>}
                                                        error={(touched.email && !!errors.email) ? true : false}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                name="password">
                                                {({ field }) => (
                                                    <TextField
                                                        label="Password"
                                                        fullWidth
                                                        {...field}
                                                        value={values.password}
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        error={(touched.password && !!errors.password) ? true : false}
                                                        helperText={(touched.password && !!errors.password) && <span style={{ color: 'red', fontSize: 14 }}>{errors.password}</span>}
                                                        placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onMouseDown={() => setShowPassword(!showPassword)}
                                                                    >
                                                                        {showPassword ? <img width="20" height="20" src="/images/icons/SVG/View.svg" /> : <img width="20" height="20" src="/images/icons/SVG/Hide.svg" />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        {
                                            loginErrors &&
                                            <Grid item>
                                                <FormHelperText style={{ color: 'red', fontSize: 14 }}>Please check your credentials and try again</FormHelperText>
                                            </Grid>
                                        }
                                    </Grid>

                                    <div className="row pt-5">
                                        <div className="col-12 text-center">

                                            {
                                                !loading ?
                                                    <Button text="Sign In" class={isValid ? "color-red fullWidth" : "disabled fullWidth"} type="submit" disabled={!isValid} />
                                                    : <BeatLoader
                                                        css={{ marginTop: '20px' }}
                                                        color='#af001e'
                                                    />
                                            }

                                            <Link className="link" to="/reset">Did you forget your password?</Link>
                                        </div>
                                        <div className="row pt-2">
                                            <div className="col-12">
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </Card>
                </AuthContainer>
            }
        </>
    );
}


const mapStateToProps = (state) => ({
    user: state.user.user,
    loading: state.user.loading
});

export default connect(mapStateToProps)(withRouter(Login));
