import React from 'react';
import { Grid, makeStyles, Typography, Card, Button, TextField } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { resetOverridesData } from '../cmsInitialValues';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Permitted from "~/Permissions/Permitted";

const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
    },
}));

const MediaLibrary = (props) => {

    const {
        british_lions_imagery,
        setBritish_lions_imagery,
        british_lions_footage,
        setBritish_lions_footage,
        springboks_imagery,
        setSpringboks_imagery,
        springboks_footage,
        setSpringboks_footage,
        setFieldValue,
        mediaLibraryEditorState,
        setMediaLibraryEditorState
    } = props;

    const classes = useStyles();

    // RESET SINGLE FIELD STATE TO INITIAL
    const resetField = () => {
        setMediaLibraryEditorState(EditorState.createWithContent(convertFromHTML(resetOverridesData.media_library.media_library)));
        setFieldValue('media_library.media_library', resetOverridesData.media_library.media_library);
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid item xs sm md lg xl>
                    <Card style={{ borderRadius: 0, padding: '10px 20px' }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs sm md lg xl>
                                <Typography style={{ fontSize: 14, color: '#3e3f60' }}>Go to the Media Library page to upload, edit or delete assets.</Typography>
                            </Grid>
                            <Grid item>
                                <Link to={'/media/category/1'} style={{ textDecoration: 'none' }}>
                                    <Button>
                                        <Typography style={{ color: '#af001e', fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>Upload Assets</Typography>
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Permitted
                some={[
                    "edit_archive_content",
                    "edit_archive_links"
                ]}>
                <Grid item>
                    <Card style={{ borderRadius: 0, minHeight: 120, padding: 30 }}>
                        <Grid container direction="column" spacing={2}>
                           {/*
                            <Grid item>
                                <Typography style={{ color: '#af001e', fontSize: 18, fontWeight: 'bold' }}>Archive</Typography>
                            </Grid>
                            */}
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container direction="row" spacing={6}>
                                           {/*ARCHIVE CONTENT EDITOR
                                           <Permitted one="edit_archive_content">
                                                <Grid item xs sm md xl lg>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>Archive</Typography>
                                                            <Typography style={{ fontSize: 10, color: '#999bb5' }}>Edit to the below text will only be applied for the selected partner tier.</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Field name="media_library">
                                                                {({ field }) => (
                                                                    <Editor
                                                                        {...field}
                                                                        name="media_library"
                                                                        editorState={mediaLibraryEditorState}
                                                                        editorStyle={{
                                                                            border: '1px solid #c6c9db',
                                                                            padding: 10,
                                                                            minHeight: 300,
                                                                            maxHeight: 300,
                                                                            overflowY: 'auto'
                                                                        }}
                                                                        placeholder="Please add content here"
                                                                        onEditorStateChange={(e) => {
                                                                            setFieldValue('media_library.media_library', convertToHTML(e.getCurrentContent()));
                                                                            setMediaLibraryEditorState(e);
                                                                        }}
                                                                        toolbar={{
                                                                            options: ['inline', 'list'],
                                                                            inline: {
                                                                                options: ['bold', 'italic', 'underline'],
                                                                            },
                                                                            list: {
                                                                                options: ['unordered'],
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Permitted>
                                            */}
                                            <Permitted one="edit_archive_links">
                                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                                    <Grid container direction="column" style={{ minHeight: '100%' }} spacing={2}>
                                                        <Grid item>
                                                            <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>British & Irish Lions access links</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" spacing={2}>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item>
                                                                            <Typography style={{ color: '#999bb5', fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase' }}>Header</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Field name="british_lions_imagery">
                                                                                {({ field }) => (
                                                                                    <TextField
                                                                                        {...field}
                                                                                        //name="british_lions_imagery"
                                                                                        type="text"
                                                                                        value={british_lions_imagery}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                borderRadius: 0
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            setBritish_lions_imagery(e.target.value)
                                                                                            setFieldValue('media_library.british_lions_imagery', e.target.value)
                                                                                        }}
                                                                                        placeholder="https://www."
                                                                                        className="form-control"
                                                                                        name="header"
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item>
                                                                            <Typography style={{ color: '#999bb5', fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase' }}>Footer</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Field name="british_lions_footage">
                                                                                {({ field }) => (
                                                                                    <TextField
                                                                                        {...field}
                                                                                        //name="british_lions_footage"
                                                                                        type="text"
                                                                                        value={british_lions_footage}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                borderRadius: 0
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            setBritish_lions_footage(e.target.value);
                                                                                            setFieldValue('media_library.british_lions_footage', e.target.value)
                                                                                        }}
                                                                                        placeholder="https://www."
                                                                                        className="form-control"
                                                                                        name="footer"
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>Rugby Australia access links</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" spacing={2}>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item>
                                                                            <Typography style={{ color: '#999bb5', fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase' }}>Header</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Field name="springboks_imagery">
                                                                                {({ field }) => (
                                                                                    <TextField
                                                                                        {...field}
                                                                                        //name="springboks_imagery"
                                                                                        type="text"
                                                                                        value={springboks_imagery}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                borderRadius: 0
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            setSpringboks_imagery(e.target.value);
                                                                                            setFieldValue('media_library.springboks_imagery', e.target.value)
                                                                                        }}
                                                                                        placeholder="https://www."
                                                                                        className="form-control"
                                                                                        name="header"
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column">
                                                                        <Grid item>
                                                                            <Typography style={{ color: '#999bb5', fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase' }}>Footer</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Field name="springboks_footage">
                                                                                {({ field }) => (
                                                                                    <TextField
                                                                                        {...field}
                                                                                        //name="springboks_footage"
                                                                                        type="text"
                                                                                        value={springboks_footage}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                borderRadius: 0
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            setSpringboks_footage(e.target.value);
                                                                                            setFieldValue('media_library.springboks_footage', e.target.value)
                                                                                        }}
                                                                                        placeholder="https://www."
                                                                                        className="form-control"
                                                                                        name="footer"
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Permitted>
                                        </Grid>
                                    </Grid>
                                    {/*CONTENT EDITOR - RESET OVERRIDES
                                    <Permitted one="edit_archive_content">
                                        <Grid item xs sm md lg xl>
                                            <Button style={{ color: '#af001e', fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }} onClick={() => resetField()}>
                                                Reset Overrides
                                        </Button>
                                        </Grid>
                                    </Permitted>
                                    */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Permitted>
        </Grid >
    );
};

export default MediaLibrary;
