import React from 'react';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { Field } from 'formik';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { resetOverridesData } from '../cmsInitialValues';
import { EditorState } from 'draft-js';
import Dropzone from "react-dropzone";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Permitted from "~/Permissions/Permitted";
import setImagePathByExtension from '../../../../helpers/Images/setImagePathByExtension';

const EditableComponent = ({
    files,
    setFiles,
    editorState,
    setEditorState,  
    slug,
    title,
    formTitle,
    setFieldValue,
    previewUpload,
    setRenameImage,
    setRenameSection,
    setRenameRenameFileIndex,
    handleOpenImageDialog,      
}) => {

    const useStyles = makeStyles((theme) => ({
        title: {
            color: '#3e3f60',
            fontSize: 14,
            fontWeight: 'bold'
        },
        description: {
            fontSize: 10, 
            color: '#999bb5'
        },
        iconStyle: {
            width: 21,
            height: 21,
            cursor: 'pointer'
        },
        imageStyle: {
            width: 21,
            height: 21,
        },
        fileTitle: {
            color: '#3e3f60',
            fontSize: 16
        },
        fileSize: {
            color: '#d8dae6',
            fontSize: 10,
            fontWeight: 'bold'
        },  
        uploadFileTitle: {
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: '#af001e',
            marginTop: '3rem'
        },      
        uploadIcon: {
            width: '100%',
            background: '#f3f4f8',
            border: '3px dashed #d8dae6',
            color: '#3e3f60',
            padding: 30,
            textAlign: 'center',
            position: 'relative'
        },              
        resetOverridesButton: {
            color: '#af001e',
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },    
    }));

    const classes = useStyles();

    // RESET SINGLE FIELD STATE TO INITIAL
    const resetField = () => {
        setEditorState(EditorState.createWithContent(convertFromHTML(resetOverridesData.lions_tour.partner_workshops)));
        setFieldValue(`lions_tour.${slug}`, resetOverridesData.lions_tour[slug]);
        
    }

    return (
        <Grid container direction="column" style={{ padding: 30 }}>
            <Grid item>
                <Grid container direction="row" spacing={6}>
                    <Permitted one={`edit_${slug}_content`}>
                        <Grid item xs sm md xl lg>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography className={classes.title}>{title}</Typography>
                                    {
                                        (slug !== 'documents') && (
                                            <Grid 
                                                container 
                                                direction="row" 
                                                alignItems="baseline"
                                                justify="space-between"
                                            >
                                                <Grid item>
                                                    <Typography className={classes.description}>
                                                        Edit to the below text will only be applied for the
                                                        selected partner tier.
                                                    </Typography>
                                                </Grid>
                                            </Grid>)
                                    }
                                </Grid>
                                <Grid item>
                                    <Field name={slug}>
                                        {({ field }) => (
                                            <Editor
                                                {...field}
                                                editorState={editorState}
                                                name={slug}
                                                editorStyle={{
                                                    border: '1px solid #c6c9db',
                                                    padding: 10,
                                                    minHeight: 300,
                                                    maxHeight: 300,
                                                    overflowY: 'auto'
                                                }}
                                                placeholder="Please add content here"
                                                onEditorStateChange={(e) => {
                                                    setFieldValue(`lions_tour.${slug}`, convertToHTML(e.getCurrentContent()));
                                                    setEditorState(e);
                                                }}
                                                toolbar={{
                                                    options: ['inline', 'list'],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline'],
                                                    },
                                                    list: {
                                                        options: ['unordered'],
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Permitted>
                    <Permitted one={`upload_delete_${slug}_files`}>
                        <Grid item xs={5} sm={5} md={5} xl={5} lg={5}>
                            <Grid 
                                container 
                                direction="column" 
                                justify="space-between" 
                                style={{ minHeight: '100%' }}
                            >
                                <Grid item>
                                    <Grid 
                                        container 
                                        direction="row" 
                                        alignItems="center"
                                        justify="space-between"
                                    >
                                        <Grid item>
                                            <Typography className={classes.title}>
                                                {formTitle}
                                            </Typography>
                                            { (!files || files?.length === 0) && <Typography className={classes.description}>No documents yet.</Typography> }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={4}>
                                        {
                                            files && files.map((file, key) => {
                                                const extension = file.type ? file.type : file.ext ? file.ext : 'doc';

                                                return(
                                                    <Grid item key={key}>
                                                        <Grid 
                                                            container 
                                                            direction="row"
                                                            alignItems="center"
                                                            justify="space-between"
                                                        >
                                                            <Grid item>
                                                                <Grid 
                                                                    container 
                                                                    direction="row"
                                                                    alignItems="baseline" 
                                                                    spacing={1}
                                                                >
                                                                    <Grid item>
                                                                        {                                                                                 
                                                                            <img
                                                                                src={setImagePathByExtension(extension).path}
                                                                                alt={setImagePathByExtension(extension).alt}
                                                                                className={classes.imageStyle}
                                                                            />
                                                                        }                                                                            
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={classes.fileTitle}>
                                                                            {file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={classes.fileSize}>
                                                                            {
                                                                                typeof file.size === 'number' ? (file.size > 0 && (file.size * 0.000001).toFixed(2)) : (Number(file.size) > 0 && (Number(file.size) / 1000).toFixed(2))
                                                                            }
                                                                            MB
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row"
                                                                    spacing={2}>
                                                                    <Grid item>
                                                                        <img
                                                                            src="/images/icons/SVG/View.svg"
                                                                            className={classes.iconStyle}
                                                                            alt="view"
                                                                            onClick={() => previewUpload(file)} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <img
                                                                            src="/images/icons/SVG/Edit.svg"
                                                                            className={classes.iconStyle}
                                                                            alt="edit"
                                                                            onClick={() => {
                                                                                setRenameImage(file.name);
                                                                                setRenameSection(`${slug}_files`);
                                                                                setRenameRenameFileIndex(key);
                                                                                handleOpenImageDialog();
                                                                            }} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <img
                                                                            src="/images/icons/SVG/Close.svg"
                                                                            className={classes.iconStyle}
                                                                            alt="image"
                                                                            onClick={() => {
                                                                                let newFiles = files.filter((item, iKey) => iKey !== key);
                                                                                setFiles(newFiles);
                                                                                setFieldValue(`lions_tour.${slug}_files`, newFiles);
                                                                            }} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            )})
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.uploadFileTitle}>
                                                Upload files
                                            </Typography>
                                            <Typography className={classes.description}>
                                                You can upload new files to be uploaded below by either clicking on the button below, or dropping the file on the button
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Dropzone
                                                accept={(slug === "partner_workshops") ?                                                    
                                                    ".ai,.esp,.eps,.gif,.jpeg,.jpg,.png,.svg,.epub,.otf,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.m4a,.m4v,.mov,.mp3,.mp4,.mpg,.wav,.wmv,.avi,.swf,.zip" :
                                                    ".pdf,.doc,.docx,.jpg,.png,.xsl,.zip,.eps"}
                                                minSize={0}
                                                onDrop={x => {
                                                    let newFiles = [];
                                                    if (files && files.length > 0) {
                                                        newFiles = [...files, ...x];
                                                    } else {
                                                        newFiles = [...x];
                                                    }
                                                    setFiles(newFiles);
                                                    setFieldValue(`lions_tour.${slug}_files`, newFiles);
                                                }}>
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                    isDragActive,
                                                    isDragReject
                                                }) => {
                                                    return (
                                                        <div 
                                                            className={classes.uploadIcon}
                                                            {...getRootProps()}>
                                                            <div className="upload-icon">
                                                                <img
                                                                    src="/images/icons/SVG/Upload-red.svg"
                                                                    alt="upload" />
                                                            </div>
                                                            <h4 className="upload-title">
                                                                Drag & Drop
                                                            </h4>
                                                            <div className="form-group">
                                                                <div className="dropZone">
                                                                    <input {...getInputProps()} />
                                                                    {!isDragActive &&
                                                                        <span>Or you can <u>browse</u> your files</span>}
                                                                    {isDragActive && isDragReject && "Drop it like it's hot!"}
                                                                    {isDragActive && !isDragReject && "File type not accepted, sorry!"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                            <Typography className={classes.description}>
                                                Accepted Formats: {(slug === "partner_workshops") ?
                                                    "ai, esp, eps, gif, jpeg, jpg, png, svg, epub, otf, pdf, doc, docx, ppt, pptx, xls, xlsx, m4a, m4v, mov, mp3, mp4, mpg, wav, wmv, avi, swf, zip" :
                                                    "pdf, doc, docx, jpg, png, xsl, zip, eps"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Permitted>
                </Grid>
            </Grid>
            <Permitted one={`edit_${slug}_content`}>
                <Grid item>
                    <Button
                        className={classes.resetOverridesButton}
                        onClick={() => resetField(slug)}>
                        Reset Overrides
                    </Button>
                </Grid>
            </Permitted>
        </Grid>
    )
};

export default EditableComponent;