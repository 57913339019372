import update from 'immutability-helper';

const initialState = {
    user: null,
    loaded: false,
    roles: [],
    users: [],
    keyDeadlines: [],
    activityLogs: [],
    selectedUsers: [],
    cmsBlocks: {},
};

const userReducer = (state = initialState, action) =>
{
    if (action.type === 'DESTROY') {
        return initialState;
    }

    if (action.type === 'user/SET_USER') {
        return update(state, {
            user: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_LOADED') {
        return update(state, {
            loaded: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_ROLES') {
        return update(state, {
            roles: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_USERS') {
        return update(state, {
            users: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_KEY_DEADLINES') {
        return update(state, {
            keyDeadlines: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_ACTIVITY_LOGS') {
        return update(state, {
            activityLogs: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_SELECTED_USERS') {
        return update(state, {
            selectedUsers: {$set: action.payload},
        });
    }

    if (action.type === 'user/SET_CMS_BLOCKS') {
        return update(state, {
            cmsBlocks: {$set: action.payload},
        });
    }

    return state;
};

export default userReducer;
