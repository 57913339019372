import React from  'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import MaterialIcon from "material-icons-react";

import './index.scss';
import FolderIcon from "~/Components/Icons/FolderIcon";

const Folders = (props) => {
    return(
        <div className="ptop-65 items">
            <Row>
                <Col>
                    <div className="library__folders-title">FOLDERS</div>
                </Col>
            </Row>
            <Row>
                {props.folders &&
                    props.folders.map((item, index) => {
                        return (
                            <div className={props.colClass} key={index}>
                                <Link to={props.uri + item.id} className={"library__link"}>
                                    <div className={"library__link__folder"}>
                                        <div className="justify-content-start d-flex">
                                            <div md={2} className="folder-icon"><FolderIcon/></div>
                                            <div className="folder-name" title={item.name}>&nbsp;{item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })
                }
            </Row>
        </div>
    )
};

export default Folders;
