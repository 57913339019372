import React from 'react';
import './InventoryDialog.scss'
import {connect} from "react-redux";
import Button from "~/Components/Button";
import Grid from "@material-ui/core/Grid";
import {withRouter} from "react-router";

const InventoryDialog = ({errors, assetsConfirm, dispatch, history}) => {

    const closeDialog = () => {
        dispatch({type: 'inventory/SET_ERRORS', payload: null});
        dispatch({type: 'inventory/SET_ASSETS_CONFIRM', payload: true});
    }

    if (!errors && assetsConfirm) {
        return null;
    }

    return (
        <div className="inventory-dialog" onClick={closeDialog}>
            <div className="dialog-content">
                <div className="dialog-header">
                    {!errors ? 'Create Inventory' : errors.unsaved ? 'Unsaved Changes' : 'Create Inventory'}
                </div>
                {!errors
                    ?
                    <>
                    <div className="dialog-text">
                        Are you sure you createe this inventory? This will give partners access to their inventory, and allow them to submit talent access requests.
                    </div>
                        <div className="dialog-footer">
                            <Button class="footer-buttons next" text="YES, CONFIRM"
                                    onClick={() => {
                                        dispatch({type: 'inventory/SET_CONFIRM', payload: true});
                                    }}/>
                            <Button class="footer-buttons cancel"
                                    text="CONTINUE EDITING"
                                    onClick={() => dispatch({type: 'inventory/SET_ASSETS_CONFIRM', payload: true})}/>

                        </div>
                    </>
                    :
                    <>
                        <div className="dialog-text">
                            {Object.keys(errors).map((error, key) => (
                                <div className="error" key={key}>{errors[error]}</div>
                            ))
                            }
                        </div>
                    </>

                }

            </div>
        </div>
    );
};

const mapStateToProps = (state => ({
    errors: state.inventory.errors,
    assetsConfirm: state.inventory.assetsConfirm,
}));

export default connect(mapStateToProps, null)(withRouter(InventoryDialog));
