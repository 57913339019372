import React from 'react';

const FiltersIconWhite = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <path fill="#FFF" fillRule="evenodd" d="M7.205 12.969v1.312h-1.97v5.906H3.924V14.28l-1.968.001V12.97h5.25zM11.798 11v9.188h-1.312V11h1.312zm8.532 4.594v1.312h-1.97v3.282h-1.312v-3.282H15.08v-1.312h5.25zM18.36 1.813v11.812h-1.312V1.812h1.313zm-13.124 0V11H3.923V1.812h1.313zm6.562 0v5.905h1.97v1.313h-5.25V7.72h1.968V1.811h1.312z"/>
            </svg>
        </>
    );
};

export default FiltersIconWhite;
