import React, { useEffect } from 'react';
import { MenuItem, Select } from "@material-ui/core";
import { connect } from "react-redux";

const RolesFilter = ({ filterCriteria, inputFilter, setFilterCriteria, dispatch }) => {
    useEffect(() => {
        setFilterCriteria('id')
    }, []);
    return (
        <Select
            value={filterCriteria}
            style={{
                boxShadow: '0 0 4px 0 rgba(62, 63, 96, 0.15)',
                borderRadius: 0,
                backgroundColor: 'white',
                border: 'none'
            }}
            onChange={(event) => {
                inputFilter(undefined, event.target.value);
                setFilterCriteria(event.target.value);
                dispatch({ type: 'roles/SET_CRITERIA', payload: event.target.value })
            }}
            placeholder="Name"
            variant="outlined"
            margin="dense">
            <MenuItem value={'id'} selected>Role Id</MenuItem>
            <MenuItem value={'display_name'} >Role Name</MenuItem>
        </Select>
    );
};

export default connect(null, null)(RolesFilter);
