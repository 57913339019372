import { authHeader } from "~/helpers/authHeader";
import { fetchUserList } from "~/Admin/UserManagements/userActions";

export const getPermissions = (isAdmin) => {
    return (dispatch) => {

        return fetch(`/api/permissions${isAdmin ? '?admin' : ''}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch(setActionPermissionsAction(data));
            });
    };
};

export const setActionPermissions = (roleId, selectedPermissions) => {

    return (dispatch) => {

        return fetch('/api/roles/' + roleId + '/permissions-sync', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({ permission_ids: selectedPermissions })
        })
            .then((response) => {

                if (response.ok) {
                    dispatch(fetchActiveRole(roleId));
                    return true;
                };

                throw Error('Unknown error!');
            });
    };
};

export const fetchActiveRole = (id) => {
    return (dispatch) => {
        return fetch('/api/roles/' + id, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        }).then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch(setRole(data.data));
            });
    }
}

export const fetchPermissionIds = (isAdmin) => {
    return (dispatch) => {
        return fetch(`/api/permissions/permission-ids${isAdmin ? '?admin' : ''}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        }).then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch(setSelectAllPermissions(data.data));
            });
    }
}

export const assignUsersToRole = (roleId, userList) => {

    return (dispatch) => {

        return fetch(`/api/roles/${roleId}/users-sync`, {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({ "user_ids": userList })
        })
            .then((response) => {

                if (response.ok) {
                    dispatch(fetchActiveRole(roleId));
                    dispatch(fetchUserList());
                    return response.ok;
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};

export const isUniqueName = (uniqueName) => {

    return (dispatch) => {
        return fetch(`/api/roles/is-unique/${uniqueName}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                return data.data;
            });
    };
};

export const filterUsers = (uid, fName, lName, email, partner, role, page, perPage) => {
    let firstName = fName ? `first_name=${fName}&` : '';
    let partnerName = partner ? `partner=${partner}&` : '';
    let roleName = role ? `role=${role}&` : '';
    let emailVal = email ? `email=${email}&` : '';
    let lastName = lName ? `last_name=${lName}&` : '';
    let userId = uid ? `user_id=${uid}&` : '';
    let pageNum = page ? `page=${page}&` : '';

    return (dispatch) => {
        return fetch(`/api/users?${firstName}${partnerName}${roleName}${emailVal}${lastName}${userId}${pageNum}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'roles/SET_USERS',
                    payload: data,
                });
            });
    };
};

export const openEditRoleDialog = (payload) => {
    return (dispatch) => {
        dispatch({ type: 'roles/SET_OPEN_EDIT_ROLE_DIALOG', payload: payload });
    }
}

export const setRole = (payload = null) => {
    return (dispatch) => {
        dispatch({ type: 'roles/SET_ROLE', payload: payload });
    }
}

export const setEditRoleType = (payload = null) => {
    return (dispatch) => {
        dispatch({ type: 'roles/SET_EDIT_ROLE_TYPE', payload: payload });
    }
}

export const setSelectAllPermissions = (payload) => {
    return (dispatch) => {
        dispatch({ type: 'roles/SET_SELECT_ALL', payload: payload });
    }
}

export const setActionPermissionsAction = (payload) => {
    return (dispatch) => {
        dispatch({ type: 'roles/SET_ACTION_PERMISSIONS', payload: payload });
    }
}