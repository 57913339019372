
export const resetTalentAccessAdminActions = () => {

    return (dispatch) => {
        dispatch({type: 'talent_access_admin/SET_MANUAL_DIALOG_SHOW', payload: false});
        dispatch({type: 'talent_access_admin/SET_MEMBER_DELETE_EXECUTE', payload: false});
        dispatch({type: 'talent_access_admin/SET_MEMBER_TO_DELETE', payload: null});
        dispatch({type: 'talent_access_admin/SET_DELETE_MODE', payload: false});
    };
}
