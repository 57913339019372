import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import AssetsList from "~/Admin/InventoryManagement/AssetsForm/AssetsList";
import {Formik} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import Button from "~/Components/Button";
import {connect} from "react-redux";
import InventoryUpload from "~/Admin/InventoryManagement/InventoryUpload/InventoryUpload";
import {getInventory} from "~/Admin/InventoryManagement/inventoryActions";
import Loader from "react-loader";
import Pagination from "react-js-pagination";

const useStyle = makeStyles((theme) => ({
    dateRoot: {
        margin: 0
    },
    switchBtn: {
        color: theme.primaryColor
    },
    inputBorder: {
        borderRadius: 0,
    },
    calendarButton: {
        height: '32px',
        padding: '4px 11px',
        position: 'relative',
        right: '-10px',
        '&:focus': {
            outline: 'none'
        }
    },
    calendarIcon: {
        position: 'relative',
        top: '-2px',
    },
    input: {
        border: '1px solid #c6c9db',
        padding: '8px 5px',
        borderRadius: 0,
        width: '100%',
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    dateAdornment: {
        marginLeft: -38,
    },
    dateError: {
        border: '1px solid #F44336',
        padding: '8px 15px',
        borderRadius: 0,

        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    paper: {
        position: 'relative',
        marginLeft: '21px',
        minWidth: '322px',
        width: 'auto',
        marginTop: '9px',
        display: 'inline-block',
    },
}));

const AssetsForm = (props) => {
    const {
        assets,
        meta,
        dispatch,
        location,
        history,
        confirm,
        originalAssets,
    } = props;

    const classes = useStyle();

    const [automatic, setAutomatic] = useState(true);
    const [manualType, setManualType] = useState('players');
    const [requestType, setRequestType] = useState('commercial appearance');
    const [loaded, setLoaded] = useState(true);
    const [deleteItems, setDeleteItems] = useState([]);

    useEffect(() => {
        getAssets();
    }, [manualType, requestType, automatic]);

    useEffect(() => {
        if (confirm) {
            handleTeamSubmit();
        }
    }, [confirm]);

    const getAssets = (page = 1) => {
        let filters = null;

        if (!automatic) {
            filters = {};
            filters['access_type'] = manualType;
            filters['request_type'] = manualType === 'staff' ? requestType : '';
        }

        props.dispatch(getInventory(filters, page));
    }

    const fileDropped = (data) => {
        let assetsTmp = [...assets];

        data.forEach(row => {
            if (row.errors.length > 0) {
                return;
            }

            if (!row.data.session_name) {
                return;
            }
            assetsTmp = [...assetsTmp, row.data];
        });

        setAssets(assetsTmp, 'import');
    };

    const deleteAssets = () => {
        return new Promise((resolve, reject) => {
            axios.delete('/api/team-assets/all', {data: {asset_ids: deleteItems}}).then(resp => {
                resolve();
            }).catch(errors => {
                dispatch({type: 'inventory/SET_ERRORS', payload: errors.response.data.errors});
                setLoaded(true);
            });
        });
    }

    const postAssets = () => {
        return new Promise((resolve, reject) => {
            if(assets.length > 0) {
                axios.post('/api/team-assets', {assets}).then(resp => {
                    resolve();
                }).catch(errors => {
                    dispatch({type: 'inventory/SET_ERRORS', payload: errors.response.data.errors});
                    dispatch({type: 'inventory/SET_CONFIRM', payload: false});
                    setLoaded(true);
                });
            } else {
                resolve();
            }
        });
    }

    const handleTeamSubmit = () => {
        setLoaded(false);

        if (deleteItems.length > 0) {
            deleteAssets().then(() => {
                postAssets().then(() => {
                    getAssets(1);
                    dispatch({type: 'inventory/SET_ORIGINAL_ASSETS', payload: assets});
                    dispatch({type: 'inventory/SET_ASSETS_CONFIRM', payload: true});
                    dispatch({type: 'inventory/SET_CONFIRM', payload: false});
                    setLoaded(true);
                });
            });
        } else {
            postAssets().then(() => {
                getAssets(1)
                dispatch({type: 'inventory/SET_ORIGINAL_ASSETS', payload: []});
                dispatch({type: 'inventory/SET_CONFIRM', payload: false});
                dispatch({type: 'inventory/SET_ORIGINAL_ASSETS', payload: assets});
                setLoaded(true);
            });
        }

    }

    const setAssets = (assets, mode = '') => {
        let newMeta = {...meta};

        if (mode === 'delete') {
            newMeta = {...newMeta, total: newMeta.total - 1};
        } else if(mode === 'add') {
            newMeta = {...newMeta, total: newMeta.total + 1};
        } else if(mode === 'import') {
            newMeta = {...newMeta, total: newMeta.total + assets.length};
        }

        dispatch({type: 'inventory/SET_ASSETS', payload: {data: assets, meta: newMeta}});
    };

    const createMode = location.state && location.state.createInventory;

    return (
        <>
            {!loaded &&
            <div className="media-loader-container">
                <Loader loaded={loaded} loadedClassName="loaded-content h-100"/>
            </div>
            }
            <Grid item md={12} style={{paddingLeft: 0, paddingRight: 0}}>
                <Formik>
                    <Grid container direction="column">

                        <Grid item>
                            <Grid container direction="column" className="assets-form">
                                <Grid item>
                                    <Typography className="step-header">
                                        Creating Assets
                                    </Typography>
                                </Grid>
                                <Grid item md={5}>
                                    <Typography className="header-notice">
                                        Create assets, and assign them to each partner according to agreed allocation.
                                        When
                                        released,
                                        partners will be able to log their request under each assets.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="action-title">
                                        Asset Upload
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography className="action-notice">
                                        Add new assets automatically by uploading a csv file. Manually create assets by
                                        switching off the
                                        below toggle.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        checked={automatic}
                                        onChange={() => {
                                            if (originalAssets !== assets) {
                                                dispatch({type: 'inventory/SET_ERRORS', payload: {unsaved: 'Please save the changes'}});
                                            } else {
                                                setAutomatic(!automatic);
                                            }
                                        }}
                                        name="automatic"
                                        color="primary"
                                        classes={{root: classes.switchBtn}}
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />
                                    {automatic ? <span className="switch-label">Automatic upload</span> :
                                        <span className="switch-label">Manual Entry</span>}

                                </Grid>
                                {!automatic &&
                                <Grid container direction="row">
                                    <Grid item md={7}>

                                        <ToggleButtonGroup
                                            value={manualType}
                                            exclusive
                                            onChange={(event, active) => {
                                                if (active) {
                                                    if (originalAssets !== assets) {
                                                        dispatch({type: 'inventory/SET_ERRORS', payload: {unsaved: 'Please save the changes'}});
                                                    } else {
                                                        setManualType(active)
                                                    }
                                                }
                                            }}
                                            className="assets-toggle"
                                            aria-label="text alignment"
                                        >
                                            <ToggleButton value="players" aria-label="left aligned">
                                                Players
                                            </ToggleButton>
                                            <ToggleButton value="staff" aria-label="centered">
                                                Staff
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                    </Grid>
                                    {manualType === 'staff' &&
                                    <Grid item>

                                        <ToggleButtonGroup
                                            value={requestType}
                                            exclusive
                                            onChange={(event, type) => {
                                                if (type) {
                                                    if (originalAssets !== assets) {
                                                        dispatch({type: 'inventory/SET_ERRORS', payload: {unsaved: 'Please save the changes'}});
                                                    } else {
                                                        setRequestType(type);
                                                    }
                                                }
                                            }}
                                            className="assets-toggle"
                                            aria-label="text alignment"
                                        >
                                            <ToggleButton value="commercial appearance" aria-label="left aligned">
                                                Commercial Appearance
                                            </ToggleButton>
                                            <ToggleButton value="partner event" aria-label="centered">
                                                Partner Event
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                    </Grid>
                                    }

                                </Grid>

                                }
                                {automatic &&
                                <InventoryUpload fileDropped={fileDropped} step={2}/>
                                }

                            </Grid>
                        </Grid>
                        <Grid item>
                            <AssetsList
                                assets={assets}
                                setAssets={setAssets}
                                manual={!automatic}
                                requestType={requestType}
                                manualType={manualType}
                                classes={classes}
                                setDeleteItems={setDeleteItems}
                            />

                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item>
                                    <Button class="footer-buttons cancel" text={createMode ? "PREVIOUS STEP" : 'CANCEL'}
                                            onClick={() => {
                                                if (createMode) {
                                                    history.push('/admin/inventory-management/team-form');
                                                } else {
                                                    history.push('/admin/inventory-management');
                                                }

                                            }}/>
                                    <Button class="footer-buttons next"
                                            text={createMode ? "CREATE INVENTORY" : 'SAVE CHANGES'}
                                            onClick={() => {
                                                if (createMode) {
                                                    dispatch({type: 'inventory/SET_ASSETS_CONFIRM', payload: false});
                                                } else {
                                                    handleTeamSubmit();
                                                }

                                            }}/></Grid>
                            </Grid>

                        </Grid>
                        <Grid item style={{marginTop: 30}}>
                            {meta && meta.per_page < meta.total && (
                                <Pagination
                                    prevPageText={
                                        <div className="prev-next">
                                            <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left"/>
                                        </div>}
                                    nextPageText={
                                        <div className="prev-next">
                                            <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right"/>
                                        </div>}
                                    activePage={meta.current_page}
                                    itemsCountPerPage={Number(meta.per_page)}
                                    totalItemsCount={meta.total}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages={true}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    linkClassPrev="prev-next"
                                    linkClassNext="prev-next"
                                    onChange={(page) => {
                                        if (originalAssets !== assets) {
                                            dispatch({type: 'inventory/SET_ERRORS', payload: {unsaved: 'Please save the changes'}});
                                        } else {
                                            getAssets(page);
                                        }

                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>

                </Formik>
            </Grid>
        </>

    );
};
const mapStateToProps = (state => ({
    team: state.inventory.team,
    step: state.inventory.step,
    confirm: state.inventory.confirm,
    assets: state.inventory.assets.data || [],
    originalAssets: state.inventory.originalAssets,
    meta: state.inventory.assets.meta || [],
}));
export default connect(mapStateToProps)(AssetsForm);
