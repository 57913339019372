import {Redirect, Route} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import PermissionChecker from "./Permissions/PermissionChecker";

const ProtectedRoute = ({ component: Comp, path, user, loaded, one, some, ...rest }) => {

    let checker = new PermissionChecker(user);

    return (
        <Route
            path={path}
            {...rest}
            render={props => {

                const pass = () => {
                    return (
                        <Comp one{...props} {...rest} />
                    );
                }

                const fail = () => {
                    return (
                        <Redirect to='/' />
                    );
                }

                if (!loaded || !user) {
                    return fail();
                }

                //If it needs permissions
                if (one) {
                    if (checker.one(one)) {
                        return pass();
                    } else {
                        return fail();
                    }
                }

                if (some && some.length > 0) {
                    if (checker.some(some)) {
                        return pass();
                    } else {
                        return fail();
                    }
                }

                //Otherwise logged in user is good enaught.
                return pass();
            }}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    loaded: state.user.loaded,
});

export default  connect(mapStateToProps)(ProtectedRoute);
