import { authHeader } from "~/helpers/authHeader";
import { setCmsBlocks } from "~/helpers/cmsHelper";

export const fetchMe = () => {

    return (dispatch) => {

        return fetch('/api/users/me', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => {
                dispatch({
                    type: 'user/SET_LOADED',
                    payload: false,
                });

                let respJson = text.length ? JSON.parse(text) : null

                if (!respJson || respJson.error) {
                    localStorage.removeItem('token');

                    dispatch({
                        type: 'user/SET_LOADED',
                        payload: true,
                    });

                    dispatch({
                        type: 'user/SET_USER',
                        payload: null,
                    });

                    return null;
                }
                return respJson;

            })
            .then((user) => {
                if(user){
                    dispatch({
                        type: 'user/SET_USER',
                        payload: user,
                    });
    
                    dispatch({
                        type: 'user/SET_CMS_BLOCKS',
                        payload: setCmsBlocks(user.partners[0]),
                    });
    
                    window.axios.defaults.headers.authorization = 'Bearer ' + localStorage.getItem('token');
                }
            })
            .then(() => {
                dispatch({
                    type: 'user/SET_LOADED',
                    payload: true,
                });
            });
    }
}
