import React from 'react';

import './validationError.scss';

const ValidationErrors = (props) => {

    if (!props.errors) {
        return null;
    }

    return (
            <div className="validation-errors">
                {props.errors.map((value, index) => {
                    return <p className="validation-error" key={index}>{value}</p>
                })}
            </div>
    );
} 
export default ValidationErrors;

