import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {connect} from "react-redux";

const ManualAllocationBar = ({dispatch}) => {
    const [showManual, setShowManual] = useState(true)

    if (!showManual) {
        return null;
    }
    return (
        <div className="events-block-new cards">
            <Grid container direction="row" justify="space-between">
                <Grid item md={10}>
                    <Typography className="manual-text">
                        You are currently using automatic allocation. Switch to manual allocation to manually edit and approve requests.
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography className="manual-action">
                                <span onClick={() => dispatch({type: 'talent_access_admin/SET_MANUAL_DIALOG_SHOW', payload: true})}>MANUAL ALLOCATION</span>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className="close-wrap">
                                <img className="close-action" src="/images/icons/SVG/Close.svg" alt="Close Manual" onClick={() => setShowManual(false)}/>
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );
};

export default connect(null, null)(ManualAllocationBar);
