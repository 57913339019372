import React from 'react';
import {Card} from "@material-ui/core";
import './ApprovalRequestSidebar.scss';

const ApprovalRequestSidebar = ({meta, requestStatus, setRequestStatusParam,tabRequestType, setType, statusValues}) => {
    return (
        <div>
            <Card className={"request-types approvals" + (tabRequestType === 'approvals' ? ' active' : '') } style={{borderRadius: 0}}>
                <div className="approval-sidebar-title" onClick={() => setType('approvals')}>
                    Approvals <span className="request-count">({meta.statusCount?.approvals})</span>
                </div>
                {tabRequestType === 'approvals' &&
                <>
                    <ul className="list-group">
                        <li className={"list-group-item list-group-item-action" + (!requestStatus ? ' active' : '')}
                            onClick={() => setRequestStatusParam(null)}>
                            <span className="request-name">All</span>
                        </li>
                        {Object.keys(statusValues).map((key) => {
                            return (
                                <li className={"list-group-item list-group-item-action" + (key === requestStatus ? ' active' : '')}
                                    key={key} onClick={() => setRequestStatusParam(key)}>
                                    <span className="request-name">{statusValues[key].name}</span> <span
                                    className="request-count">({meta.statusCount ? meta.statusCount[key] || 0 : 0})</span>
                                </li>
                            );
                        })}
                    </ul>
                </>
                }
            </Card>
        </div>
    );
};

export default ApprovalRequestSidebar;
