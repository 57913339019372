export const LIONS_TOUR_2025_MENU = [
    {
        link: "/lions-series-2025",
        label: "Series Information",
        permission: ["view_brand_guidelines"],
    },
    {
        link: "/lions-series-2025/key-contacts",
        label: "Key Contacts",
        permission: ["view_brand_guidelines"],
    },
];
