import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from '@material-ui/core';

const DeleteDeadlineDialog = (props) => {

    const useStyles = makeStyles((theme) => ({
        submitButton: {
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            color: 'white',
            height: 50,
            paddingLeft: 40,
            paddingRight: 40,
            fontWeight: 'bold',
            borderRadius: 0,
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        cancelButton: {
            fontWeight: 'bold',
            color: '#3e3f60',
            height: 50,
        }
    }));

    const classes = useStyles();

    return (
        <Dialog
            PaperProps={{ style: { maxWidth: 390, borderRadius: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: 25, paddingTop: 10 } }}
            open={props.openDeleteDeadlineDialog}
            disableBackdropClick
            keepMounted
            onClose={props.handleCloseDialog}>
            <DialogTitle>
                <span style={{ fontSize: 30, fontWeight: 'bolder', color: '#3e3f60' }}>Delete Key Deadline?</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this Key Deadline? This action will not be reversible.
                </DialogContentText>
                <Grid container direction="row" alignItems="flex-start">
                    <Grid item>
                        <Button fullWidth size="large" className={classes.submitButton} onClick={() => props.handleDeleteDeadline(true)} color="primary">
                            Yes, delete
                    </Button>
                    </Grid>
                    <Grid item>
                        <Button fullWidth size="large" className={classes.cancelButton} onClick={() => props.handleDeleteDeadline(false)} color="primary">
                            No, cancel
                    </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default connect()(DeleteDeadlineDialog);
