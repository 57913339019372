import React from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import {Field} from "formik";
import Typography from "@material-ui/core/Typography";
import Button from "~/Components/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const StaffList = ({staff, setStaff, classes, manual = false}) => {

    const handleChange = (event, key, propertyName) => {
        let staffTmp = [...staff];
        staff[key][propertyName] = event.target.value;
        setStaff(staffTmp);

    }

    const deleteRecord = (key) => {
        let staffTmp = [...staff];
        staffTmp.splice(key, 1);
        setStaff(staffTmp);
    }

    const addStaff = () => {
        let staffTmp = [...staff, {name: '', category: ''}];
        setStaff(staffTmp);
    }
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>STAFF NAME</TableCell>
                            <TableCell align="right">CATEGORY</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staff.map((staff, key) => (
                            <TableRow key={key}>
                                <TableCell scope="row">
                                    {key + 1}
                                </TableCell>
                                <TableCell scope="row">
                                    <Field
                                        name="name[]"
                                        type="text">
                                        {({field}) => (
                                            <TextField
                                                name="title"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputBorder
                                                    }
                                                }}
                                                variant="outlined"
                                                {...field}
                                                value={staff.name}
                                                fullWidth
                                                placeholder="Name"
                                                onChange={(event) => handleChange(event, key, 'name')}
                                                error={false}
                                                helperText={''}
                                            />
                                        )}

                                    </Field>
                                </TableCell>
                                <TableCell align="right" className="inputs-select list">
                                    <Select
                                        variant="outlined"
                                        name="category[]"
                                        value={staff.category}
                                        onChange={(event) => handleChange(event, key, 'category')}
                                    >
                                        <MenuItem value="Head Coach">Head Coach</MenuItem>
                                        <MenuItem value="Managing Director">Managing director</MenuItem>
                                        <MenuItem value="Chairman">Chairman</MenuItem>
                                        <MenuItem value="Board Member">Board Member</MenuItem>
                                        <MenuItem value="Assistant Coach">Assistant Coach</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography className="delete-row" onClick={() => deleteRecord(key)}>x</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {manual &&
                <Button class="footer-buttons add-new" text="ADD MEMBER" onClick={addStaff}/>
            }
        </div>

    );
};

export default StaffList;
