import React from 'react';

import './AuthContainer.scss';

class AuthContainer extends React.Component {

    componentDidMount() {
        document.body.classList.add('authentication');
    }

    componentWillUnmount() {
        document.body.classList.remove('authentication');
    }

    render() {
        return (
            <div className="container-fluid h-100 animals">
                <div className="row h-100">
                    <div className="w-100">
                        <div className='auth-box'>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AuthContainer;
