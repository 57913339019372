import React from 'react';
import { connect } from "react-redux";
import {
    Grid
} from '@material-ui/core';
import { Redirect, withRouter } from 'react-router-dom';
import AdminMenu, { menuItems } from "./AdminMenu";
import PermissionChecker from "../Permissions/PermissionChecker";

const Admin = (props) => {

    //Redirect to first item in a menu..
    let checker = new PermissionChecker(props.user);

    let redirectTo = menuItems.find((item) => {
        if (Array.isArray(item.permission)) {
            return checker.some(item.permission);
        }
        return checker.one(item.permission);
    });

    if (redirectTo) {
        return (
            <Redirect to={redirectTo.link} />
        );
    }

    return (
        <section>
            <Grid container>
                <h1>Admin</h1>
            </Grid>
            <AdminMenu/>
        </section>
    );
}

const mapStateToProps = (state) => ({
    user: state.user?.user || null
});

export default connect(mapStateToProps)(withRouter(Admin));
