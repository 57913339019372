import React from 'react';
import './index.scss';
const CloseIcon = ({setShowInfo}) => {

    return (
        <div onClick={setShowInfo}>
            <span className="close-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path fill="#3E3F60" fillRule="evenodd" d="M23.457.476l2.298 2.298-10.341 10.341 10.341 10.342-2.298 2.298-10.342-10.341L2.774 25.755.476 23.457l10.341-10.342L.476 2.774 2.774.476l10.341 10.341L23.457.476z"/>
            </svg>
            </span>
        </div>
    );
};

export default CloseIcon;
