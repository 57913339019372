import React, { useEffect } from "react";
import classNames from "classnames";
import MaterialIcon from "material-icons-react";
import Moment from "react-moment";

import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Pagination from "react-js-pagination";
import { DropdownButton, Dropdown, Row, Col, Container } from "react-bootstrap";
import SearchInput from "~/Components/SearchInput";
import qs from "querystring";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PermissionChecker from "~/Permissions/PermissionChecker";

import axios from "axios";
import "./list.scss";
import "bootstrap-daterangepicker/daterangepicker.css";

import {
    Card,
    Grid,
    List,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import FiltersIconWhite from "../Components/Icons/FiltersIconWhite";
import FiltersIconBlack from "../Components/Icons/FiltersIconBlack";
import { withRouter } from "react-router";
import momentNative from "moment";
import { setOrderBy } from "~/Media/actions";
import Loader from "react-loader";
import Typography from "@material-ui/core/Typography";
import Permitted from "~/Permissions/Permitted";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    tableCard: {
        marginTop: 10,
    },
    tableHeaderBorder: {
        borderBottom: "2px solid #af001e",
    },
    tableCell: {
        [theme.breakpoints.up("md")]: {
            whiteSpace: "nowrap",
        },
        [theme.breakpoints.down("md")]: {
            whiteSpace: "none",
        },
    },
    pendingChip: {
        backgroundColor: "#d8dae64D",
        color: "#3e3f60",
    },
    awaitChip: {
        backgroundColor: "#ebae451A",
        color: "#ebae45",
    },
    approvedChip: {
        backgroundColor: "#44de6f1A",
        color: "#44de6f",
    },
    rejectedChip: {
        backgroundColor: "#af001e1A",
        color: "#af001e",
    },
    card: {
        padding: "0",
        height: "100%",
        borderRadius: 0,
    },
    tableContainer: {
        //height: "100%"
    },
}));

const BrandApprovalRequestsList = (props) => {
    const [request_types, setRequestTypes] = React.useState([]);
    const [meta, setMeta] = React.useState([]);
    const [showFilter, setShowFilter] = React.useState(false);
    const [requests, setRequests] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [sortBy, setSortBy] = React.useState("created_at");
    const [perPage, setPerPage] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [requestType, setRequestType] = React.useState(null);
    const [requestStatus, setRequestStatus] = React.useState(null);
    const [dateFrom, setDateFrom] = React.useState(null);
    const [dateTo, setDateTo] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);
    const [tabRequestType, setTabRequestType] = React.useState("approvals");

    const { user } = props;

    const classes = useStyles();
    const checker = new PermissionChecker(user);

    const location = props.location;
    let queryParams =
        location.search[0] === "?"
            ? qs.parse(location.search.slice(1))
            : qs.parse(location.search);

    const loadData = () => {
        let params = {
            page: queryParams.page || page,
            perPage: queryParams.perPage || perPage,
            sortBy: queryParams.sortBy || sortBy,
        };

        if (queryParams.search) {
            params.name = queryParams.search;
        }

        if (queryParams.userName) {
            params.userName = queryParams.userName;
        }

        if (queryParams.requestType) {
            params.request_type_id = queryParams.requestType;
        }

        if (queryParams.type) {
            params.type = queryParams.type;
            params.bothTypes = false;
        } else {
            params.type = "";
            params.bothTypes = true;
            setType(params.type);
        }

        setTabRequestType(params.type);

        if (queryParams.requestStatus) {
            params.status = queryParams.requestStatus;
        }

        if (queryParams.accessType) {
            params.accessType = queryParams.accessType;
        }

        if (queryParams.sessionName) {
            params.sessionName = queryParams.sessionName;
        }

        if (queryParams.dateFrom) {
            params.date_from = queryParams.dateFrom;
        }

        if (queryParams.dateTo) {
            params.date_to = queryParams.dateTo;
        }

        if (queryParams.type_of_request) {
            params.type = queryParams.type_of_request;
        }

        if (
            queryParams.search ||
            queryParams.requestType ||
            queryParams.requestStatus ||
            queryParams.search ||
            queryParams.dateFrom ||
            queryParams.dateTo ||
            queryParams.accessType ||
            queryParams.sessionName ||
            queryParams.type_of_request
        ) {
            setShowFilter(true);
        } else {
            setShowFilter(false);
        }
        //
        setSearch(queryParams.search);
        setRequestStatus(queryParams.requestStatus);
        setDateFrom(queryParams.dateFrom);
        setDateTo(queryParams.dateTo);
        setPerPage(queryParams.perPage || 10);
        setSortBy(queryParams.orderBy || "created_at");

        if (queryParams.requestType) {
            let type = request_types.find((type) => {
                return Number(queryParams.requestType) === type.id;
            });

            setRequestType(type);
        } else {
            setRequestType(null);
        }

        let endpoint = "/api/brand-request/user/list";

        if (props.adminMode) {
            endpoint = "/api/brand-admin";
        }

        setLoaded(false);

        axios
            .get(endpoint, {
                params: params,
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.data.data.length === 0) {
                    unsetQueryParams("page");
                }
                setLoaded(true);
                setMeta(res.data.meta);
                setRequests(res.data.data);
            });
    };

    const path = props.match.path;

    React.useEffect(loadData, [props.location.search]);

    React.useEffect(() => {
        axios
            .get("/api/request-type", {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setRequestTypes(res.data.requestTypes.data);

                if (queryParams.requestType) {
                    let type = res.data.requestTypes.data.find((type) => {
                        return Number(queryParams.requestType) === type.id;
                    });

                    setRequestType(type);
                }
            });
    }, []);

    const goToPage = (page) => {
        setQueryParams({ page });
    };

    const setSortByParam = (value) => {
        setQueryParams({ sortBy: value });
    };

    const setType = (value) => {
        setQueryParams({ type: value });
    };

    const setRequestTypeParam = (value) => {
        setQueryParams({ requestType: value ? value.id : null });
    };

    const setPerPageParam = (value) => {
        setQueryParams({ perPage: value });
    };

    const setQueryParams = (params) => {
        Object.keys(params).forEach((key) => {
            if (!params[key]) {
                delete params[key];
                delete queryParams[key];
            }
        });

        props.history.push({
            pathname: path,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };

    const setRequestStatusParam = (value) => {
        setQueryParams({ requestStatus: value });
    };

    const setSearchParam = (value) => {
        value = value.replace("?", "");

        setQueryParams({ search: value });
    };

    const setSearchSessionParam = (value) => {
        setQueryParams({ sessionName: value });
        setQueryParams({ search: value });
    };

    const handleDateRange = (start, end) => {
        setQueryParams({
            dateFrom: momentNative(start.format()).format("YYYY-MM-DD"),
            dateTo: momentNative(end.format()).format("YYYY-MM-DD"),
        });

        setDateFrom(start.format());
        setDateTo(end.format());
    };

    const sorByValues = {
        created_at: "Date",
        name: "Name",
        request_type_id: "Request Type",
        status: "Status",
    };

    const perPageValues = [10, 25, 50, 100];

    let statusValues = {
        pending: {
            class: "grey",
            name: "Pending",
        },
        cancelled: {
            class: "dark",
            name: "Cancelled",
        },
        approved: {
            class: "green",
            name: "Approved",
        },
        change: {
            class: "orange",
            name: "Awaiting changes",
        },
        rejected: {
            class: "red",
            name: "Rejected",
        },
    };

    const unsetQueryParams = (...parameters) => {
        if (Array.isArray(parameters)) {
            parameters.forEach((param) => {
                delete queryParams[param];
            });
        } else {
            delete queryParams[parameters];
        }

        props.history.push({
            pathname: path,
            search: qs.stringify(queryParams),
        });
    };

    const handleCancelDateRange = (event, picker) => {
        picker.element.val("");

        unsetQueryParams("dateFrom", "dateTo");
    };

    const handleApplyDateRange = (event, picker) => {
        picker.element.val(
            picker.startDate.format("DD/MM/YYYY") +
                " - " +
                picker.endDate.format("DD/MM/YYYY")
        );
    };

    const resetQuery = (selectedType) => {
        queryParams = {};
        setType(selectedType);
    };

    return (
        <>
            {!loaded && (
                <div className="media-loader-container">
                    <Loader
                        loaded={loaded}
                        loadedClassName="loaded-content h-100"
                    />
                </div>
            )}
            {/* TODO - remove comment when need navigation
                <section className="bodyContent bottom-line">
                    <Container className="navInnerContainer d-none d-md-block col-lg-11 col-md-10">
                        <ul className="navInner">
                            {
                                (checker.one('submit_approval_request') || checker.one('review_approvals_request')) && (checker.one('submit_talent_request') || checker.one('review_talent_request')) &&
                                <li className={`navInner__item d-none d-md-block ${tabRequestType === '' ? ' active' : ' '}`}
                                    onClick={() => resetQuery('')}>
                                    <span className="tab-name">All Requests</span>
                                </li>
                            }
                            <Permitted one={"review_approvals_request"}>
                                <li className={`navInner__item d-none d-md-block ${tabRequestType === 'approvals' ? ' active' : ' '}`}
                                    onClick={() => resetQuery('approvals')}>
                                    <span className="tab-name">Approvals</span>
                                </li>
                            </Permitted>
                        </ul>

                    </Container>
                </section>
            */}
            <section className="request-list-wrapper">
                <div
                    className="brand-approval-requests-list"
                    style={{ paddingTop: "27px" }}
                >
                    <div className="filters d-flex justify-content-end mb-4">
                        <Grid container direction="row" justify="space-between">
                            <Grid item>
                                <Typography className="type-title">
                                    {!tabRequestType
                                        ? "All Requests"
                                        : tabRequestType === "talent"
                                        ? "Talent Access"
                                        : "Approvals"}
                                </Typography>
                            </Grid>
                            <Grid item className="d-flex">
                                <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <div className="filter-label">
                                                    SORT:
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <DropdownButton
                                                    title={
                                                        sorByValues[
                                                            queryParams.sortBy
                                                        ] || sorByValues[sortBy]
                                                    }
                                                >
                                                    {Object.entries(
                                                        sorByValues
                                                    ).map((value, key) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={key}
                                                                onClick={() =>
                                                                    setSortByParam(
                                                                        value[0]
                                                                    )
                                                                }
                                                            >
                                                                {value[1]}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </DropdownButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <div className="filter-label">
                                                    SHOW:
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <DropdownButton title={perPage}>
                                                    {perPageValues.map(
                                                        (value) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={value}
                                                                    onClick={() =>
                                                                        setPerPageParam(
                                                                            value
                                                                        )
                                                                    }
                                                                >
                                                                    {value}
                                                                </Dropdown.Item>
                                                            );
                                                        }
                                                    )}
                                                </DropdownButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {!showFilter && (
                                            <div
                                                className="filter-icons-wrapper"
                                                onClick={() =>
                                                    setShowFilter(true)
                                                }
                                            >
                                                <FiltersIconBlack />
                                            </div>
                                        )}
                                        {showFilter && (
                                            <div
                                                className="filter-icons-wrapper active"
                                                onClick={() =>
                                                    setShowFilter(false)
                                                }
                                            >
                                                <FiltersIconWhite />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="row" spacing={4}>
                        <Grid item md={2}>
                            <Card
                                className="request-types"
                                style={{ borderRadius: 0 }}
                            >
                                <ul className="list-group">
                                    <li
                                        className={
                                            "list-group-item list-group-item-action" +
                                            (!requestStatus ? " active" : "")
                                        }
                                        onClick={() =>
                                            setRequestStatusParam(null)
                                        }
                                    >
                                        <span className="request-name">
                                            All
                                        </span>{" "}
                                        <span className="request-count">
                                            ({meta.statusCount?.all || 0})
                                        </span>
                                    </li>
                                    {Object.keys(statusValues).map((key) => {
                                        return (
                                            <li
                                                className={
                                                    "list-group-item list-group-item-action" +
                                                    (key === requestStatus
                                                        ? " active"
                                                        : "")
                                                }
                                                key={key}
                                                onClick={() =>
                                                    setRequestStatusParam(key)
                                                }
                                            >
                                                <span className="request-name">
                                                    {statusValues[key].name}
                                                </span>{" "}
                                                <span className="request-count">
                                                    (
                                                    {meta.statusCount
                                                        ? meta.statusCount[
                                                              key
                                                          ] || 0
                                                        : 0}
                                                    )
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Card>
                        </Grid>
                        <Grid item md={10}>
                            <Card className={classes.card}>
                                <TableContainer
                                    className={classes.tableContainer}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                className={
                                                    classes.tableHeaderBorder
                                                }
                                            >
                                                {props.adminMode && (
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        User Name
                                                    </TableCell>
                                                )}
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    Request Name
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    align="left"
                                                >
                                                    {tabRequestType === "talent"
                                                        ? "Talent Access Type"
                                                        : "Request Type"}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    align="left"
                                                >
                                                    Submission Date
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    align="left"
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    align="left"
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {showFilter && (
                                                <TableRow
                                                    key={0}
                                                    className="filters"
                                                >
                                                    {props.adminMode && (
                                                        <TableCell
                                                            className={
                                                                classes.tableCell
                                                            }
                                                        ></TableCell>
                                                    )}
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {tabRequestType ===
                                                            "approvals" ||
                                                        !tabRequestType ? (
                                                            <SearchInput
                                                                searchHandler={(
                                                                    searchValue
                                                                ) =>
                                                                    setSearchParam(
                                                                        searchValue
                                                                    )
                                                                }
                                                                value={search}
                                                            />
                                                        ) : (
                                                            <SearchInput
                                                                searchHandler={(
                                                                    searchValue
                                                                ) =>
                                                                    setSearchSessionParam(
                                                                        searchValue
                                                                    )
                                                                }
                                                                value={
                                                                    queryParams.sessionName ||
                                                                    ""
                                                                }
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        {tabRequestType ===
                                                            "approvals" && (
                                                            <DropdownButton
                                                                className="brand-dropdown"
                                                                title={
                                                                    requestType
                                                                        ? requestType.name
                                                                        : "All"
                                                                }
                                                            >
                                                                <Dropdown.Item
                                                                    key={0}
                                                                    onClick={() =>
                                                                        setRequestTypeParam(
                                                                            null
                                                                        )
                                                                    }
                                                                >
                                                                    All
                                                                </Dropdown.Item>
                                                                {request_types.map(
                                                                    (
                                                                        value,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={
                                                                                    key
                                                                                }
                                                                                onClick={() =>
                                                                                    setRequestTypeParam(
                                                                                        value
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    value.name
                                                                                }
                                                                            </Dropdown.Item>
                                                                        );
                                                                    }
                                                                )}
                                                            </DropdownButton>
                                                        )}
                                                        {tabRequestType ===
                                                            "talent" && (
                                                            <DropdownButton
                                                                className="brand-dropdown"
                                                                title={
                                                                    queryParams.accessType ||
                                                                    "All"
                                                                }
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setQueryParams(
                                                                            {
                                                                                accessType:
                                                                                    null,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    All
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setQueryParams(
                                                                            {
                                                                                accessType:
                                                                                    "players",
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Player
                                                                    Access
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setQueryParams(
                                                                            {
                                                                                accessType:
                                                                                    "staff",
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Staff Access
                                                                </Dropdown.Item>
                                                            </DropdownButton>
                                                        )}
                                                        {!tabRequestType && (
                                                            <DropdownButton
                                                                className="brand-dropdown"
                                                                title={
                                                                    queryParams.type_of_request ||
                                                                    "All"
                                                                }
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setQueryParams(
                                                                            {
                                                                                type_of_request:
                                                                                    null,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    All
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setQueryParams(
                                                                            {
                                                                                type_of_request:
                                                                                    "approvals",
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Approvals
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setQueryParams(
                                                                            {
                                                                                type_of_request:
                                                                                    "talent",
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Talent
                                                                    Access
                                                                </Dropdown.Item>
                                                            </DropdownButton>
                                                        )}
                                                    </TableCell>

                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                autoUpdateInput:
                                                                    (dateFrom &&
                                                                        dateTo) ||
                                                                    false,
                                                                locale: {
                                                                    format: "DD/MM/YYYY",
                                                                    cancelLabel:
                                                                        "Clear",
                                                                },
                                                                startDate:
                                                                    momentNative(
                                                                        dateFrom
                                                                    ).format(
                                                                        "DD/MM/YYYY"
                                                                    ),
                                                                endDate:
                                                                    momentNative(
                                                                        dateTo
                                                                    ).format(
                                                                        "DD/MM/YYYY"
                                                                    ),
                                                            }}
                                                            onCancel={
                                                                handleCancelDateRange
                                                            }
                                                            onApply={
                                                                handleApplyDateRange
                                                            }
                                                            onCallback={
                                                                handleDateRange
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                className="filter-input"
                                                                defaultValue=""
                                                            />
                                                        </DateRangePicker>
                                                    </TableCell>

                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        <DropdownButton
                                                            className="brand-dropdown"
                                                            title={
                                                                requestStatus
                                                                    ? statusValues[
                                                                          requestStatus
                                                                      ].name
                                                                    : "All"
                                                            }
                                                        >
                                                            <Dropdown.Item
                                                                key={0}
                                                                onClick={() =>
                                                                    setRequestStatusParam(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                All
                                                            </Dropdown.Item>
                                                            {Object.keys(
                                                                statusValues
                                                            ).map(
                                                                (
                                                                    value,
                                                                    key
                                                                ) => {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={
                                                                                key
                                                                            }
                                                                            onClick={() =>
                                                                                setRequestStatusParam(
                                                                                    value
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                statusValues[
                                                                                    value
                                                                                ]
                                                                                    .name
                                                                            }
                                                                        </Dropdown.Item>
                                                                    );
                                                                }
                                                            )}
                                                        </DropdownButton>
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="right"
                                                    ></TableCell>
                                                </TableRow>
                                            )}

                                            {requests &&
                                                requests
                                                    .sort((item1, item2) => {
                                                        console.log(
                                                            new Date(
                                                                item2.updated_at
                                                            ) -
                                                                new Date(
                                                                    item1.updated_at
                                                                )
                                                        );
                                                        return (
                                                            new Date(
                                                                item2.updated_at
                                                            ) -
                                                            new Date(
                                                                item1.updated_at
                                                            )
                                                        );
                                                    })
                                                    .map((req, key) => {
                                                        return (
                                                            <TableRow
                                                                className="request-row"
                                                                key={key}
                                                            >
                                                                {props.adminMode && (
                                                                    <TableCell
                                                                        className={
                                                                            classes.tableCell
                                                                        }
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        {
                                                                            req
                                                                                .user
                                                                                ?.name
                                                                        }
                                                                    </TableCell>
                                                                )}
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    {req.name}
                                                                </TableCell>
                                                                {tabRequestType ? (
                                                                    <TableCell
                                                                        className={
                                                                            classes.tableCell
                                                                        }
                                                                        align="left"
                                                                        style={{
                                                                            textTransform:
                                                                                "capitalize",
                                                                        }}
                                                                    >
                                                                        {req.type ===
                                                                        "approvals"
                                                                            ? req
                                                                                  .request_type
                                                                                  .name
                                                                            : req
                                                                                  .teamAsset
                                                                                  .access_type}
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell
                                                                        className={
                                                                            classes.tableCell
                                                                        }
                                                                        align="left"
                                                                    >
                                                                        {req.type ===
                                                                        "approvals"
                                                                            ? "Approvals"
                                                                            : "Talent Access"}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                    align="left"
                                                                >
                                                                    <Moment format="DD/MM/YYYY">
                                                                        {
                                                                            req.created_at
                                                                        }
                                                                    </Moment>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                    align="left"
                                                                >
                                                                    <div
                                                                        className={classNames(
                                                                            "colum-td",
                                                                            "col6",
                                                                            "points",
                                                                            statusValues[
                                                                                req
                                                                                    .status
                                                                            ]
                                                                                .class
                                                                        )}
                                                                    >
                                                                        <MaterialIcon icon="fiber_manual_record" />
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    width="5%"
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                    align="right"
                                                                >
                                                                    <Permitted
                                                                        one={
                                                                            req.type ===
                                                                            "approvals"
                                                                                ? "submit_approval_request"
                                                                                : "submit_talent_request"
                                                                        }
                                                                    >
                                                                        <Link
                                                                            component={
                                                                                RouterLink
                                                                            }
                                                                            className="button color-red inGrid view-request"
                                                                            to={{
                                                                                pathname:
                                                                                    props.adminMode
                                                                                        ? `/admin/request-management/${req.id}`
                                                                                        : `/my-requests/${req.id}`,
                                                                            }}
                                                                        >
                                                                            {props.adminMode
                                                                                ? "REVIEW REQUEST "
                                                                                : "VIEW REQUEST "}
                                                                            <MaterialIcon icon="arrow_forward" />
                                                                        </Link>
                                                                    </Permitted>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            {(!requests ||
                                                requests.length === 0) && (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <p>
                                                            You have no requests
                                                            is this category.
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>

                            <ul className="list-inline pt-2">
                                <li className="list-inline-item points grey">
                                    <MaterialIcon icon="fiber_manual_record" />
                                    Pending
                                </li>
                                <li className="list-inline-item points green">
                                    <MaterialIcon icon="fiber_manual_record" />
                                    Approved
                                </li>
                                <li className="list-inline-item points orange">
                                    <MaterialIcon icon="fiber_manual_record" />
                                    Awaiting Changes
                                </li>
                                <li className="list-inline-item points red">
                                    <MaterialIcon icon="fiber_manual_record" />
                                    Rejected
                                </li>

                                <li className="list-inline-item points dark">
                                    <MaterialIcon icon="fiber_manual_record" />
                                    Cancelled
                                </li>
                            </ul>

                            {meta.per_page < meta.total && (
                                <Pagination
                                    activePage={parseInt(meta.current_page)}
                                    itemsCountPerPage={parseInt(meta.per_page)}
                                    totalItemsCount={parseInt(meta.total)}
                                    pageRangeDisplayed={5}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    onChange={(page) => goToPage(page)}
                                />
                            )}
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user,
});
export default connect(mapStateToProps)(withRouter(BrandApprovalRequestsList));
