import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ResponseDialog from "~/Components/ResponseDialog";
import { destroyNotification } from './notificationActions';

const NotificationProvider = (props) => {

    const onClose = () => {
        props.dispatch(destroyNotification());
    };

    return (
        <>
            {props.children}
            {props.notification.status === 'success' && (
                <div className="notification-container">
                    <ResponseDialog
                        onClose={() => onClose()}
                        text={props.notification.text || 'Action successfully completed!'}
                        title={props.notification.title || 'Success'}/>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    notification: state.notification,
});

export default connect(mapStateToProps)(NotificationProvider);
