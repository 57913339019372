import React from 'react';

const FolderIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27">
                <path fill="#3E3F60" fillRule="evenodd" d="M10.563 4.125L13 8.188h13V22H0V4.125h10.563zM4.875 9.813V8.188l6.229-.001L9.643 5.75H1.625v14.625h22.75V9.813h-19.5z"/>
            </svg>
        </>
    );
};

export default FolderIcon;
