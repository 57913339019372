import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
import { withStyles, Grid } from "@material-ui/core";
import RequestDetails from "./RequestDetails";
import axios from "axios";

import './index.scss';
import SubmissionDetails from "~/BrandApprovalRequest/SubmissionDetails";
import Description from "~/BrandApprovalRequest/Description";
import CommentsForm from "~/BrandApprovalRequest/CommentsForm";
import RequestActions from "~/BrandApprovalRequest/RequestActions";
import BrandForm from "../BrandApprovalForm/BrandForm";
import Button from "~/Components/Button";
import PreviewFile from "~/Components/PreviewFile";
import TalentAccessRequestForm from "~/TalentAccessForm/TalentAccessRequestForm";
import PermissionChecker from "~/Permissions/PermissionChecker";
import {connect} from "react-redux";

const styles = theme => ({});

const mapStateToProps = ((state) => ({
    user: state.user.user,
}));

export default connect(mapStateToProps, null)(withStyles(styles)(props => {
    const [request, setRequest] = useState();
    const [refreshRequest, setRefreshRequest] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(false);

    const checker = new PermissionChecker(props.user);

    useEffect(() => {
        if (refreshRequest) {
            let endpoint = props.history.location.pathname.indexOf('admin') !== -1 ? 'brand-admin' : 'brand-request';
            axios
                .get(`/api/${endpoint}/${props.match.params.id}`)
                .then(res => {
                    setRefreshRequest(false);
                    setShowEdit(false);
                    setRequest(res.data.data);
                }).catch((error) => {
                    if (error.response && (error.response.status === 404 || error.response.status === 403)) {
                        props.history.push('/404')
                    }
                });
        }
    }, [refreshRequest]);

    const handleSubmit = (payload) => {
        let uri = request.type === 'approvals' ? "/api/brand-request/" : "/api/talent-access-request/"
        return axios
            .post(uri + request.id, payload)
            .then(res => {
                setRefreshRequest(true);
                return res;
            });
    };

    const handleBack = () => {
        setShowEdit(false);
        window.scrollTo(0, 0);
    };

    const setLoaded = () => {

    }

    if (request) {
        if (request.type === 'approvals' && !checker.one('submit_approval_request')) {
            return <Redirect to='/dashboard'  />
        }

        if (request.type === 'talent' && !checker.one('submit_talent_request')) {
            return <Redirect to='/dashboard'  />
        }
    }


    return (
        <section className="brand-approval sticky-footer my-request">
            {
                props.adminMode ?
                    <Grid container direction="row">
                        <Grid item>
                            <span>
                                <Button onClick={props.history.goBack} class="btn-grey footer-downloads noMarg" text="Back" />
                            </span>
                        </Grid>
                    </Grid>
                    : <Grid container direction="row">
                        <Grid item>
                            <span>
                                <Button onClick={props.history.goBack} class="btn-grey footer-downloads noMarg" text="Back" />
                            </span>
                        </Grid>
                    </Grid>
            }
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div className="bodyContent">
                        <div className="request-title">
                            {
                                request &&
                                <h1>{`${request.id}: ${request.name}`}</h1>
                            }
                        </div>

                        {
                            request && (
                                <>
                                    {
                                        showEdit &&
                                        <section className="approvals-content">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <SubmissionDetails request={request} />
                                                </div>
                                                <div className="col-lg-9">
                                                    {request.type === 'approvals'
                                                        ?
                                                        <BrandForm defaultRequest={{...request}}
                                                                   onBack={handleBack.bind(this)}
                                                                   values={{...request}}
                                                                   submitButtonLabel="SAVE REQUEST"
                                                                   onSubmit={handleSubmit.bind(this)}/>
                                                        :
                                                        <TalentAccessRequestForm
                                                            defaultRequest={{...request}}
                                                            onBack={handleBack.bind(this)}
                                                            values={{...request}}
                                                            submitButtonLabel="SAVE REQUEST"
                                                            onSubmit={handleSubmit.bind(this)}
                                                        />
                                                    }
                                                </div>

                                            </div>
                                        </section>
                                    }
                                    {
                                        !showEdit &&
                                        <>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="request-sections">
                                                    <SubmissionDetails request={request} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="request-sections">
                                                    <RequestDetails request={request} setShowPreview={setShowPreview} setPreviewFile={setPreviewFile} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="request-sections">
                                                    <Description request={request} />
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" spacing={2} justify="flex-end">
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                    <CommentsForm request={request} setRefreshRequest={setRefreshRequest} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }

                                    <Grid container direction="row" spacing={3} justify="flex-end" >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="request-sections">
                                            <RequestActions adminMode={props.adminMode}
                                                request={request}
                                                setRequest={setRequest}
                                                setShowEdit={setShowEdit}
                                                showEdit={showEdit}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    </div>
                </Grid>
            </Grid>
            <PreviewFile
                previewFile={previewFile}
                showPreview={showPreview}
                setLoaded={setLoaded}
                setShowPreview={() => setShowPreview(false)}
            />
        </section>
    );
}));
