import React, {useEffect, useState} from 'react';
import {Grid, makeStyles, Paper} from "@material-ui/core";
import LibraryApi from "~/services/api/library";
import {connect} from "react-redux";
import {resetOverridesData} from "~/Admin/PartnerManagement/ContentManagement/cmsInitialValues";

const Description = ({approvalsCms}) => {

    const useStyles = makeStyles((theme) => ({
        icons: {
            width: 22,
            height: 22
        },
        documentPaper: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            minHeight: 46,
            height: 'auto',
            display: 'flex',
            alignItems: 'left',
            width: '100%',
            minWidth: '200px',
            [theme.breakpoints.down(700)]: {
                width: 'max-content'
            },
        },
        downloadIcon: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            textAlign: 'center',
            cursor: "pointer",
        },
        fileNameText: {
            paddingLeft: '.25rem',
            [theme.breakpoints.between(960, 1600)]: {
                visibility: 'hidden',
                position: 'absolute',
                paddingLeft: '1.25rem',
                minWidth: '190px',
                "&::before": {
                    visibility: 'visible',
                    content: '"Approvals Flow Chart"',
                    paddingLeft: '.25rem',
                    float: 'left',
                },
            },
        }
    }));

    const classes = useStyles();
    const [approvalDocuments, setApprovalDocuments] = useState(null);

    const approvalsDescription = approvalsCms?.content?.html;
    const overrideApprovals = approvalsCms?.content?.override_approvals;

    useEffect(() => {

        if (approvalsCms && approvalsCms.files && overrideApprovals == "1") {
            setApprovalDocuments(approvalsCms?.files);
        } else {
            axios.get('/api/media-categories/manuals?slug=approval-documents').then((response) => {
                setApprovalDocuments(response.data.data);
            });
        }
    }, [approvalsCms]);
    return (
        <div className="approvals-description">
            <div className="instructions">
                <div className="pb-1">
                    The Partnerships Team have developed a clear and simple
                    process to assist brands with creative executions and
                    approvals.
                </div>
                <div className="pb-1">
                    See Partner Handbook document for the full approvals process
                    and key timings.
                </div>                

                {approvalDocuments &&

                approvalDocuments.map((document, key) => {
                    return (
                        <Grid container alignContent="center" key={key} spacing={1} justify="flex-start">
                            <Grid item md>
                                <Paper className={classes.documentPaper}>
                                    <span><img className={classes.icons}
                                               src="/images/icons/SVG/File Type/File.svg"/></span>
                                    <span
                                        className={classes.fileNameText}>{document.name.length > 20 ? document.name.substring(0, 20) + '...' : document.name}</span>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <a href={document.full_path || document.path} download={`${document.name}.${document.ext}`}>
                                    <Paper className={classes.downloadIcon}>
                                        <img className={classes.icons} src="/images/icons/SVG/Download.svg"/>
                                    </Paper>
                                </a>

                            </Grid>
                        </Grid>
                    )
                })

                }
            </div>

            <h3>
                Please note:
            </h3>
            {approvalsDescription && overrideApprovals == "1"
                ?
                <div dangerouslySetInnerHTML={{__html: approvalsDescription}}/>
                :
                <div dangerouslySetInnerHTML={{ __html: resetOverridesData.requests.approvals}} />
            }
        </div>
    );
};

const mapStateToProps = ((state) => ({
    approvalsCms: state.user.cmsBlocks?.requests_approvals,
}));

export default connect(mapStateToProps)(Description);
