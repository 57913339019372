import React, {useState, useEffect, useCallback} from 'react';
import { Field, Form, Formik } from "formik";
import {
    Button,
    Divider,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    Drawer,
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import Upload from "~/Components/Upload";
import BeatLoader from "react-spinners/BeatLoader";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {fetchUsersList} from "~/Admin/PartnerManagement/partnerActions";
import {debounce} from "lodash";
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({

    closeButton: {
        cursor: 'pointer',
        float: 'right',
    },
    createPartnerButton: {
        backgroundColor: theme.primaryColor,
        color: 'white',
        fontWeight: 600,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001eA5',
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: '#af001e80'
        }
    },
    cancelPartnerButton: {
        backgroundColor: '#d8dae6',
        fontWeight: 600,
        color: '#3e3f60',
        borderRadius: 0
    },
    dividerLink: {
        backgroundColor: theme.primaryColor
    },
    notice: {
        maxWidth: 366,
        fontSize: 10,
        color: theme.lightGrey,
    },
    dropDownLabel: {
        width: 137,
        height: 22,
        fontSize: 14,
        fontWeight: 600,
        color: theme.dark,
    },
    createPartnerPadding: {
        padding: 20,
        minWidth: 400
    },
    updateLogo: {
        backgroundColor: '#f3f4f8',
        padding: '11px 135px 11px 127px',
        fontSize: 12,
        color: '#3e3f60'
    }
}));

const UpdatePartner = (props) => {

    const classes = useStyles();
    const {
        handleUpdateSubmit,
        setShowDrawer,
        loading,
        users,
        users_dropdowns,
        active_partner_data,
        updatePartnerDrawer,
        partner_tiers,
        openUploadImage,
        handleOpenUploadImage,
        handleCloseUploadImage,
        handleDrawerClose
    } = props;

    const [userSearch, setUserSearch] = useState('');
    const RequestSchema = Yup.object().shape({
        partner_name: Yup.string()
            .required('Required'),
        logo: Yup.mixed()
            .required('A file is required'),
        user_managers: Yup.array()
            .min(1, 'Required')
            .max(3, 'Maximum 3 managers'),
        partnership_managers: Yup.array()
            .min(1, 'Required')
            .max(3, 'Maximum 3 managers'),
        partner_tier_id: Yup.string()
            .required('Required')
    });

    const initialValues = {
        user_managers: active_partner_data ? active_partner_data.user_managers : [],
        partnership_managers: active_partner_data ? active_partner_data.partnership_managers : [],
        partner_name: active_partner_data ? active_partner_data.name : '',
        logo: active_partner_data ? active_partner_data.logo : null,
        partner_tier_id: active_partner_data ? active_partner_data.partner_tier_id : false
    };

    const searchUsersHandler = (search) => {
        props.dispatch(fetchUsersList(search));
    }

    const debouncedOnInput = debounce(searchUsersHandler, 300);
    const inputHandler = useCallback((search) => {
        debouncedOnInput(search);
    }, []);

    useEffect(() => {
        inputHandler(userSearch);
    }, [userSearch]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    return (
        <Drawer
            disableBackdropClick
            BackdropProps={{ invisible: true }}
            anchor="right"
            open={updatePartnerDrawer && Object.keys(users_dropdowns).length > 0}
            onClose={handleDrawerClose}
            classes={{ paper: classes.createPartnerPadding }}>
            <Formik
                validationSchema={RequestSchema}
                onSubmit={(values) => {
                    handleUpdateSubmit(values);
                }}
                enableReinitialize
                initialValues={initialValues}>
                {({ values, touched, errors, setFieldValue }) => (
                    <Form>
                        <Grid container direction="column" spacing={3} >
                            <Grid item>
                                <Close onClick={handleDrawerClose} className={classes.closeButton} />
                            </Grid>
                            <Grid item>
                                <Field name="partner_name">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...field}
                                                    fullWidth
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0,
                                                            borderColor: '#c6c9db'
                                                        }
                                                    }}
                                                    value={values.partner_name || ''}
                                                    placeholder="New Partner"
                                                    error={touched.partner_name && !!errors.partner_name ? true : false}
                                                    helperText={touched.partner_name && !!errors.partner_name ? errors.partner_name : ''}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Typography>Partner Logo</Typography>
                            </Grid>
                            <Grid item>
                                {
                                    !openUploadImage &&
                                    <>
                                        <Upload sendFiles={(file) => setFieldValue('logo', file[0])} accept=".jpg,.jpeg,.png" />
                                        <p className="input-error">
                                            {touched.logo && !values.logo ? errors.logo : null}
                                        </p>
                                        <p>
                                            {values.logo ? values.logo.path : ''}
                                        </p>
                                    </>
                                }
                                {
                                    active_partner_data.logo && openUploadImage &&
                                    <Grid container direction="column" spacing={1} alignItems="center">
                                        <Grid item>
                                            <img style={{ maxWidth: 367 }} src={active_partner_data.logo} alt={active_partner_data.name} />
                                        </Grid>
                                        <Grid item>
                                            <Button fullWidth className={classes.updateLogo} onClick={handleOpenUploadImage}><img src="/images/icons/SVG/Upload.svg" style={{ width: 24, paddingRight: 10 }} />Update Logo</Button>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Divider className={classes.dividerLink} />
                            <Grid item>
                                <Typography>Permissions</Typography>
                            </Grid>
                            <Grid item>
                                <Field name="partner_tier_id">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography className={classes.dropDownLabel}>Partner Tier</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...field}
                                                    style={{
                                                        borderRadius: 0,
                                                        borderColor: '#c6c9db'
                                                    }}
                                                    value={values.partner_tier_id}
                                                    error={touched.partner_tier_id && !!errors.partner_tier_id ? true : false}>
                                                    {
                                                        partner_tiers && partner_tiers.map((user, key) => {
                                                            return (
                                                                <MenuItem key={key} value={user.id}>{user.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error">
                                                    {touched.partner_tier_id ? errors.partner_tier_id : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Field name="partnership_managers">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography className={classes.dropDownLabel}>Partnership Manager</Typography>
                                                <Typography className={classes.notice}>Choose up to 3 partnership manager. You will only be able to choose admin users with a XXX role</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    debug={true}
                                                    options={users}
                                                    value={values.partnership_managers}
                                                    onChange={(event, value) => {
                                                        setFieldValue('partnership_managers', []);
                                                        value.forEach((item, index) => {
                                                            setFieldValue(`partnership_managers.${index}`, item);
                                                        })

                                                    }}
                                                    onInputChange={(event) => setUserSearch(event.target ? event.target.value : '')}
                                                    fullWidth
                                                    disableCloseOnSelect={true}
                                                    filterOptions={(options, state) => options}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(option, {selected}) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{marginRight: 8}}
                                                                checked={selected}
                                                                value={option.id}
                                                                disabled={true}
                                                            />
                                                            {option.name}
                                                        </React.Fragment>
                                                    )}
                                                    getOptionSelected={((option, value) => option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: 0,
                                                                    borderColor: '#c6c9db'
                                                                }
                                                            }}
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error">
                                                    {touched.partnership_managers && values.partnership_managers.length === 0 ? errors.partnership_managers : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Field name="user_managers">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography className={classes.dropDownLabel}>User Manager</Typography>
                                                <Typography className={classes.notice}>Choose up to 3 user manager. You will only be able to choose users with a XXX role</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    debug={true}
                                                    options={users}
                                                    value={values.user_managers}
                                                    onChange={(event, value) => {
                                                        setFieldValue('user_managers', []);
                                                        value.forEach((item, index) => {
                                                            setFieldValue(`user_managers.${index}`, item);
                                                        })

                                                    }}
                                                    onInputChange={(event) => setUserSearch(event.target ? event.target.value : '')}
                                                    fullWidth
                                                    disableCloseOnSelect={true}
                                                    filterOptions={(options, state) => options}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(option, {selected}) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{marginRight: 8}}
                                                                checked={selected}
                                                                value={option.id}
                                                                disabled={true}
                                                            />
                                                            {option.name}
                                                        </React.Fragment>
                                                    )}
                                                    getOptionSelected={((option, value) => option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: 0,
                                                                    borderColor: '#c6c9db'
                                                                }
                                                            }}
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error">
                                                    {touched.user_managers && values.user_managers.length === 0 ? errors.user_managers : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        {loading ?
                                            <BeatLoader
                                                css={{ marginTop: '20px' }}
                                                color='#af001e'
                                                loading={loading}
                                            />
                                            :
                                            <Button size="large" fullWidth className={classes.createPartnerButton} type="submit">Update Partner</Button>}
                                    </Grid>
                                    <Grid item>
                                        <Button size="large" fullWidth onClick={handleDrawerClose} className={classes.cancelPartnerButton}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default connect(null, null)(UpdatePartner);
