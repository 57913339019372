import React, { useEffect } from "react";
import { connect } from "react-redux";
import PermissionChecker from "./PermissionChecker";

const Permitted = (props) => {
    let checker = new PermissionChecker(props.user);

    const pass = () => {
        return <>{props.children}</>;
    };

    const fail = () => {
        return <></>;
    };

    if (props.one) {
        if (checker.one(props.one)) {
            return pass();
        }
    }

    if (props.some && props.some.length > 0) {
        if (checker.some(props.some)) {
            return pass();
        }
    }

    if (props.all && props.all.length > 0) {
        if (checker.all(props.all)) {
            return pass();
        }
    }

    return fail();
};

const mapStateToProps = (state) => ({
    user: state.user?.user || null,
});

export default connect(mapStateToProps)(Permitted);
