import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Paper,
} from '@material-ui/core';
import { PageMenu, PARTNER_INFORMATION_MENU } from '../Components/PageMenu';
import AdminPartnersDropdown from '../Components/AdminPartnersDropdown/AdminPartnersDropdown';
import { resetOverridesData } from '~/Admin/PartnerManagement/ContentManagement/cmsInitialValues';
import setImagePathByExtension from '../helpers/Images/setImagePathByExtension';

const PartnerWorkshops = (props) => {

    const useStyles = makeStyles((theme) => ({
        section: {
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60
        },
        textPadding: {
            paddingTop: 20
        },
        documentsBackground: {
            backgroundColor: 'white'
        },
        sectionTitle: {
            color: '#af001e', 
            textTransform: 'uppercase', 
            fontSize: 20, 
            fontWeight: 600,
            paddingBottom: '1em'
        },        
        icons: {
            width: 22,
            height: 22
        },
        documentPaper: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            height: 46,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            columnGap: '5px',
        },
        downloadIcon: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            textAlign: 'center'
        },
        downloadAll: {
            height: 50,
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            borderRadius: 0,
            fontWeight: 600,
            marginTop: 5,
            color: 'white',
            "&:hover": {
                color: 'white',
                backgroundColor: '#af001eA5',
                textDecoration: 'none'
            }
        },
    }));

    const classes = useStyles();
   
    const partnerWorkshopsContent = props.partnerWorkshopsCms?.content.html;
    const partnerWorkshopsFiles = props.partnerWorkshopsCms?.files;

    return (
        <section className={classes.section}>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <h1 className="content-title">Partner Information</h1>
                </Grid>
                <Grid item>
                    <AdminPartnersDropdown tabId="0" />
                </Grid>
            </Grid>                
            <PageMenu menuItems={PARTNER_INFORMATION_MENU}/>
            <Divider />

            <Grid className={classes.gridPadding}>
                <Grid item sm={6}>
                    <Typography variant="h5" className={classes.sectionTitle}>
                        Partner Workshops
                    </Typography>
                    {partnerWorkshopsContent
                        ?
                        <div dangerouslySetInnerHTML={{ __html: partnerWorkshopsContent }} />
                        :
                        <div dangerouslySetInnerHTML={{ __html: resetOverridesData.lions_tour.partner_workshops }} />
                    }                    
                    {
                        partnerWorkshopsFiles && partnerWorkshopsFiles.map((file, key) => (
                            <Grid container justify="space-between" key={key} direction="row" spacing={1}
                                alignContent="center">
                                <Grid item md>
                                    <Paper className={classes.documentPaper}>
                                        <img className={classes.icons}
                                            src={setImagePathByExtension(file.ext).path} 
                                            alt={setImagePathByExtension(file.ext).alt}/> {file.name}
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <a href={file.full_path} download={file.original_name}>
                                        <Paper className={classes.downloadIcon}>
                                            <img className={classes.icons} src="/images/icons/SVG/Download.svg" />
                                        </Paper>
                                    </a>
                                </Grid>
                            </Grid>
                        ))                   
                    }                                
                </Grid>               
            </Grid>            
        </section>
    );
}

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
    partnerWorkshopsCms: state.user.cmsBlocks?.lions_tour_partner_workshops,
});

export default connect(mapStateToProps)(PartnerWorkshops);
