import {authHeader} from "~/helpers/authHeader";

export const showSuccessNotification = (title, message) => {

    return (dispatch) => {



        dispatch({
            type: 'notification/SET',
            payload: {
                status: 'success',
                text: message,
                title: title,
            },
        });
    };
};

export const destroyNotification = () => {

    return (dispatch) => {

        dispatch({
            type: 'notification/DESTROY',
        });
    };
};
