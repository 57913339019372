import React, {Fragment} from 'react';

const ExtensionIcon = ({text, width, height}) => {
    return (
        <div className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" width={width} height={height}>
                <path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
                <text x={12 - text.length} y="15" fill="white" fontSize="3">{text.toUpperCase()}</text>
            </svg>
        </div>
    );
};

export default ExtensionIcon;
