import React from 'react';
import classnames from 'classnames';

import './index.scss';

const Button = ({ ...props }) => {
    return (
        <input
            type={props.type || 'button'}
            onClick={props.disabled ? null : props.onClick}
            className={classnames('button', props.class)}
            value={props.text}
            disabled={props.disabled}
        />
    );
}

export default Button;
