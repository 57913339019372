import React from "react";
import MaterialIcon from "material-icons-react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import LibraryApi from "~/services/api/library";

export default ({request, setShowPreview, setPreviewFile}) => {

    const fileLists =
        request.files &&
        request.files.reduce((acc, file) => {
            if (!acc[file.version]) {
                acc[file.version] = [];
            }
            acc[file.version] = acc[file.version].concat(file);
            return acc;
        }, {});

    const count = request.files && request.files.length;

    const downloadAll = () => {
        axios.get(`/api/brand-request/download-all/${request.id}`, {responseType: 'blob'}).then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', 'request_files.zip');
            document.body.appendChild(link);
            link.click();


        }).catch(function (error) {
            console.log(error);
        });
    }

    const downloadFile = (f) => {
        let ext = f.original_name.substr(f.original_name.lastIndexOf('.'), 0);
        LibraryApi.downloadImage({path: f.download, name: f.original_name, ext: ext})
    }

    return (
        <div className="events-block-new cards description">
            <Grid container direction="column">
                <Grid item>
                    <h4 className="tabbed-content-title">Submission Justification</h4>

                </Grid>
                <Grid item>
                    <h4 className="tabbed-content-subtitle">Request Brief</h4>
                    <ul>
                        <li className="spacing">
                            <div className="description-values">{request.description}</div>
                        </li>
                    </ul>

                </Grid>
                <Grid item className="supporting-doc-wrap">
                    <h4>Supporting Documents</h4>
                    {Object.keys(fileLists).map((version, index) => {
                        return (
                            <React.Fragment key={index + "ver"}>

                                {fileLists[version] &&
                                fileLists[version].map((f, i) => (
                                    <div key={i + "o_name"}
                                         className={(count > i + 1) ? 'supporting-doc row' : 'supporting-doc row last'}>
                                        <div className="icon-wrapper col-md-1">
                                            <img src="/images/icons/SVG/File Type/File.svg" alt="file"/>
                                        </div>
                                        <div className="filename col-md-7 col-lg-8 p-0 text-left" title={f.original_name}>
                                            {f.original_name.length > 20 ? f.original_name.substring(0, 20) + '...' : f.original_name}
                                        </div>
                                        <div
                                            className="icon-wrapper p-0 col-md-1 text-right">{(f.media_type === 'photos' || f.media_type === 'videos') &&
                                        <img src="/images/icons/SVG/View.svg" className="view" onClick={() => {
                                            let file = {...f, name: f.original_name, path: f.url}
                                            setPreviewFile(file)
                                            setShowPreview(true)
                                        }}/>
                                        }
                                        </div>
                                        <div className="col-md-1 p-0 text-right">
                                            <span className="icon-wrapper m-0">
                                                <img src="/images/icons/SVG/Download.svg" className="download-icon"
                                                    onClick={() => downloadFile(f)}/>
                                            </span>
                                        </div>

                                    </div>
                                ))}
                            </React.Fragment>
                        );
                    })}
                    <div>{count > 1 &&
                    <span className="download-text" onClick={downloadAll}>Download all</span>
                    }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
