import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getSinceLastVisited,
    getUserTicketCounts,
    editNote,
    deleteNotice,
    editDeadline,
    deleteDeadline
} from './dashboardActions';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
import NoticesDeadlines from './NoticesDeadlines/NoticesDeadlines';
import SinceLastVisit from './SinceLastVisit/SinceLastVisit';
import DashboardCounter from './DashboardCounter/DashboardCounter';
import WelcomeModal from './DashboardDialogs/WelcomeModal/WelcomeModal';
import AddNoticeDialog from './DashboardDialogs/AddNoticeDialog/AddNoticeDialog';
import AddKeyDeadlineDialog from './DashboardDialogs/AddKeyDeadlineDialog/AddKeyDeadlineDialog';
import DeleteNoticeDialog from './DashboardDialogs/DeleteNoticeDialog/DeleteNoticeDialog';
import UpdateNoticeDialog from './DashboardDialogs/UpdateNoticeDialog/UpdateNoticeDialog';
import UpdateDeadlineDialog from './DashboardDialogs/UpdateDeadlineDialog/UpdateDeadlineDialog';
import DeleteDeadlineDialog from './DashboardDialogs/DeleteDeadlineDialog/DeleteDeadlineDialog';
import { useHistory } from 'react-router-dom';

const Dashboard = (props) => {

    const useStyles = makeStyles((theme) => ({
        section: {
            width: '100%'
        },
    }));

    const history = useHistory();
    const [openWelcomeMessage, setOpenWelcomeMessage] = useState(false);
    const classes = useStyles();
    const [openAddNoteDialog, setOpenAddNoteDialog] = useState(false);
    const [openAddKeyDeadlineDialog, setOpenAddKeyDeadlineDialog] = useState(false);
    const [dontShowWelcomMessageAgain, setDontShowWelcomMessageAgain] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [activeNoticeID, setActiveNoticeID] = useState(null);
    const [openUpdateNoteDialog, setOpenUpdateNoteDialog] = useState(false);
    const [editNoteData, setEditNoteData] = useState(null);
    const [openUpdateDeadlineDialog, setOpenUpdateDeadlineDialog] = useState(false);
    const [editDeadlineData, setEditDeadlineData] = useState(null);
    const [openDeleteDeadlineDialog, setOpenDeleteDeadlineDialog] = useState(false);
    const [activeDeadlineID, setActiveDeadlineID] = useState(null);

    useEffect(() => {
        props.dispatch(getUserTicketCounts());
        props.dispatch(getSinceLastVisited());
    }, []);

    useEffect(() => {
        if (history.location.state?.fromLogin && localStorage.getItem('welcomeMessage') !== 'false') {
            setOpenWelcomeMessage(true)
        }
    }, [history])

    const handleOpenEditDialog = (item) => {
        setEditNoteData(item);
        setOpenUpdateNoteDialog(true);
    }

    const handleOpenEditDeadlineDialog = (item) => {
        setEditDeadlineData(item);
        setOpenUpdateDeadlineDialog(true);
    }

    const handleDontShowWelcomeMessage = () => {
        setDontShowWelcomMessageAgain(!dontShowWelcomMessageAgain);
    }

    const handleCloseWelcomeMessage = () => {
        if (dontShowWelcomMessageAgain) {
            localStorage.setItem('welcomeMessage', false);
        }
        setOpenWelcomeMessage(false);
    }

    const handleCloseDialog = () => {
        setOpenAddNoteDialog(false);
        setOpenDeleteDialog(false);
        setOpenAddKeyDeadlineDialog(false);
        setOpenUpdateNoteDialog(false);
        setOpenUpdateDeadlineDialog(false);
        setOpenDeleteDeadlineDialog(false);
    }

    const handleOpenDeleteDialog = (id) => {
        setActiveNoticeID(id)
        setOpenDeleteDialog(true);
    }

    const handleOpenDeleteDeadlineDialog = (id) => {
        setActiveDeadlineID(id)
        setOpenDeleteDeadlineDialog(true);
    }

    const handleDeleteNotice = (confirm) => {
        if (confirm) props.dispatch(deleteNotice(activeNoticeID))
            .then(setActiveNoticeID(null));
        setOpenDeleteDialog(false);
    }

    const handleDeleteDeadline = (confirm) => {
        if (confirm) props.dispatch(deleteDeadline(activeDeadlineID))
            .then(setActiveDeadlineID(null));
        setOpenDeleteDeadlineDialog(false);
    }

    const handleEditNote = (values) => {
        props.dispatch(editNote({ id: editNoteData.id, data: values }))
            .then(() => {
                setEditNoteData(null);
                setOpenUpdateNoteDialog(false);
            })
    };

    const handleEditDeadline = (values) => {
        props.dispatch(editDeadline({ id: editDeadlineData.id, data: values }))
            .then(() => {
                setEditDeadlineData(null);
                setOpenUpdateDeadlineDialog(false);
            })
    };

    return (
        <>
            <section className={classes.section}>
                <Grid container spacing={4} direction="column">
                    <Grid item>
                        <h1 className="content-title">Dashboard</h1>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item md>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <DashboardCounter />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <SinceLastVisit />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <NoticesDeadlines
                                setOpenAddNoteDialog={setOpenAddNoteDialog}
                                setOpenAddKeyDeadlineDialog={setOpenAddKeyDeadlineDialog}
                                handleOpenEditDialog={handleOpenEditDialog}
                                handleOpenDeleteDialog={handleOpenDeleteDialog}
                                handleOpenEditDeadlineDialog={handleOpenEditDeadlineDialog}
                                handleOpenDeleteDeadlineDialog={handleOpenDeleteDeadlineDialog}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            <WelcomeModal
                openWelcomeMessage={openWelcomeMessage}
                handleCloseWelcomeMessage={handleCloseWelcomeMessage}
                handleDontShowWelcomeMessage={handleDontShowWelcomeMessage}
            />

            <AddNoticeDialog
                openAddNoteDialog={openAddNoteDialog}
                handleCloseDialog={handleCloseDialog}
            />

            <AddKeyDeadlineDialog
                openAddKeyDeadlineDialog={openAddKeyDeadlineDialog}
                handleCloseDialog={handleCloseDialog}
            />

            <DeleteNoticeDialog
                openDeleteDialog={openDeleteDialog}
                handleDeleteNotice={handleDeleteNotice}
                handleCloseDialog={handleCloseDialog}
            />

            <UpdateNoticeDialog
                openUpdateNoteDialog={openUpdateNoteDialog}
                handleCloseDialog={handleCloseDialog}
                editNoteData={editNoteData}
                handleEditNote={handleEditNote}
            />

            <UpdateDeadlineDialog
                editDeadlineData={editDeadlineData}
                openUpdateDeadlineDialog={openUpdateDeadlineDialog}
                handleCloseDialog={handleCloseDialog}
                handleEditDeadline={handleEditDeadline}
            />

            <DeleteDeadlineDialog
                openDeleteDeadlineDialog={openDeleteDeadlineDialog}
                handleDeleteDeadline={handleDeleteDeadline}
            />

        </>
    );
}

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
    user: state.user.user,
    status_counts: state.homepage.status_counts,
    since_last_visit: state.homepage.since_last_visit.data,
});

export default connect(mapStateToProps)(Dashboard);
