import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import { showSuccessNotification } from '~/Notification/notificationActions';
import { connect } from "react-redux";
import './index.scss';
import Description from "~/TalentAccessForm/Description";
import AssetsList from "~/TalentAccessForm/AssetsList/AssetsList";
import Grid from "@material-ui/core/Grid";
import Button from "~/Components/Button";
import TalentAccessRequestForm from "~/TalentAccessForm/TalentAccessRequestForm";
import AdminPartnersDropdown from "~/Components/AdminPartnersDropdown/AdminPartnersDropdown";
import Permitted from '~/Permissions/Permitted';

const TalentAccessForm = props => {
    const activeAsset = props.activeAsset;

    const [step, setStep] = useState(1);
    const [accessType, setAccessType] = useState('players');
    const [requestType, setRequestType] = useState('commercial appearance');
    const [members, setMembers] = useState([]);

    const handleSubmit = (payload) => {
        payload.append('team_asset_id', activeAsset.id);
        return axios
            .post("/api/talent-access-request", payload)
            .then(res => {

                let id = res.data.data.id;
                props.history.push('/my-requests/' + id);

                props.dispatch(
                    showSuccessNotification(
                        'Your request has been submitted',
                        'We will review your request soon, and in the meantime, you can review your request here.'
                    )
                );

                return res;
            });
    };

    const handleMembers = (values) => {
        let membersTmp = values.map(member => member.id);
        setMembers(membersTmp);
    }

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item className="approvals bodyContent">
                <section className="library-header">
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <h1 className="content-title">Talent Access</h1>
                        </Grid>
                        <Grid item>
                            <AdminPartnersDropdown tabId="4" subTab="0" />
                        </Grid>
                    </Grid>
                </section>
            </Grid>
            <Grid item className="approvals-content">
                <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Description />
                    </Grid>
                    <Permitted one="submit_talent_request">
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="talent-access">
                            {step === 1 &&
                                <AssetsList
                                    accessType={accessType}
                                    setAccessType={setAccessType}
                                    requestType={requestType}
                                    setRequestType={setRequestType}
                                    handleMembers={handleMembers}
                                />
                            }

                            {step === 2 &&
                                <TalentAccessRequestForm defaultRequest={{}}
                                    submitButtonLabel="SUBMIT REQUEST"
                                    onSubmit={handleSubmit.bind(this)}
                                    setStep={setStep}
                                    step={step}
                                    handleMembers={handleMembers}
                                />
                            }

                            {step === 1 &&
                                <Grid item md={12}>
                                    <Grid container direction="row" justify={"flex-end"}>
                                        <Grid item>
                                            <Button class={activeAsset ? 'footer-buttons next' : 'footer-buttons cancel'}
                                                text="NEXT STEP"
                                                disabled={!activeAsset}
                                                onClick={() => setStep(step + 1)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                        </Grid>
                    </Permitted>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    activeAsset: state.talentAccess.activeAsset,
});

export default connect(mapStateToProps)(withRouter(TalentAccessForm));
