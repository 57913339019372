class PermissionChecker {

    constructor(user) {
        this.user = user;
    }

    userPermissions() {
        return this.user?.permissions || [];
    }

    one(onePermission) {
        if (!this.user) {
            return false;
        }

        if (this.user.is_super_admin) {
            return true;
        }

        if (this.userPermissions().includes(onePermission)) {
            return true;
        }

        return false;
    }

    some(somePermissions = []) {
        if (!this.user) {
            return false;
        }

        if (this.user.is_super_admin) {
            return true;
        }

        const userPermissions = this.userPermissions();

        const passed = somePermissions.some((p) => {
            return userPermissions.includes(p);
        });

        return passed;
    }

    all(somePermissions = []) {
        if (!this.user) {
            return false;
        }

        if (this.user.is_super_admin) {
            return true;
        }

        const userPermissions = this.userPermissions();

        return somePermissions.every((p) => {
            return userPermissions.indexOf(p) !== -1;
        });
    }
}

export default PermissionChecker;
