import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserTicketCounts } from '../dashboardActions';
import {
    makeStyles,
    Card,
    Typography,
    Grid
} from '@material-ui/core';

const DashboardCounter = (props) => {

    const useStyles = makeStyles((theme) => ({
        title: {
            fontSize: 26,
        },
        borderColorGray: {
            height: '100%',
            borderTop: '3px solid #f3f4f8',
            minHeight: 120,
            borderRadius: 0,
            padding: '2em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorOrange: {
            height: '100%',
            borderTop: '3px solid #ebae45',
            minHeight: 120,
            borderRadius: 0,
            padding: '2em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorGreen: {
            height: '100%',
            borderTop: '3px solid #44de6f',
            minHeight: 120,
            borderRadius: 0,
            padding: '2em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorRed: {
            height: '100%',
            borderTop: '3px solid #de5644',
            minHeight: 120,
            borderRadius: 0,
            padding: '2em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorBlack: {
            height: '100%',
            borderTop: '3px solid #3e3f60',
            minHeight: 120,
            borderRadius: 0,
            padding: '2em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        props.dispatch(getUserTicketCounts());
    }, []);

    return (
        <Grid container alignItems="stretch" justify="space-between" spacing={1}>
            <Grid item xs={12} sm={6} md={3} xl lg>
                <Card className={classes.borderColorGray}>
                    <Typography className={classes.title}>{props.status_counts.pending || 0}</Typography>
                    <Typography>Pending Requests</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl lg>
                <Card className={classes.borderColorOrange}>
                    <Typography className={classes.title}>{props.status_counts.change || 0}</Typography>
                    <Typography>Awaiting Changes</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl lg>
                <Card className={classes.borderColorGreen}>
                    <Typography className={classes.title}>{props.status_counts.approved || 0}</Typography>
                    <Typography>Approved Requests</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl lg>
                <Card className={classes.borderColorRed}>
                    <Typography className={classes.title}>{props.status_counts.rejected || 0}</Typography>
                    <Typography>Rejected Requests</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl lg>
                <Card className={classes.borderColorBlack}>
                    <Typography className={classes.title}>{props.status_counts.cancelled || 0}</Typography>
                    <Typography>Cancelled Request</Typography>
                </Card>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    status_counts: state.homepage.status_counts,
});

export default connect(mapStateToProps)(DashboardCounter);
