import React, { useEffect, useState } from "react";
import Button from "~/Components/Button";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { connect } from "react-redux";
import { showSuccessNotification } from "~/Notification/notificationActions";
import { withRouter } from "react-router";
import { getAsset } from "~/TalentAccessForm/talentAccessActions";

const RequestActions = ({
    request,
    adminMode,
    setShowEdit,
    setRequest,
    showEdit,
    dispatch,
    history,
}) => {
    const [loader, setLoader] = React.useState(null);

    const editRequest = () => {
        setShowEdit(true);

        if (request.type === "talent") {
            dispatch({
                type: "talent_access/SET_ACTIVE_ASSET",
                payload: request.teamAsset,
            });
        }

        window.scrollTo(0, 0);
    };
    const confirm = useConfirm();

    const cancelRequest = () => {
        confirm({
            title: "Cancel request?",
            description: "Are you sure you want to cancel this request?",
        }).then(() => {
            setLoader("cancel");
            axios
                .put("/api/brand-request/" + request.id + "/cancel")
                .then((res) => {
                    dispatch(
                        showSuccessNotification(
                            "Your request has been cancelled",
                            "You cancelled your request successfully!."
                        )
                    );

                    setRequest(res.data.data);
                })
                .finally((errors) => {
                    setLoader(null);
                });
        });
    };

    const resubmitRequest = () => {
        confirm({
            title: "Resubmit request?",
            description: "Are you sure you want to resubmit this request?",
        }).then(() => {
            setLoader("resubmit");
            let uri =
                request.type === "approvals"
                    ? "/api/brand-request/"
                    : "/api/talent-access-request/";
            axios
                .post(uri + request.id + "/resubmit")
                .then((res) => {
                    setRequest(res.data.data);

                    dispatch(
                        showSuccessNotification(
                            "Your request has been resubmitted",
                            "We will review your request soon!."
                        )
                    );
                })
                .finally((errors) => {
                    setLoader(null);
                });
        });
    };

    const changeStatusAdmin = (status) => {
        confirm({
            title: "Changed request status?",
            description:
                "Are you sure you want to change status of this request?",
        }).then(() => {
            setLoader(status);
            axios
                .post("/api/brand-admin/" + request.id + "/status", {
                    status: status,
                })
                .then((res) => {
                    setRequest(res.data.data);

                    dispatch(
                        showSuccessNotification(
                            "Success!",
                            "Request status has been changed!"
                        )
                    );

                    setLoader({});
                })
                .finally(() => {
                    setLoader(null);
                });
        });
    };

    if (adminMode) {
        const deleteRequest = () => {
            confirm({
                title: "Delete request?",
                description: "Are you sure you want to delete this request?",
            }).then(() => {
                setLoader("resubmit");
                axios
                    .delete("/api/brand-request/" + request.id)
                    .then((res) => {
                        dispatch(
                            showSuccessNotification(
                                "Request has been deleted",
                                ""
                            )
                        );
                    })
                    .finally((errors) => {
                        setLoader(null);
                        history.push("/admin/request-management");
                    });
            });
        };
        return (
            <Grid container direction="row" justify="flex-end">
                <Button
                    class="btn-red footer-downloads-request noMarg"
                    disabled={
                        request.status === "rejected" || loader === "rejected"
                    }
                    text={
                        loader === "rejected" ? "Saving..." : "Reject Request"
                    }
                    onClick={() => changeStatusAdmin("rejected")}
                />
                <Button
                    class="btn-orange footer-downloads-request noMarg"
                    disabled={
                        request.status === "change" || loader === "change"
                    }
                    text={loader === "change" ? "Saving..." : "Request Changes"}
                    onClick={() => changeStatusAdmin("change")}
                />
                <Button
                    class="btn-green footer-downloads-request noMarg"
                    disabled={
                        request.status === "approved" || loader === "approved"
                    }
                    text={
                        loader === "approved" ? "Saving..." : "Approve Request"
                    }
                    onClick={() => changeStatusAdmin("approved")}
                />
                <Button
                    class="btn-dark footer-downloads-request noMarg"
                    disabled={
                        request.status === "cancelled" || loader === "cancelled"
                    }
                    text={
                        loader === "cancelled" ? "Saving..." : "Cancel Request"
                    }
                    onClick={() => changeStatusAdmin("cancelled")}
                />

                {request.type === "approvals" && (
                    <Button
                        class="btn-grey footer-downloads-request noMarg"
                        text={
                            loader === "rejected"
                                ? "Saving..."
                                : "Delete Request"
                        }
                        onClick={deleteRequest}
                    />
                )}
            </Grid>
        );
    }

    if (["approved", "rejected"].includes(request.status) || showEdit) {
        return <></>;
    }

    return (
        <Grid
            container
            direction="row"
            spacing={1}
            justify="flex-end"
            className="pt-3"
        >
            {request.status === "cancelled" && (
                <>
                    <Grid item>
                        <Button
                            class="btn-grey noMarg"
                            text="EDIT REQUEST"
                            onClick={() => editRequest()}
                        />
                    </Grid>
                    <Grid item className="pr-0">
                        <Button
                            disabled={loader === "resubmit"}
                            class="edit noMarg color-red"
                            text={
                                loader === "resubmit"
                                    ? "Resubmitting..."
                                    : "RESUBMIT REQUEST"
                            }
                            onClick={() => resubmitRequest()}
                        />
                    </Grid>
                </>
            )}

            {request.status === "pending" && (
                <>
                    <Grid item>
                        <Button
                            class="btn-grey noMarg"
                            text="EDIT REQUEST"
                            onClick={() => editRequest()}
                        />
                    </Grid>
                    <Grid item className="pr-0">
                        <Button
                            disabled={loader === "cancel"}
                            class="edit noMarg color-red"
                            text={
                                loader === "cancel"
                                    ? "Canceling..."
                                    : "CANCEL REQUEST"
                            }
                            onClick={() => cancelRequest()}
                        />
                    </Grid>
                </>
            )}

            {request.status === "change" && (
                <>
                    <Grid item className="pr-0">
                        <Button
                            disabled={loader === "cancel"}
                            class="btn-grey noMarg"
                            text={
                                loader === "cancel"
                                    ? "Canceling..."
                                    : "CANCEL REQUEST"
                            }
                            onClick={() => cancelRequest()}
                        />
                    </Grid>

                    <Grid item className="pr-0">
                        <Button
                            class="btn-grey noMarg"
                            text="EDIT REQUEST"
                            onClick={() => editRequest()}
                        />
                    </Grid>

                    <Grid item className="pr-0">
                        <Button
                            disabled={loader === "resubmit"}
                            class="edit noMarg color-red"
                            text={
                                loader === "resubmit"
                                    ? "Resubmitting..."
                                    : "RESUBMIT REQUEST"
                            }
                            onClick={() => resubmitRequest()}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default connect(null, null)(withRouter(RequestActions));
