import update from 'immutability-helper';

const initialState = {
    list: {
        data: [],
        meta: {}
    },
    loading: false,
};

const brandReducer = (state = initialState, action) =>
{
    if (action.type === 'brand/DESTROY') {
        return initialState;
    }

    if (action.type === 'brand/SET_LOADING') {
        return update(state, {
            loading: {$set: action.payload},
        });
    }

    if (action.type === 'brand/SET_LIST') {
        return update(state, {
            list: {$set: action.payload},
        });
    }

    if (action.type === 'brand/UPDATE_ITEM') {

        let itemKey = state.list.data.findIndex((itm) => {
            return itm.id === action.payload.id;
        });

        if (itemKey < 0) {
            return update(state, {
                list: {
                    data: {
                        $push: [action.payload]
                    }
                },
            });
        }

        return update(state, {
            list: {
                data : {
                    [itemKey] : {
                        $set: action.payload
                    }
                }
            }
        });
    }

    return state;
};

export default brandReducer;
