import React from "react";
import { connect } from "react-redux";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Permitted from "../../Permissions/Permitted";

const PageMenu = (props) => {
    const [currentTab, setCurrentTab] = React.useState(0);

    const useStyles = makeStyles((theme) => ({
        activeTabItem: {
            color: "#af001e",
            fontWeight: "bold",
            fontFamily: theme.demiBold,
        },
        activeTabLink: {
            borderBottom: "2px solid #af001e",
            "&:hover": {
                textDecoration: "none",
            },
        },
        nonActiveTabItem: {
            color: "black",
            fontFamily: theme.demiBold,
            "&:hover": {
                fontWeight: "bold",
                color: "#af001e",
                textDecoration: "none",
                fontFamily: theme.demiBold,
            },
        },
        indicator: {
            backgroundColor: "white",
            display: "none",
        },
        fullWidth: {
            width: "100%",
        },
        responsivDirection: {
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
            },
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },
    }));

    const classes = useStyles();

    const pathName = props.location.pathname;

    const getChildren = (item, key) => {
        return (
            <Link
                key={key}
                to={item.link}
                className={
                    pathName === item.link
                        ? classes.activeTabLink
                        : classes.nonActiveTabItem
                }
            >
                <Tab
                    value={key}
                    index={key}
                    label={item.label}
                    className={
                        pathName === item.link
                            ? classes.activeTabItem
                            : classes.nonActiveTabItem
                    }
                />
            </Link>
        );
    };

    return (
        <Tabs
            classes={{
                indicator: classes.indicator,
                root: classes.fullWidth,
                flexContainer: classes.responsivDirection,
            }}
            value={currentTab}
        >
            {props.menuItems.map((item, key) => {
                if (Array.isArray(item.permission)) {
                    return (
                        <Permitted key={key} some={item.permission}>
                            {getChildren(item, key)}
                        </Permitted>
                    );
                } else {
                    return (
                        <Permitted key={key} one={item.permission}>
                            {getChildren(item, key)}
                        </Permitted>
                    );
                }
            })}
        </Tabs>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(withRouter(PageMenu));
