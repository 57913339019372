import React, {useState} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import {Field} from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "~/Components/Button";

const PlayersList = ({players, setPlayers, classes, manual = false}) => {
    const handleChange = (event, key, propertyName) => {
        let playersTmp = [...players];
        playersTmp[key][propertyName] = propertyName !== 'captain' ? event.target.value : event.target.checked;
        setPlayers(playersTmp);
    }

    const deleteRecord = (key) => {
        let playersTmp = [...players];
        playersTmp.splice(key, 1);
        setPlayers(playersTmp);
    }

    const addPlayer = () => {
        let playerTmp = [...players, {name: '', nationality: '', captain: false}];
        setPlayers(playerTmp);
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>PLAYER NAME</TableCell>
                            <TableCell align="right">NATIONALITY</TableCell>
                            <TableCell align="right"/>
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {players.length > 0 && players.map((player, key) => (
                            <TableRow key={key}>
                                <TableCell scope="row">
                                    {key + 1}
                                </TableCell>
                                <TableCell scope="row">
                                    <Field
                                        name="name[]"
                                        type="text">
                                        {({field}) => (
                                            <TextField
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputBorder
                                                    }
                                                }}
                                                variant="outlined"
                                                {...field}
                                                value={player.name}
                                                onChange={(event) => handleChange(event, key, 'name')}
                                            />
                                        )}

                                    </Field>
                                </TableCell>
                                <TableCell align="right">
                                    <Field
                                        name="nationality[]"
                                        type="text">
                                        {({field}) => (
                                            <TextField
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputBorder
                                                    }
                                                }}
                                                variant="outlined"
                                                {...field}
                                                value={player.nationality}
                                                fullWidth
                                                placeholder="Nationality"
                                                onChange={(event) => handleChange(event, key, 'nationality')}
                                                error={false}
                                                helperText={''}
                                            />
                                        )}

                                    </Field>
                                </TableCell>
                                <TableCell align="right">
                                    <Checkbox
                                        color="primary"
                                        checked={Boolean(player.captain)}
                                        onChange={(event) => handleChange(event, key, 'captain')}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    Captain
                                </TableCell>

                                <TableCell align="right">
                                    <Typography className="delete-row" onClick={() => deleteRecord(key)}>x</Typography>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            {manual &&
                <Button class="footer-buttons add-new"  text="ADD MEMBER" onClick={addPlayer}/>
            }
        </div>

    );
};

export default PlayersList;
