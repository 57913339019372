import React, {Fragment} from 'react';
import './index.scss';
import {fetchMediaCategory, resetState} from "~/Media/actions";
import {connect} from "react-redux";
import MaterialIcon from "material-icons-react";
import {Link} from "react-router-dom";
import GridListTemplate from "./GridListTemplate";
import DescriptionTemplate from "./DescriptionTemplate";
import Loader from "react-loader";
import {Redirect} from "react-router";

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            noticePerPage: 5,
            pageCount: 1,
            perPage: 1,
            total: 1,
            searchName: '',
            showPreview: false,
            previewFile: {},
            loaded: true,
            responseError: false,
        }
    }


    componentDidMount() {
        let {id, folderId} = this.props.match.params;
        this.props.dispatch(resetState());
        this.fetchMediaCategoryItemsList(id, folderId);

        this.props.setNavigationCurrent(id);
    }

    fetchMediaCategoryItemsList(id, folderId) {
        this.props.dispatch(
            fetchMediaCategory({
                id: id,
                folderId: folderId,
                perPage: this.props.perPage,
                orderBy: this.props.orderBy,
                name: this.state.searchName
            })
        ).then(error => {
            this.setState({responseError: error});
        });
    }

    renderBreadCrumbs(item, index) {
        let last = false;

        if (index + 1 === this.props.breadcrumbs.length) {
            last = true;
        }

        let uri = this.props.location.pathname.indexOf('category') !== -1 ? 'category' : 'manage';

        return (
            <Fragment key={index}>
                <Link to={`/${this.props.libraryScope}/${uri}/${item.link}`} title={item.name}>
                    <span> {item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}</span>
                </Link>
                {!last && <MaterialIcon icon="chevron_right"/>}
            </Fragment>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        let {id, folderId} = this.props.match.params;
        this.props.setNavigationCurrent(id);
        let {id: prevId, folderId: prevFolderId} = prevProps.match.params;
        const {perPage: prevPerPage, orderBy: prevOrderBy} = prevProps;

        const {perPage, orderBy} = this.props;
        if (id !== prevId) {
            this.props.dispatch(resetState());
            this.setState({searchName: ''});
        }
        if (folderId !== prevFolderId
            || id !== prevId
            || perPage !== prevPerPage
            || this.state.searchName !== prevState.searchName
            || orderBy.queryVal !== prevOrderBy.queryVal
        ) {
            this.fetchMediaCategoryItemsList(id, folderId)
        }

    }

    manageAssets() {
        let categoryId = this.props.match.params.id;
        let manageUrl = this.props.location.pathname.match(/\/([a-z]+)\//);

        if (manageUrl && manageUrl[1]) {

            if (this.props.parent.ext === 'Folder') {
                let parentId = this.props.parent.id;
                let categoryId = this.props.parent.media_library_id;
                this.props.history.push(`/${manageUrl[1]}/manage/${categoryId}/${parentId}`);
            } else {
                this.props.history.push(`/${manageUrl[1]}/manage/${categoryId}`);
            }
        }
    }

    goToPage(page) {
        this.props.dispatch(
            fetchMediaCategory({
                id: this.props.match.params.id,
                folderId: this.props.match.params.folderId,
                perPage: this.props.perPage,
                orderBy: this.props.orderBy,
            }, page)
        ).then(error => {
            this.setState({responseError: error});
        });
    }

    setPreviewFile(previewFile) {
        
        if (!previewFile.media_type && (previewFile.ext === 'pdf')) {
             previewFile.media_type = previewFile.ext
        }; 
        
        this.setState({previewFile, showPreview: true})
    }

    setLoaded(state) {
        this.setState({loaded: state});
    }

    render() {
        if (!this.props.breadcrumbs && !this.state.responseError) {
            return (<div className="grid-list-container">Loading...</div>)
        }

        if(this.state.responseError) {
            return <Redirect to="/404" />
        }
        return (
            <>

                {!this.state.loaded &&
                    <div className="media-loader-container">
                        <Loader loaded={this.state.loaded} loadedClassName="loaded-content h-100"/>
                    </div>
                }
                {this.props.templateType === 'grid_list' &&
                    <GridListTemplate {...this.props}
                                      setPreviewFile={this.setPreviewFile.bind(this)}
                                      libaryScope={this.props.libraryScope}
                                      goToPage={this.goToPage.bind(this)}
                                      manageAssets={this.manageAssets.bind(this)}
                                      renderBreadCrumbs={this.renderBreadCrumbs.bind(this)}
                                      setState={this.setState.bind(this)}
                                      previewFile={this.state.previewFile}
                                      showPreview={this.state.showPreview}
                                      parent={this.props.parent}
                                      setLoaded={this.setLoaded.bind(this)}
                                      setSearchName={(value) => this.setState({searchName: value})}
                    />
                }
                {this.props.templateType === 'descriptive' &&
                    <DescriptionTemplate title={this.props.parent.name}
                                         description={this.props.description}
                                         files={this.props.files}
                                         user={this.props.user}
                                         category={this.props.parent}
                                         setLoaded={this.setLoaded.bind(this)}
                                         manageAssets={this.manageAssets.bind(this)}
                    />
                }
                {this.props.templateType === 'combo' &&
                    <>
                        <DescriptionTemplate 
                            title=""
                            description={this.props.description}
                            files={this.props.files}
                            user={this.props.user}
                            category={this.props.parent}
                            setLoaded={this.setLoaded.bind(this)}
                            manageAssets={this.manageAssets.bind(this)}
                        />                        
                        <div style={{marginTop: "30px"}}>
                            <GridListTemplate 
                                {...this.props}
                                setPreviewFile={this.setPreviewFile.bind(this)}
                                libaryScope={this.props.libraryScope}
                                goToPage={this.goToPage.bind(this)}
                                manageAssets={this.manageAssets.bind(this)}
                                renderBreadCrumbs={this.renderBreadCrumbs.bind(this)}
                                setState={this.setState.bind(this)}
                                previewFile={this.state.previewFile}
                                showPreview={this.state.showPreview}
                                parent={this.props.parent}
                                setLoaded={this.setLoaded.bind(this)}
                                setSearchName={(value) => this.setState({searchName: value})}
                            />
                        </div>
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    folders: state.media.folders,
    files: state.media.files,
    parent: state.media.parent,
    breadcrumbs: state.media.breadcrumbs,
    meta: state.media.meta,
    user: state.user.user,
    view: state.media.view,
    perPage: state.media.perPage,
    orderBy: state.media.orderBy,
    templateType: state.media.templateType,
    title: state.media.title,
    slug: state.media.slug,
    description: state.media.description,
});

export default connect(mapStateToProps)(Item);

