export const setCmsBlocks = (partner) => {
    if (partner) {
        const initialValue = {};

        return partner.partner_cms_content.reduce((obj, item) => {
            return {...obj, [item['block_name']]: {content: item.content, files: item.partner_cms_content_files}};
        }, initialValue);

    }

    return null;
}
