import React from 'react';
import {
    makeStyles,
    Grid,
    Typography,
    Card,
    Button,
    Link as LinkHref
} from '@material-ui/core';

const EmailCard = ({ title, items }) => {
    const useStyles = makeStyles((theme) => ({
        title: {
            color: '#999bb5', 
            textTransform: 'uppercase', 
            fontSize: 14, 
            fontWeight: 'bolder', 
            paddingBottom: 11,
        },
        cardPadding: {
            padding: '18px 10px 2em 10px',
            width: 290,
            height: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            borderRadius: 0
        },
        cardTitle: {
            fontSize: 18, 
            fontWeight: 'bold', 
            color: '#3e3f60',
        },
        divider: {
            width: 20, 
            marginTop: 5, 
            marginBottom: 24, 
            borderTop: '2px solid rgba(62, 63, 96, 0.25)',
        },        
        content: {
            fontSize: 14,
            marginBottom: 10,
        },
        iconSize: {
            width: 18,
            height: 18,
            margin: "0 10px 5px 10px",
        },
        email: {
            fontSize: 14, 
            wordWrap: "break-word", 
            width: "80%",
            paddingTop: 7,
        },
        buttonWrapper: {
            marginTop: 22,
        },
        emailButton: {
            borderRadius: 0,
            backgroundColor: '#af001e',
            color: 'white !important',
            fontWeight: 'bold',
            padding: '8px 15px 8px 15px',

            '&:hover': {
                backgroundColor: '#af001e99',
            }
        },
    }));

    const classes = useStyles();

    return (
        <Grid item md={12}>
            <Typography variant="h6" className={classes.title}>
                {title}
            </Typography>
            <Grid container direction="row" spacing={2}>
                {items.map((item, index) => (
                    <Grid item key={index}>
                        <Card className={classes.cardPadding}>
                            <Typography variant="h6" className={classes.cardTitle}>
                                {item.name}
                            </Typography>
                            <hr className={classes.divider} />
                            <Typography variant="body1" className={classes.content}>
                                <img className={classes.iconSize} src="/images/icons/SVG/Profile.svg" /> 
                                {item.title}
                            </Typography>
                            <Typography variant="body1" className={classes.content}>
                                <img className={classes.iconSize} src="/images/icons/SVG/Email.svg" /> 
                                {item.email}
                            </Typography>
                            { item.phone && (
                                <Typography variant="body1" className={classes.content}>
                                    <img className={classes.iconSize} src="/images/icons/SVG/Phone.svg" /> 
                                    {item.phone}
                                </Typography>
                            )}
                            <div className={classes.buttonWrapper} >
                                <Button 
                                    component={LinkHref} 
                                    href={`mailto:${item.email}`} 
                                    className={classes.emailButton}
                                >
                                    Email
                                </Button>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default EmailCard;
