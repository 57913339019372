import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import clsx from "clsx";
import {
    makeStyles,
    Drawer,
    AppBar,
    Toolbar,
    CssBaseline,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Badge,
    Fade,
    Popper,
    Typography,
    Grid,
    Card,
    Button,
    CardActions,
    CardHeader,
    CardContent,
    Box,
} from "@material-ui/core";
import {
    Menu,
    Close,
    ExitToApp,
    AccountCircle,
    Notifications,
    Lens,
    PanoramaFishEye,
} from "@material-ui/icons";
import {
    getAllNoticeboard,
    setNoteAsRead,
    markAllNotesAsRead,
    logoutUser,
} from "./headerActions";

import Permitted from "~/Permissions/Permitted";

import Moment from "react-moment";

const Header = (props) => {
    const drawerWidth = 240;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: "white",
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: "auto",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: 59,
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "2em",
            backgroundColor: "#f3f4f8",
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: "2em",
            backgroundColor: "#f3f4f8",
            height: "100%",
        },
        grow: {
            flexGrow: 1,
        },
        myProfileListItem: {
            backgroundColor: "#af001eA6",
            color: "white",
            "&:hover": {
                color: "#000000",
                "& $iconColor": {
                    fill: "#000000",
                },
            },
        },
        logoutListItem: {
            backgroundColor: "#af001e",
            color: "white",
            "&:hover": {
                color: "#000000",
                "& $iconColor": {
                    fill: "#000000",
                },
            },
        },
        iconColor: {
            fill: "#ffffff",
        },
        bigRedButton: {
            backgroundColor: "#af001e",
            borderRadius: 0,
            height: 64,
            "&:hover": {
                backgroundColor: "#af001eA5",
            },
        },
        logoImage: {
            marginLeft: 20,
            height: 40,
        },
        noticeCard: {
            zIndex: 99999,
            width: 363,
            maxHeight: 566,
        },
        noticeCardList: {
            overflowY: "auto",
            padding: 0,
        },
        noticeIcon: {
            fontSize: ".8rem",
            fill: "#af001e",
            margin: 5,
        },
        title: {
            fontSize: 20,
            fontWeight: 600,
            color: "#3e3f60",
        },
        noticeListItem: {
            display: "flex",
            alignItems: "baseline",
        },
        markAsRead: {
            color: "#af001e",
        },
        navIcons: {
            width: 20,
            height: 20,
        },
        link: {
            color: "#3e3f60",
            "&:hover": {
                color: "#3e3f60",
            },
        },
        selectedNav: {
            borderLeft: "2px solid #af001e",
            backgroundColor: "#af001e33",
            color: "#3e3f60",
            "&:hover": {
                color: "#3e3f60",
            },
        },
        linkHover: {
            "&:hover": {
                textDecoration: "none",
            },
        },
    }));

    const classes = useStyles();
    const [open, setOpen] = useState(
        localStorage.getItem("drawerState") === "false" ? false : true
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        if (props.user) props.dispatch(getAllNoticeboard());
    }, [props.user]);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleDrawerOpen = () => {
        localStorage.setItem("drawerState", true);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        localStorage.setItem("drawerState", false);
        setOpen(false);
    };

    const handleMarkAllAsRead = () => {
        props.dispatch(markAllNotesAsRead());
    };

    const handleLogoutUser = () => {
        props.dispatch(logoutUser());
    };

    const setAsRead = (id) => {
        props.dispatch(setNoteAsRead(id));
    };

    const getSomeAdminPermissions = () => {
        return [
            "admin_view_ticket_hospitality",
            "admin_view_appearances",
            "admin_view_activation",
            "admin_view_partner_workshops",
            "admin_view_right_protection",
            "admin_view_documents",
            "admin_view_brand_guidelines",
            "admin_view_logo_assets",
            "admin_view_brand_documents",
            "admin_view_archive",
            "admin_view_right_documentations",
            "admin_review_talent_request",
            "admin_view_talent_access",
            "admin_review_approvals_request",
            "view_user_list",
            "view_role_list",
            "view_partner_list",
        ];
    };

    return props.user ? (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar disableGutters>
                    {open ? (
                        <IconButton
                            onClick={handleDrawerClose}
                            className={classes.bigRedButton}
                        >
                            <Close
                                className={classes.iconColor}
                                fontSize="large"
                            />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={handleDrawerOpen}
                            className={classes.bigRedButton}
                        >
                            <Menu
                                className={classes.iconColor}
                                fontSize="large"
                            />
                        </IconButton>
                    )}
                    <Link to="/dashboard">
                        <img
                            className={classes.logoImage}
                            alt="Lions Logo"
                            src="/images/logo/LionsSeries-Logo-Landscape-RGB-POS_AW.svg"
                        />
                    </Link>
                    <div className={classes.grow} />
                    <div style={{ marginRight: "2em" }}>
                        <IconButton onClick={handleClick}>
                            <Badge
                                badgeContent={props.noticeboard.unread}
                                color="secondary"
                            >
                                <Notifications />
                            </Badge>
                        </IconButton>
                        <Popper
                            open={openMenu}
                            placement="bottom-end"
                            anchorEl={anchorEl}
                            disablePortal={false}
                            className={classes.noticeCard}
                        >
                            <Card raised style={{ borderRadius: 0 }}>
                                <CardHeader
                                    title={
                                        <Typography className={classes.title}>
                                            Notifications
                                        </Typography>
                                    }
                                />
                                <Divider />
                                <CardContent className={classes.noticeCardList}>
                                    <List style={{ maxHeight: "400px" }}>
                                        {props.noticeboard.data &&
                                        props.noticeboard.data.length > 0 ? (
                                            props.noticeboard.data.map(
                                                (notice, index) => {
                                                    return (
                                                        <Fragment
                                                            key={notice.id}
                                                        >
                                                            <ListItem
                                                                className={
                                                                    classes.noticeListItem
                                                                }
                                                            >
                                                                {notice.read ? (
                                                                    <PanoramaFishEye
                                                                        className={
                                                                            classes.noticeIcon
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Lens
                                                                        className={
                                                                            classes.noticeIcon
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            setAsRead(
                                                                                notice.id
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                <ListItemText>
                                                                    <p>
                                                                        {
                                                                            notice.notice
                                                                        }
                                                                    </p>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#c6c9db",
                                                                        }}
                                                                    >
                                                                        <Moment
                                                                            fromNow
                                                                            ago
                                                                            className="moment-date"
                                                                        >
                                                                            {
                                                                                notice.created_at
                                                                            }
                                                                        </Moment>{" "}
                                                                        ago
                                                                    </Typography>
                                                                </ListItemText>
                                                            </ListItem>
                                                            {props.noticeboard
                                                                .data.length -
                                                                1 ===
                                                            index ? null : (
                                                                <Divider />
                                                            )}
                                                        </Fragment>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <>
                                                <ListItem>
                                                    <ListItemText>
                                                        <Typography>
                                                            No notifications yet
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        )}
                                    </List>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    <Button
                                        onClick={handleMarkAllAsRead}
                                        className={classes.markAsRead}
                                    >
                                        Mark all as read
                                    </Button>
                                </CardActions>
                            </Card>
                        </Popper>
                        <Link to="/change-password">
                            <IconButton edge="end">
                                <AccountCircle />
                            </IconButton>
                        </Link>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar} />
                <Divider />
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ height: "inherit" }}
                >
                    <Grid item xs sm md lg xl>
                        <List>
                            <ListItem
                                button
                                component={Link}
                                to="/dashboard"
                                className={
                                    props.location.pathname === "/dashboard"
                                        ? classes.selectedNav
                                        : classes.link
                                }
                            >
                                <ListItemIcon>
                                    <img
                                        className={classes.navIcons}
                                        src="/images/icons/SVG/Dashboard.svg"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <Permitted some={["view_brand_guidelines"]}>
                                <ListItem
                                    button
                                    component={Link}
                                    to="/lions-series-2025"
                                    className={
                                        props.location.pathname.includes(
                                            "/lions-series-2025"
                                        )
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/Lions_Tour_2025.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Lions Series 2025" />
                                </ListItem>
                            </Permitted>
                            <Permitted some={["view_brand_guidelines"]}>
                                <ListItem
                                    button
                                    component={Link}
                                    to="/partner-information"
                                    className={
                                        props.location.pathname.includes(
                                            "/partner-information"
                                        )
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/PartnershipInformation.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Partner Information" />
                                </ListItem>
                            </Permitted>
                            <Permitted
                                some={[
                                    "view_brand_guidelines",
                                    "view_logo_assets",
                                    "edit_brand_guidelines_content",
                                    "upload_delete_brand_guidelines_files",
                                    "upload_logo_assets_content",
                                    "edit_existing_logo_assets",
                                    "delete_existing_logo_assets",
                                    "create_edit_delete_logo_asset_folder",
                                    "view_brand_documents",
                                    "upload_brand_documents_content",
                                    "edit_existing_brand_documents",
                                    "delete_existing_brand_documents",
                                    "create_edit_delete_brand_documents_folder",
                                ]}
                            >
                                <ListItem
                                    button
                                    component={Link}
                                    to="/brand"
                                    className={
                                        props.location.pathname.includes(
                                            "/brand"
                                        )
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/Brand.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Brand & Documents" />
                                </ListItem>
                            </Permitted>
                            <Permitted
                                some={[
                                    "upload_imagery_footage_content",
                                    "edit_existing_imagery_footage",
                                    "delete_existing_imagery_footage",
                                    "create_edit_delete_imagery_footage",
                                    "edit_archive_content",
                                    "edit_archive_links",
                                    "view_imagery_footage",
                                    "view_archive",
                                ]}
                            >
                                <ListItem
                                    button
                                    component={Link}
                                    to="/media"
                                    className={
                                        props.location.pathname.includes(
                                            "/media"
                                        )
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/Media Library.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Media Library" />
                                </ListItem>
                            </Permitted>
                            {/* <Link to="/welcome" className={classes.linkHover}>
                        <ListItem button className={props.location.pathname === '/welcome' ? classes.selectedNav : classes.link}>
                            <ListItemIcon>
                                <img className={classes.navIcons} src="/images/icons/SVG/Documentations.svg" />
                            </ListItemIcon>
                            <ListItemText primary="Rights & Documentation" />
                        </ListItem>
                    </Link> */}
                            {/* <Link to="/welcome" className={classes.linkHover}>
                        <ListItem button className={props.location.pathname === '/welcome' ? classes.selectedNav : classes.link}>
                            <ListItemIcon>
                                <img className={classes.navIcons} src="/images/icons/SVG/Talent Access.svg" />
                            </ListItemIcon>
                            <ListItemText primary="Talent Access" />
                        </ListItem>
                    </Link> */}
                            <Permitted
                                some={[
                                    "submit_approval_request",
                                    "review_approvals_request",
                                    "view_approvals",
                                ]}
                            >
                                <ListItem
                                    button
                                    component={Link}
                                    to="/approvals"
                                    className={
                                        props.location.pathname === "/approvals"
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/Approvals.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Approvals" />
                                </ListItem>
                            </Permitted>
                            {/* <Permitted
                                    some={[
                                        "submit_talent_request",
                                        "review_talent_request",
                                        "view_talent_access",
                                        "edit_talent_access",
                                        "upload_delete_talent_access_files",
                                        "view_talent_access_inventory",
                                        "create_talent_access_assets",
                                        "edit_existing_talent_access",
                                        "delete_talent_access_existing"
                                    ]}>
                                    <ListItem button component={Link} to="/talent-access" className={props.location.pathname === '/talent-access' ? classes.selectedNav : classes.link}>
                                        <ListItemIcon>
                                            <img className={classes.navIcons} src="/images/icons/SVG/TalentAccess.svg" />
                                        </ListItemIcon>
                                        <ListItemText primary="Talent Access" />
                                    </ListItem>
                                </Permitted> */}
                            <Permitted
                                some={[
                                    "review_talent_request",
                                    "review_approvals_request",
                                ]}
                            >
                                <ListItem
                                    button
                                    component={Link}
                                    to="/my-requests"
                                    className={
                                        props.location.pathname ===
                                            "/my-requests" ||
                                        props.location.pathname.includes(
                                            "/my-requests/"
                                        )
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/My Request.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="My Requests" />
                                </ListItem>
                            </Permitted>
                            <Permitted some={getSomeAdminPermissions()}>
                                <ListItem
                                    button
                                    component={Link}
                                    to="/admin"
                                    className={
                                        props.location.pathname === "/admin" ||
                                        props.location.pathname.includes(
                                            "/admin/"
                                        )
                                            ? classes.selectedNav
                                            : classes.link
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            className={classes.navIcons}
                                            src="/images/icons/SVG/Admin.svg"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Admin" />
                                </ListItem>
                            </Permitted>
                        </List>
                    </Grid>
                    <Grid item>
                        <List style={{ paddingBottom: 0 }}>
                            <ListItem
                                button
                                className={classes.myProfileListItem}
                                component={Link}
                                to="/change-password"
                            >
                                <ListItemIcon>
                                    <AccountCircle
                                        className={classes.iconColor}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="My Profile" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.logoutListItem}
                                onClick={handleLogoutUser}
                            >
                                <ListItemIcon>
                                    <ExitToApp className={classes.iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    ) : (
        <>{props.children}</>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    noticeboard: state.homepage.noticeboard,
});

export default connect(mapStateToProps)(withRouter(Header));
