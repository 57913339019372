import React, {useEffect, useState} from 'react';
import {Card, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AdminMenu from "~/Admin/AdminMenu";
import Typography from "@material-ui/core/Typography";
import qs from 'querystring';

import './GroupAllocation.scss';
import Button from "~/Components/Button";
import Matched from "~/Admin/RequestManagement/GroupAllocation/Matched/Matched";
import TimeColumn from "~/Admin/RequestManagement/GroupAllocation/TimeColumn/TimeColumn";
import {object} from "prop-types";
import ApproveAllocationsDialog
    from "~/Admin/RequestManagement/GroupAllocation/ApproveAllocationsDialog/ApproveAllocationsDialog";
import LibraryApi from "~/services/api/library";

const useStyle = makeStyles((theme) => ({
    card: {
        padding: 30,
        height: "100%",
        borderRadius: 0
    },
    tableCell: {
        border: 'solid 0.5px #c6c9db',
    }
}))

const GroupAllocation = ({history, location}) => {
    let queryParams = location.search[0] === '?' ? qs.parse(location.search.slice(1)) : qs.parse(location.search);
    const classes = useStyle();

    const [teamCaptain, setTeamCaptain] = useState(null);
    const [groupsData, setGroupsData] = useState([]);
    const [matchedGroup, setMatchedGroup] = useState(null);
    const [submissionData, setSubmissionData] = useState([]);
    const [approveSubmit, setApproveSubmit] = useState(false);
    const [approvalDialog, showApprovalDialog] = useState(false);
    const [team, setTeam] = useState(null);

    const backHandler = () => {
        history.push('/admin/request-management');
    }

    const {
        asset,
        access_type,
        request_type
    } = queryParams;

    const teamId = queryParams.team;

    useEffect(() => {
        getGroupAllocations();
        axios.get('/api/teams/' + teamId).then(res => {
            setTeam(res.data.data);
        });

    }, []);

    useEffect(() => {
        if (approveSubmit) {
            automaticAllocationSubmit()
        }
    }, [approveSubmit]);

    const getGroupAllocations = () => {
        const filter = `?team=${teamId}&session_name=${asset}&request_type=${request_type}&access_type=${access_type}`;

        axios.get('/api/talent-access-request/group-allocations' + filter).then(res => {
            setGroupsData(res.data.data.groups);
            setTeamCaptain(res.data.data.captain);
        });
    }

    const generateSubmissionData = (data, requests) => {

        data.forEach(group => {
            for (var i = 1; i <= 3; i++) {
                if (group.time_columns[i]) {
                    let timeColumn = group.time_columns[i][0];
                    if (!requests[timeColumn.request]) {
                        requests[timeColumn.request] = [];
                    }
                    group.group.forEach(member => {
                        requests[group.time_columns[i][0].request].push(member.id);
                    })
                }
            }
        })

        return requests;
    }
    const automaticAllocationSubmit = () => {
        let requests = [];

        requests = generateSubmissionData(groupsData, requests);

        if (access_type === 'players') {

            requests = generateSubmissionData(teamCaptain, requests);
        }

        let ids = Object.keys(requests);

        let data = {
            requests: [],
            access_type: access_type,
            team: teamId,
            request_type: request_type,
        }

        ids.forEach(id => {
            data.requests.push({id: id, members: requests[id]})
        });

        axios.put('/api/talent-access-request/automatic-allocation', data)
            .then(() => {
                getGroupAllocations();
                showApprovalDialog(false);
            }).catch(resp => {console.log(resp.errors)});
    }

    const generateExportData = (data, requests) => {
        data.forEach(group => {
            for (var i = 1; i <= 3; i++) {
                if (group.time_columns[i]) {
                    let timeColumn = group.time_columns[i][0];
                    if (!requests[timeColumn.request]) {
                        requests[timeColumn.request] = [];
                        // requests[timeColumn.request][i] = [];
                    }


                    requests[group.time_columns[i][0].request].push(Object.keys(timeColumn.member_ids));
                }
            }
        });

        return requests;
    }

    const exportData = () => {
        let requests = [];

        requests = generateExportData(groupsData, requests);

        if (access_type === 'players') {

            requests = generateExportData(teamCaptain, requests);
        }

        let ids = Object.keys(requests);

        let params = `access_type=${access_type}&team=${teamId}&request_type=${request_type}`;
        ids.forEach(id => {
            params += `&requests[]={"id":"${id}", "members":[${requests[id]}]}`
        });

        LibraryApi.downloadImage({
            path: '/api/talent-access-request/export-allocations?' + params,
            name: 'allocations',
            ext: 'csv'
        });
    }

    const renderRow = (rows, captain = false) => {

        return rows.map((members, key) => {
            let currentGroup = key;
            return (
                <TableRow key={key}>
                    <TableCell className={classes.tableCell} width="25%">
                        <Grid container direction="column">
                            <Grid item>
                                <Typography className="group-label">{captain ? 'CAPTAIN' : 'GROUP ' + (key + 1)}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    {members.group && members.group.map((member, memberKey) => {
                                        return (
                                            <Grid item key={memberKey}>
                                                <Typography className="player">
                                                    {member.name}
                                                    {access_type === 'players' &&
                                                        <span className="nationality">Tier {memberKey + 1} | {member.nationality}</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell className={classes.tableCell} width="25%">
                        {members.time_columns[1] && members.time_columns[1].map((column, key) => {
                            let partner = column.partner.data;
                            return (
                                <TimeColumn
                                    {...{key,
                                        partner,
                                        members,
                                        column,
                                        captain,
                                        setMatchedGroup,
                                        currentGroup,
                                        matchedGroup
                                    }}
                                />
                            )
                        })
                        }
                    </TableCell>
                    <TableCell className={classes.tableCell} width="25%">
                        {members.time_columns[2] && members.time_columns[2].map((column, index) => {
                            let partner = column.partner.data;
                            return (
                                <TimeColumn
                                    {...{index,
                                        partner,
                                        members,
                                        column,
                                        setMatchedGroup,
                                        currentGroup,
                                        captain,
                                        matchedGroup
                                    }}
                                />
                            )
                        })
                        }
                    </TableCell>
                    <TableCell className={classes.tableCell} width="25%">
                        {members.time_columns[3] && members.time_columns[3].map((column, key) => {
                            let partner = column.partner.data;
                            return (
                                <TimeColumn
                                    {...{
                                        key,
                                        partner,
                                        members,
                                        column,
                                        setMatchedGroup,
                                        currentGroup,
                                        captain,
                                        matchedGroup
                                    }}
                                />
                            )
                        })
                        }
                    </TableCell>
                </TableRow>
            );
        });

    }

    return (
        <section
            className={classes.section + ' brand-approval brand-approval-requests sticky-footer brand-approval-requests-title'}

            onClick={(event) => {
                if (event.target.className !== 'partner-image') {
                    setMatchedGroup(null);
                }
            }}
        >
            <Grid container>
                <Grid item>
                    <h1 className={classes.sectionTitle}>Admin</h1>
                </Grid>
            </Grid>
            <Grid container>
                <AdminMenu/>
            </Grid>
            <Divider/>
            <section className="group-grid">
                <Grid container direction="column" className="back-wrapper">
                    <Grid item md={12}>
                        <Button class="back noMarg noRadius border-0" text="BACK" onClick={backHandler}/>
                    </Grid>
                </Grid>
                <Grid container direction="column" className="title-wrapper">
                    <Grid item md={12}>
                        <Typography className="small-title">{queryParams.access_type}</Typography>
                        <Typography className="title">{queryParams.request_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    <Grid md={12} item>
                        <Card className={classes.card}>
                            <Grid container direction="row" justify="flex-start">
                                <Grid item md={3} className="selected-values">
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item>
                                            <Typography className="header-label">TEAM</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="header-value">{team ? team.name : ''}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3} className="selected-values">
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item>
                                            <Typography className="header-label">SESSION</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="header-value">{queryParams.asset}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={6}>
                                    <Grid container direction="row" className="action-wrapper" justify="flex-end">
                                        <Grid item>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item>
                                                    <Button class="btn-grey noMarg" text="EXPORT ALLOCATION"
                                                            onClick={exportData}/>
                                                </Grid>
                                                <Grid item>
                                                    <Button class="noMarg no-radius color-red"
                                                            disabled={approveSubmit}
                                                            text="APPROVE ALL ALLOCATIONS" onClick={() => showApprovalDialog(true)}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Card>
                    </Grid>
                    <Grid md={12} item>
                        <Card className={classes.card}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeaderBorder}>
                                            <TableCell  width="25%"/>
                                            <TableCell className={classes.tableCell} width="25%" style={{textAlign: 'center'}}>
                                                <Typography className="group-label">HOUR 1</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left" width="25%" style={{textAlign: 'center'}}>
                                                <Typography className="group-label">HOUR 2</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left" width="25%" style={{textAlign: 'center'}}>
                                                <Typography className="group-label">HOUR 3</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupsData.length > 0 && renderRow(groupsData)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer style={{marginTop: 50}}>
                                <Table>
                                    <TableBody>
                                        {teamCaptain && renderRow(teamCaptain, true)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                </Grid>
            </section>
            {approvalDialog &&
                <ApproveAllocationsDialog setApproveSubmit={setApproveSubmit} showApprovalDialog={showApprovalDialog}/>
            }
        </section>

    );
};

export default GroupAllocation;
