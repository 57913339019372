import React from 'react';
import './index.scss'
import {Row, Col} from "react-bootstrap";
import { Close } from '@material-ui/icons';
import {
    makeStyles,
} from '@material-ui/core';

const ResponseDialog = ({text, title, onClose}) => {

    const useStyles = makeStyles((theme) => ({
        closeButton: {
            cursor: 'pointer',
            position: 'absolute',
            right: '5px',
            top: '5px'
        }
    }));

    const classes = useStyles();

    const onCloseHandler = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <div className="response-dialog d-flex">
            <Close onClick={() => onCloseHandler()} className={classes.closeButton} />
            <div className="icon-container">
                <img src="/images/icons/SVG/Check-Green.svg" alt="check"/>
            </div>
            <div className="text-container">
                <div className="title">{title}</div>
                <div className="content">{text}</div>
            </div>
        </div>
    );
};

export default ResponseDialog;
