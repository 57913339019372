export const canManageMedia = (page) => {
    if (page === 'logo-assets') {
        return [
            'upload_logo_assets_content',
            'delete_existing_logo_assets',
            'edit_existing_logo_assets',
            'create_edit_delete_logo_asset_folder',
            'admin_view_logo_assets'
        ]
    }
    if (page === 'brand-documents') {
        return [
            'upload_brand_documents_content',
            'delete_existing_brand_documents',
            'edit_existing_brand_documents',
            'create_edit_delete_brand_documents_folder',
            'admin_view_brand_documents'
        ]
    }
    if (page === 'imagery-footage') {
        return [
            'upload_imagery_footage_content',
            'delete_existing_imagery_footage',
            'edit_existing_imagery_footage',
            'create_edit_delete_imagery_footage',
            'admin_view_imagery_footage'
        ]
    }

    return [];
}

export const canUpload = (page) => {
    if (page === 'logo-assets') {
        return [
            'upload_logo_assets_content',
        ]
    }
    if (page === 'brand-documents') {
        return [
            'upload_brand_documents_content',
        ]
    }
    if (page === 'imagery-footage') {
        return [
            'upload_imagery_footage_content',
        ]
    }

    return [];
}

export const canEdit = (page) => {
    if (page === 'logo-assets') {
        return [
            'edit_existing_logo_assets',
        ]
    }
    if (page === 'brand-documents') {
        return [
            'edit_existing_brand_documents',
        ]
    }
    if (page === 'imagery-footage') {
        return [
            'edit_existing_imagery_footage',
        ]
    }

    return [];
}

export const canDelete = (page) => {
    if (page === 'logo-assets') {
        return [
            'delete_existing_logo_assets',
        ]
    }
    if (page === 'brand-documents') {
        return [
            'delete_existing_brand_documents',
        ]
    }
    if (page === 'imagery-footage') {
        return [
            'delete_existing_imagery_footage',
        ]
    }

    return [];
}

export const canManageFolder = (page) => {
    if (page === 'logo-assets') {
        return [
            'create_edit_delete_logo_asset_folder',
        ]
    }
    if (page === 'imagery-footage') {
        return [
            'create_edit_delete_imagery_footage',
        ]
    }

    return [];
}
