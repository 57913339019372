import React from 'react';

const GridIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <path fill="#3E3F60" fillRule="evenodd" d="M10.344 11.719v8.531H1.813v-8.531h8.53zm9.844 0v8.531h-8.532v-8.531h8.531zM9.03 13.03H3.125v5.907h5.906V13.03zm9.844 0h-5.906v5.907h5.906V13.03zM10.344 1.875v8.531H1.813V1.875h8.53zm9.844 0v8.531h-8.532V1.875h8.531zM9.03 3.188H3.125v5.906h5.906V3.188zm9.844 0h-5.906v5.906h5.906V3.188z"/>
            </svg>
        </>
    );
};

export default GridIcon;
