import React from "react";
import moment from "moment";

export default ({request}) => {
    let partner = request.user?.partners && request.user?.partners[0]
        ? request.user?.partners && request.user?.partners[0]
        : null;

    return (

        <div className="events-block-new cards submission">
            <h4>User details</h4>
            <ul>
                <li>
                    <label>SUBMITTED BY:</label>
                    <span>{request.user.name}</span>
                </li>

                <li>
                    <label>PARTNER TIER:</label>
                    <span>{partner?.partner_tier?.name}</span>
                </li>
                <li>
                    <label>PARTNER:</label>
                    <span className="request-status">{partner?.name}</span>
                </li>
            </ul>
            <h4>Submission details</h4>
            <ul>
                <li>
                    <label>SUBMISSION DATE:</label>
                    <span>{moment(request.created_at).format('DD/MM/YYYY')}</span>
                </li>

                <li>
                    <label>UPDATED ON:</label>
                    <span>{moment(request.updated_at).format('DD/MM/YYYY')}</span>
                </li>
                <li>
                    <label>STATUS:</label>
                    <span
                        className="request-status">{request.status === 'change' ? 'Awaiting Changes' : request.status}</span>
                </li>
            </ul>
        </div>
    );
};
