import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./index.scss";

import Button from "~/Components/Button";
import DownloadIcon from "~/Components/Icons/DownloadIcon";
import PdfIcon from "~/Components/Icons/PdfIcon";
import LibraryApi from "~/services/api/library";
import PermissionChecker from "~/Permissions/PermissionChecker";
import { connect } from "react-redux";
import { resetOverridesData } from "~/Admin/PartnerManagement/ContentManagement/cmsInitialValues";
import setImagePathByExtension from "../../../helpers/Images/setImagePathByExtension";

const DescriptionTemplate = ({
    title,
    files,
    user,
    category,
    setLoaded,
    brandCms,
    mediaLibraryCms,
    manageAssets,
}) => {
    const downloadAll = () => {
        let downloadFiles = files;
        let url = "/api/uploads/zip/download?";
        let propertyPath = "path";

        if (brandCms && brandCms.files) {
            url = "/api/partner-cms-content/download-all?";
            propertyPath = "full_path";
            downloadFiles = brandCms.files;
        }

        LibraryApi.downloadZip(downloadFiles, setLoaded, propertyPath, url);
    };

    let checker = new PermissionChecker(user);

    const brandText = brandCms?.content.html || resetOverridesData.brand.brand;
    const archiveText =
        mediaLibraryCms?.content.html ||
        resetOverridesData.media_library.media_library;
    const springboksFootage = mediaLibraryCms?.content.springboks_footage;
    const springboksImagery = mediaLibraryCms?.content.springboks_imagery;
    const britishLionsFootage = mediaLibraryCms?.content.british_lions_footage;
    const britishLionsImagery = mediaLibraryCms?.content.british_lions_imagery;

    const brandFiles = brandCms?.files || files;

    const initialFiles = [
        {
            id: 0,
            name: "Series",
            path: "/files/lions-series-au2025-brand-guidelines.pdf",
            ext: "pdf",
        },
        {
            id: 1,
            name: "The British & Irish Lions",
            path: "/files/lions-brand-guidelines-core-2023_v7.pdf",
            ext: "pdf",
        },
        {
            id: 2,
            name: "Wallabies",
            path: "/files/wallabies-and-wallaroos-guidelines.pdf",
            ext: "pdf",
        },
        {
            id: 3,
            name: "Lions Origin Clubs & Schools",
            path: "/files/lions-origin-guidelines.pdf",
            ext: "pdf",
        },
    ];

    return (
        <div className="ptop-65 library-description">
            <Row>
                <Col className="subheader-title">{title}</Col>
            </Row>
            {category.slug !== "imagery-footage" ? (
                <Row>
                    <Col lg={6}>
                        <div
                            className="brand-description"
                            dangerouslySetInnerHTML={{ __html: brandText }}
                        />
                        <div className="brand-files">
                            <div>
                                {initialFiles.map((file, key) => {
                                    let path = file.path;
                                    return (
                                        <div
                                            key={key}
                                            className="d-flex files-container"
                                        >
                                            <div className="rectangle-items">
                                                <PdfIcon />{" "}
                                                {file.name.length > 30
                                                    ? file.name.substring(
                                                          0,
                                                          30
                                                      ) + "..."
                                                    : file.name}
                                            </div>
                                            <div className="rectangle-download">
                                                <a
                                                    href={path}
                                                    download={`${file.name}.${file.ext}`}
                                                >
                                                    <DownloadIcon
                                                        disableDownload={true}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="download-all">
                                <Button
                                    class="fullWidth color-red"
                                    text="Download All"
                                    onClick={() => {
                                        LibraryApi.downloadImage({
                                            path: `/files/brand-guidelines_v1.zip`,
                                            name: "brand-guidelines",
                                            ext: "zip",
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        {brandFiles && brandFiles.length > 0 && (
                            <>
                                <p className="title-download-section">
                                    Documentation
                                </p>
                                <div>
                                    {brandFiles &&
                                        brandFiles.map((file, key) => {
                                            let path =
                                                file.full_path || file.path;
                                            return (
                                                <div
                                                    key={key}
                                                    className="d-flex files-container"
                                                >
                                                    <div className="rectangle-items">
                                                        <img
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                marginRight: 5,
                                                            }}
                                                            src={
                                                                setImagePathByExtension(
                                                                    file.ext
                                                                ).path
                                                            }
                                                            alt={
                                                                setImagePathByExtension(
                                                                    file.ext
                                                                ).alt
                                                            }
                                                        />
                                                        {file.name.length > 20
                                                            ? file.name.substring(
                                                                  0,
                                                                  20
                                                              ) + "..."
                                                            : file.name}
                                                    </div>
                                                    <div className="rectangle-download">
                                                        <a
                                                            href={path}
                                                            download={`${file.name}.${file.ext}`}
                                                        >
                                                            <DownloadIcon
                                                                disableDownload={
                                                                    true
                                                                }
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className="download-all">
                                    <Button
                                        class="fullWidth color-red"
                                        text="Download All"
                                        onClick={downloadAll}
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            ) : (
                <Row className="m-0 mt-2">
                    {/* REMOVE COMMENT TO SHOW DESCRIPTION
                        <Col lg={3} className="description-wrapper">
                            <div className="brand-description" dangerouslySetInnerHTML={{ __html: archiveText }} />
                        </Col>
                    */}
                    <Col lg={6} md={6} xs={12}>
                        <div className="tiles">
                            <div className="image-wrapper left-image">
                                <img
                                    src="/images/archive/lions.png"
                                    alt="British & Irish Lions"
                                />
                            </div>
                            <div className="title">British & Irish Lions</div>
                            <div className="links active">
                                <span className="icons">
                                    <img
                                        src="/images/icons/SVG/Link-red.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="link-text">
                                    {britishLionsImagery ? (
                                        <a
                                            href={britishLionsImagery}
                                            target="_blank"
                                        >
                                            Access imagery
                                        </a>
                                    ) : (
                                        <a
                                            href="https://inpho.ie/app/WebObjects/Shop.woa/wa/s?cl=-V4LC8lLcbtaSfy9kYsavw..a&ts=bpjvMgsm4fT6vyFEv8de7pI-uZyEOqYFcOWhHeujq64.a"
                                            target="_blank"
                                        >
                                            Access imagery
                                        </a>
                                    )}
                                </span>
                            </div>
                            {/* TODO - remove comment when link is there
                            <div className="links active">
                                <span className="icons">
                                    <img src="/images/icons/SVG/Link-red.svg" alt="" />
                                </span>
                                <span className="link-text">
                                    {britishLionsFootage
                                        ?
                                        <a href={britishLionsFootage} target="_blank">Access footage</a>
                                        :
                                        <a href="#" target="_blank">Access footage</a>
                                    }
                                </span>
                            </div>
                            */}
                        </div>
                    </Col>
                    {/* TODO - remove comment when link is there
                    <Col lg={6} md={6} xs={12}>
                        <div className="tiles">
                            <div className="image-wrapper">
                                <img src="/images/archive/rugby-au.png" alt="Rugby Australia Badge" />
                            </div>
                            <div className="title">
                                Rugby Australia
                            </div>
                            <div className="links active">
                                <span className="icons">
                                    <img src="/images/icons/SVG/Link-red.svg" alt="" />
                                </span>
                                <span className="link-text">
                                    {springboksImagery
                                        ?
                                        <a href={springboksImagery} target="_blank">Access imagery</a>
                                        :
                                        <a href="#" target="_blank">Access imagery</a>
                                    }

                                </span>
                            </div>
                            <div className={'links active'}>
                                <span className="icons">
                                <img src="/images/icons/SVG/Link-red.svg" alt="" />
                                </span>
                                <span className="link-text">
                                    {springboksFootage
                                        ?
                                        <a href={springboksFootage} target="_blank">Access footage</a>
                                        :
                                        <a href="#" target="_blank">Access footage</a>
                                    }

                                </span>
                            </div>
                        </div>
                    </Col>
                    */}
                </Row>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    brandCms: state.user.cmsBlocks?.brand,
    mediaLibraryCms: state.user.cmsBlocks?.media_library,
});

export default connect(mapStateToProps)(DescriptionTemplate);
