import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Paper,
    Card,
    CardContent,
} from '@material-ui/core';
import AdminPartnersDropdown from '../Components/AdminPartnersDropdown/AdminPartnersDropdown';
import { PageMenu, PARTNER_INFORMATION_MENU } from '../Components/PageMenu';
import { resetOverridesData } from '~/Admin/PartnerManagement/ContentManagement/cmsInitialValues';
import setImagePathByExtension from '../helpers/Images/setImagePathByExtension';

const RightsProtection = (props) => {
    const rightProtectionCms = props.rightProtectionCms;

    const useStyles = makeStyles((theme) => ({
        section: {
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60
        },
        textPadding: {
            paddingTop: 20
        },
        documentsBackground: {
            backgroundColor: 'white'
        },
        sectionTitle: {
            color: '#af001e', 
            textTransform: 'uppercase', 
            fontSize: 20, 
            fontWeight: 600,
            paddingBottom: '1em'
        },
        icons: {
            width: 22,
            height: 22
        },
        documentPaper: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            height: 46,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            columnGap: '5px',
        },
        downloadIcon: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            textAlign: 'center'
        },
        rightProtection: {
            '& ol': {
                listStyleType: 'decimal',
            },

            '& ol li': {
                paddingTop: 15,
                fontSize: 16,
            },

            '& ol li::marker': {
                color: '#af001e',
                fontWeight: 'bold',
                display: 'block',
                width: '1em',
                marginLeft: '-1em',
            },           
        },
    }));

    const classes = useStyles();

    const rightsProtection = rightProtectionCms?.content.html;
    const rightFiles = rightProtectionCms?.files;

    
    return (
        <section className={classes.section}>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <h1 className="content-title">Partner Information</h1>
                </Grid>
                <Grid item>
                    <AdminPartnersDropdown tabId="0" />
                </Grid>
            </Grid>  

            <PageMenu menuItems={PARTNER_INFORMATION_MENU}/>
            <Divider />
            <Grid container spacing={2} className={classes.gridPadding}>
                <Grid item md={6}>
                    <Typography variant="h5" className={classes.sectionTitle}>
                        Rights & Protection Information
                    </Typography>
                    {rightsProtection
                        ?
                        <div dangerouslySetInnerHTML={{ __html: rightsProtection }} />
                        :
                        <div dangerouslySetInnerHTML={{ __html: resetOverridesData.lions_tour.right_protection }} />
                    }                    
                    {
                        rightFiles && (
                            <Grid className={classes.gridPadding}>
                                <Typography variant="h5" className={classes.sectionTitle}>
                                    Terms & Conditions
                                </Typography>
                                {
                                    rightFiles.map((file, key) => (
                                        <Grid 
                                            container 
                                            justify="space-between" 
                                            key={key} 
                                            direction="row" 
                                            spacing={1}
                                            alignContent="center"
                                        >
                                            <Grid item md>
                                                <Paper className={classes.documentPaper}>
                                                    <img className={classes.icons}
                                                        src={setImagePathByExtension(file.ext).path} 
                                                        alt={setImagePathByExtension(file.ext).alt}/> {file.name}
                                                </Paper>
                                            </Grid>
                                            <Grid item>
                                                <a href={file.full_path} download={file.original_name}>
                                                    <Paper className={classes.downloadIcon}>
                                                        <img className={classes.icons} src="/images/icons/SVG/Download.svg" />
                                                    </Paper>
                                                </a>
                                            </Grid>
                                        </Grid>
                                ))}
                            </Grid>
                        )                         
                    }
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={4} direction="column">
                        <Grid item>
                            <Card style={{ borderRadius: 0 }}>
                                <CardContent style={{ padding: 30 }}>
                                    <Typography variant="body1"
                                        style={{ color: '#af001e', fontWeight: 600, fontSize: 18 }}>
                                        What to do if you spot ambush marketing?
                                    </Typography>
                                    <Typography variant="body1" className={classes.textPadding}>
                                        Please report any instances of ambush marketing to your Partner 
                                        Manager as soon as possible.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card style={{ borderRadius: 0 }} >
                                <CardContent style={{ padding: 30 }}>
                                    <Typography variant="body1"
                                        style={{ color: '#af001e', fontWeight: 600, fontSize: 18 }}>
                                        What to include in your report?
                                    </Typography>
                                    <Typography variant="body1" className={classes.textPadding}>
                                        When sending a report, make sure to include as much information as 
                                        possible, including:
                                    </Typography>
                                    <Grid item className={classes.rightProtection}>
                                        <ol>
                                            <li>The date and location of where the activity was seen</li>
                                            <li>Brief description of the activity, including details of the entity /
                                            organisation behind the activity
                                            </li>
                                            <li>Visual representation of the activity (i.e. photographs or website
                                            address)
                                            </li>
                                        </ol>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>   
        </section>
    );
}

const mapStateToProps = ((state) => ({
    users: state.admin.users.rows,
    rightProtectionCms: state.user.cmsBlocks?.lions_tour_right_protection,
}));

export default connect(mapStateToProps)(RightsProtection);
