import React from 'react'
import { connect } from "react-redux";
import AuthContainer from './AuthContainer';
import { Link } from 'react-router-dom'
import ValidationErrors from '../Components/Utils/validationErrors';
import Button from '../Components/Button';

import BeatLoader from 'react-spinners/BeatLoader';
import './index.scss';
import { authHeader } from "~/helpers/authHeader";
import Card from "@material-ui/core/Card";
import {Redirect} from "react-router";

class NewPass extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            error: null,
            loading: false,
            email: '',
            password: '',
            password_confirmation: '',
            message: '',
            status: false,
            errors: {},
        };
    }

    resetPassword() {

        this.setState({
            loading: true
        });

        fetch('/api/users/reset-password', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation,
                token: this.props.match.params.token,
            })
        }).then((response) => {

            this.setState({
                loading: false,
                status: response.ok,
            });

            response.json().then((data) => {

                if (this.state.status) {
                    this.props.setUser(data);
                    this.props.history.push("/dashboard");
                } else {
                    this.setState({
                        errors: data || {},
                        message: data.message || ''
                    });
                }
            });
        });
    }

    handleKeyPress(e) {
        if (e.keyCode === 13) {
            this.loginUser();
        }
    }

    render() {
        if (this.props.user) {
            return <Redirect to="/dashboard" />
        }
        return (
            <AuthContainer>
                {!this.state.status && (
                    <Card
                        raised={true}
                        className="card">

                        <div className="row">
                            <div className="col-12 text-center">
                                <img height="100" alt="Lions Logo" src="/images/logo/LionsSeries-Logo-Portrait-RGB-POS_AW.svg" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>Reset your password</h1>
                                <p>Please fill in the form below to reset your password.</p>
                            </div>
                        </div>

                        <div className="row pt-5">
                            <div className="col-12">
                                <label className="float-right" htmlFor="email">Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Enter your email..."
                                    className="form-control"
                                    onChange={e => this.setState({ email: e.target.value })}
                                    onKeyDown={e => this.handleKeyPress(e)}
                                />
                                <ValidationErrors errors={this.state.errors.email || [this.state.message]} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <label className="float-right" htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Enter new password..."
                                    className="form-control"
                                    onChange={e => this.setState({ password: e.target.value })}
                                    onKeyDown={e => this.handleKeyPress(e)}
                                />
                                <ValidationErrors errors={this.state.errors.password} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <label className="float-right" htmlFor="password_confirmation">Confirm</label>
                                <input
                                    type="password"
                                    name="password_confirmation"
                                    className="form-control"
                                    placeholder="Confirm new password..."
                                    onChange={e => this.setState({ password_confirmation: e.target.value })}
                                    onKeyDown={e => this.handleKeyPress(e)}
                                />
                                <ValidationErrors errors={this.state.errors.password_confirmation} />
                            </div>
                        </div>

                        <div className="row pt-5">
                            <div className="col-12 text-center">
                                {!this.state.loading && (
                                    <Button class="color-red fullWidth" onClick={() => this.resetPassword()} text="Reset and Login" />
                                )}

                                <BeatLoader
                                    css={{ marginTop: '20px' }}
                                    color='#af001e'
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>

                    </Card>)}

                {this.state.status && (
                    <Card
                        raised={true}
                        className="card">

                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>Your password has been changed!</h1>
                                <p>User has been logged in!</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-center">
                                <Link className="link" to="/dashboard">Go to home page!</Link>
                            </div>
                        </div>

                    </Card>
                )}
            </AuthContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

const reducerToProp = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch({
                type: 'user/SET_USER',
                payload: user,
            });
        }
    };
};

export default connect(mapStateToProps, reducerToProp)(NewPass);
