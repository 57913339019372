import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Checkbox,
    MobileStepper,
    Modal,
    Backdrop,
    Card,
    Typography,
    Button,
    Grid
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const tutorialSteps = [
    {
        title: 'Welcome to the Lions Series Australia 2025 Partner Portal',
        subtitle: 'The Partner Portal is a platform to access all key information regarding the Tour.',
        imgPath:
            '/images/welcome-screen/lions_2025.png',
    },
    {
        title: '1. Approvals',
        subtitle: 'Easy to use online approvals system',
        imgPath:
            '/images/welcome-screen/7_approvals.jpg',
    },
    {
        title: '2. Brand',
        subtitle: 'Access to the full suite of Lions, Rugby Australia and Series logos, creative, assets and brand guidelines.',
        imgPath:
            '/images/welcome-screen/3_brand.jpg',
    },
    {
        title: '3. Media Library',
        subtitle: 'Access to the media library for all imagery and footage requirements',
        imgPath:
            '/images/welcome-screen/4_media_library.jpg',
    },
    // {
    //     title: '4. Rights & Documentations',
    //     subtitle: 'Ability to monitor and manage partner rights',
    //     imgPath:
    //         '/images/welcome-screen/5_rights.jpg',
    // },
    // {
    //     title: '5. Talent Access',
    //     subtitle: 'Forms to request key rights such as player & management appearances',
    //     imgPath:
    //         '/images/welcome-screen/6_talent_access.jpg',
    // },
    {
        title: '4. Tickets',
        subtitle: 'Ticketing and hospitality information',
        imgPath:
            '/images/welcome-screen/2_lions_tour_2021.jpg',
    },
    
];

const WelcomeModal = (props) => {

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalColPadding: {
            margin: '10em 1em 0 1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'inherit',
            position: 'relative'
        },
        rowHeight: {
            height: 'inherit',
            flexDirection: 'column'
        },
        closeButtonRow: {
            marginRight: 40,
            textAlign: 'right'
        },
        closeButton: {
            position: 'absolute',
            zIndex: 99999,
            fill: '#c6c9db',
            width: 40,
            height: 40,
            cursor: 'pointer'
        },
        nextButton: {
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            color: 'white',
            borderRadius: 0,
            width: 120,
            height: 50,
            fontWeight: 'bold',
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        dotsColor: {
            backgroundColor: '#af001e',
        },
        secondaryCheckboxColor: {
            color: '#af001e',
        },
        stepperBgColor: {
            backgroundColor: 'white',
            alignSelf: 'center'
        },
        dotMargin: {
            marginLeft: 10,
            marginRight: 10
        }
    }));

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSetStep = (index) => {
        setActiveStep((prevIndex) => prevIndex = index);
    };

    const handleClose = () => {
        setOpenWelcomeMessage(false);
        setOpenAddNoteDialog(false);
    };

    return (
        <Modal
            disableBackdropClick
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            open={props.openWelcomeMessage}
        >
            <Card style={{ borderRadius: 0, maxWidth: 1248, width: '100%', maxHeight: 650, height: '100%' }}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={5} sm={5} md={5}>
                        <Grid container justify="space-around" direction="column" style={{ height: '100%' }}>
                            <Grid item className={classes.modalColPadding}>
                                <Grid container direction="column" spacing={4}>
                                    <Grid item>
                                        <Typography variant="h5">{tutorialSteps[activeStep].title}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">{tutorialSteps[activeStep].subtitle}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    style={{ position: 'absolute', bottom: '50%', top: '50%' }}>
                                    <Grid item>
                                        {activeStep === tutorialSteps.length - 1 ?
                                            <Button className={classes.nextButton} size="small" onClick={props.handleCloseWelcomeMessage}>Finish</Button>
                                            : <Button className={classes.nextButton} size="small" onClick={handleNext}>Next</Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <MobileStepper
                                            variant="dots"
                                            steps={tutorialSteps.length}
                                            position="static"
                                            activeStep={activeStep}
                                            classes={{
                                                dot: classes.dotMargin,
                                                dotActive: classes.dotsColor,
                                                root: classes.stepperBgColor
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    style={{ position: 'absolute', bottom: '20px' }}>
                                    <Checkbox
                                        style={{
                                            color: "#af001e",
                                        }}
                                        classes={{
                                            colorSecondary: classes.secondaryCheckboxColor,
                                            checked: classes.secondaryCheckboxColor
                                        }}
                                        onChange={props.handleDontShowWelcomeMessage}
                                    />
                                    <Typography>Do not show this again</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} className={classes.rowHeight} style={{ backgroundImage: `url(${tutorialSteps[activeStep].imgPath})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <Grid item md={12} className={classes.closeButtonRow}>
                            <Close className={classes.closeButton} onClick={props.handleCloseWelcomeMessage} />
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Modal>
    );
}


export default connect()(WelcomeModal);
