import React from 'react';
import {Col, Row} from "react-bootstrap";
import './list.scss';
import ListItem from "./ListItem";

const List = (props) => {

    return (
        <Row>
            {props.files && props.files.length > 0 &&
            <Col md={12} lg={12} className="list-column">
                <div className="table-responsive">
                    <table className="table list">
                        <thead>
                        <tr>
                            <th scope="col">
                                {props.selectedDownloadItem && Object.keys(props.selectedDownloadItem).length === props.files.length
                                    ?
                                    <img src="/images/icons/SVG/checkbox-active.svg" className="checkbox-item"
                                         onClick={props.deselectFiles}/>
                                    :
                                    <img src="/images/icons/SVG/checkbox_list.svg" onClick={props.selectAllItems} className="checkbox-item"/>
                                }
                            </th>
                            <th scope="col">NAME</th>
                            <th scope="col">SIZE</th>
                            <th scope="col">FORMAT</th>
                            <th scope="col">LAST MODIFIED</th>
                            <th scope="col" className="last-col"/>
                        </tr>
                        </thead>
                        <tbody>
                        {props.files &&
                        props.files.map((item, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    setPreviewFile={props.setPreviewFile}
                                    item={item}
                                    setSelectedDownloadItems={props.setSelectedDownloadItems}
                                    selectedDownloadItem={props.selectedDownloadItem}
                                    selectAllItems={props.selectAllItems}
                                    getMonths={props.getMonths}
                                    setLoaded={props.setLoaded}
                                />
                            );
                        })
                        }
                        </tbody>
                    </table>
                </div>

            </Col>
            }
        </Row>
    );
};

export default List;
