import React, { useEffect, useState } from 'react';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { assignUsersToRole, fetchActiveRole, setRole, openEditRoleDialog, setEditRoleType } from "./roleActions";
import { connect } from "react-redux";
import { getRoles } from "../userActions";
import RoleDetails from "./RoleDetails/RoleDetails";
import EditPartnerUsersDialog from "~/Admin/PartnerManagement/EditPartnerUsersDialog/EditPartnerUsersDialog";
import ExpandedPermissions from "./ExpandedPermissions/ExpandedPermissions";
import CreateRole from "./CreateRole/CreateRole";
import EditRole from "./EditRole/EditRole";
import Pagination from "react-js-pagination";
import Permitted from "~/Permissions/Permitted";
import {filterUsers} from "~/Admin/UserManagements/RoleManagementTab/roleActions";

const RoleManagementTab = (props) => {
    const {
        classes,
        roles,
        dispatch,
        users,
        meta,
        usersMeta,
        activeRole,
        criteria,
        createRole,
        setCreateRole,
    } = props;

    const [editPartnerUsersDialog, setEditPartnerUsersDialog] = useState(false);
    const [userList, setUserList] = useState([]);
    const [showRoleDetails, setShowRoleDetails] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        props.dispatch(filterUsers());
    }, []);

    useEffect(() => {
        if (activeRole) {
            let userIds = activeRole.users.map(user => user.id);
            setUserList(userIds)
        }
    }, [activeRole]);

    const handleActivePartnerUserList = (userList) => {
        setUserList(userList);
    };

    const handleSaveUsers = () => {
        props.dispatch(assignUsersToRole(activeRole.id, userList))
            .then(() => {
                setEditPartnerUsersDialog(false);
                dispatch(getRoles({ criteria, input: '' }, null));
            });
    }

    const handleEditPartnerUsersDialogClose = () => {
        setEditPartnerUsersDialog(false);
    };

    // TOGGLE EDIT ROLE SIDENAV
    const handleOpenEditRoleDialog = (role) => {
        if (role) {
            dispatch(fetchActiveRole(role.id));
            dispatch(setEditRoleType(role.role_type));
            dispatch(openEditRoleDialog(true));
        }
    }

    const toggleCreateRole = () => {
        setCreateRole(!createRole);
    }

    const toggleRoleDetails = (role) => {
        if (role) {
            dispatch(fetchActiveRole(role.id));
            setShowRoleDetails(true);
        } else {
            dispatch(setRole());
            setShowRoleDetails(false)
        }
    }

    const goToPage = (page) => {
        setPage(page);
        dispatch(getRoles({ criteria, input: '' }, null, page))
    }


    return (
        <>
            <Grid container direction="column">
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead style={{ borderBottom: '2px solid #af001e'}}>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>Role ID</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>Name</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>Associated User</Typography>
                                    </TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roles ? roles.map((role) => (
                                    <TableRow key={role.id} className={classes.tableRowHover}>
                                        <TableCell className={classes.tableCell}>
                                            {role.id}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableCell}>{role.display_name}</TableCell>
                                        <TableCell align="left" className={classes.tableCell}>{role.users_count}</TableCell>
                                        <TableCell align="left">
                                            <Grid container direction="row" justify="flex-end" spacing={1}>
                                                <Grid item>
                                                    <Permitted one="edit_existing_role">
                                                        <IconButton
                                                            style={{
                                                                width: 42,
                                                                height: 42,
                                                                backgroundColor: 'white',
                                                                borderRadius: 0
                                                            }}
                                                            disableRipple
                                                            onClick={() => handleOpenEditRoleDialog(role)} >
                                                            <img className={classes.editButtonIcon} src="/images/icons/SVG/Edit.svg" />
                                                        </IconButton>
                                                    </Permitted>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        style={{
                                                            width: 42,
                                                            height: 42,
                                                            backgroundColor: '#af001e',
                                                            borderRadius: 0
                                                        }}
                                                        disableRipple
                                                        onClick={() => toggleRoleDetails(role)}>
                                                        <img className={classes.arrowIcon} src="/images/icons/SVG/Arrows/Right.svg" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))
                                    :
                                    <TableRow className={classes.tableRowHover}>
                                        <TableCell component="th" scope="row" colSpan={6}>
                                            <Typography className={classes.tableRowCell}>No results for given criteria</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <Grid item md={12} className={classes.paginationWrapper}>
                        {meta && meta.per_page < meta.total && (
                            <Pagination
                                prevPageText={
                                    <div className="prev-next">
                                        <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left" />
                                    </div>}
                                nextPageText={
                                    <div className="prev-next">
                                        <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right" />
                                    </div>}
                                activePage={meta.current_page}
                                itemsCountPerPage={Number(meta.per_page)}
                                totalItemsCount={meta.total}
                                pageRangeDisplayed={5}
                                hideFirstLastPages={true}
                                itemClass="page-item"
                                linkClass="page-link"
                                linkClassPrev="prev-next"
                                linkClassNext="prev-next"
                                onChange={goToPage}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <ExpandedPermissions
                createRole={createRole}
                activeRole={activeRole}
                toggleRoleDetails={toggleRoleDetails}
            />

            <CreateRole
                {...{
                    createRole,
                    toggleCreateRole
                }}
            />

            <EditRole
                {...{
                    activeRole
                }}
            />

            <RoleDetails
                showRoleDetails={showRoleDetails}
                toggleRoleDetails={toggleRoleDetails}
                setEditPartnerUsersDialog={setEditPartnerUsersDialog}
            />

            <EditPartnerUsersDialog
                active_partner_data={activeRole}
                meta={usersMeta}
                {...{
                    handleSaveUsers,
                    users,
                    userList,
                    handleActivePartnerUserList,
                    editPartnerUsersDialog,
                    filterUsers,
                    handleEditPartnerUsersDialogClose
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    users: state.role.users.data,
    meta: state.user.roles.meta,
    roles: state.user.roles.data,
    activityLogs: state.user.activityLogs.data,
    lastActivity: state.user.activityLogs.last_activity,
    partners: state.partner.partners.data,
    selectedUsers: state.user.selectedUsers,
    loggedInUser: state.user.user,
    activeRole: state.role.role,
});

export default connect(mapStateToProps)(RoleManagementTab);
