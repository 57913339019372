import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Grid, makeStyles, Tabs, Tab, Typography, Table, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import { withRouter, Link } from 'react-router-dom';
import { destroyPartnersManagement, assignUsersToPartner, getPartners, getPartnerPermissions, setActivePartnerData, setPartnerPermission, updatePartner, addPartner, getUserDropdowns, fetchUsersList, getPartnerTiers, deletePartner, clearActivePartnerData } from './partnerActions';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import AdminMenu from "~/Admin/AdminMenu";
import PermissionsTree from "~/Components/PermissionsTree/PermissionsTree";
import classNames from "classnames";
import CreatePartner from './CreatePartner/CreatePartner';
import UpdatePartner from './UpdatePartner/UpdatePartner';
import EditPartnerUsersDialog from './EditPartnerUsersDialog/EditPartnerUsersDialog';
import DeletePartnerDialog from './DeletePartnerDialog/DeletePartnerDialog';
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";
import {filterUsers} from "./partnerActions";

const useStyles = makeStyles((theme) => ({
    section: {
        // paddingLeft: 43,
    },
    partnerDetailsTitles: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 600,
        color: '#999bb5'
    },
    userListWrapper: {
        padding: '30px',
        minHeight: '333px',
        height: '100%'
    },
    indicator: {
        color: theme.primaryColor,
    },
    tourimg: {
        maxWidth: '-webkit-fill-available'
    },
    table: {
        minWidth: 650,
    },
    gridPadding: {
        paddingTop: 60,
        paddingBottom: 60
    },
    textPadding: {
        paddingTop: 20
    },
    arrowIcon: {
        width: 29,
        height: 29,
        "&:hover": {
            backgroundColor: '#af001eA5',
        }
    },
    cardTitle: {
        fontSize: '18px',
        fontStretch: 'normal',
        color: theme.primaryColor,
        fontFamily: theme.demiBold,
    },
    newPartnerButton: {
        borderRadius: 0,
        backgroundColor: theme.primaryColor,
        color: 'white',
        "&:hover": {
            backgroundColor: '#af001eA5',
        }
    },
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'none',
    },
    activeTab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
        color: theme.primaryColor,
        fontWeight: 'bold'
    },
    titlePadding: {
        paddingTop: '4rem'
    },
    test: {
        width: 500
    },
    createPartnerPadding: {
        padding: 20,
        minWidth: 400
    },
    subtitle: {
        color: theme.primaryColor,
    },
    dividerLink: {
        backgroundColor: theme.primaryColor
    },
    dialog: {
        borderRadius: 0,
        maxWidth: 490
    },
    timelineItem: {
        '&&&:before': {
            display: 'none',
        },
    },
    activityLabel: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#999bb5',
    },
    activityTime: {
        fontSize: '10px',
        color: '#3e3f60',
    },
    activityText: {
        fontSize: '14px',
        color: '#3e3f60',
    },
    paginationWrapper: {
        marginTop: '20px',
    },
    animatedWrapper: {
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        width: '100%',
    },
    partnerLogo: {
        maxWidth: '100%',
        border: '1px solid black'
    },
    editTxt: {
        fontSize: '12px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        color: '#999bb5',
        cursor: 'pointer',
        '&.noPartners': {
            fontSize: '16px',
            fontWeight: 'normal',
        }
    },
    tabLink: {
        width: '100%',
        borderBottom: '2px solid #af001e',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        width: '100%',
        maxWidth: '100%',
        '&:hover': {
            fontWeight: 'bold',
            color: '#af001e'
        }
    },
    tabLinkInactive: {
        width: '100%',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItem: {
        width: '100%',
        color: '#af001e',
        fontWeight: 'bold',
        maxWidth: '100%',
    },
    deletePartner: {
        fontSize: 16,
        color: '#de5644',
        fontWeight: 600
    },
    sectionTitle: {
        fontFamily: theme.demiBold,
        color: '#af001e', 
        textTransform: 'uppercase', 
        fontSize: 20, 
        fontWeight: 600,
    },
    manageButton: {
        fontSize: 14,
        color: '#3e3f60',
        fontWeight: 600,
        border: 'solid 2px #af001e',
        backgroundColor: 'transparent',
        width: 217,
        borderRadius: 0,
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#af001e',
        }
    }
}));

const PartnerManagement = (props) => {

    useEffect(() => {
        return () => {
            props.dispatch(destroyPartnersManagement());
        }
    }, [])

    const classes = useStyles();
    const {
        partners,
        partner_permissions,
        active_partner_data,
        users,
        meta,
        users_dropdowns,
        partner_tiers,
        history,
        user,
    } = props;

    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [activePartner, setActivePartner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [createPartnerDrawer, setCreatePartnerDrawer] = useState(false);
    const [updatePartnerDrawer, setUpdatePartnerDrawer] = useState(false);
    const [value, setValue] = useState(false);
    const [deletePartnerDialog, setDeletePartnerDialog] = useState(false);
    const [editPartnerUsersDialog, setEditPartnerUsersDialog] = useState(false);
    const [openUploadImage, setOpenUploadImage] = useState(true);
    const [userList, setUserList] = useState([]);

    const checker = new PermissionChecker(user);

    useEffect(() => {
        props.dispatch(getPartners());
        props.dispatch(getPartnerPermissions());
        props.dispatch(getUserDropdowns());
        props.dispatch(fetchUsersList());
        props.dispatch(getPartnerTiers());
    }, [])

    const handleAddPartner = (values) => {

        let data = new FormData;

        data.append('name', values.partner_name);

        if (values.logo) {
            data.append('logo', values.logo);
        }

        values.partnership_managers.forEach(id => {
            data.append('partnership_managers[]', id);
        });

        values.user_managers.forEach(id => {
            data.append('user_managers[]', id);
        });

        if (values.partner_tier_id) {
            data.append('partner_tier_id', values.partner_tier_id);
        }

        props.dispatch(addPartner(data))
            .then(() => {
                setCreatePartnerDrawer(false);
            })

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleUpdateSubmit = (values) => {

        let data = new FormData;

        data.append('name', values.partner_name);

        if (typeof (values.logo) !== 'string') {
            data.append('logo', values.logo);
        }

        values.partnership_managers.forEach(item => {
            data.append('partnership_managers[]', item.id);
        });

        values.user_managers.forEach(item => {
            data.append('user_managers[]', item.id);
        });

        if (values.partner_tier_id) {
            data.append('partner_tier_id', values.partner_tier_id);
        }

        props.dispatch(updatePartner(activePartner, data))
            .then(() => {
                setOpenUploadImage(true);
                setUpdatePartnerDrawer(false);
                props.dispatch(setActivePartnerData(activePartner));
            });

    };

    const setPartnerPermissions = () => {
        props.dispatch(setPartnerPermission(activePartner, selectedPermissions));
    }

    const handleDrawerClose = () => {
        setCreatePartnerDrawer(false);
        setUpdatePartnerDrawer(false);
        setOpenUploadImage(true);
    }

    const handleDeletePartner = (id, reassignPartnerId) => {
        props.dispatch(deletePartner(id, reassignPartnerId))
            .then(() => {
                setDeletePartnerDialog(false);
                setValue(null);
                props.dispatch(clearActivePartnerData());
                props.dispatch(getPartners());
            });
    }

    const handleDeletePartnerDialogOpen = () => {
        setDeletePartnerDialog(true);
    };

    const handleDeletePartnerDialogClose = () => {
        setDeletePartnerDialog(false);
    };

    const handleEditPartnerUsersDialogOpen = () => {
        setEditPartnerUsersDialog(true);
    };

    const handleEditPartnerUsersDialogClose = () => {
        setEditPartnerUsersDialog(false);
        handleSetActivePartner(activePartner);
    };

    const handleOpenUploadImage = () => {
        setOpenUploadImage(false);
    };

    const handleCloseUploadImage = () => {
        setOpenUploadImage(true);
    };

    const handleSetActivePartner = (partnerId) => {
        setActivePartner(partnerId)
        props.dispatch(setActivePartnerData(partnerId))
            .then((data) => {
                if (data && data.logo) {
                    setOpenUploadImage(true);
                }

                if (data && data.users) {
                    let activePartnerUserList = data.users.map((user) => {
                        return user.id;
                    });

                    handleActivePartnerUserList(activePartnerUserList);
                }
            });
    };

    const handleActivePartnerUserList = (userList) => {
        setUserList(userList);
    };

    const handleSaveUsers = () => {

        props.dispatch(assignUsersToPartner(activePartner, userList))
            .then(() => {
                setEditPartnerUsersDialog(false);
            })
    }

    return (
        <section className={classes.section}>
            <Grid container>
                <Grid item>
                    <h1 className="content-title">Admin</h1>
                </Grid>
            </Grid>
            <Grid container>
                <AdminMenu />
            </Grid>
            <Divider />
            <div className={classes.titlePadding}>
                {active_partner_data &&
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
                        <Grid item xs sm md lg xl>
                            {
                                <h2 className={classes.sectionTitle}>{active_partner_data.name}</h2>
                            }
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="space-between" spacing={2}>
                                <Permitted some={[
                                    "edit_ticket_content",
                                    "upload_delete_ticket_files",
                                    "admin_view_ticket_hospitality",
                                    "edit_appearances_content",
                                    "upload_delete_appearances_files",
                                    "admin_view_appearances",
                                    "edit_activation_content",
                                    "upload_delete_activation_files",
                                    "admin_view_activation",
                                    "edit_partner_workshops_content",
                                    "upload_delete_partner_workshops_files",
                                    "admin_view_partner_workshops",
                                    "edit_rights_protection_content",
                                    "upload_delete_rights_protection_files",
                                    "admin_view_right_protection",
                                    "edit_documents_content",
                                    "upload_delete_document_files",
                                    "admin_view_documents",
                                    "edit_brand_guidelines_content",
                                    "upload_delete_brand_guidelines_files",
                                    "admin_view_brand_guidelines",
                                    "edit_archive_content",
                                    "edit_archive_links",
                                    "admin_view_archive",
                                    "edit_rights_documentations",
                                    "upload_delete_rights_documentations_files",
                                    "admin_view_right_documentations",
                                    "admin_view_talent_access",
                                    "edit_talent_access",
                                    "upload_delete_talent_access_files",
                                    "edit_approvals",
                                    "upload_delete_approvals_files"
                                ]}>
                                    <Grid item>
                                        <Link to={`/admin/content-management/${active_partner_data.id}`} style={{ textDecoration: 'none' }}>
                                            <Button className={classes.manageButton}>
                                                Manage Content
                                        </Button>
                                        </Link>
                                    </Grid>
                                </Permitted>
                                <Grid item>
                                    <Button className={classes.manageButton}>Manage Inventory</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Grid container direction="column" spacing={2} className={classes.textPadding}>
                    <Grid item xs sm md lg xl>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs sm md lg xl>
                                        <Card className="request-types" style={{ borderRadius: 0 }}>
                                            {
                                                checker.one('view_partner_list') &&
                                                <Tabs
                                                    orientation="vertical"
                                                    variant="scrollable"
                                                    value={value}
                                                    onChange={handleChange}
                                                    classes={{
                                                        indicator: classes.tabs,
                                                    }}>
                                                    {
                                                        partners && partners.length > 0 && partners.map((partner, key) => (
                                                            <Tab
                                                                key={key}
                                                                style={{outline: 'none'}}
                                                                label={partner.name}
                                                                classes={{ wrapper: activePartner && activePartner === partner.id ? classes.activeTab : classes.tab }}
                                                                className={
                                                                    activePartner && activePartner === partner.id
                                                                        ? classes.tabItem
                                                                        : classes.tabItemBlack
                                                                }
                                                                onClick={() => { handleSetActivePartner(partner.id) }}
                                                            />
                                                        ))
                                                    }
                                                </Tabs>
                                            }
                                        </Card>
                                    </Grid>
                                    <Grid item xs sm md lg xl>
                                        <Permitted one="create_new_partner">
                                            <Button
                                                fullWidth
                                                className={classes.newPartnerButton}
                                                onClick={() => setCreatePartnerDrawer(true)}>
                                                <img src="/images/icons/SVG/Add.svg" style={{ width: 25, paddingRight: 10, fill: 'white', filter: 'brightness(0) invert(1)' }} /> New Partner
                                            </Button>
                                        </Permitted>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                active_partner_data &&
                                <Grid item xs sm md lg xl>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs sm md lg xl>
                                                            {active_partner_data &&
                                                                <Card style={{ borderRadius: 0 }}>
                                                                    <Grid container direction="column" style={{ padding: '30px' }} spacing={2}>
                                                                        <Grid item xs sm md lg xl>
                                                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                                                <Grid item className={classes.cardTitle}>Partner Details</Grid>
                                                                                <Permitted one="edit_partner">
                                                                                    <Grid item className={classes.editTxt} onClick={() => setUpdatePartnerDrawer(true)}>Edit partner</Grid>
                                                                                </Permitted>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs sm md lg xl>
                                                                            <img className={classes.partnerLogo} src={active_partner_data.logo} alt={active_partner_data.name} />
                                                                        </Grid>
                                                                        <Grid item xs sm md lg xl>
                                                                            <Grid container direction="row" alignItems="center" justify="space-between">
                                                                                <Grid item xs sm md lg xl className={classes.partnerDetailsTitles}>Partner Tier</Grid>
                                                                                <Grid item>
                                                                                    <Typography style={{ fontSize: 14 }}>{active_partner_data.partner_tier.name}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs sm md lg xl>
                                                                            <Grid container direction="row" alignItems="center" justify="space-between">
                                                                                <Grid item xs sm md lg xl className={classes.partnerDetailsTitles} >Partner Manager</Grid>
                                                                                <Grid item>
                                                                                    {
                                                                                        active_partner_data.partnership_managers.map((manager, key) => {
                                                                                            return <Typography style={{ fontSize: 14 }} key={key}>{manager.name}</Typography>
                                                                                        })
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs sm md lg xl>
                                                                            <Grid container direction="row" alignItems="center" justify="space-between">
                                                                                <Grid item xs sm md lg xl className={classes.partnerDetailsTitles} >User Manager</Grid>
                                                                                <Grid item>
                                                                                    {
                                                                                        active_partner_data.user_managers.map((manager, key) => {
                                                                                            return <Typography style={{ fontSize: 14 }} key={key}>{manager.name}</Typography>
                                                                                        })
                                                                                    }</Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs sm md lg xl>
                                                                            <Grid container direction="row" justify="space-between">
                                                                                <Grid item xs sm md lg xl className={classes.partnerDetailsTitles} >Assigned Users</Grid>
                                                                                <Grid item>
                                                                                    <Typography style={{ fontSize: 14 }}>{active_partner_data.users.length}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Card>
                                                            }
                                                        </Grid>
                                                        <Grid item xs sm md lg xl>
                                                            <Card className="request-types" style={{ borderRadius: 0 }}>
                                                                <Grid container direction="column" style={{ padding: '30px 0' }} spacing={2}>
                                                                    <Grid item style={{ position: 'relative', width: '100%' }}>
                                                                        {
                                                                            active_partner_data && partner_permissions &&
                                                                            <PermissionsTree
                                                                                children={partner_permissions}
                                                                                disabled={!checker.one('edit_partner_permissions')}
                                                                                hasPermissions={
                                                                                    active_partner_data
                                                                                        ? active_partner_data.permissions
                                                                                        : []
                                                                                }
                                                                                setSelectedPermissions={setSelectedPermissions}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Permitted one="edit_partner_permissions">
                                                                        <Grid item style={{ padding: '0 30px' }}>
                                                                            {
                                                                                selectedPermissions.length > 0 &&
                                                                                <Button fullWidth className={classes.newPartnerButton} onClick={setPartnerPermissions}>
                                                                                    Save
                                                                                </Button>
                                                                            }
                                                                        </Grid>
                                                                    </Permitted>
                                                                    <Grid item style={{ padding: '0 30px' }}>
                                                                        <Typography style={{ color: '#3e3f60', fontWeight: 600, fontSize: 12 }}>Please note</Typography>
                                                                        <Typography style={{ color: '#3e3f60', fontSize: 12 }}>References in red are overrides from the default partner tier permissions. Click on reset permissions to go back to the default set up.</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs sm md lg xl>
                                                    <Card className={classes.userListWrapper} style={{ borderRadius: 0 }}>
                                                        <Grid container justify="space-between">
                                                            <Grid item className={classes.cardTitle}>Assigned Users</Grid>
                                                            <Grid item>
                                                                <Permitted all={['edit_partner_user_list']}>
                                                                    <Typography
                                                                        className={classes.editTxt}
                                                                        onClick={handleEditPartnerUsersDialogOpen}>
                                                                        Edit user list
                                                                    </Typography>
                                                                </Permitted>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className={classes.partnerTable}>
                                                            <Grid item xs sm md lg xl>
                                                                {
                                                                    active_partner_data && active_partner_data.users && active_partner_data.users.length > 0 ?
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left">First Name</TableCell>
                                                                                    <TableCell align="left">Last Name</TableCell>
                                                                                    <TableCell align="left">Email</TableCell>
                                                                                    <TableCell align="left"></TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {
                                                                                    active_partner_data.users.map((user, key) => (
                                                                                        <TableRow key={key}>
                                                                                            <TableCell component="th" scope="row">
                                                                                                {user.first_name || user.name}
                                                                                            </TableCell>
                                                                                            <TableCell align="left">{user.last_name}</TableCell>
                                                                                            <TableCell align="left">{user.email}</TableCell>
                                                                                            <TableCell align="left"></TableCell>
                                                                                        </TableRow>
                                                                                    ))
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                        :
                                                                        <Grid className={classNames(classes.editTxt, 'no-partner')}>
                                                                            No partners yet
                                                                        </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs sm md lg xl>
                        <Permitted one="delete_existing_partner">
                            {
                                active_partner_data &&
                                <Card style={{ borderRadius: 0, padding: '10px 20px' }}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">

                                        <Grid item xs sm md lg xl>
                                            <Grid container direction="row" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Typography style={{ color: '#af001e', fontSize: 14, fontWeight: 600 }}>Delete Partner</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>Before proceeding, you will be asked to re-assign users to a new partner.</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleDeletePartnerDialogOpen} className={classes.deletePartner}>Delete Partner</Button>
                                        </Grid>
                                    </Grid>
                                </Card>
                            }
                        </Permitted>
                    </Grid>
                </Grid>
            </div>

            <CreatePartner
                {...{
                    loading,
                    users,
                    users_dropdowns,
                    handleAddPartner,
                    active_partner_data,
                    createPartnerDrawer,
                    partner_tiers,
                    handleDrawerClose
                }}
            />
            {active_partner_data &&
                <UpdatePartner
                    {...{
                        loading,
                        users,
                        users_dropdowns,
                        handleUpdateSubmit,
                        active_partner_data,
                        updatePartnerDrawer,
                        partner_tiers,
                        openUploadImage,
                        handleOpenUploadImage,
                        handleCloseUploadImage,
                        handleDrawerClose
                    }}
                />

            }
            <EditPartnerUsersDialog
                {...{
                    handleSaveUsers,
                    users,
                    meta,
                    users_dropdowns,
                    userList,
                    handleActivePartnerUserList,
                    editPartnerUsersDialog,
                    active_partner_data,
                    filterUsers,
                    handleEditPartnerUsersDialogClose
                }}
            />
            <DeletePartnerDialog
                {...{
                    deletePartnerDialog,
                    active_partner_data,
                    partners,
                    handleDeletePartnerDialogClose,
                    handleDeletePartner
                }}
            />
        </section >

    );
};

PartnerManagement.propTypes = {
    partners: PropTypes.array,
    partner_permissions: PropTypes.array,
    active_partner_data: PropTypes.object,
    meta: PropTypes.object,
    partner_tiers: PropTypes.array,
    history: PropTypes.object
}

const mapStateToProps = (state) => ({
    users: state.partner.users.data,
    meta: state.partner.users.meta,
    partners: state.partner.partners.data,
    partner_permissions: state.partner.partner_permissions.data,
    active_partner_data: state.partner.active_partner_data,
    users_dropdowns: state.partner.users_dropdowns,
    partner_tiers: state.partner.partner_tiers,
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(PartnerManagement));
