import update from 'immutability-helper';

const initialState = {
    teams: {
        data: [],
        meta: {}
    },
    team: {name: ''},
    players: [],
    staff: [],
    assets: [],
    originalAssets: [],
    loaded: false,
    step: 1,
    inventory: {
        data: [],
        meta: {},
    },
    errors: null,
    inventoryDialog: false,
    assetsConfirm: true,
    confirm: false,
};

const inventoryReducer = (state = initialState, action) =>
{
    if (action.type === 'inventory/SET_TEAMS') {
        return update(state, {
            teams: {$set: action.payload},
            // loaded: {$set: true},
        });
    }

    if (action.type === 'inventory/SET_TEAM') {
        return update(state, {
            team: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_PLAYERS') {
        return update(state, {
            players: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_STAFF') {
        return update(state, {
            staff: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_ASSETS') {
        return update(state, {
            assets: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_STEP') {
        return update(state, {
            step: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_TEAM_ASSETS') {
        return update(state, {
            inventory: {$set: action.payload},
            loaded: {$set: true},
        });
    }
    if (action.type === 'inventory/SET_INVENTORY_DIALOG') {
        return update(state, {
            inventoryDialog: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_ERRORS') {
        return update(state, {
            errors: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_ASSETS_CONFIRM') {
        return update(state, {
            assetsConfirm: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_CONFIRM') {
        return update(state, {
            confirm: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_LOADED') {
        return update(state, {
            loaded: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_ORIGINAL_ASSETS') {
        return update(state, {
            originalAssets: {$set: action.payload},
        });
    }

    return state;
};

export default inventoryReducer;
