import React from 'react';
import './ManualDialog.scss'
import Button from "~/Components/Button";
import {connect} from "react-redux";
import {resetTalentAccessAdminActions} from "~/Admin/RequestManagement/TalentAccessRequest/talentAccessAdminActions";

const ManualDialog = ({dispatch, deleteMode, memberToDelete}) => {

    const closeDialog = () => {
    }

    return (
        <div className="manual-dialog" onClick={closeDialog}>
            <div className="dialog-content">
                <div className="dialog-header">
                    Manual Allocation
                </div>
                <div className="dialog-text">
                    {deleteMode
                        ?
                        `Are you sure you want to remove ${memberToDelete.name} from this allocation?`
                        :
                        'Are you sure you want to switch to manual allocation? You will not be able to see the allocation table for this category.'
                    }

                </div>
                <div className="dialog-footer">
                    {deleteMode
                        ?
                        <Button class="footer-buttons next" text="YES, REMOVE"
                                onClick={() => {
                                    dispatch({type: 'talent_access_admin/SET_MEMBER_DELETE_EXECUTE', payload: true});
                                    dispatch({type: 'talent_access_admin/SET_MANUAL_DIALOG_SHOW', payload: false});
                                }}/>
                        :
                        <Button class="footer-buttons next" text="YES, SWITCH"
                                onClick={() => {
                                    dispatch({type: 'talent_access_admin/SET_MANUAL_MODE', payload: true});
                                    dispatch({type: 'talent_access_admin/SET_MANUAL_DIALOG_SHOW', payload: false});
                                }}/>
                    }
                    <Button class="footer-buttons cancel"
                            text="NO, CANCEL"
                            onClick={() => {
                                dispatch(resetTalentAccessAdminActions());
                            }}/>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ((state) => ({
    deleteMode: state.talentAccessAdminRequest.deleteMode,
    memberToDelete: state.talentAccessAdminRequest.memberToDelete,
}));

export default connect(mapStateToProps)(ManualDialog);
