import React from 'react';
import {
    Dialog,
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Divider
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import Upload from '~/Components/Upload';
import Button from "~/Components/Button";
import PreviewFile from "~/Components/PreviewFile";

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        width: 163,
        fontSize: 16,
        fontWeight: 600,
        color: '#3e3f60',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#ecedf3',
        borderRadius: 0
    },
    saveChangesButton: {
        width: 163,
        fontSize: 16,
        fontWeight: 600,
        color: '#ffffff',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#af001e',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001e80',
        },
    },
    section: {
        paddingLeft: 43,
    },
    indicator: {
        color: '#af001e'
    },
    tourimg: {
        width: '100%'
    },
    table: {
        borderRadius: 0
    },
    gridPadding: {
        paddingTop: 60,
        paddingBottom: 60
    },
    textPadding: {
        paddingTop: 20
    },
    arrowIcon: {
        width: 29,
        height: 29
    },
    tabItem: {
        color: '#af001e',
        fontWeight: 'bold'
    },
    tabLink: {
        borderBottom: '2px solid #af001e',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        '&:hover': {
            fontWeight: 'bold',
            color: '#af001e'
        }
    },
    tabLinkInactive: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    paginationWrapper: {
        marginTop: '20px',
    },
}));

const AddFilesToCommentDialog = (props) => {

    const classes = useStyles();
    const {
        handleCloseAddFilesDialog,
        openAddFilesDialog,
        sendFiles,
        files,
        handleRemoveFileFromUploads,
        previewUpload,
        fileTypeIcon
    } = props;

    return (
        <Dialog
            open={openAddFilesDialog}
            onClose={handleCloseAddFilesDialog}
            fullWidth
            maxWidth={'sm'}
            PaperProps={{ style: { borderRadius: 0, paddingTop: 20, overflow: 'initial' } }}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="row" alignItems="baseline" justify="space-between" spacing={2}>
                        <Grid item>
                            <Typography style={{ paddingLeft: 20, fontSize: 30, fontWeight: 600, color: '#3e3f60' }}>Attach Documents</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.closeButton} onClick={handleCloseAddFilesDialog}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Upload sendFiles={sendFiles} accept=".doc,.docx,.eps,.jpg,.png,.svg,.ai,.psd,.pdf,.xlsx,.mp4,.mov,.zip," />
                </Grid>
                {
                    files.length > 0 &&
                    <Grid item>
                        <Grid container direction="column" style={{ padding: 30 }} spacing={2}>
                            <Grid item>
                                <Typography style={{ color: '#999bb5', textTransform: 'uppercase', fontWeight: 'bold', fontSize: 14 }}>Uploaded Files</Typography>
                            </Grid>
                            {
                                files.map((item, key) => (
                                    <Grid item key={key}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Grid container direction="row" justify="space-between" key={key} spacing={2}>
                                                    <Grid item>
                                                        <Grid container direction="row" alignItems="flex-end" spacing={2}>
                                                            <Grid item>
                                                                {fileTypeIcon(item.name)}
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography style={{
                                                                    whiteSpace: 'nowrap',
                                                                    width: 150,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'
                                                                }}>{item.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography style={{ color: '#d8dae6', fontSize: 10 }}>{(item.size * 0.000001).toFixed(2)} MB</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            {
                                                                <Grid item>
                                                                    <img
                                                                        style={{ width: 19, height: 19, cursor: 'pointer' }}
                                                                        src="/images/icons/SVG/View.svg"
                                                                        alt={item.name}
                                                                        onClick={() => previewUpload(item)}
                                                                    />
                                                                </Grid>
                                                            }
                                                            <Grid item>
                                                                <img
                                                                    style={{ width: 19, height: 19, cursor: 'pointer' }}
                                                                    src="/images/icons/SVG/Close.svg"
                                                                    alt="close"
                                                                    onClick={() => {
                                                                        handleRemoveFileFromUploads(item);
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item>
                                                <LinearProgress variant="determinate" value={50} />
                                            </Grid> */}
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <Grid item style={{ textAlign: 'right' }}>
                                <div className="comment-btn-wrapper">
                                    <Button class="color-red noMarg" style={{ fontSize: 16 }} text="CONFIRM UPLOAD" onClick={handleCloseAddFilesDialog} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {/* <PreviewFile
                previewFile={previewFile}
                handleHidePreview={handleHidePreview}
                setShowPreview={() => handleHidePreview(false)}
            /> */}
        </Dialog >
    );
}


export default connect(null, null)(AddFilesToCommentDialog);
