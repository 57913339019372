import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import { deleteUser, fetchUserActivity, fetchUserList, filterUsersMultiple, addUser, setSelectedUsers, updateUserAction, massDeleteUsers, massAssignUsersToPartner, massAssignUsersToRole } from "../userActions";
import CreateUser from './CreateUser/CreateUser';
import UserDetails from './UserDetails/UserDetails';
import UpdateUser from './UpdateUser/UpdateUser';
import DeleteUserDialog from './DeleteUserDialog/DeleteUserDialog';
import Pagination from "react-js-pagination";
import MassDeleteUsersDialog from './MassDeleteUsersDialog/MassDeleteUsersDialog';
import MassChangePartnerDialog from './MassChangePartnerDialog/MassChangePartnerDialog';
import MassChangeRoleDialog from './MassChangeRoleDialog/MassChangeRoleDialog';
import FooterCommands from '../FooterCommands/FooterCommands';
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";
import { Search } from '@material-ui/icons';
import __ from 'lodash';

const UserManagementTab = (props) => {

    const {
        users,
        createUser,
        toggleCreateUser,
        classes,
        dispatch,
        lastActivity,
        activityLogs,
        partners,
        roles,
        meta,
        selectedUsers,
        loggedInUser,
        setSidebarElement,
        setCreateUser,
        selectAllCheckbox,
        setSelectAllCheckbox,
        showFilter
    } = props;

    const [page, setPage] = useState(1);
    const [emailError, setEmailError] = useState({ error: false, message: '' });
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
    const [editUser, setEditUser] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [massDeleteDialog, setMassDeleteDialog] = useState(false);
    const [massRoleDialog, setMassRoleDialog] = useState(false);
    const [massPartnerDialog, setMassPartnerDialog] = useState(false);
    const checker = new PermissionChecker(loggedInUser);
    const [searchParams, setSearchParams] = useState({
        uid: '',
        firstname: '',
        lastname: '',
        email: '',
        role: ''
    })

    // ON COMPONENT LOAD
    useEffect(() => {
        props.dispatch(fetchUserList());
    }, []);

    // PAGINATION NAVIGATION
    const goToPage = (page) => {
        setPage(page);
        dispatch(fetchUserList(page))
    }

    // SUBMIT CREATE USER
    const handleSubmit = (data) => {
        setLoading(true);
        setEmailError({
            error: false,
            message: ''
        })
        props.dispatch(addUser(data))
            .then((res) => {
                if (res) {
                    setLoading(false);
                    setCreateUser(false);
                    return
                }
                setEmailError({
                    error: true,
                    message: res.email[0]
                })
                return;
            });
    };

    // SET CURRENT SIDEBAR
    const setCurrentSidebar = (r) => {
        if (r) {
            setSidebarElement(r);
        }
    }

    // TOGGLE USER DETAILS SIDEBAR
    const toggleUserDetails = (user) => {
        props.dispatch(setSelectedUsers([]));
        setSelectAllCheckbox(false);
        if (user) {
            setActiveUser(user);
            props.dispatch(fetchUserActivity(user.id));
        } else {
            setActiveUser(null);
        }
        setUserDetails(!userDetails);
    }

    // TOGGLE EDIT USER SIDEBAR
    const toggleEditUser = (user) => {
        props.dispatch(setSelectedUsers([]));
        setSelectAllCheckbox(false);
        setActiveUser(user);
        setEditUser(!editUser);
    }

    // HANDLE UPDATE USER
    const handleUpdateUser = (data) => {
        props.dispatch(updateUserAction({ id: activeUser.id, data: data }))
            .then(() => {
                setActiveUser(null);
                setEditUser(false);

                if (loggedInUser.id === activeUser.id) {
                    props.dispatch(fetchMe());
                }
            })
    }

    // HANDLE OPEN DELETE DIALOG
    const handleOpenDeleteDialog = () => {
        setUserDetails(false);
        setOpenDeleteDialog(true);
    };

    // HANDLE CLOSE DELETE DIALOG
    const handleCloseDeleteDialog = (event) => {
        if (event) {
            setOpenDeleteDialog(false);
            return
        }
        setOpenDeleteDialog(false);
        setUserDetails(true);
    };

    // HANDLE DELETE USER
    const handleDeleteUser = (confirm) => {
        if (confirm) {
            return props.dispatch(deleteUser(activeUser.id))
                .then(() => {
                    setActiveUser(null);
                    setOpenDeleteDialog(false);
                })
        } else {
            setActiveUser(null);
        }
        setOpenDeleteDialog(false);
    };

    // HANDLE TOGGLE MASS DELETE DIALOG
    const handleToggleMassDeleteDialog = () => {
        setMassDeleteDialog(!massDeleteDialog);
    }

    // HANDLE TOGGLE MASS ROLE DIALOG
    const handleToggleMassRoleDialog = () => {
        setMassRoleDialog(!massRoleDialog);
    }

    // HANDLE TOGGLE MASS PARTNER DIALOG
    const handleToggleMassPartnerDialog = () => {
        setMassPartnerDialog(!massPartnerDialog);
    }

    // HANDLE SELECT USER
    const handleSelectUser = (id) => {
        let newState = { ...selectedUsers };
        if (newState[id]) {
            delete newState[id];
        } else {
            newState = { ...newState, [id]: true };
        }

        props.dispatch(setSelectedUsers(newState));
    }

    // SELECT ALL USERS
    const selectAll = () => {
        let selectAllUsers = { ...selectedUsers };
        if (Object.keys(selectAllUsers).length >= 0 && !selectAllCheckbox) {
            users.map(user => {
                selectAllUsers = { ...selectAllUsers, [user.id]: true }
            });
        } else {
            users.map(user => {
                delete selectAllUsers[user.id]
            });
        }
        props.dispatch(setSelectedUsers(selectAllUsers));
        setSelectAllCheckbox(!selectAllCheckbox);
    }

    // DELETE SELECTED USERS
    const deleteSelectedUsers = () => {
        const payload = new FormData;

        Object.keys(selectedUsers).map(item => payload.append('user_ids[]', Number(item)));

        props.dispatch(massDeleteUsers(payload))
            .then(() => {
                handleToggleMassDeleteDialog();
                props.dispatch(setSelectedUsers([]));
            });
    }

    // SUBMIT CHANGE ROLE SELECTED USERS
    const changeRoleSelectedUsers = (roleId) => {
        props.dispatch(massAssignUsersToRole(roleId, Object.keys(selectedUsers).map(item => Number(item))));
        deselectAllUsers();
        handleToggleMassRoleDialog();
    }

    // SUBMIT CHANGE PARTNER SELECTED USERS
    const changePartnerSelectedUsers = (partnerList) => {
        props.dispatch(massAssignUsersToPartner(partnerList, Object.keys(selectedUsers).map(item => Number(item))));
        deselectAllUsers();
        handleToggleMassPartnerDialog();
    }

    // SELECT ALL USERS
    const selectAllUsers = () => {
        let selectAllUsers = { ...selectedUsers };
        users.map(user => {
            selectAllUsers = { ...selectAllUsers, [user.id]: true }
        });
        props.dispatch(setSelectedUsers(selectAllUsers));
        setSelectAllCheckbox(true);
    }

    // DESELECT ALL USERS
    const deselectAllUsers = () => {
        let selectAllUsers = { ...selectedUsers };
        users.map(user => {
            delete selectAllUsers[user.id]
        });
        props.dispatch(setSelectedUsers([]));
        setSelectAllCheckbox(false);
    }

    // AFTER INSERT FILTER DATA
    useEffect(() => {
        props.dispatch(filterUsersMultiple(searchParams, 0, 10));
    }, [searchParams]);

    // DATA FILTER
    const filterUsers = __.debounce((type, value) => {
        if (type === 'uid') {
            setSearchParams((prev) => (
                {
                    ...prev,
                    uid: value
                }
            ))
        }

        if (type === 'firstname') {
            setSearchParams((prev) => (
                {
                    ...prev,
                    firstname: value
                }
            ))
        }

        if (type === 'lastname') {
            setSearchParams((prev) => (
                {
                    ...prev,
                    lastname: value
                }
            ))
        }

        if (type === 'email') {
            setSearchParams((prev) => (
                {
                    ...prev,
                    email: value
                }
            ))
        }

        if (type === 'role') {
            setSearchParams((prev) => (
                {
                    ...prev,
                    role: value
                }
            ))
        }

    }, 500)

    return (
        <>
            <Grid container direction="column" style={{ paddingBottom: '13rem' }}>
                <Grid item>
                    <TableContainer component={Paper} style={{ borderRadius: 0 }}>
                        <Table className={classes.table}>
                            <TableHead style={{ borderBottom: '2px solid #af001e' }}>
                                <TableRow>
                                    {
                                        checker.some([
                                            "edit_existing_user",
                                            "mass_delete_users"
                                        ]) &&
                                        <TableCell padding="checkbox" className={classes.tableHeadCell}>
                                            <Checkbox
                                                indeterminate={(selectedUsers && Object.keys(selectedUsers).length) && Object.keys(selectedUsers).length !== users.length ? true : false}
                                                checked={selectAllCheckbox && Object.keys(selectedUsers).length === users.length}
                                                color="primary"
                                                style={{
                                                    borderRadius: 0
                                                }}
                                                onClick={() => selectAll()} />
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <Typography className={classes.tableCell}>User ID</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>First Name</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>Last Name</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>Email</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className={classes.tableCell}>Role</Typography>
                                    </TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    showFilter && (
                                        <TableRow className="filters">
                                            <TableCell className={classes.tableCell}>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Search"
                                                    onChange={(searchValue) => { 
                                                        filterUsers('uid', searchValue.target.value)
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0
                                                        },
                                                        endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Search"
                                                    onChange={(searchValue) => filterUsers('firstname', searchValue.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0
                                                        },
                                                        endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Search"
                                                    onChange={(searchValue) => filterUsers('lastname', searchValue.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0
                                                        },
                                                        endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Search"
                                                    onChange={(searchValue) => filterUsers('email', searchValue.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0
                                                        },
                                                        endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Search"
                                                    onChange={(searchValue) => filterUsers('role', searchValue.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0
                                                        },
                                                        endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ minWidth: 125 }}>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    users
                                        && users.length > 0
                                        ? users.map((user) => (
                                            <TableRow key={user.id} className={classes.tableRowHover}>
                                                {
                                                    checker.some([
                                                        "edit_existing_user",
                                                        "mass_delete_users"
                                                    ]) &&
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            style={{
                                                                borderRadius: 0
                                                            }}
                                                            color="primary"
                                                            checked={Object.keys(selectedUsers).includes(String(user.id))}
                                                            onClick={() => handleSelectUser(user.id)} />
                                                    </TableCell>
                                                }
                                                <TableCell component="th" scope="row">
                                                    <Typography className={classes.tableCell}>{user.id}</Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography className={classes.tableCell}>{user.first_name || user.name}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography className={classes.tableCell}>{user.last_name}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography className={classes.tableCell}>{user.email}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography className={classes.tableCell}>{user.role && user.role.display_name}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Grid container direction="row" justify="flex-end" spacing={1}>
                                                        <Grid item>
                                                            <Permitted one="edit_existing_user">
                                                                <IconButton
                                                                    style={{
                                                                        width: 42,
                                                                        height: 42,
                                                                        backgroundColor: 'white',
                                                                        borderRadius: 0
                                                                    }}
                                                                    disableRipple
                                                                    onClick={() => toggleEditUser(user)} >
                                                                    <img className={classes.editButtonIcon} src="/images/icons/SVG/Edit.svg" />
                                                                </IconButton>
                                                            </Permitted>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton
                                                                style={{
                                                                    width: 42,
                                                                    height: 42,
                                                                    backgroundColor: '#af001e',
                                                                    borderRadius: 0
                                                                }}
                                                                disableRipple
                                                                onClick={() => toggleUserDetails(user)}>
                                                                <img className={classes.arrowIcon} src="/images/icons/SVG/Arrows/Right.svg" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow className={classes.tableRowHover}>
                                            <TableCell component="th" scope="row" colSpan={6}>
                                                <Typography className={classes.tableRowCell}>No results for given criteria</Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item md={12} className={classes.paginationWrapper}>
                    {meta && meta.per_page < meta.total && (
                        <Pagination
                            prevPageText={
                                <div className="prev-next">
                                    <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left" />
                                </div>}
                            nextPageText={
                                <div className="prev-next">
                                    <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right" />
                                </div>}
                            activePage={meta.current_page}
                            itemsCountPerPage={meta.per_page}
                            totalItemsCount={Number(meta.total)}
                            pageRangeDisplayed={5}
                            hideFirstLastPages={true}
                            itemClass="page-item"
                            linkClass="page-link"
                            linkClassPrev="prev-next"
                            linkClassNext="prev-next"
                            onChange={goToPage}
                        />
                    )}
                </Grid>
            </Grid>

            <CreateUser
                {...{
                    createUser,
                    toggleCreateUser,
                    handleSubmit,
                    setCurrentSidebar,
                    emailError,
                    loading,
                    partners,
                    roles
                }}
            />

            <UserDetails
                {...{
                    userDetails,
                    toggleUserDetails,
                    toggleEditUser,
                    activeUser,
                    setCurrentSidebar,
                    handleOpenDeleteDialog,
                    lastActivity,
                    activityLogs
                }}
            />

            <UpdateUser
                {...{
                    editUser,
                    toggleEditUser,
                    handleUpdateUser,
                    activeUser,
                    partners,
                    setCurrentSidebar,
                    roles
                }}
            />

            <DeleteUserDialog
                {...{
                    openDeleteDialog,
                    handleCloseDeleteDialog,
                    handleDeleteUser
                }}
            />

            {
                Object.keys(selectedUsers).length > 0 &&
                <FooterCommands
                    {...{
                        users,
                        selectAll,
                        selectedUsers,
                        selectAllUsers,
                        deselectAllUsers,
                        deleteSelectedUsers,
                        changeRoleSelectedUsers,
                        changePartnerSelectedUsers,
                        massDeleteDialog,
                        massPartnerDialog,
                        massRoleDialog,
                        handleToggleMassDeleteDialog,
                        handleToggleMassRoleDialog,
                        handleToggleMassPartnerDialog,
                        selectAllCheckbox,
                        roles,
                        partners,
                        loggedInUser
                    }} />
            }

            <MassDeleteUsersDialog
                {...{
                    massDeleteDialog,
                    handleToggleMassDeleteDialog,
                    deleteSelectedUsers
                }}
            />

            <MassChangeRoleDialog
                {...{
                    roles,
                    massRoleDialog,
                    handleToggleMassRoleDialog,
                    changeRoleSelectedUsers
                }}
            />

            <MassChangePartnerDialog
                {...{
                    partners,
                    massPartnerDialog,
                    handleToggleMassPartnerDialog,
                    changePartnerSelectedUsers
                }}
            />

        </>
    );
};
const mapStateToProps = (state) => ({
    users: state.user.users.data,
    meta: state.user.users.meta,
    active_partner_data: state.partner.active_partner_data,
    roles: state.user.roles.data,
    criteria: state.role.criteria,
    selectedUsers: state.user.selectedUsers,
    partners: state.partner.partners.data,
    loggedInUser: state.user.user,
});

export default connect(mapStateToProps)(UserManagementTab);
