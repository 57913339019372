import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { fetchMe } from './appLoaderActions';

const AppLoader = (props) => {

    useEffect(() => {
        props.dispatch(fetchMe());
    }, []);

    return (
        <Loader loadedClassName="loaded-content h-100"
                loaded={props.loaded}>
            {props.children}
        </Loader>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    loaded: state.user.loaded,
});

export default connect(mapStateToProps)(AppLoader);
