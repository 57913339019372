import React from 'react';

const InfoIcon = ({setShowInfo}) => {
    const toggleInfo = () => {
        if (setShowInfo) {
            setShowInfo();
        }
        document.getElementsByTagName('body')[0].classList.add('no-scroll');
    }
    return (
        <div onClick={toggleInfo}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
                <path fill="#3E3F60" fillRule="evenodd" d="M10.45 0c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.25C5.617 1.25 1.7 5.168 1.7 10s3.917 8.75 8.75 8.75c4.832 0 8.75-3.918 8.75-8.75s-3.918-8.75-8.75-8.75zm.625 6.875v5.625h1.875V15h-5v-1.25h1.875V9.375h-1.25v-1.25h2.5zm0-3.75V6.25H9.2V4.375h1.875z"/>
            </svg>
        </div>
    );
};

export default InfoIcon;
