import React from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Paper,
} from "@material-ui/core";
import { PageMenu, PARTNER_INFORMATION_MENU } from "../Components/PageMenu";

const PartnerHandbook = (props) => {
    const useStyles = makeStyles((theme) => ({
        section: {},
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60,
        },
        textPadding: {
            paddingTop: 20,
        },
        sectionTitle: {
            color: "#af001e",
            textTransform: "uppercase",
            fontSize: 20,
            fontWeight: 600,
            paddingBottom: "1em",
        },
        icons: {
            width: 22,
            height: 22,
        },
        documentPaper: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            height: 46,
            display: "flex",
            alignItems: "center",
            width: "100%",
            columnGap: "5px",
        },
        downloadIcon: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            textAlign: "center",
        },
    }));

    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Grid container direction="column">
                <Grid item>
                    <h1 className="content-title">Partner Information</h1>
                </Grid>
            </Grid>
            <PageMenu menuItems={PARTNER_INFORMATION_MENU} />
            <Divider />
            <Grid className={classes.gridPadding}>
                <Grid item sm={6}>
                    <Typography variant="h5" className={classes.sectionTitle}>
                        Partner Handbook
                    </Typography>
                    <Typography variant="body1">
                        Please refer to the below document for Partner Handbook
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <Grid
                        container
                        alignContent="center"
                        spacing={1}
                        style={{ paddingTop: "1em" }}
                    >
                        <Grid item sm>
                            <Paper className={classes.documentPaper}>
                                <img
                                    className={classes.icons}
                                    src="/images/icons/SVG/File Type/File.svg"
                                />
                                Partner Handbook
                            </Paper>
                        </Grid>
                        <Grid item>
                            <a
                                href="/files/partner-handbook-oct-2024.pdf"
                                download
                            >
                                <Paper className={classes.downloadIcon}>
                                    <img
                                        className={classes.icons}
                                        src="/images/icons/SVG/Download.svg"
                                    />
                                </Paper>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
};

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
});

export default connect(mapStateToProps)(PartnerHandbook);
