import { authHeader } from "~/helpers/authHeader";


export const getUserTicketCounts = () => {

    return (dispatch) => {

        return fetch('/api/brand-request/user/status-count', {
            method: 'GET',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'homepage/SET_REQUESTS_STATUS_COUNT',
                    payload: data,
                });
            });
    };
};


export const getNoticeboard = () => {

    return (dispatch) => {

        return fetch('/api/notifications', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'homepage/SET_NOTICEBOARD',
                    payload: data,
                });
            });
    };
};


export const getAllNoticeboard = (page = 1) => {

    let pageParam = `?page=${page}`;

    return (dispatch) => {

        return fetch('/api/notifications/' + pageParam, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'homepage/SET_NOTICEBOARD',
                    payload: data,
                });
            });
    };
};


export const addNotice = (data) => {

    return (dispatch) => {

        return fetch('/api/notifications', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data)
        }).then((response) => {

            if (response.ok) {
                return dispatch(getAllNoticeboard())
                    .then(() => {
                        return true;
                    });
            };

            if (response.status === 422) {
                return response.json()
            };

            throw Error('Unknown error!');
        });
    };
};


export const deleteNotice = (id) => {

    return (dispatch) => {

        return fetch('/api/notifications/' + id, {
            method: 'DELETE',
            credentials: 'include',
            headers: authHeader()
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(getAllNoticeboard())
                        .then(() => {
                            return true;
                        });
                };

                throw Error('Unknown error!');
            });
    };
};


export const editNote = (data) => {

    return (dispatch) => {

        return fetch('/api/notifications/' + data.id, {
            method: 'PUT',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data.data)
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(getAllNoticeboard())
                        .then(() => {
                            return true;
                        });
                };

                throw Error('Unknown error!');
            });
    };
};

export const getSinceLastVisited = () => {

    return (dispatch) => {

        return fetch('/api/brand-request/user/since-last-viewed', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'homepage/SET_SINCE_LAST_VISIT',
                    payload: data,
                });
            });
    };
};

export const getKeyDeadlines = (page) => {

    return (dispatch) => {

        let params = '?perPage=20' + (page ? '&page=' + page : '');
        return fetch('/api/keydeadlines' + params, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'user/SET_KEY_DEADLINES',
                    payload: data,
                });

                return data;
            });
    };
};

export const addKeyDeadlines = (data) => {

    return (dispatch) => {

        return fetch('/api/keydeadlines', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(getKeyDeadlines())
                        .then(() => {
                            return true;
                        });
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};



export const editDeadline = (data) => {

    return (dispatch) => {

        return fetch('/api/keydeadlines/' + data.id, {
            method: 'PUT',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data.data)
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(getKeyDeadlines())
                        .then(() => {
                            return true;
                        });
                };

                throw Error('Unknown error!');
            });
    };
};


export const deleteDeadline = (id) => {

    return (dispatch) => {

        return fetch('/api/keydeadlines/' + id, {
            method: 'DELETE',
            credentials: 'include',
            headers: authHeader()
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(getKeyDeadlines())
                        .then(() => {
                            return true;
                        });
                };

                throw Error('Unknown error!');
            });
    };
};
