import React, { useState, useEffect, useRef } from 'react';
import { Button, Dialog, Grid, Typography, DialogContent, makeStyles, Table, TableCell, TableHead, TableBody, TableRow, TextField, TableContainer, IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import { connect } from 'react-redux';
import __ from 'lodash';
import Pagination from "react-js-pagination";
import Permitted from "~/Permissions/Permitted";

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        width: 163,
        fontSize: 16,
        fontWeight: 600,
        color: '#3e3f60',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#ecedf3',
        borderRadius: 0
    },
    saveChangesButton: {
        width: 163,
        fontSize: 16,
        fontWeight: 600,
        color: '#ffffff',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#af001e',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001e80',
        },
    },
    section: {
        paddingLeft: 43,
    },
    indicator: {
        color: '#af001e'
    },
    tourimg: {
        width: '100%'
    },
    table: {
        borderRadius: 0
    },
    gridPadding: {
        paddingTop: 60,
        paddingBottom: 60
    },
    textPadding: {
        paddingTop: 20
    },
    arrowIcon: {
        width: 29,
        height: 29
    },
    tabItem: {
        color: '#af001e',
        fontWeight: 'bold'
    },
    tabLink: {
        borderBottom: '2px solid #af001e',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        '&:hover': {
            fontWeight: 'bold',
            color: '#af001e'
        }
    },
    tabLinkInactive: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    paginationWrapper: {
        marginTop: '20px',
    },
}));

const EditPartnerUsersDialog = (props) => {

    useEffect(() => {
        inputFilter();
    }, [])

    const {
        editPartnerUsersDialog,
        handleEditPartnerUsersDialogClose,
        active_partner_data,
        userList,
        handleActivePartnerUserList,
        users,
        handleSaveUsers,
        meta,
    } = props;

    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    // const [uid, setUid] = useState('');
    // const [fName, setFName] = useState('');
    // const [lName, setLName] = useState('');
    // const [email, setEmail] = useState('');
    // const [partner, setPartner] = useState('');
    // const [role, setRole] = useState('');
    const [filterParams, setFilterParams] = useState({
        uid: '',
        fName: '',
        lName: '',
        email: '',
        partner: '',
        role: ''
    })

    const handleSelectUser = (id) => {

        let data = [...userList];

        if (!data.includes(id)) {
            data.push(id);
        } else {
            data.splice(data.indexOf(id), 1);
        }

        handleActivePartnerUserList(data);
    }

    useEffect(() => {
        props.dispatch(props.filterUsers(filterParams, page, rowsPerPage));
    }, [filterParams, page, rowsPerPage])

    const inputFilter = __.debounce((type, value) => {
        if (type === 'uid') setFilterParams((prev) => (
            {
                ...prev,
                uid: value
            }
        ));

        if (type === 'fName') setFilterParams((prev) => (
            {
                ...prev,
                fName: value
            }
        ));

        if (type === 'lName') setFilterParams((prev) => (
            {
                ...prev,
                lName: value
            }
        ));

        if (type === 'email') setFilterParams((prev) => (
            {
                ...prev,
                email: value
            }
        ));

        if (type === 'partner') setFilterParams((prev) => (
            {
                ...prev,
                partner: value
            }
        ));

        if (type === 'role') setFilterParams((prev) => (
            {
                ...prev,
                role: value
            }
        ));
    }, 500);

    const resetFilter = __.debounce(() => {
        setFilterParams({
            uid: '',
            fName: '',
            lName: '',
            email: '',
            partner: '',
            role: ''
        })
        setPage(0);
        setRowsPerPage(10);
    }, 500)

    return (
        <Dialog
            open={editPartnerUsersDialog}
            onClose={handleEditPartnerUsersDialogClose}
            fullWidth
            maxWidth={'lg'}
            PaperProps={{ style: { borderRadius: 0, padding: 20 } }}>
            <DialogContent>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs sm md lg xl>
                                <Grid container direction="row" alignItems="baseline" spacing={2}>
                                    <Grid item>
                                        <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3e3f60' }}>Assigned Users</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ fontSize: 20, color: '#999bb5' }}>{active_partner_data && active_partner_data.users.length}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item>
                                        <Button
                                            className={classes.cancelButton}
                                            onClick={handleEditPartnerUsersDialogClose}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Permitted one="edit_existing_role">
                                            <Button
                                                className={classes.saveChangesButton}
                                                onClick={handleSaveUsers}>Save changes</Button>
                                        </Permitted>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
                        <Typography style={{ fontSize: 16, color: '#3e3f60' }}>To assign and remove users from this partner’s list, simply click on the first column of the table. To view all users, simply remove the filter on the partner column.</Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ borderBottom: '2px solid #af001e', textTransform: 'uppercase' }}>
                                        <TableCell />
                                        <TableCell style={{ color: '#999bb5', textTransform: 'uppercase', fontSize: 16, fontWeight: 'bolder' }}>User ID</TableCell>
                                        <TableCell align="left" style={{ color: '#999bb5', textTransform: 'uppercase', fontSize: 16, fontWeight: 'bolder' }}>First Name</TableCell>
                                        <TableCell align="left" style={{ color: '#999bb5', textTransform: 'uppercase', fontSize: 16, fontWeight: 'bolder' }}>Last Name</TableCell>
                                        <TableCell align="left" style={{ color: '#999bb5', textTransform: 'uppercase', fontSize: 16, fontWeight: 'bolder' }}>Email</TableCell>
                                        <TableCell align="left" style={{ color: '#999bb5', textTransform: 'uppercase', fontSize: 16, fontWeight: 'bolder' }}>Partner</TableCell>
                                        <TableCell align="left" style={{ color: '#999bb5', textTransform: 'uppercase', fontSize: 16, fontWeight: 'bolder' }}>Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <img src="/images/icons/SVG/Reset.svg" style={{ width: 25, cursor: "pointer" }} onClick={resetFilter} alt="reset" />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: 0
                                                    }
                                                }}
                                                // value={filterParams.uid}
                                                onChange={(e) => {
                                                    inputFilter('uid', e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: 0
                                                    }
                                                }}
                                                // value={filterParams.fName}
                                                onChange={(e) => {
                                                    inputFilter('fName', e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: 0
                                                    }
                                                }}
                                                // value={filterParams.lName}
                                                onChange={(e) => {
                                                    inputFilter('lName', e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: 0
                                                    }
                                                }}
                                                // value={filterParams.email}
                                                onChange={(e) => {
                                                    inputFilter('email', e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: 0
                                                    }
                                                }}
                                                // value={filterParams.partner}
                                                onChange={(e) => {
                                                    inputFilter('partner', e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: 0
                                                    }
                                                }}
                                                // value={filterParams.role}
                                                onChange={(e) => {
                                                    inputFilter('role', e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {users && users.length > 0 ? users.map((user, key) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <ToggleButton
                                                    value="check"
                                                    selected={userList.includes(user.id)}
                                                    onChange={() => {
                                                        handleSelectUser(user.id);
                                                    }}
                                                    style={{
                                                        backgroundColor: userList.includes(user.id) ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                        borderRadius: '50%',
                                                        padding: 0,
                                                        border: 'none'
                                                    }}>
                                                    <Check style={{ color: 'white' }} />
                                                </ToggleButton>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography>{user.id}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography>{user.first_name}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography>{user.last_name}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography>{user.email}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    user.partners && user.partners.map((partner, key) => (
                                                        <Typography key={key}>{partner.name}</Typography>
                                                    ))
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography>{user.role && user.role.display_name}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                        : <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography>No result for given criteria</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={classes.paginationWrapper}>
                        {meta && meta.per_page < meta.total && (
                            <Pagination
                                prevPageText={
                                    <div className="prev-next">
                                        <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left" />
                                    </div>}
                                nextPageText={
                                    <div className="prev-next">
                                        <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right" />
                                    </div>}
                                activePage={meta.current_page}
                                itemsCountPerPage={Number(meta.per_page)}
                                totalItemsCount={meta.total}
                                pageRangeDisplayed={5}
                                hideFirstLastPages={true}
                                itemClass="page-item"
                                linkClass="page-link"
                                linkClassPrev="prev-next"
                                linkClassNext="prev-next"
                                onChange={(page) => {
                                    inputFilter(undefined, undefined, undefined, undefined, undefined, undefined, page);
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}



export default connect(null, null)(EditPartnerUsersDialog);
