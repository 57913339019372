import React, { useState } from 'react';
import { Divider, Grid, Tabs, Tab, makeStyles, Card } from '@material-ui/core';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";
import EditableComponent from './EditableComponent';

const TabPanel = (props) => {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}>
            {value === index && (
                children
            )}
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
    },
}));

const PartnerInformation = (props) => {

    const {
        subTab,        
        user
    } = props;

    const classes = useStyles();
    const [value, setValue] = useState(Number(subTab));
    const checker = new PermissionChecker(user);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const menuItems = [
        checker.one("admin_view_partner_workshops") && {
            name: 'Partner Workshops',
            component: <EditableComponent {...props}
                files={props.partnerWorkshopsFiles}
                setFiles={props.setPartnerWorkshopsFiles}
                editorState={props.partnerWorkshopsEditorState}
                setEditorState={props.setPartnerWorkshopsEditorState}                
                slug="partner_workshops"
                title="Partner Workshops Informations"
                formTitle="Workshops Files"
            />
        },
        checker.one("admin_view_right_protection") && {
            name: 'Rights Protection',
            component: <EditableComponent {...props}
                files={props.rightProtectionFiles}                        
                setFiles={props.setRightProtectionFiles}
                editorState={props.rightProtectionEditorState}
                setEditorState={props.setRightProtectionEditorState}
                slug="right_protection"
                title="Rights & Protection Informations"
                formTitle="Terms & Conditions"
            />
        },
        /*DOCUMENTS TAB
        checker.some([
            "admin_view_documents",
            "edit_documents_content",
            "upload_delete_document_files"
        ]) && {
            name: 'Documents',
            component: <EditableComponent {...props}
                files={props.documentsFiles}
                setFiles={props.setDocumentsFiles}
                editorState={props.documentsEditorState}
                setEditorState={props.setDocumentsEditorState}
                slug="documents"
                title="Documents"
                formTitle="Documents"
            />
        }
        */
    ];


    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Card style={{ borderRadius: 0, minHeight: 500 }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                classes={{
                                    indicator: classes.tabs,
                                }}>
                                {
                                    menuItems
                                        .filter(x => x != false)
                                        .map((item, index) => (
                                            <Tab style={{outline: 'none'}} key={index} label={item.name} />
                                        ))
                                }
                            </Tabs>
                        </Grid>
                        <Divider />
                        {
                            menuItems
                                .filter(x => x != false)
                                .map((item, index) => (
                                    <TabPanel key={index} value={value} index={index}>
                                        {item.component}
                                    </TabPanel>
                                ))
                        }
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PartnerInformation;
