import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Typography,
    Drawer,
    Divider
} from '@material-ui/core';
import {
    fetchPermissionIds,
    getPermissions,
    setActionPermissions
} from "../roleActions";
import { withRouter } from 'react-router-dom';
import { getRoles } from "~/Admin/UserManagements/userActions";
import { Close } from '@material-ui/icons';
import Button from "~/Components/Button";
import Switch from "@material-ui/core/Switch";
import PermissionsTree from "~/Components/PermissionsTree/PermissionsTree";
import Permitted from "~/Permissions/Permitted";
import './RoleDetails.scss';


const useStyles = makeStyles((theme) => ({
    createUserPadding: {
        padding: 20,
        minWidth: 400
    },
    closeButton: {
        cursor: 'pointer'
    },
    subtitle: {
        color: theme.primaryColor,
        fontFamily: theme.demiBold,
        fontSize: 18,

    },
    dividerLink: {
        backgroundColor: theme.primaryColor,
    },
    userRow: {
        borderBottom: '1px solid rgba(224, 224, 224);',
        padding: '10px 0',
    },
    helperText: {
        fontSize: 10,
        color: theme.lightGrey,
    },
    switchBtn: {
        color: theme.primaryColor
    },
    switchText: {
        color: theme.dark,
        fontSize: 14,
        padding: '7px 0',
    }
}));

const RoleDetails = (props) => {

    const classes = useStyles();
    const {
        activeRole,
        toggleEditRole,
        showRoleDetails,
        setCurrentSidebar,
        setEditPartnerUsersDialog,
        toggleRoleDetails,
        actionPermissions,
        dispatch,
        criteria,
    } = props;

    const [permissionStatus, setPermissionStatus] = useState(activeRole && activeRole.multiple_partner_access);

    useEffect(() => {
        dispatch(getPermissions(activeRole && activeRole.role_type === 'admin' ? true : false));
        dispatch(fetchPermissionIds());
    }, [activeRole]);


    const handleSelectPermissions = (checked) => {
        dispatch(setActionPermissions(activeRole.id, checked));
    }

    const deleteRole = () => {
        axios.delete('/api/roles/' + activeRole.id).then(() => {
            dispatch(getRoles({ criteria, input: '' }, null));
            toggleRoleDetails(null)
        })
    }

    const duplicateRole = () => {
        axios.post('/api/roles/duplicate/' + activeRole.id).then((res) => {
            toggleRoleDetails(null);
            dispatch(getRoles({ criteria, input: '' }, null));
            toggleEditRole(res.data.data);
        })
    }

    const users = activeRole && activeRole ? activeRole.users.slice(0, 5) : [];

    return (
        <Drawer
            disableBackdropClick
            BackdropProps={{ invisible: true }}
            anchor="right"
            open={showRoleDetails}
            onClose={() => toggleRoleDetails(null)}
            classes={{ paper: classes.createUserPadding }}>
            {
                activeRole &&
                <Grid container direction="column" spacing={3} ref={setCurrentSidebar} className="role-details">
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <Close onClick={() => toggleRoleDetails(null)} className={classes.closeButton} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">Role ID: {activeRole.id}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">{activeRole.display_name}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.subtitle}>Role Details</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} direction="row">
                            <Grid item>
                                Role Type
                            </Grid>
                            <Grid item>
                                {activeRole.role_type}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider className={classes.dividerLink} />
                    </Grid>

                    <Grid item className={classes.userRow}>
                        <Typography variant="h6" className={classes.subtitle}>User List <span className="users-count">{activeRole.users_count}</span></Typography>
                    </Grid>
                    <Grid item>
                        {
                            users.map((user, key) => {
                                return (
                                    <Grid container key={key} spacing={2} direction="row" className={classes.userRow}>
                                        <Grid item>
                                            {user.name}
                                        </Grid>
                                        <Grid item>
                                            {user.email}
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid item>
                        <Permitted one="edit_existing_role">
                            <Button
                                class="btn-grey role-btn-grey"
                                onClick={() => setEditPartnerUsersDialog(true)}
                                text="VIEW FULL USER LIST" />
                        </Permitted>
                    </Grid>
                    <Grid item>
                        <Divider className={classes.dividerLink} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.subtitle}>Partner Permissions</Typography>
                        <Typography className={classes.helperText}>Enable this toggle to enable multiple partner access</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item>
                                <Switch
                                    checked={activeRole.multiple_partner_access}
                                    name="automatic"
                                    color="primary"
                                    classes={{ root: classes.switchBtn }}
                                    inputProps={{ 'aria-label': 'permission status checkbox' }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.switchText}>{activeRole.multiple_partner_access ? 'Activated' : 'Deactivated'}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider className={classes.dividerLink} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.subtitle}>Action Permissions</Typography>
                        <Typography className={classes.helperText}>These will be effective for all partners linked to the user’s account.</Typography>
                    </Grid>
                    <Grid item>
                        {
                            actionPermissions && actionPermissions.length > 0 &&
                            <PermissionsTree
                                disabled={true}
                                children={actionPermissions}
                                hasPermissions={activeRole && activeRole.permissions ? activeRole.permissions : []}
                                setSelectedPermissions={(checked) => handleSelectPermissions(checked)}
                            />
                        }
                    </Grid>
                    <Grid item>
                        <Button
                            class="btn-grey role-btn-grey"
                            onClick={() => {
                                dispatch({ type: 'roles/EXPAND_ALL_PERMISSIONS', payload: { dialog: true, roleType: activeRole.role_type } });
                                dispatch({ type: 'roles/SET_READ_ONLY', payload: true })
                            }}
                            text="EXPAND PERMISSIONS" />
                    </Grid>
                    <Grid item>
                        <Divider className={classes.dividerLink} />
                    </Grid>
                    <Grid item>
                        <Permitted one="create_new_role">
                            <Button
                                class="btn-grey role-btn-grey duplicate"
                                onClick={duplicateRole}
                                text="DUPLICATE ROLE" />
                        </Permitted>
                    </Grid>
                    <Grid item>
                        <Permitted one="delete_existing_role">
                            <Button
                                class="noMarg btn-brick-red"
                                onClick={deleteRole}
                                text="DELETE ROLE" />
                        </Permitted>
                    </Grid>
                </Grid>
            }
        </Drawer>

    );
}

const mapStateToProps = ((state) => ({
    actionPermissions: state.role.actionPermissions.data,
    criteria: state.role.criteria,
    activeRole: state.role.role
}))

export default connect(mapStateToProps)(withRouter(RoleDetails));
