import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "~/Components/Button";
import MembersForm from "~/Admin/InventoryManagement/MembersForm/MembersForm";
import AssetsForm from "~/Admin/InventoryManagement/AssetsForm/AssetsForm";
import { connect } from "react-redux";
import Permitted from '~/Permissions/Permitted';

import { getTeam, getTeams } from './inventoryActions';

const TeamForm = ({ activeTeam, teams, dispatch }) => {
    const [teamForm, setTeamForm] = useState(false);

    useEffect(() => {
        dispatch(getTeams());
        if (activeTeam.id) {
            setTeamForm(true);
        }
    }, []);

    const setActiveTeam = (id) => {
        dispatch(getTeam(id)).then(() => setTeamForm(true));
    }

    const newTeam = () => {
        setTeamForm(true);
        dispatch({ type: 'inventory/SET_TEAM', payload: { name: '' } });
        dispatch({ type: 'inventory/SET_PLAYERS', payload: [] });
        dispatch({ type: 'inventory/SET_STAFF', payload: [] });
    }
    return (
        <Grid container direction="row" spacing={4} style={{ marginTop: 0, marginLeft: 0, marginRight: 0 }}>
            <>
                <Grid item md={3} className="team-list">
                    <Grid container display="column">
                        <Grid item md={12}>
                            <Typography className="step-header">
                                Team List
                            </Typography>
                        </Grid>

                        <Grid item>
                            {teams.length === 0
                                ?
                                <Typography variant="h5" className="team-notice">
                                    Create teams and set up all of its members, so user can select them accordingly
                                    while filling out their talent access request form.
                                </Typography>
                                :
                                <Typography variant="h5" className="team-notice">
                                    Teams and their members allow to define the list from which users can select from
                                    while filling out their talent access request form.
                                </Typography>
                            }
                        </Grid>
                        {teams.length > 0 &&
                            <>
                                <Grid item md={12}>
                                    <Typography className="light-grey-labels">
                                        YOUR TEAMS
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    {teams.map((team, key) => (
                                        <Typography className={"team-box" + (activeTeam.id === team.id ? ' active' : '')}
                                            onClick={() => setActiveTeam(team.id)} key={key}>
                                            {team.name}
                                        </Typography>
                                    )
                                    )}

                                </Grid>
                            </>
                        }

                        <Grid item>
                            <Permitted one="create_talent_access_assets">
                                <Button class="new-team-btn" text="ADD TEAM" onClick={newTeam} />
                            </Permitted>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid className="inventory" item md={9}>
                    {!teamForm
                        ?
                        <>{teams.length === 0 &&
                            <div className="not-found">
                                <div className="text-wrapper">
                                    <div className="title">No teams yet</div>
                                    <div className="message">Set up your teams & create your inventory to get your
                                    Create and select a team to set up its players
                                </div>
                                </div>
                            </div>
                        }
                        </>
                        :
                        <MembersForm />
                    }
                </Grid>
            </>

        </Grid>
    );
};

const mapStateToProps = (state) => ({
    teams: state.inventory.teams.data,
    activeTeam: state.inventory.team,
});

export default connect(mapStateToProps, null)(TeamForm);
