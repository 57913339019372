import React from 'react';
import { Col, Row } from "react-bootstrap";
import './index.scss';
import LibraryApi from "~/services/api/library";
import setImagePathByExtension from '../../helpers/Images/setImagePathByExtension';

const PreviewFile = ({ showPreview, previewFile, setShowPreview, setLoaded }) => {

    const closeModal = (event) => {
        if (event.target.id === 'library__preview') {
            setShowPreview();
        }
    }
    return (
        <Row>
            <Col>
                {
                    showPreview &&
                    <div id="library__preview" className="library__preview" onClick={closeModal}>
                        <div className="library__preview__wrapper">
                            {
                                (previewFile.media_type === 'videos' || previewFile.media_type === "mp3") && <video src={previewFile.path} controls className="library__preview__wrapper__item" />
                            }

                            {
                                (previewFile.media_type === 'photos' || previewFile.media_type === 'svg') && <img src={previewFile.converted_file_path ? previewFile.converted_file_path : previewFile.path} className="library__preview__wrapper__item" />
                            }

                            {
                                (previewFile.media_type === 'pdf' || previewFile.media_type === 'application/pdf') && <img src="/images/icons/SVG/File Type/PDF.svg" style={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 40
                                }} />
                            }
                            {
                                (previewFile.media_type === 'zip' || previewFile.media_type === 'application/zip') && <img src="/images/icons/SVG/File Type/Zip.svg" style={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 40
                                }} />
                            }
                            {
                                (previewFile.media_type === 'file' || previewFile.media_type === 'application/msword' || previewFile.media_type === 'application/xslt+xml' || previewFile.media_type === 'doc' || previewFile.media_type === 'docx' || !previewFile.media_type) && <img src="/images/icons/SVG/File Type/File.svg" style={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 40
                                }} />
                            }
                            <div className="title">
                                <span className="float-left  pr-3">
                                    <img src={setImagePathByExtension(previewFile.media_type).path}
                                        alt={setImagePathByExtension(previewFile.media_type).alt}/>
                                </span>
                                <span title={previewFile.orientation}>
                                    {previewFile.name.length > 20 ? previewFile.name.substring(0, 19) + '...' : previewFile.name}
                                </span>
                                <span className="float-right download">
                                    <img src="/images/icons/SVG/download_list.svg" onClick={() => LibraryApi.downloadImage(previewFile, setLoaded)} />
                                </span>
                                <div className="close" onClick={() => setShowPreview()}>
                                    <img src="/images/icons/SVG/Close-White.svg" />
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </Col>
        </Row>
    );
};

export default PreviewFile;
