import React from "react";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import SearchInput from "~/Components/SearchInput";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import momentNative from "moment";
import Moment from "react-moment";
import classNames from "classnames";
import MaterialIcon from "material-icons-react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

const ApprovalsRequestList = ({
    classes,
    adminMode,
    setSearchParam,
    queryParams,
    search,
    setRequestTypeParam,
    request_types,
    showFilter,
    requestType,
    handleCancelDateRange,
    handleApplyDateRange,
    handleDateRange,
    dateFrom,
    dateTo,
    requestStatus,
    statusValues,
    setRequestStatusParam,
    requests,
    setQueryParams,
}) => {
    return (
        <Card className={classes.card}>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableHeaderBorder}>
                            {adminMode && (
                                <TableCell className={classes.tableCell}>
                                    User Name
                                </TableCell>
                            )}
                            <TableCell className={classes.tableCell}>
                                Request Name
                            </TableCell>
                            <TableCell
                                className={classes.tableCell}
                                align="left"
                            >
                                Request Type
                            </TableCell>
                            <TableCell
                                className={classes.tableCell}
                                align="left"
                            >
                                Submission Date
                            </TableCell>
                            <TableCell
                                className={classes.tableCell}
                                align="left"
                            >
                                Status
                            </TableCell>
                            <TableCell
                                className={classes.tableCell}
                                align="left"
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showFilter && (
                            <TableRow key={0} className="filters">
                                {adminMode && (
                                    <TableCell className={classes.tableCell}>
                                        <SearchInput
                                            searchHandler={(searchValue) =>
                                                setQueryParams({
                                                    userName: searchValue,
                                                })
                                            }
                                            value={queryParams.userName}
                                        />
                                    </TableCell>
                                )}
                                <TableCell
                                    className={classes.tableCell}
                                    component="th"
                                    scope="row"
                                >
                                    <SearchInput
                                        searchHandler={(searchValue) =>
                                            setSearchParam(searchValue)
                                        }
                                        value={search}
                                    />
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                >
                                    <DropdownButton
                                        className="brand-dropdown"
                                        title={
                                            requestType
                                                ? requestType.name
                                                : "All"
                                        }
                                    >
                                        <Dropdown.Item
                                            key={0}
                                            onClick={() =>
                                                setRequestTypeParam(null)
                                            }
                                        >
                                            All
                                        </Dropdown.Item>
                                        {request_types.map((value, key) => {
                                            return (
                                                <Dropdown.Item
                                                    key={key}
                                                    onClick={() =>
                                                        setRequestTypeParam(
                                                            value
                                                        )
                                                    }
                                                >
                                                    {value.name}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </DropdownButton>
                                </TableCell>

                                <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                >
                                    <DateRangePicker
                                        initialSettings={{
                                            autoUpdateInput:
                                                (dateFrom && dateTo) || false,
                                            locale: {
                                                format: "DD/MM/YYYY",
                                                cancelLabel: "Clear",
                                            },
                                            startDate:
                                                momentNative(dateFrom).format(
                                                    "DD/MM/YYYY"
                                                ),
                                            endDate:
                                                momentNative(dateTo).format(
                                                    "DD/MM/YYYY"
                                                ),
                                        }}
                                        onCancel={handleCancelDateRange}
                                        onApply={handleApplyDateRange}
                                        onCallback={handleDateRange}
                                    >
                                        <input
                                            type="text"
                                            className="filter-input"
                                            defaultValue=""
                                        />
                                    </DateRangePicker>
                                </TableCell>

                                <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                >
                                    <DropdownButton
                                        className="brand-dropdown"
                                        title={
                                            requestStatus
                                                ? statusValues[requestStatus]
                                                      .name
                                                : "All"
                                        }
                                    >
                                        <Dropdown.Item
                                            key={0}
                                            onClick={() =>
                                                setRequestStatusParam(null)
                                            }
                                        >
                                            All
                                        </Dropdown.Item>
                                        {Object.keys(statusValues).map(
                                            (value, key) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={key}
                                                        onClick={() =>
                                                            setRequestStatusParam(
                                                                value
                                                            )
                                                        }
                                                    >
                                                        {
                                                            statusValues[value]
                                                                .name
                                                        }
                                                    </Dropdown.Item>
                                                );
                                            }
                                        )}
                                    </DropdownButton>
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    align="right"
                                />
                            </TableRow>
                        )}

                        {requests &&
                            requests.map((req, key) => {
                                return (
                                    <TableRow className="request-row" key={key}>
                                        {adminMode && (
                                            <TableCell
                                                className={classes.tableCell}
                                                scope="row"
                                            >
                                                {req.user?.name}
                                            </TableCell>
                                        )}
                                        <TableCell
                                            className={classes.tableCell}
                                            scope="row"
                                        >
                                            {req.name}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {req.type === "approvals"
                                                ? req.request_type.name
                                                : req.teamAsset.access_type}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            <Moment format="DD/MM/YYYY">
                                                {req.created_at}
                                            </Moment>
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            <div
                                                className={classNames(
                                                    "colum-td",
                                                    "col6",
                                                    "points",
                                                    statusValues[req.status]
                                                        .class
                                                )}
                                            >
                                                <MaterialIcon icon="fiber_manual_record" />
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            width="5%"
                                            className={classes.tableCell}
                                            align="right"
                                        >
                                            <Link
                                                component={RouterLink}
                                                className="button color-red inGrid view-request"
                                                to={{
                                                    pathname: adminMode
                                                        ? `/admin/request-management/${req.id}`
                                                        : `/my-requests/${req.id}`,
                                                }}
                                            >
                                                {adminMode ? "REVIEW" : "VIEW"}{" "}
                                                REQUEST
                                                <MaterialIcon icon="arrow_forward" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        {(!requests || requests.length === 0) && (
                            <TableRow>
                                <TableCell
                                    colSpan={5}
                                    className={classes.tableCell}
                                    component="th"
                                    scope="row"
                                >
                                    <p>
                                        You have no requests in this category.
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default ApprovalsRequestList;
