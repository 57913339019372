import React from "react";
import {Row} from "react-bootstrap";
import GridItem from "./GridItem";

const Grid = (props) => {
    const renderThumbnail = function (item) {
        return (
            <GridItem
                setPreviewFile={props.setPreviewFile}
                item={item}
                setSelectedDownloadItems={props.setSelectedDownloadItems}
                selectedDownloadItem={props.selectedDownloadItem}
                selectAllItems={props.selectAllItems}
                setLoaded={props.setLoaded}
            />
        );

    }
    return (
        <Row className="justify-content-start">
            {props.files &&
            props.files.map((item, index) => {
                return (
                    <div className={props.colClass} key={index}>
                        {renderThumbnail(item)}
                    </div>
                );
            })
            }
        </Row>

    );
}

export default Grid;
