import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import SearchInput from "~/Components/SearchInput";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Moment from "react-moment";
import MaterialIcon from "material-icons-react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./TalentRequestList.scss";
import Button from "~/Components/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { debounce } from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const TalentRequestList = ({
    classes,
    adminMode,
    queryParams,
    showFilter,
    requests,
    setQueryParams,
    talentRequestType,
    history,
    setUserNameParam,
}) => {
    const [teams, setTeams] = useState([]);
    const [teamAssets, setTeamAssets] = useState([]);
    const [team, setTeam] = useState("");
    const [teamAsset, setTeamAsset] = useState("");

    const searchSessionHandler = (value, reason) => {
        if (reason === "clear" || reason === "input") {
            axios.get("/api/team-assets?session_name=" + value).then((res) => {
                setTeamAssets(getUniqueAssets(res.data.data));
            });
        }
    };

    const debouncedOnInput = debounce(searchSessionHandler, 300);
    const inputHandler = useCallback((value, reason) => {
        debouncedOnInput(value, reason);
    }, []);

    const searchSessions = (event, value, reason) => {
        inputHandler(value, reason);
    };

    const getUniqueAssets = (data) => {
        let assets = [];
        let unique = [];
        data.forEach((asset) => {
            if (!unique[asset.session_name]) {
                unique[asset.session_name] = true;
                assets = [...assets, asset];
            }
        });

        return assets;
    };

    useEffect(() => {
        axios.get("/api/teams").then((res) => setTeams(res.data.data));
        axios.get("/api/team-assets").then((res) => {
            setTeamAssets(getUniqueAssets(res.data.data));
        });
    }, []);

    const getFilterVal = (id, collection) => {
        if (id && collection === "teams") {
            let team = teams.find((team) => team.id === Number(id));
            return team?.name;
        }

        if (id && collection === "teamAssets") {
            let teamAsset = teamAssets.find((team) => team.id === Number(id));
            return teamAsset?.session_name;
        }

        return "ALL";
    };

    const isReadyForAutomatic = () => {
        return (
            team &&
            teamAsset &&
            queryParams.accessType &&
            queryParams.talentRequestType
        );
    };

    const goToAutomaticAllocation = () => {
        history.push(
            `/admin/talent-request-group-allocation?team=${team}&asset=${teamAsset}&access_type=${queryParams.accessType}&request_type=${queryParams.talentRequestType}`
        );
    };

    return (
        <>
            <Card
                className={classes.card}
                style={{ height: "auto", marginBottom: 10, padding: 30 }}
            >
                <Grid container direction="row" className="filters">
                    <Grid item md={6}>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography className="access-type">
                                    {!talentRequestType
                                        ? ""
                                        : talentRequestType ===
                                          "player-commercial"
                                        ? "PLAYER ACCESS"
                                        : "STAFF ACCESS"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="request-type-selected">
                                    {talentRequestType === "player-commercial"
                                        ? "commercial appearance"
                                        : talentRequestType}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container direction="row" justify="space-between">
                            <Grid item className="talent-filters">
                                <DropdownButton
                                    className="brand-dropdown"
                                    style={{ position: "unset" }}
                                    title={getFilterVal(
                                        queryParams.teamId,
                                        "teams"
                                    )}
                                >
                                    <Dropdown.Item
                                        key={0}
                                        onClick={() => {
                                            setQueryParams({ teamId: "" });
                                            setTeam("");
                                        }}
                                    >
                                        All
                                    </Dropdown.Item>
                                    {teams.map((team, key) => (
                                        <Dropdown.Item
                                            key={key}
                                            onClick={() => {
                                                setQueryParams({
                                                    teamId: team.id,
                                                });
                                                setTeam(team.id);
                                            }}
                                        >
                                            {team.name}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </Grid>
                            <Grid item className="talent-filters">
                                <Autocomplete
                                    debug={true}
                                    classes={{
                                        inputRoot: classes.autocomplete,
                                    }}
                                    options={teamAssets}
                                    onChange={(event, value) => {
                                        setQueryParams({
                                            teamAssetName: value
                                                ? value.session_name
                                                : null,
                                        });
                                        setTeamAsset(
                                            value ? value.session_name : null
                                        );
                                    }}
                                    onInputChange={searchSessions}
                                    fullWidth
                                    disableCloseOnSelect={false}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={(option) =>
                                        option.session_name
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label=""
                                            placeholder="Session Name"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    class={
                                        "noMarg no-radius allocate-btn" +
                                        (isReadyForAutomatic()
                                            ? " color-red"
                                            : " btn-grey")
                                    }
                                    disabled={!isReadyForAutomatic()}
                                    text="ALLOCATE PLAYERS"
                                    onClick={goToAutomaticAllocation}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Card className={classes.card}>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHeaderBorder}>
                                <TableCell className={classes.tableCell}>
                                    USER NAME
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    PARTNER NAME
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                >
                                    ALLOC PLAYERS
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                >
                                    ALLOC TIME
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                >
                                    SUBMISSION DATE
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showFilter && (
                                <TableRow key={0} className="filters">
                                    <TableCell
                                        className={classes.tableCell}
                                        component="th"
                                        scope="row"
                                    >
                                        <SearchInput
                                            searchHandler={(searchValue) =>
                                                setQueryParams({
                                                    userName: searchValue,
                                                })
                                            }
                                            value={queryParams.userName}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        component="th"
                                        scope="row"
                                    >
                                        <SearchInput
                                            searchHandler={(searchValue) =>
                                                setQueryParams({
                                                    partner: searchValue,
                                                })
                                            }
                                            value={queryParams.partner}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        align="left"
                                    >
                                        <SearchInput
                                            searchHandler={(searchValue) =>
                                                setQueryParams({
                                                    playerQty: searchValue,
                                                })
                                            }
                                            value={queryParams.playerQty}
                                        />
                                    </TableCell>

                                    <TableCell
                                        className={classes.tableCell}
                                        align="left"
                                    >
                                        <SearchInput
                                            searchHandler={(searchValue) =>
                                                setQueryParams({
                                                    allocTime: searchValue,
                                                })
                                            }
                                            value={queryParams.allocTime}
                                        />
                                    </TableCell>

                                    <TableCell
                                        className={classes.tableCell}
                                        align="left"
                                    />
                                    <TableCell
                                        className={classes.tableCell}
                                        align="right"
                                    />
                                </TableRow>
                            )}

                            {requests &&
                                requests.map((req, key) => {
                                    return (
                                        <TableRow
                                            className="request-row"
                                            key={key}
                                        >
                                            <TableCell
                                                className={classes.tableCell}
                                                component="th"
                                                scope="row"
                                            >
                                                {req?.user?.name}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                component="th"
                                                scope="row"
                                            >
                                                {req.teamAsset?.partner?.name}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {req.teamAsset?.access_type ===
                                                "players"
                                                    ? req.teamAsset
                                                          ?.player_qty +
                                                      "x Players"
                                                    : ""}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {req.teamAsset?.time}h
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <Moment format="DD/MM/YYYY">
                                                    {req.created_at}
                                                </Moment>
                                            </TableCell>
                                            <TableCell
                                                width="5%"
                                                className={classes.tableCell}
                                                align="right"
                                            >
                                                <Link
                                                    component={RouterLink}
                                                    className="button color-red inGrid view-request"
                                                    to={{
                                                        pathname: `/admin/talent-request-management/${req.id}`,
                                                    }}
                                                >
                                                    {adminMode
                                                        ? "REVIEW"
                                                        : "VIEW"}{" "}
                                                    REQUEST
                                                    <MaterialIcon icon="arrow_forward" />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            {(!requests || requests.length === 0) && (
                                <TableRow>
                                    <TableCell
                                        colSpan={5}
                                        className={classes.tableCell}
                                        component="th"
                                        scope="row"
                                    >
                                        <p>
                                            You have no requests is this
                                            category.
                                        </p>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    );
};

export default connect(null, null)(withRouter(TalentRequestList));
