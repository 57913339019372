import { authHeader } from "~/helpers/authHeader";
import { setCmsBlocks } from "~/helpers/cmsHelper";

export const loginUser = (user) => {
    return (dispatch) => {
        return fetch('/api/users/login', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(user)
        })
    };
};


export const changePassword = (form) => {

    return (dispatch) => {

        return fetch('/api/user/change-password', {
            method: 'PUT',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(form)
        })
            .then((response) => {

                if (response.ok) {
                    return true
                }

                if (response.status === 422) {
                    return response.json()
                        .then((messages) => {
                            return messages;
                        });
                }

                throw Error('Unknown error!');
            });
    };
};
