import {authHeader} from "~/helpers/authHeader";

export const fetchMediaCategories = () => {

    return (dispatch) => {
        return fetch('/api/media-categories', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'media_library/SET_LIST',
                    payload: data,
                });
            });
    };
};

export const fetchMediaCategory = (data, page = 1) => {

    let pageParam = `?page=${page}`;
    let folderParam = data.folderId ? `&parent_folder_id=${data.folderId}` : '';
    let perPageParam = data.perPage ? `&perPage=${data.perPage}` : '';
    let orderByParam = data.orderBy ? `&orderBy=${data.orderBy.queryVal}` : '';
    let partnerParam = data.partnerId ? `&partner_id=${data.partnerId}` : '';
    let nameParam = data.name ? `&name=${data.name}` : '';

    return (dispatch) => {
        let params = `${data.id}${pageParam}${folderParam}${perPageParam}${orderByParam}${partnerParam}${nameParam}`;

        return fetch(`/api/media-categories/category-files/${params}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                if (data.data) {
                    dispatch({
                        type: 'media_library/SET_ITEM',
                        payload: data,
                    });
                    return false;
                }
                return true;


            });
    };
};

export const resetState = () => {
    return (dispatch) => {
        dispatch({
            type: 'media_library/RESET_ITEM'
        });
    };
}

export const setView = (view) => {
    return (dispatch) => {
        dispatch({
           type: 'media_library/SET_VIEW',
           view: view,
        });
    }
}

export const setPerPage = (perPage) => {
    return (dispatch) => {
        dispatch({
            type: 'media_library/SET_PER_PAGE',
            perPage: perPage,
        });
    }
}

export const setOrderBy = (orderBy) => {
    return (dispatch) => {
        dispatch({
            type: 'media_library/SET_ORDER_BY',
            orderBy,
        });
    }
}

export const setShowInfo = (showInfo) => {
    return (dispatch) => {
        dispatch({
            type: 'media_library/SET_SHOW_INFO',
            showInfo,
        });
    }
}

export const setRefInfoElement = (refInfoElement) => {
    return (dispatch) => {
        dispatch({
            type: 'media_library/SET_REF_INFO',
            refInfoElement,
        });
    }
}
