import React from 'react';
import './ApproveAllocationsDialog.scss';
import Button from "~/Components/Button";

const ApproveAllocationsDialog = ({setApproveSubmit, showApprovalDialog}) => {

    const closeDialog = () => {
    }

    return (
        <div className="approve-allocations-dialog" onClick={closeDialog}>
            <div className="dialog-content">
                <div className="dialog-header">
                    Approve Allocations
                </div>
                <div className="dialog-text">
                    Are you sure you want to approve all allocations? This will send an email to all involved partners.
                </div>
                <div className="dialog-footer">

                    <Button class="footer-buttons approve" text="YES, APPROVE"
                            onClick={() => {
                                setApproveSubmit(true);
                            }}/>

                    <Button class="footer-buttons cancel"
                            text="NO, CANCEL"
                            onClick={() => {
                                showApprovalDialog(false);
                            }}/>
                </div>
            </div>
        </div>
    );
};

export default ApproveAllocationsDialog;
