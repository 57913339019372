import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import Login from "../Login/Login";
import Media from "../Media";
import Reset from "../Login/Reset";
import NewPass from "../Login/NewPass";
import ChangePassword from "../Login/ChangePassword";
import Dashboard from "../Dashboard/Dashboard";
import AppLoader from "../AppLoader";
import GAListener from "../GAListener";
import ProtectedRoute from "../ProtectedRoute";
import store from "../Store";
import Brand from "~/Brand";

import LionsTour2025 from "~/LionsTour2025/LionsTour2025";
import KeyContact2025 from "../LionsTour2025/KeyContact/index";

import PartnerHandbook from "~/PartnerInformation/PartnerHandbook";
import PartnerWorkshops from "~/PartnerInformation/PartnerWorkshops";
import TicketsAndHospitality from "~/PartnerInformation/TicketsAndHospitality";
import Appearences from "~/PartnerInformation/Appearences";
import Activation from "~/PartnerInformation/Activation";
import RightsProtection from "~/PartnerInformation/RightsProtection";

import BrandApprovalRequest from "~/BrandApprovalRequest/index";
import BrandApprovalRequests from "~/BrandApprovalRequests/index";
import TalentAccessRequest from "~/Admin/RequestManagement/TalentAccessRequest/index";
import BrandApprovalForm from "~/BrandApprovalForm/index";
import Header from "~/Components/Header/Header";

import Admin from "~/Admin/Admin";
import UserManagement from "~/Admin/UserManagements/UserManagements";
import NotificationProvider from "~/Notification/NotificationProvider";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { ConfirmProvider } from "material-ui-confirm";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import RequestManagement from "~/Admin/RequestManagement/RequestManagement";
import PageNotFound from "~/PageNotFound/PageNotFound";
import PartnerManagement from "~/Admin/PartnerManagement/PartnerManagement";
import InventoryManagement from "~/Admin/InventoryManagement/InventoryManagement";
import TalentAccessForm from "~/TalentAccessForm";
import GroupAllocation from "~/Admin/RequestManagement/GroupAllocation/GroupAllocation";
import ContentManagement from "~/Admin/PartnerManagement/ContentManagement/ContentManagement";

const THEME = createMuiTheme({
    palette: {
        primary: {
            main: "#af001e",
        },
    },
    typography: {
        fontFamily: "AvenirNext-Regular",
        src: 'url("Fonts/5746790/d1d43d8e-e792-4a3d-aebe-2afeafdffbee.woff2") format("woff2"), url("Fonts/5746790/0df04b85-219c-4fe0-9736-12c04931d434.woff") format("woff")',
    },
    primaryColor: "#af001e",
    lightGrey: "#999bb5",
    dark: "#3e3f60",
    brickColor: "#de5644",
    demiBold: "AvenirNext-DemiBold",
    bold: "AvenirNext-Bold",
    overrides: {
        MuiChip: {
            root: {
                borderRadius: 0,
                marginRight: 2,
            },
        },
    },
});

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <MuiThemeProvider theme={THEME}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Provider store={store}>
                        <NotificationProvider>
                            <ConfirmProvider>
                                <AppLoader>
                                    <BrowserRouter>
                                        <Header>
                                            <GAListener>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/"
                                                        component={Login}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/reset"
                                                        component={Reset}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/new-password/:token"
                                                        component={NewPass}
                                                    />

                                                    <ProtectedRoute
                                                        path="/change-password"
                                                        component={
                                                            ChangePassword
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        path="/dashboard"
                                                        component={Dashboard}
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        some={[
                                                            "view_brand_guidelines",
                                                        ]}
                                                        path="/lions-series-2025"
                                                        component={
                                                            LionsTour2025
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        one="view_brand_guidelines"
                                                        path="/lions-series-2025/key-contacts"
                                                        component={
                                                            KeyContact2025
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        some={[
                                                            "view_brand_guidelines",
                                                        ]}
                                                        path="/partner-information"
                                                        component={
                                                            PartnerHandbook
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        one="view_brand_guidelines"
                                                        path="/partner-information/partner-workshops"
                                                        component={
                                                            PartnerWorkshops
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        one="view_brand_guidelines"
                                                        path="/partner-information/tickets-and-hospitality"
                                                        component={
                                                            TicketsAndHospitality
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        one="view_brand_guidelines"
                                                        path="/partner-information/appearances"
                                                        component={Appearences}
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        one="view_brand_guidelines"
                                                        path="/partner-information/activation"
                                                        component={Activation}
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        one="view_brand_guidelines"
                                                        path="/partner-information/rights-protection"
                                                        component={
                                                            RightsProtection
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "view_brand_guidelines",
                                                            "view_logo_assets",
                                                            "edit_brand_guidelines_content",
                                                            "upload_delete_brand_guidelines_files",
                                                            "upload_logo_assets_content",
                                                            "edit_existing_logo_assets",
                                                            "delete_existing_logo_assets",
                                                            "create_edit_delete_logo_asset_folder",
                                                            "view_brand_documents",
                                                            "upload_brand_documents_content",
                                                            "edit_existing_brand_documents",
                                                            "delete_existing_brand_documents",
                                                            "create_edit_delete_brand_documents_folder",
                                                        ]}
                                                        path="/brand"
                                                        component={Brand}
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "upload_imagery_footage_content",
                                                            "edit_existing_imagery_footage",
                                                            "delete_existing_imagery_footage",
                                                            "create_edit_delete_imagery_footage",
                                                            "edit_archive_content",
                                                            "edit_archive_links",
                                                            "view_imagery_footage",
                                                            "view_archive",
                                                        ]}
                                                        path="/media"
                                                        component={Media}
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "submit_approval_request",
                                                            "review_approvals_request",
                                                            "view_approvals",
                                                        ]}
                                                        exact
                                                        path="/approvals"
                                                        component={
                                                            BrandApprovalForm
                                                        }
                                                    />

                                                    {/* <ProtectedRoute
                                                        exact
                                                        some={[
                                                            "submit_talent_request",
                                                            "review_talent_request",
                                                            "view_talent_access",
                                                            "edit_talent_access",
                                                            "upload_delete_talent_access_files",
                                                            "view_talent_access_inventory",
                                                            "create_talent_access_assets",
                                                            "edit_existing_talent_access",
                                                            "delete_talent_access_existing"
                                                        ]}
                                                        path="/talent-access"
                                                        component={TalentAccessForm} /> */}

                                                    <ProtectedRoute
                                                        some={[
                                                            "review_talent_request",
                                                            "review_approvals_request",
                                                        ]}
                                                        exact
                                                        path="/my-requests"
                                                        component={
                                                            BrandApprovalRequests
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "submit_talent_request",
                                                            "submit_approval_request",
                                                        ]}
                                                        exact
                                                        path="/my-requests/:id"
                                                        component={
                                                            BrandApprovalRequest
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "admin_view_ticket_hospitality",
                                                            "admin_view_appearances",
                                                            "admin_view_activation",
                                                            "admin_view_partner_workshops",
                                                            "admin_view_right_protection",
                                                            "admin_view_documents",
                                                            "admin_view_brand_guidelines",
                                                            "admin_view_logo_assets",
                                                            "admin_view_brand_documents",
                                                            "admin_view_archive",
                                                            "admin_view_right_documentations",
                                                            "admin_review_talent_request",
                                                            "admin_view_talent_access",
                                                            "admin_review_approvals_request",
                                                            "view_user_list",
                                                            "view_role_list",
                                                            "view_partner_list",
                                                        ]}
                                                        exact
                                                        path="/admin"
                                                        component={Admin}
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "view_user_list",
                                                            "create_new_user",
                                                            "edit_existing_user",
                                                            "delete_existing_user",
                                                            "create_new_admin",
                                                            "edit_existing_admin",
                                                            "delete_existing_admin",
                                                            "view_role_list",
                                                            "create_new_role",
                                                            "edit_existing_role",
                                                            "delete_existing_role",
                                                            "create_new_admin_role",
                                                            "edit_existing_admin_role",
                                                            "delete_existing_admin_role",
                                                            "mass_delete_users",
                                                        ]}
                                                        path={`/admin/user-management`}
                                                        component={
                                                            UserManagement
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        one="view_partner_list"
                                                        path={`/admin/partner-management`}
                                                        component={
                                                            PartnerManagement
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        exact
                                                        some={[
                                                            // admin content management Lions Tour 25
                                                            "admin_view_ticket_hospitality",

                                                            // admin content management Brand
                                                            "admin_view_brand_guidelines",

                                                            // admin content management Media Library
                                                            "admin_view_archive",

                                                            // admin content management Rights and Documentation
                                                            "admin_view_right_documentations",

                                                            // admin content management Requests
                                                            "admin_view_talent_access",
                                                            "edit_approvals",
                                                            "upload_delete_approvals_files",

                                                            "admin_view_appearances",
                                                            "admin_view_activation",
                                                            "admin_view_partner_workshops",
                                                            "admin_view_right_protection",
                                                            "admin_view_documents",
                                                            "admin_view_key_deadlines",
                                                            "admin_view_brand_guidelines",
                                                            "admin_view_imagery_footage",
                                                            "admin_review_talent_request",
                                                            "admin_view_talent_access",
                                                            "admin_review_approvals_request",

                                                            "edit_ticket_content",
                                                            "upload_delete_ticket_files",
                                                            "edit_appearances_content",
                                                            "upload_appearances_files",
                                                            "edit_activation_content",
                                                            "upload_delete_activation_files",
                                                            "edit_partner_workshops_content",
                                                            "upload_delete_partner_workshops_files",
                                                            "edit_rights_protection_content",
                                                            "upload_delete_rights_protection_files",
                                                            "edit_rights_documentations",
                                                            "upload_delete_rights_documentations_files",
                                                            "edit_brand_guidelines_content",
                                                            "upload_delete_brand_guidelines_files",
                                                            "edit_archive_content",
                                                            "edit_archive_links",
                                                            "edit_talent_access",
                                                            "upload_delete_talent_access_files",
                                                            "edit_approvals",
                                                            "upload_delete_approvals_files",
                                                        ]}
                                                        path={`/admin/content-management/:partnerId`}
                                                        component={
                                                            ContentManagement
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "submit_talent_request",
                                                            "edit_talent_access",
                                                            "upload_delete_talent_access_files",
                                                            "view_talent_access_inventory",
                                                            "create_talent_access_assets",
                                                            "edit_existing_talent_access",
                                                            "delete_talent_access_existing",
                                                        ]}
                                                        path={`/admin/inventory-management/:form?`}
                                                        component={
                                                            InventoryManagement
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "admin_review_talent_request",
                                                            "admin_review_approvals_request",
                                                        ]}
                                                        exact
                                                        path={`/admin/request-management`}
                                                        component={
                                                            RequestManagement
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        some={[
                                                            "admin_review_talent_request",
                                                            "admin_review_approvals_request",
                                                        ]}
                                                        adminMode={true}
                                                        path={`/admin/request-management/:id`}
                                                        component={
                                                            BrandApprovalRequest
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        one="admin_review_talent_request"
                                                        adminMode={true}
                                                        path={`/admin/talent-request-management/:id`}
                                                        component={
                                                            TalentAccessRequest
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        one="admin_review_talent_request"
                                                        adminMode={true}
                                                        path={`/admin/talent-request-group-allocation`}
                                                        component={
                                                            GroupAllocation
                                                        }
                                                    />

                                                    <ProtectedRoute
                                                        component={PageNotFound}
                                                    />
                                                </Switch>
                                            </GAListener>
                                        </Header>
                                    </BrowserRouter>
                                </AppLoader>
                            </ConfirmProvider>
                        </NotificationProvider>
                    </Provider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;
