import React from 'react';

const Errors = ({errors, hideErrors}) => {

    const getErrors = function () {
        if (errors) {
            hideErrors();

            if (errors.errors) {
                let keys = Object.keys(errors.errors);

                if (keys.length > 0) {
                    return keys.map(function (key) {
                        return errors.errors[key].map((item, index) => {
                            return (
                                <div key={index}>{`${key}: ${item}`}</div>
                            );
                        });
                    });
                }
            } else {
                return (
                    <div>System error</div>
                );
            }
        }
    }

    return (
        <div className="library__errors">
            {getErrors()}
        </div>
    );

};

export default Errors;
