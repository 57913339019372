import React from 'react';

const CalendarIcon = ({width, height, className}) => {
    return (
        <span className={className}>
            <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
                <title>Icons/Calendar</title>
                <g id="Icons/Calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M11,2 L11,5 L21,5 L21,2 L23,2 L23,5 L30,5 L30,30 L2,30 L2,5 L9,5 L9,2 L11,2 Z M28,15 L4,15 L4,28 L28,28 L28,15 Z M9,7 L4,7 L4,13 L28,13 L28,7 L23,7 L23,9 L21,9 L21,7 L11,7 L11,9 L9,9 L9,7 Z" id="Combined-Shape" fill="#3E3F60"/>
                </g>
            </svg>
        </span>
    );
};

export default CalendarIcon;
