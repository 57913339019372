import React, { useState } from 'react';
import { Dialog, Button, TextField, makeStyles, Grid, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import './index.scss';

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        width: 163,
        fontSize: 16,
        fontWeight: 600,
        color: '#3e3f60',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: 'transparent',
        borderRadius: 0
    },
    saveChangesButton: {
        width: 231,
        fontSize: 16,
        fontWeight: 600,
        color: '#ffffff',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#af001e',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001e80',
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: '#af001e80'
        }
    },
    section: {
        paddingLeft: 43,
    },
    indicator: {
        color: '#af001e'
    },
    tourimg: {
        width: '100%'
    },
    table: {
        borderRadius: 0
    },
    gridPadding: {
        paddingTop: 60,
        paddingBottom: 60
    },
    textPadding: {
        paddingTop: 20
    },
    arrowIcon: {
        width: 29,
        height: 29
    },
    tabItem: {
        color: '#af001e',
        fontWeight: 'bold'
    },
    tabLink: {
        borderBottom: '2px solid #af001e',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        '&:hover': {
            fontWeight: 'bold',
            color: '#af001e'
        }
    },
    tabLinkInactive: {
        '&:hover': {
            textDecoration: 'none'
        }
    }
}));

const RenameImageDialog = ({ imageName, handleCloseImageDialog, handleSaveImageDialog, open, setFieldValue }) => {

    const classes = useStyles();
    const [newImageName, setNewImageName] = useState();

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleCloseImageDialog}
            className="dialog"
        >
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="row" alignItems="baseline" justify="space-between" spacing={2}>
                        <Grid item>
                            <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3e3f60' }}>Rename Image</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.closeButton} onClick={() => handleCloseImageDialog(false)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        defaultValue={imageName}
                        placeholder={imageName}
                        onChange={(e) => setNewImageName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={2}>
                        <Grid item>
                            <Button className={classes.cancelButton} onClick={() => handleCloseImageDialog(false)}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.saveChangesButton} onClick={() => handleSaveImageDialog(newImageName, setFieldValue)} type="submit">Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default RenameImageDialog;
