import React from 'react';

const RadioInactive = () => {
    return (
        <>
            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Inactive</title>
                <g id="Portal-Design---Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M7.2,0.1 C3.55492065,0.1 0.6,3.05492065 0.6,6.7 C0.6,10.3450793 3.55492065,13.3 7.2,13.3 C10.8450793,13.3 13.8,10.3450793 13.8,6.7 C13.8,3.05492065 10.8450793,0.1 7.2,0.1 Z M7.2,2.1 C9.74050985,2.1 11.8,4.15949015 11.8,6.7 C11.8,9.24050985 9.74050985,11.3 7.2,11.3 C4.65949015,11.3 2.6,9.24050985 2.6,6.7 C2.6,4.15949015 4.65949015,2.1 7.2,2.1 Z" id="Inactive" fill="#D8DAE6" fillRule="nonzero"></path>
                </g>
            </svg>
        </>
    );
};

export default RadioInactive;
