import React from "react";
import MaterialIcon from "material-icons-react";

export default ({request}) => {

    return (
        <div className="events-block-new cards">
            <h4>Project description</h4>
            <ul>{request.type === 'approvals' &&
                <>
                    <li className="spacing">
                        <label>WHERE IT WILL BE USED?</label>
                        <div className="description-values">{request.location}</div>
                    </li>

                    <li className="spacing">
                        <label>WHEN WILL IT START TO BE USED?</label>
                        <div className="description-values">{request.date}</div>
                    </li>
                </>
            }
            <li className="spacing">
                {request.type === 'approvals' &&
                    <label>DESCRIPTION</label>
                }
                <div className="description-values">{request.description}</div>
            </li>
            </ul>
        </div>
    );
};
