import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {connect} from "react-redux";
import {getInventory, getTeams} from "~/Admin/InventoryManagement/inventoryActions";
import Button from "~/Components/Button";

import './index.scss';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import {Check} from "@material-ui/icons";
import Pagination from "react-js-pagination";
import moment from "moment";

const AssetsList = (props) => {
    const {
        inventory,
        dispatch,
        accessType,
        setAccessType,
        activeAsset,
        requestType,
        setRequestType,
        meta,
    } = props;

    const[page, setPage] = useState(1);
    const[loaded, setLoaded] = useState(false);


    useEffect(() => {
        setLoaded(false);
        let filters = {access_type: accessType, has_team: true, has_qty: true};

        if (accessType === 'staff') {
            filters['request_type'] = requestType;
        }
        dispatch(getInventory(filters, page)).then(() => setLoaded(true));

    }, [accessType, requestType, page]);

    const setActiveAsset = (asset) => {
        dispatch({type: 'talent_access/SET_ACTIVE_ASSET', payload: asset})
    }
    return (
        <Grid container direction="column" className="talent-access">
            <Grid item><Typography className="grey-label">SELECT ACCESS TYPE</Typography></Grid>
            <Grid item style={{marginBottom: 30}}>
                <ToggleButtonGroup
                    value={accessType}
                    exclusive
                    onChange={(event, type) => {
                        if (type) {
                            setAccessType(type);
                            setPage(1);
                        }

                    }}
                    aria-label="text alignment"
                    className="assets-toggle"
                >
                    <ToggleButton disabled={!loaded} value="players" aria-label="player access">
                        Player Access
                    </ToggleButton>
                    <ToggleButton disabled={!loaded} value="staff" aria-label="staff access">
                        Staff Access
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>

            {accessType === 'staff' &&
            <>
                <Grid item><Typography className="grey-label">SELECT REQUEST TYPE</Typography></Grid>
                <Grid item style={{marginBottom: 30}}>
                    <ToggleButtonGroup
                        value={requestType}
                        exclusive
                        onChange={(event, type) => {
                            if (type) {
                                setRequestType(type)
                                setPage(1);
                            }
                        }}
                        aria-label="request type"
                        className="assets-toggle"
                    >
                        <ToggleButton disabled={!loaded} value="commercial appearance" aria-label="commercial appearance">
                            Commercial Appearance
                        </ToggleButton>
                        <ToggleButton disabled={!loaded} value="partner event" aria-label="partner event">
                            Partner Event
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </>
            }

            <Grid item><Typography className="grey-label">SELECT ALLOCATION</Typography></Grid>
            <Grid item md={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>Qty</TableCell>
                                <TableCell>Session</TableCell>
                                <TableCell>Session Date</TableCell>
                                <TableCell>Team</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>{accessType === 'players' ? 'Players' : 'Staff'}</TableCell>
                            </TableRow>
                        </TableHead>
                        {loaded &&
                            <TableBody>
                            {
                                inventory.map((asset, key) => {
                                        if (!asset.team_id) {
                                            return null;
                                        }
                                        return (
                                            <TableRow key={key}>
                                                <TableCell scope="row">
                                                    <ToggleButton
                                                        value="check"
                                                        selected={activeAsset && activeAsset.id === asset.id}
                                                        onChange={() => {
                                                            setActiveAsset(asset)
                                                        }}
                                                        style={{
                                                            backgroundColor: activeAsset && activeAsset.id === asset.id ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                            borderRadius: '50%',
                                                            padding: 0,
                                                            border: 'none'
                                                        }}>
                                                        <Check style={{color: 'white'}}/>
                                                    </ToggleButton>
                                                </TableCell>
                                                <TableCell scope="row">{asset.qty}</TableCell>
                                                <TableCell scope="row">{asset.session_name}</TableCell>
                                                <TableCell scope="row">{moment(asset.session_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                                                <TableCell
                                                    scope="row">{asset.team ? asset.team.name : ''}</TableCell>
                                                <TableCell scope="row">{asset.time}h</TableCell>
                                                <TableCell
                                                    scope="row">{asset.team_staff_category_id ? asset.teamStaffCategory.name : (asset.player_qty + 'x Players')}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item style={{marginTop: 30}}>
                {meta && meta.per_page < meta.total && (
                    <Pagination
                        prevPageText={
                            <div className="prev-next">
                                <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left"/>
                            </div>}
                        nextPageText={
                            <div className="prev-next">
                                <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right"/>
                            </div>}
                        activePage={meta.current_page}
                        itemsCountPerPage={Number(meta.per_page)}
                        totalItemsCount={meta.total}
                        pageRangeDisplayed={5}
                        hideFirstLastPages={true}
                        itemClass="page-item"
                        linkClass="page-link"
                        linkClassPrev="prev-next"
                        linkClassNext="prev-next"
                        onChange={(page) => setPage(page)}
                    />
                )}
            </Grid>


        </Grid>
    );
};
const mapStateToProps = (state) => ({
    activeAsset: state.talentAccess.activeAsset,
    meta: state.inventory.inventory.meta,
    inventory: state.inventory.inventory.data,
});

export default connect(mapStateToProps)(AssetsList);
