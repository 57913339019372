import update from 'immutability-helper';

const initialState = {
    list: {
        data: [],
    },
    meta: {},
    links: {},
    item: {},
    parent: {name: ''},
    loading: false,
    view: 'grid',
    perPage: 15,
    templateType: '',
    showInfo: false,
    refInfoElement: null,
    showOnBrands: '',
    orderBy: {title: 'Name', queryVal: 'name'},
};

const mediaLibraryReducer = (state = initialState, action) =>
{
    if (action.type === 'media_library/SET_LIST') {
        return update(state, {
            list: {$set: action.payload},
        });
    }

    if (action.type === 'media_library/SET_VIEW') {
        return update(state, {
            view: {$set: action.view},
        });
    }

    if (action.type === 'media_library/SET_PER_PAGE') {
        return update(state, {
            perPage: {$set: action.perPage},
        });
    }

    if (action.type === 'media_library/SET_ORDER_BY') {
        return update(state, {
            orderBy: {$set: action.orderBy},
        });
    }

    if (action.type === 'media_library/SET_SHOW_INFO') {
        return update(state, {
            showInfo: {$set: action.showInfo},
        });
    }

    if (action.type === 'media_library/SET_REF_INFO') {
        return update(state, {
            refInfoElement: {$set: action.refInfoElement},
        });
    }

    if (action.type === 'media_library/SET_ITEM') {
        return update(state, {
            parent: {$set: action.payload.data.parent},
            folders: {$set: action.payload.data.folders},
            files: {$set: action.payload.data.files},
            breadcrumbs: {$set: action.payload.data.breadcrumbs},
            templateType: {$set: action.payload.data.templateType},
            showOnBrands: {$set: action.payload.data.showOnBrands},
            title: {$set: action.payload.data.title},
            description: {$set: action.payload.data.description},
            meta: {$set: action.payload.meta},
            links: {$set: action.payload.links},
        });
    }

    if (action.type === 'media_library/RESET_ITEM') {
        return update(state, {
            orderBy: {$set: {title: 'Name', queryVal: 'name'}},
            view: {$set: 'grid'},
            perPage: {$set: 15},
            meta: {$set: {}},
            list: {$set: {data: []}},
            links: {$set:{}},
            item: {$set:{}},
            parent: {$set: {name: ''}},
            templateType: {$set: ''},
            showOnBrands: {$set: ''},
            showInfo: {$set: false},
            refInfoElement: {$set: null},
        });
}

    if (action.type === 'media_library/UPLOAD') {
        return update(state, {
            success: {$set: action.payload.data.success},
        });
    }

    return state;
};

export default mediaLibraryReducer;
