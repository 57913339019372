import update from 'immutability-helper';

const initialState = {
    status: '',
    text: '',
    title: '',
};

const notificationReducer = (state = initialState, action) =>
{
    if (action.type === 'notification/SET') {

        action.payload.status = 'success';

        return update(state, {$set: action.payload});
    }

    if (action.type === 'notification/DESTROY') {
        return initialState;
    }

    return state;
};

export default notificationReducer;
