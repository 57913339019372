import React, {useEffect, useRef, useState} from 'react';
import {Card, Divider, Grid, makeStyles, Tabs, Tab, Typography} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import AdminMenu from "~/Admin/AdminMenu";

import './index.scss';
import {
    getTeams,
    getInventory
} from './inventoryActions';
import {connect} from 'react-redux';
import Button from "~/Components/Button";
import TeamForm from "~/Admin/InventoryManagement/TeamForm";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InventoryFilter from "~/Admin/InventoryManagement/InventoryFilter";
import InventoryDialog from "~/Admin/InventoryManagement/InventoryDialog/InventoryDialog";
import ProtectedRoute from "~/ProtectedRoute";
import AssetsForm from "~/Admin/InventoryManagement/AssetsForm/AssetsForm";
import Pagination from "react-js-pagination";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {Check} from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    titlePadding: {
        paddingTop: '2rem'
    },
    stepTitle: {
        fontFamily: theme.demiBold,
        color: '#af001e', 
        textTransform: 'uppercase', 
        fontSize: 20, 
        fontWeight: 600,
        paddingBottom: '1em'        
    },
    section: {
        // paddingLeft: 43,
    },
    stepper: {
        fontFamily: theme.bold,
        fontSize: 16,
        color: theme.lightGrey,
    },
    tabLink: {
        width: '100%',
        borderBottom: '2px solid ' + theme.primaryColor,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        width: '100%',
        '&:hover': {
            fontWeight: 'bold',
            color: theme.primaryColor,
        }
    },
    tabLinkInactive: {
        width: '100%',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItem: {
        width: '100%',
        color: theme.primaryColor,
        fontWeight: 'bold'
    },
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor
    },
}));

const InventoryManagement = (props) => {

    const classes = useStyles();
    const {
        inventory,
        loaded,
        dispatch,
        team,
        match,
        history,
        meta,
        confirm,
    } = props;

    const form = match.params.form;

    const [hasInventory, setHasInventory] = useState(false);
    const [page, setPage] = useState();
    const [selectedPartner, setSelectedPartner] = useState('');
    const [selectedRequestType, setSelectedRequestType] = useState('');

    let title = 'Inventory Management';

    if (form === 'team-form') {
        title = team.id ? 'Manage teams' : 'Create teams';
    } else if (form === 'assets-form') {
        title = 'Create assets';
    }

    useEffect(() => {
        if (!form) {
            initialLoad();
        }
    }, [form]);

    const initialLoad = () => {
        dispatch({type: 'inventory/SET_LOADED', payload: false});
        props.dispatch(getTeams());
        props.dispatch(getInventory()).then((hasFilters) => {
            setHasInventory(hasFilters);
            dispatch({type: 'inventory/SET_LOADED', payload: true});
        });
    }

    useEffect(() => {
        dispatch({type: 'inventory/SET_LOADED', payload: false});
        props.dispatch(getInventory({
            partner_id: selectedPartner || '',
            request_type: selectedRequestType || ''
        }, page)).then(() => {
                dispatch({type: 'inventory/SET_LOADED', payload: true});
            }
        );

    }, [page, selectedPartner, selectedRequestType]);

    const showAssetsForm = () => {
        dispatch({type: 'inventory/SET_ASSETS', payload: inventory});
        history.push('/admin/inventory-management/assets-form');
    }

    const handleFilterChange = (filter, value) => {
        if(filter === 'partner') {
            setSelectedPartner(value);
        } else {
            setSelectedRequestType(value);
        }
        setPage(null);
    }

    const showMembersForm = () => {
        history.push('/admin/inventory-management/team-form');
    }

    return (
        <section className={classes.section + ' inventory-container'}>
            <InventoryDialog/>
            <Grid container>
                <Grid item>
                    <h1 className="content-title">Admin</h1>
                </Grid>
            </Grid>
            <Grid container>
                <AdminMenu/>
            </Grid>
            <Divider/>
            <Grid container direction="column" justify="flex-start" className={classes.titlePadding}>
                {form &&
                <Grid item>
                    <Typography className={classes.stepper}>STEP {form === 'team-form' ? 1 : 2} OF 2</Typography>
                </Grid>
                }

                <Grid item style={{paddingBottom: 17}}>
                    <Grid container direction="row" justify="space-between" className={classes.titlePadding} >
                        <Grid item>
                            <Typography className={classes.stepTitle}>{title}</Typography>
                        </Grid>
                        {inventory.length > 0 && !form &&
                        <Grid item>
                            <Button class="header-buttons" text="Manage Teams" onClick={showMembersForm}/>
                            <Button class="header-buttons" text="Manage Assets" onClick={showAssetsForm}/>
                        </Grid>
                        }

                        {form === 'assets-form' &&
                        <Grid item>
                            <Button class="btn-grey footer-downloads noMarg" text="BACK TO INVENTORY" onClick={() => history.push('/admin/inventory-management')}/>
                        </Grid>
                        }

                    </Grid>

                </Grid>
                {!loaded
                    ?
                    <div>Loading...</div>
                    :
                    <Grid item>
                    <Grid container>
                        {!form &&
                        <Grid item md={12}>
                            <Grid container direction="column" spacing={2}>
                                {hasInventory &&
                                <>
                                    <Grid item>
                                        <InventoryFilter
                                            page={page}
                                            selectedPartner={selectedPartner}
                                            selectedRequestType={selectedRequestType}
                                            handleFilterChange={handleFilterChange}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell/>
                                                        <TableCell>Qty</TableCell>
                                                        <TableCell>Partner</TableCell>
                                                        <TableCell>Session</TableCell>
                                                        <TableCell>Session Date</TableCell>
                                                        <TableCell>Access Type</TableCell>
                                                        <TableCell>Request Type</TableCell>
                                                        <TableCell>Team</TableCell>
                                                        <TableCell>Time</TableCell>
                                                        <TableCell>Member</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        inventory.map((asset, key) => (
                                                            <TableRow key={key}>
                                                                <TableCell scope="row">
                                                                    <ToggleButton
                                                                        value="check"
                                                                        selected={asset.talentRequest}
                                                                        style={{
                                                                            backgroundColor: asset.talentRequest ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                                            borderRadius: '50%',
                                                                            padding: 0,
                                                                            border: 'none'
                                                                        }}>
                                                                        <Check style={{color: 'white'}}/>
                                                                    </ToggleButton>
                                                                </TableCell>
                                                                <TableCell scope="row">{asset.qty}</TableCell>
                                                                <TableCell
                                                                    scope="row">{asset.partner ? asset.partner.name : ''}</TableCell>
                                                                <TableCell scope="row">{asset.session_name}</TableCell>
                                                                <TableCell scope="row">{moment(asset.session_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                                                                <TableCell scope="row">{asset.access_type}</TableCell>
                                                                <TableCell scope="row">{asset.request_type}</TableCell>
                                                                <TableCell
                                                                    scope="row">{asset.team ? asset.team.name : ''}</TableCell>
                                                                <TableCell scope="row">{asset.time}h</TableCell>
                                                                <TableCell
                                                                    scope="row">{asset.team_staff_category_id ? asset.teamStaffCategory.name : (asset.player_qty + 'x Players')}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item style={{marginTop: 30}}>
                                        {meta && meta.per_page < meta.total && (
                                            <Pagination
                                                prevPageText={
                                                    <div className="prev-next">
                                                        <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left"/>
                                                    </div>}
                                                nextPageText={
                                                    <div className="prev-next">
                                                        <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right"/>
                                                    </div>}
                                                activePage={meta.current_page}
                                                itemsCountPerPage={Number(meta.per_page)}
                                                totalItemsCount={meta.total}
                                                pageRangeDisplayed={5}
                                                hideFirstLastPages={true}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                linkClassPrev="prev-next"
                                                linkClassNext="prev-next"
                                                onChange={(page) => setPage(page)}
                                            />
                                        )}
                                    </Grid>
                                </>

                                }

                            </Grid>

                        </Grid>
                        }

                        <Grid item className="inventory" md={12}>
                            {!form && !hasInventory &&

                            <div className="not-found">
                                <div className="text-wrapper">
                                    <div className="title">No inventory yet</div>
                                    <div className="message">Set up your teams & create your inventory to get your
                                        talent access platform up and running.
                                    </div>
                                    <div className="button-wrapper">
                                        <Button class="fullWidth color-red" text="GET STARTED"
                                                onClick={() => history.push('/admin/inventory-management/team-form')}/>
                                    </div>
                                </div>
                            </div>
                            }

                            <ProtectedRoute exact path={`${match.path}/team-form`}
                                            component={TeamForm}/>
                            <ProtectedRoute exact path={`${match.path}/assets-form`}
                                            component={AssetsForm}/>

                        </Grid>
                    </Grid>

                </Grid>
                }

            </Grid>
        </section>

    );
};

const mapStateToProps = (state) => ({
    teams: state.inventory.teams.data,
    players: state.inventory.players,
    staff: state.inventory.staff,
    assets: state.inventory.assets,
    activeTeam: state.inventory.team,
    meta: state.inventory.inventory.meta,
    inventory: state.inventory.inventory.data,
    loaded: state.inventory.loaded,
    confirm: state.inventory.confirm,
    team: state.inventory.team,
});

export default connect(mapStateToProps)(withRouter(InventoryManagement));
