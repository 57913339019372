import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Field} from "formik";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "~/Components/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import FormControl from "@material-ui/core/FormControl";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import CalendarIcon from "~/Components/Icons/CalendarIcon";
import Grid from "@material-ui/core/Grid";

const AssetsList = ({assets, setAssets, manualType, requestType, manual, classes, setDeleteItems}) => {

    const [partners, setPartners] = useState([]);
    const [teams, setTeams] = useState([]);
    const [teamStaffCategories, setTeamStaffCategories] = useState([]);

    const displaySession = !manual || (manual && manualType === 'players') || (manualType === 'staff' && requestType === 'commercial appearance');


    useEffect(() => {
        axios.get('/api/partners').then(res => {
            setPartners(res.data.data);
        });
        axios.get('/api/teams').then(res => {
            setTeams(res.data.data);
        });
        axios.get('/api/teams-staff-categories').then(res => {
            setTeamStaffCategories(res.data.data);
        });
    },[]);

    const handleChange = (event, key, propertyName) => {
        let assetsTmp = [...assets];
        assetsTmp[key][propertyName] = event.target.value;
        setAssets(assetsTmp);

    }
    const handleDate = (date, key) => {
        let assetsTmp = [...assets];
        assetsTmp[key]['session_date'] = date;
        setAssets(assetsTmp);

    }
    const deleteRecord = (key) => {
        if (assets[key].id) {
            setDeleteItems(prevState => ([...prevState, assets[key].id]));
        }

        let assetsTmp = [...assets];
        assetsTmp.splice(key, 1);
        setAssets(assetsTmp, 'delete');
    }
    const addAsset = () => {
        let initialValues = {
            qty: '',
            partner_id: '',
            access_type: manualType,
            team_id: '',
            time: '',
            player_qty: '',
            session_name: '',
            session_date: '',

        }
        if (manualType === 'staff') {
            initialValues['team_staff_category_id'] = '';
            initialValues['request_type'] = requestType;
        }

        let assetTmp = [...assets, initialValues];
        setAssets(assetTmp, 'add');
    }

    const setStartDate = () => {
        let currentDate = new Date();
        return currentDate.getFullYear() + '-' + Number(currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    }

    return (
        <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Qty</TableCell>
                        <TableCell>Partner</TableCell>
                        {displaySession &&
                            <TableCell>Session</TableCell>
                        }
                        {!manual &&
                            <TableCell>Access Type</TableCell>
                        }
                        {!manual &&
                            <TableCell>Request Type</TableCell>
                        }

                        <TableCell>Team</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Session Date</TableCell>
                        <TableCell>
                            {manual && manualType === 'staff' ? 'Staff' : 'Member'}
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assets.length > 0 && assets.map((asset, key) => {
                        return(
                        <TableRow key={key}>
                            <TableCell scope="row" width={100}>
                                <Field
                                    name="qty[]"
                                    type="text">
                                    {({field}) => (
                                        <TextField
                                            InputProps={{
                                                classes: {
                                                    root: classes.inputBorder
                                                }
                                            }}
                                            variant="outlined"
                                            {...field}
                                            value={asset.qty}
                                            onChange={(event) => handleChange(event, key, 'qty')}
                                        />
                                    )}

                                </Field>
                            </TableCell>
                            <TableCell  className="inputs-select list" scope="row">
                                <FormControl required>
                                <Select
                                    variant="outlined"
                                    name="partner_name[]"
                                    value={asset.partner_name ? asset.partner_name :  asset.partner && asset.partner.name ? asset.partner.name : null}
                                    onChange={(event) => handleChange(event, key, 'partner_name')}
                                >
                                    {partners.map((partner, partnerKey) => {
                                        return (
                                            <MenuItem key={partnerKey} value={partner.name}>{partner.name}</MenuItem>
                                        )
                                    })}

                                </Select>
                                </FormControl>

                            </TableCell>
                            {displaySession &&
                            <TableCell scope="row">
                                <Field
                                    name="session_name[]"
                                    type="text">
                                    {({field}) => (
                                        <TextField
                                            InputProps={{
                                                classes: {
                                                    root: classes.inputBorder
                                                }
                                            }}
                                            disabled={asset.access_type === 'staff' && asset.request_type === 'partner event'}
                                            variant="outlined"
                                            {...field}
                                            value={asset.session_name}
                                            onChange={(event) => handleChange(event, key, 'session_name')}
                                        />
                                    )}

                                </Field>
                            </TableCell>
                            }
                            {!manual &&
                                <TableCell scope="row" className="inputs-select list">
                                <Select
                                    variant="outlined"
                                    name="access_type[]"
                                    value={asset.access_type}
                                    onChange={(event) => handleChange(event, key, 'access_type')}
                                >
                                    <MenuItem value="players">Player Access</MenuItem>
                                    <MenuItem value="staff">Staff Access</MenuItem>

                                </Select>
                            </TableCell>
                            }

                            {!manual &&
                                <TableCell scope="row" className="inputs-select list">
                                        <Select
                                            variant="outlined"
                                            name="request_type[]"
                                            value={asset.access_type === 'staff' ? asset.request_type : ''}
                                            disabled={asset.access_type === 'players'}
                                            onChange={(event) => handleChange(event, key, 'request_type')}
                                        >
                                            <MenuItem value="commercial appearance">Commercial Appearance</MenuItem>
                                            <MenuItem value="partner event">Partner Event</MenuItem>
                                        </Select>
                                    </TableCell>
                            }

                            <TableCell scope="row" className="inputs-select list">
                                <Select
                                    variant="outlined"
                                    name="teams[]"
                                    value={asset.team_name ? asset.team_name : asset.team && asset.team.name ? asset.team.name : ''}
                                    onChange={(event) => handleChange(event, key, 'team_name')}
                                >
                                    {teams && teams.length > 0 && teams.map((team, teamKey) => {
                                        return (
                                            <MenuItem key={teamKey} value={team.name}>{team.name}</MenuItem>
                                        )
                                    })}

                                </Select>
                            </TableCell>
                            <TableCell scope="row">
                                <Select
                                    variant="outlined"
                                    name="time[]"
                                    value={asset.time}
                                    onChange={(event) => handleChange(event, key, 'time')}
                                >
                                    <MenuItem value="1">1h</MenuItem>
                                    <MenuItem value="2">2h</MenuItem>
                                    <MenuItem value="3">3h</MenuItem>

                                </Select>
                                {/*<Field*/}
                                {/*    name="time[]"*/}
                                {/*    type="text">*/}
                                {/*    {({field}) => (*/}
                                {/*        <TextField*/}
                                {/*            InputProps={{*/}
                                {/*                classes: {*/}
                                {/*                    root: classes.inputBorder*/}
                                {/*                }*/}
                                {/*            }}*/}
                                {/*            variant="outlined"*/}
                                {/*            {...field}*/}
                                {/*            value={asset.time}*/}
                                {/*            onChange={(event) => handleChange(event, key, 'time')}*/}
                                {/*        />*/}
                                {/*    )}*/}

                                {/*</Field>*/}
                            </TableCell>
                            <TableCell scope="row" width={160}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    autoOk
                                    inputVariant="standard"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    minDate={asset.id ? null : setStartDate()}
                                    value={asset.session_date ? asset.session_date : null}
                                    onChange={(date) => {
                                        handleDate(moment(date).format(moment.HTML5_FMT.DATE), key)
                                    }}
                                    placeholder="DD/MM/YYYY"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        className: classes.calendarButton,
                                    }}
                                    fullWidth
                                    keyboardIcon={<CalendarIcon width="12px" height="22px"
                                                                className={classes.calendarIcon}/>}
                                    classes={{root: classes.dateRoot}}
                                    InputProps={{
                                        className: classes.input

                                    }}
                                    InputAdornmentProps={{
                                        className: classes.dateAdornment
                                    }}
                                    PopoverProps={{
                                        classes: {
                                            paper: classes.paper
                                        }
                                    }}

                                />
                            </TableCell>
                            {asset.access_type === 'staff'
                                ?
                                <TableCell scope="row" className="inputs-select list">
                                    <Select
                                        variant="outlined"
                                        name="staff[]"
                                        labelId="staff-label"
                                        value={asset.category ? asset.category : (asset.teamStaffCategory ?  asset.teamStaffCategory.name : '')}
                                        onChange={(event) => handleChange(event, key, 'category')}
                                    >
                                        {teamStaffCategories.map((category, categoryKey) => {
                                            return (
                                                <MenuItem key={categoryKey}
                                                          value={category.name}>{category.name}</MenuItem>
                                            )
                                        })
                                        }

                                    </Select>
                                </TableCell>
                                :
                                <TableCell scope="row">
                                    <Select
                                        variant="outlined"
                                        name="player_qty[]"
                                        value={asset.player_qty}
                                        onChange={(event) => handleChange(event, key, 'player_qty')}
                                    >
                                        <MenuItem value="1">1x Players</MenuItem>
                                        <MenuItem value="2">2x Players</MenuItem>
                                        <MenuItem value="3">3x Players</MenuItem>
                                        <MenuItem value="4">4x Players</MenuItem>
                                        <MenuItem value="5">5x Players</MenuItem>
                                        <MenuItem value="6">6x Players</MenuItem>
                                        <MenuItem value="7">7x Players</MenuItem>
                                        <MenuItem value="9">9x Players</MenuItem>
                                        <MenuItem value="10">10x Players</MenuItem>
                                        <MenuItem value="11">11x Players</MenuItem>
                                        <MenuItem value="12">12x Players</MenuItem>

                                    </Select>
                                    {/*<Field*/}
                                    {/*    name="player_qty[]"*/}
                                    {/*    type="text">*/}
                                    {/*    {({field}) => (*/}
                                    {/*        <TextField*/}
                                    {/*            InputProps={{*/}
                                    {/*                classes: {*/}
                                    {/*                    root: classes.inputBorder*/}
                                    {/*                }*/}
                                    {/*            }}*/}
                                    {/*            {...field}*/}
                                    {/*            variant="outlined"*/}
                                    {/*            value={asset.player_qty}*/}
                                    {/*            onChange={(event) => handleChange(event, key, 'player_qty')}*/}
                                    {/*        />*/}
                                    {/*    )}*/}

                                    {/*</Field>*/}
                                </TableCell>
                            }

                            <TableCell align="right">
                                {!asset.talentRequest &&
                                    <Typography className="delete-row" onClick={() => deleteRecord(key)}>x</Typography>
                                }
                            </TableCell>
                        </TableRow>
                    )})}
                </TableBody>
            </Table>
        </TableContainer>
            {manual &&
                <Button class="button footer-buttons add-new" text="CREATE ASSET" onClick={addAsset}/>
            }

        </>
    );
};

export default AssetsList;
