
import { combineReducers } from 'redux'
import userReducer from './userReducer';
import adminReducer from './adminReducer';
import brandReducer from './brandReducer';
import mediaLibraryReducer from "./mediaLibraryReducer";
import assetsReducer from "./assetsReducer";
import homepageReducer from './homepageReducer';
import notificationReducer from './notificationReducer';
import partnerReducer from './partnerReducer';
import inventoryReducer from "~/reducers/inventoryReducer";
import talentAccessReducer from "~/reducers/talentAccessReducer";
import talentAccessAdminRequestReducer from "~/reducers/talentAccessAdminRequestReducer";
import roleReducer from "~/reducers/roleReducer";

const rootReducer = combineReducers({
    user: userReducer,
    admin: adminReducer,
    brand: brandReducer,
    media: mediaLibraryReducer,
    assets: assetsReducer,
    homepage: homepageReducer,
    notification: notificationReducer,
    partner: partnerReducer,
    inventory: inventoryReducer,
    talentAccess: talentAccessReducer,
    talentAccessAdminRequest: talentAccessAdminRequestReducer,
    role: roleReducer,
});

export default rootReducer;
