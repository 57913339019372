// import { getCommunications } from './commActions';
import { authHeader } from "~/helpers/authHeader";
import axios from 'axios';

export const getTeams = () => {

    return (dispatch) => {

        return fetch('/api/teams', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'inventory/SET_TEAMS',
                    payload: data,
                });
            });
    };
};

export const getTeam = (id) => {

    return (dispatch) => {

        return fetch('/api/teams/' + id, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'inventory/SET_TEAM',
                    payload: data.data,
                });
                dispatch({
                    type: 'inventory/SET_PLAYERS',
                    payload: data.data.team_players,
                });
                dispatch({
                    type: 'inventory/SET_STAFF',
                    payload: data.data.team_staff,
                });

                return true;
            });

    };
};

export const getInventory = (filters = null, page = 1) => {
    let filerParams = '';

    if (filters) {
        filerParams = `&partner_id=${filters.partner_id || ''}&request_type=${filters.request_type || ''}&access_type=${filters.access_type || ''}&has_team=${filters.has_team || ''}&has_qty=${filters.has_qty || ''}`;
    }

    return (dispatch) => {

        return fetch(`/api/team-assets?page=${page}${filerParams}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'inventory/SET_TEAM_ASSETS',
                    payload: data,
                });

                dispatch({
                    type: 'inventory/SET_ASSETS',
                    payload: data,
                });

                dispatch({
                    type: 'inventory/SET_ORIGINAL_ASSETS',
                    payload: data.data,
                });
                return data.data.length > 0;
            });
    };
};

export const importMembers = (files) => {

    return (dispatch) => {

        return fetch('/api/teams/upload/members', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: files
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'inventory/SET_MEMBERS',
                    payload: data,
                });
            });
    };

};
