import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Row, Col} from 'react-bootstrap';

import './index.scss';
import {connect} from "react-redux";
import MaterialIcon from "material-icons-react";
import {fetchMediaCategory, resetState, setOrderBy, setPerPage, setView} from "~/Media/actions";
import Dialog from "~/Components/Dialog";
import Button from "~/Components/Button";
import Upload from "~/Components/Upload";
import Pagination from "react-js-pagination";
import LibraryApi from "~/services/api/library";
import {Link} from "react-router-dom";
import ExtensionIcon from "~/Components/ExtensionIcon";
import Errors from "~/Components/Errors";
import Filter from "~/Components/Filter";
import Edit from "./Edit";
import Loader from "react-loader";
import {Redirect} from "react-router";
import PermissionChecker from "~/Permissions/PermissionChecker";
import {canDelete, canEdit, canManageFolder, canManageMedia, canUpload} from "~/MediaLibrary/permissions";
import {Field, Form, Formik} from "formik";
import {Grid, Typography} from "@material-ui/core";
import * as Yup from "yup";
import PartnersAutocomplete from "~/MediaLibrary/Manage/PartnersAutocomplete";

const ManageItem = (
    {
        match: {params},
        dispatch,
        parent,
        files,
        folders,
        meta,
        mediaPerPage,
        orderBy,
        uri,
        history,
        location,
        assetsPerPage,
        templateType,
        view,
        user,
        setNavigationCurrent
    }) => {
    const [showEdit, setShowEdit] = useState(null);
    const [showDelete, setShowDelete] = useState(null);
    const [fileEdit, setFileEdit] = useState({});
    const [showCreateFolder, setShowCreateFolder] = useState(null);
    const [errors, setErrors] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [responseError, setResponseError] = useState(false);
    const [getItems, setGetItems] = useState(null);

    let checker = new PermissionChecker(user);

    let slug = parent.slug || parent?.media_library?.slug;

    const manageUrl = location.pathname.match(/\/([a-z]+)\//);
    const perPage = assetsPerPage ? assetsPerPage : mediaPerPage;

    const UploadSchema = Yup.object().shape({
        partners: Yup.array(),
        files: Yup.mixed()
            .required('A file is required')
    });

    useEffect(() => {
        dispatch(
            resetState()
        )
        setNavigationCurrent(params.id);
    }, []);

    useEffect(() => {

        if (responseError) {
            history.push('/404');
        } else {
            fetchMediaCategoryItemsList(params.id, params.folderId);
        }

    }, [perPage, orderBy, params.folderId, searchName, responseError]);

    const fetchMediaCategoryItemsList = (id, folderId) => {
        let data = {id: id, folderId: folderId, perPage, name: searchName, orderBy: orderBy};

        if (params.partnerId) {
            data['partnerId'] = params.partnerId;
        }

        dispatch(
            fetchMediaCategory(data)
        ).then(error => {
            setResponseError(error);
        });
        setFileEdit({});
    }

    const chunk = (a,n) => [...Array(Math.ceil(a.length/n))].map((_,i)=>a.slice(n*i,n+n*i));

    const sendFiles = (values, getItems, resetForm) => {

        if (!values.files.length) {
            setErrors({errors: {file: ['Wrong extension, please use: doc,docx,eps,jpg,jpeg,png,svg,ai,psd,pdf,xlsx,mp4,mov']}});
            return;
        }

        if (values.files.length > 100) {
            setErrors({errors: {file: ['Number of files for upload is exceeded']}});
            return;
        }

        let chunks = chunk(values.files, window.numOfFilesAllowed);

        chunks.forEach(chunkFiles => {
            const payload = new FormData();

            if (values.partners && values.partners.length) {
                values.partners.forEach((partner) => {
                    payload.append("partner_ids[]", partner.id);
                });
            }

            let library = params.library === 'creative_assets' ? 'creative_assets' : 'media_library';

            payload.append('media_library_id', params.id);
            payload.append('library', library);

            if (params.partnerId) {
                payload.append('partner_id', params.partnerId);
            }

            if (params.folderId) {
                payload.append('parent_folder_id', params.folderId);
            }

            if (fileEdit.token) {
                payload.append('token', fileEdit.token);
            }

            chunkFiles.forEach((file) => {
                payload.append("file[]", file);
            });

            setLoaded(false);


            LibraryApi.postFiles(payload).then(() => {

                if (getItems) {
                    getItems();
                } else {
                    fetchMediaCategoryItemsList(params.id, params.folderId)
                }

                if (resetForm) {
                    resetForm({values: ''})
                }

                setLoaded(true);

            }).catch(responseErrors => {
                setLoaded(true);
                setErrors(responseErrors)
            });
        })


    };
    const deleteFile = function () {
        LibraryApi.deleteFile(fileEdit.id).then(() => {
            fetchMediaCategoryItemsList(params.id, params.folderId)
            setShowDelete(false);
        });
    };

    const updateFile = (name, fileId, partners) => {

        if (!name) {
            return false;
        }
        let data = {
            name,
            media_library_id: params.id,
            parent_folder_id: params.folderId
        };

        data['partner_ids'] = [];

        if (partners && partners.length) {
            partners.forEach(partner => {
                data['partner_ids'] = [...data['partner_ids'], partner.id];
            });

        }

        LibraryApi.updateFile(fileId, data).then(() => {
            fetchMediaCategoryItemsList(params.id, params.folderId)
            setShowEdit(false);
        }).catch(responseErrors => setErrors(responseErrors));
    }

    const closeModal = function (editScope) {
        setShowEdit(false);
        setShowDelete(false);
        setShowCreateFolder(false);

        if (editScope) {
            fetchMediaCategoryItemsList(params.id, params.folderId);
        }
    }
    const createFolder = (name, partners) => {
        if (!name) {
            return false;
        }

        let data = {
            name,
            media_library_id: params.id,
            parent_folder_id: params.folderId,
            library: params.library === 'creative_assets' ? 'creative_assets' : 'media_library',
            recursive: true,
        }

        if (partners && partners.length) {
            data['partner_ids'] = [];
            partners.forEach(partner => {
                data['partner_ids'] = [...data['partner_ids'], partner.id];
            });

        }

        if (params.partnerId) {
            data['partner_id'] = params.partnerId;
        }

        LibraryApi.createFolder(data).then(() => {
            fetchMediaCategoryItemsList(params.id, params.folderId);
            setShowCreateFolder(false);
        }).catch((responseErrors) => {
            setErrors(responseErrors);
        });

    }

    const goToPage = (page) => {
        let data = {id: params.id, folderId: params.folderId, perPage: perPage}
        if (params.partnerId) {
            data['partnerId'] = params.partnerId;
        }

        dispatch(
            fetchMediaCategory(data, page)
        );
    }

    const setNameShort = (name, max = 32) => {
        return (
            name.length > 32
                ? name.substring(0, max) + '...'
                : name
        );
    }

    const getFolderUrl = (item) => {
        return uri ? `${uri}/${item.id}` : `/${manageUrl[1]}/manage/${item.media_library_id}/${item.id}`;
    }
    const cancelEdit = () => {
        let backUri = `/${manageUrl[1]}/category/${params.id}`;
        if (params.folderId) {
            backUri = `${backUri}/${params.folderId}`;
        }
        history.push({pathname: backUri});
    }

    const renderList = (items, mode = 'folders') => {

        return (
            items.map((item, index) => {
                return (
                    <tr id={item.id} key={item.id}>
                        {mode === 'files'
                            ?
                            <td>
                                {item.media_type === 'photos' || item.media_type === 'videos'
                                    ?
                                    <Fragment>
                                        <img src={item.thumbnail_image}
                                             className="library__list-action__images"/>
                                        &nbsp;
                                        <span title={item.name}>{setNameShort(item.name)}</span>
                                    </Fragment>
                                    :
                                    <div className="d-flex">
                                        <ExtensionIcon text={item.ext} width="50px" height="50px"/>
                                        <div title={item.name} className="file-name-custom">{setNameShort(item.name)}</div>
                                    </div>
                                }
                            </td>
                            :
                            <td>
                                <div className="library__folder">
                                    <Link to={getFolderUrl(item)} className={"library__folder__link"}>
                                        <MaterialIcon icon="folder"/>&nbsp;{item.name}
                                    </Link>
                                </div>
                            </td>

                        }
                        <td>{item.ext}</td>
                        <td>{item.ext === 'Folder' ? `${item.size} files` : `${item.size} kb`}</td>
                        <td>
                            {checker.some(canEdit(slug)) &&
                                <span className="library__media-command" onClick={() => {
                                    setFileEdit(item);
                                    setShowEdit(true);
                                }}>
                                    <MaterialIcon icon="edit"/>
                                </span>
                            }
                            {checker.some(canDelete(slug)) &&
                                <span className="library__media-command" onClick={() => {
                                    setFileEdit(item)
                                    setShowDelete(true);
                                }}>
                                    <MaterialIcon icon="delete"/>
                                </span>
                            }

                            <span className="library__media-command">{item.ext !== 'Folder' &&
                            <MaterialIcon icon="get_app" onClick={() => {
                                LibraryApi.downloadImage(item, setLoaded)
                            }}/>
                            }
                            </span>
                        </td>
                    </tr>
                );
            })
        );
    }
    if (!slug) {
        return null;
    }

    function acceptedFiles (pageSlug) {
        switch(pageSlug) {
            case "brand-documents":
                return  ".doc,.docx,.eps,.psd,.pdf,.xlsx,.zip";
            case "logo-assets":
                return ".jpg,.png,.svg,.ai,.mp4,.mov,.zip,.eps";
            case "imagery-footage":
                return ".doc,.docx,.eps,.jpg,.png,.svg,.ai,.psd,.pdf,.xlsx,.mp4,.mov,.zip";
                default :
                return ".doc,.docx,.eps,.jpg,.png,.svg,.ai,.psd,.pdf,.xlsx,.mp4,.mov,.zip";
        };
    };

    return (

        <div className="grid-list-container">
            {!checker.some(canManageMedia(slug)) || templateType === 'descriptive' &&
                <Redirect to="/"/>
            }

            <Row>
                <Col md={12} lg={12}>{errors &&
                <Errors errors={errors} hideErrors={() => setTimeout(() => {
                    setErrors(null)
                }, 4000)}/>
                }
                </Col>
            </Row>
            {location.pathname.indexOf('manage') !== -1 &&
            <Row>
                <Col md={12} lg={12}>
                    <Button class="noFill download no-radius" text="Cancel Edit" onClick={cancelEdit}/>
                </Col>
            </Row>
            }
            {templateType === 'grid_list' &&
            <Row>
                <Col md={12} lg={12}>
                    {checker.some(canManageFolder(slug)) &&
                        <Button class="noFill no-radius download" text="Create Folder"
                                onClick={() => setShowCreateFolder(true)}/>
                    }
                </Col>
            </Row>
            }

            {!!showEdit &&
            <Edit
                handleItem={updateFile}
                sendFiles={sendFiles}
                dialogTitle="Change Name"
                closeModal={closeModal}
                fileName={fileEdit.name}
                setNameShort={setNameShort}
                file={fileEdit}
                mode="edit"
                btnTxt="Save"
                templateType={templateType}
                accept={acceptedFiles(slug)}
            />
            }
            {!loaded &&
            <div className="media-loader-container">
                <Loader loaded={loaded} loadedClassName="loaded-content h-100"/>
            </div>
            }
            {!!showDelete &&
            <Dialog
                handleItem={deleteFile}
                dialogTitle="Delete File/Folder"
                closeModal={closeModal}
                fileName={fileEdit.name}
                btnTxt="Delete"
            />
            }

            {!!showCreateFolder &&
            <Dialog
                handleItem={createFolder}
                dialogTitle="Create Folder"
                closeModal={closeModal}
                mode="create_folder"
                btnTxt="Create Folder"
            />
            }

            <Row className="pb-3">
                <Col md={3}>
                    <div className="library-name" title={parent.name}>
                        {parent.name.length > 20 ? parent.name.substring(0, 20) + '...' : parent.name}
                    </div>
                </Col>
                <Col>
                    <div className="float-right">
                        <Filter
                            perPage={mediaPerPage}
                            showGridToggle={false}
                            view={view}
                            orderBy={orderBy}
                            setSearchName={(value) => setSearchName(value)}
                            {...{setOrderBy, setPerPage, setView}}
                        />
                    </div>
                </Col>
            </Row>
            <section className="library">
                <Row>
                    <Col md={6} lg={3} sm={12}>
                        {checker.some(canUpload(slug)) &&
                            <>
                            <Formik
                                validationSchema={UploadSchema}
                                onSubmit={(values, { resetForm }) => {
                                    sendFiles(values, null, resetForm);
                                }}
                                enableReinitialize>
                                {({ values, touched, errors, setFieldValue }) => (
                                    <Form>
                                        <Grid item>
                                            <Field name="partners">
                                                {({ field }) => (
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <Typography className="test">Choose Partners</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <PartnersAutocomplete setFieldValue={(value) => {
                                                                setFieldValue('partners', []);
                                                                value.forEach((item, index) => {
                                                                    setFieldValue(`partners.${index}`, item);
                                                                })

                                                            }}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="input-error">
                                                                {touched.partnership_managers && values.partnership_managers.length === 0 ? errors.partnership_managers : null}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Upload sendFiles={(files, getItems) => {
                                            setFieldValue('files', files);
                                            setGetItems(getItems);
                                        }} accept={acceptedFiles(slug)}/>
                                        <p className="input-error">
                                            {errors.files ? errors.files : null}
                                        </p>
                                        <div className="related-wrapper mb-4">
                                            {values.files &&
                                                values.files.map((item, key) => {
                                                    if (!item.main_asset) {
                                                        return (
                                                            <div className="d-flex justify-content-between pt-1 pb-1" key={key}>
                                                                <div title={item.name}>{setNameShort(item.name, 48)}.{item.ext}</div>
                                                                <div>
                                                                    <span onClick={() => {
                                                                        let files = values.files;
                                                                        delete files[key];
                                                                        setFieldValue('files', files);
                                                                    }}
                                                                          className="delete-wrapper">
                                                                        <MaterialIcon icon="delete"/>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <Button class="color-red noMarg create-buttons download fullWidth" text="SUBMIT" type="submit">Confirm upload</Button>
                                    </Form>
                                )}
                            </Formik>
                            </>
                        }
                    </Col>
                    <Col md={6} lg={9} sm={12}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Filename</th>
                                <th scope="col">Format</th>
                                <th scope="col">Size</th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody>
                            {folders &&
                            renderList(folders)
                            }
                            {files &&
                            renderList(files, 'files')
                            }
                            </tbody>
                        </table>
                        {meta.per_page < meta.total && (
                            <Pagination
                                prevPageText={
                                    <div className="prev-next">
                                        <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left"/>
                                    </div>}
                                nextPageText={
                                    <div className="prev-next">
                                        <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right"/>
                                    </div>}
                                hideFirstLastPages={true}
                                activePage={meta.current_page}
                                itemsCountPerPage={meta.per_page}
                                totalItemsCount={meta.total}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                linkClassPrev="prev-next"
                                linkClassNext="prev-next"
                                onChange={goToPage}
                            />
                        )}
                    </Col>
                </Row>
            </section>

        </div>
    );
}

const mapStateToProps = (state) => ({
    files: state.media.files,
    folders: state.media.folders,
    parent: state.media.parent,
    meta: state.media.meta,
    breadcrumbs: state.media.breadcrumbs,
    mediaPerPage: state.media.perPage,
    templateType: state.media.templateType,
    orderBy: state.media.orderBy,
    view: state.media.view,
    user: state.user.user,
    partners: state.partner.partners.data,
});

export default connect(mapStateToProps)(ManageItem);

