import React, { useState, useEffect, cloneElement } from 'react';
import { Button, Card, Divider, Grid, makeStyles, Tab, Tabs, Select, MenuItem, Typography } from "@material-ui/core";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPartners } from '../partnerActions';
import { destroyPartnersManagement, saveDataContentManagement, setActivePartnerData } from '../partnerActions';
import { Formik, Form } from 'formik';
import { EditorState, Modifier, ContentState, convertFromRaw } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { resetOverridesData } from './cmsInitialValues';

import RenameImageDialog from '~/Components/RenameImageDialog/RenameImageDialog';
import PreviewFile from "~/Components/PreviewFile";
import AdminMenu from "~/Admin/AdminMenu";
import PartnerInformation from "~/Admin/PartnerManagement/ContentManagement/PartnerInformation/PartnerInformation";
import Brand from "~/Admin/PartnerManagement/ContentManagement/Brand/Brand";
import MediaLibrary from "~/Admin/PartnerManagement/ContentManagement/MediaLibrary/MediaLibrary";
import RightsDocumentations from "~/Admin/PartnerManagement/ContentManagement/RightsDocumentations/RightsDocumentations";
import Requests from "~/Admin/PartnerManagement/ContentManagement/Requests/Requests";
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";

const useStyles = makeStyles((theme) => ({
    section: {
        //paddingLeft: 43,
    },
    titlePadding: {
        paddingTop: '2rem'
    },
    manageButton: {
        fontSize: 14,
        color: '#3e3f60',
        fontWeight: 600,
        border: 'solid 1px #af001e',
        backgroundColor: 'transparent',
        width: 195,
        borderRadius: 0
    },
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
    },
    activeTab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
        color: theme.primaryColor,
        fontWeight: 'bold'
    },
    tabLink: {
        width: '100%',
        borderBottom: '2px solid #af001e',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        width: '100%',
        borderBottom: 'solid 1px #d8dae6',
        textAlign: 'left',

        '&:hover': {
            fontWeight: 'bold',
            color: '#af001e'
        }
    },
    tabLinkInactive: {
        width: '100%',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItem: {
        width: '100%',
        color: '#af001e',
        fontWeight: 'bold',
        borderBottom: 'solid 1px #d8dae6',
        textAlign: 'left'
    },
    backButton: {
        width: 139,
        height: 48,
        padding: '12px 29px 11px',
        boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
        backgroundColor: '#ffffff',
        borderRadius: 0
    },
    cancelButton: {
        width: 200,
        height: 48,
        padding: '11px 18.5px 12px',
        backgroundColor: '#ecedf3',
        borderRadius: 0,
        fontWeight: 600,
        color: '#3e3f60'
    },
    saveChanges: {
        width: 200,
        height: 48,
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#af001e',
        color: 'white',
        borderRadius: 0,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#af001e78'
        }
    },
    partnerSelect: {
        color: '#999bb5',
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
}));

const ContentManagement = (props) => {
    const classes = useStyles();
    const {
        partners,
        history,
        match,
        location,
        active_partner_data,
        user
    } = props;
    const checker = new PermissionChecker(user);

    // CLEANUP
    useEffect(() => {
        return () => {
            props.dispatch(destroyPartnersManagement());
        }
    }, [])

    // FETCH PARTNERS DATA
    useEffect(() => {
        props.dispatch(getPartners());
        props.dispatch(setActivePartnerData(partner))
            .then((data) => {
                setFieldData(data);
            });
    }, [])

    const [activeTab, setActiveTab] = useState((location.state && location.state.tabId) ? Number(location.state.tabId) : 0);
    const [partner, setPartner] = useState(match.params.partnerId);
    const [subTab, setSubTab] = useState((location.state && location.state.subTab) ? location.state.subTab : "0");
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [renameImage, setRenameImage] = useState(null);
    const [renameSection, setRenameSection] = useState(null);
    const [renameRenameFileIndex, setRenameRenameFileIndex] = useState(null);
    const [partnerWorkshopsFiles, setPartnerWorkshopsFiles] = useState([]);
    const [rightProtectionFiles, setRightProtectionFiles] = useState([]);
    const [documentsFiles, setDocumentsFiles] = useState([]);
    const [brandFiles, setBrandFiles] = useState([]);
    const [talentAccessFiles, setTalentAccessFiles] = useState([]);
    const [approvalsFiles, setApprovalsFiles] = useState([]);
    const [approvalsSwitchState, setApprovalsSwitchState] = useState(false);
    const [talentAccessSwitchState, setTalentAccessSwitchState] = useState(false);
    const [talentAccessEditorState, setTalentAccessEditorState] = useState(null);
    const [approvalsEditorState, setApprovalsEditorState] = useState(null);
    const [brandEditorState, setBrandEditorState] = useState(null);
    const [partnerWorkshopsEditorState, setPartnerWorkshopsEditorState] = useState(null);
    const [rightProtectionEditorState, setRightProtectionEditorState] = useState(null);
    const [documentsEditorState, setDocumentsEditorState] = useState(null);
    const [british_lions_imagery, setBritish_lions_imagery] = useState('');
    const [british_lions_footage, setBritish_lions_footage] = useState('');
    const [springboks_imagery, setSpringboks_imagery] = useState('');
    const [springboks_footage, setSpringboks_footage] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [mediaLibraryEditorState, setMediaLibraryEditorState] = useState(null);

    // CHANGE TAB
    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // SET STATE TO FIELDS
    const setFieldData = (data) => {
        setPartnerWorkshopsFiles((data.cms_content?.lions_tour_partner_workshops && (data.cms_content?.lions_tour_partner_workshops.length > 0)) ? data.cms_content?.lions_tour_partner_workshops[0]?.partner_cms_content_files : []);
        setRightProtectionFiles(data.cms_content?.lions_tour_right_protection[0].partner_cms_content_files);
        setDocumentsFiles(data.cms_content?.lions_tour_documents[0].partner_cms_content_files);
        setBrandFiles(data.cms_content?.brand[0].partner_cms_content_files);
        setApprovalsSwitchState(data.cms_content?.requests_approvals[0].content.override_approvals === '1' ? true : false);
        setTalentAccessSwitchState(data.cms_content?.requests_talent_access[0].content.override_talent_access === '1' ? true : false);
        setTalentAccessFiles(data.cms_content?.requests_talent_access[0].partner_cms_content_files);
        setApprovalsFiles(data.cms_content?.requests_approvals[0].partner_cms_content_files);
        setBritish_lions_imagery(data.cms_content?.media_library[0].content.british_lions_imagery)
        setBritish_lions_footage(data.cms_content?.media_library[0].content.british_lions_footage)
        setSpringboks_imagery(data.cms_content?.media_library[0].content.springboks_imagery)
        setSpringboks_footage(data.cms_content?.media_library[0].content.springboks_footage)
        setTalentAccessEditorState(EditorState.createWithContent(convertFromHTML(data.cms_content?.requests_talent_access[0].content.html || '') ));
        setApprovalsEditorState(EditorState.createWithContent(convertFromHTML(data.cms_content?.requests_approvals[0].content.html || '')));
        setBrandEditorState(EditorState.createWithContent(convertFromHTML(data.cms_content?.brand[0].content.html || '')));
        setPartnerWorkshopsEditorState(EditorState.createWithContent(convertFromHTML((data.cms_content?.lions_tour_partner_workshops && (data.cms_content?.lions_tour_partner_workshops.length > 0)) ? data.cms_content?.lions_tour_partner_workshops[0]?.content.html : '')));
        setRightProtectionEditorState(EditorState.createWithContent(convertFromHTML(data.cms_content?.lions_tour_right_protection[0].content.html || '')));
        setDocumentsEditorState(EditorState.createWithContent(convertFromHTML(data.cms_content?.lions_tour_documents[0].content.html || '')));
        setMediaLibraryEditorState(EditorState.createWithContent(convertFromHTML(data.cms_content?.media_library[0].content.html || '')));
    }

    // HANDLE OPEN RENAME IMAGE DIALOG
    const handleOpenImageDialog = () => {
        setOpenRenameDialog(true);
    }

    // HANDLE CLOSE RENAME IMAGE DIALOG
    const handleCloseImageDialog = () => {
        setOpenRenameDialog(false);
    }

    // HANDLE SAVE RENAME IMAGE DIALOG
    const handleSaveImageDialog = (newName, setFieldValue) => {
        updateImageName(renameSection, renameRenameFileIndex, newName, setFieldValue);
        setOpenRenameDialog(false);
    }

    // RESET TO INITIAL VALUES
    const resetOverrides = () => {

        const payloadData = new FormData();

        // PARTNER ID
        payloadData.append('partner_id', partner);

        // LIONS TOUR 25
        payloadData.append('lions_tour[partner_workshops]', resetOverridesData.lions_tour.partner_workshops);

        resetOverridesData.lions_tour.partner_workshops_files.forEach(item => {
            if (item.id) {
                payloadData.append('lions_tour[file_ids][]', item.id);
            } else {
                payloadData.append('lions_tour[partner_workshops_files][]', item);
            }
        })

        payloadData.append('lions_tour[right_protection]', resetOverridesData.lions_tour.right_protection);

        resetOverridesData.lions_tour.right_protection_files.forEach(item => {
            if (item.id) {
                payloadData.append('lions_tour[file_ids][]', item.id);
            } else {
                payloadData.append('lions_tour[right_protection_files][]', item);
            }
        })

        payloadData.append('lions_tour[documents]', resetOverridesData.lions_tour.documents);

        resetOverridesData.lions_tour.documents_files.forEach(item => {
            if (item.id) {
                payloadData.append('lions_tour[file_ids][]', item.id);
            } else {
                payloadData.append('lions_tour[documents_files][]', item);
            }
        })

        // BRAND
        payloadData.append('brand[brand]', resetOverridesData.brand.brand);

        resetOverridesData.brand.brand_files.forEach(item => {
            if (item.id) {
                payloadData.append('brand[file_ids][]', item.id);
            } else {
                payloadData.append('brand[brand_files][]', item);
            }
        })

        // MEDIA LIBRARY
        payloadData.append('media_library[media_library]', resetOverridesData.media_library.media_library);
        payloadData.append('media_library[british_lions_imagery]', resetOverridesData.media_library.british_lions_imagery);
        payloadData.append('media_library[british_lions_footage]', resetOverridesData.media_library.british_lions_footage);
        payloadData.append('media_library[springboks_imagery]', resetOverridesData.media_library.springboks_imagery);
        payloadData.append('media_library[springboks_footage]', resetOverridesData.media_library.springboks_footage);


        // REQUESTS
        payloadData.append('requests[override_talent_access]', resetOverridesData.requests.override_talent_access ? "1" : "0");
        payloadData.append('requests[override_approvals]', resetOverridesData.requests.override_approvals ? "1" : "0");

        if (resetOverridesData.requests.override_talent_access) {
            payloadData.append('requests[talent_access]', resetOverridesData.requests.talent_access);

            resetOverridesData.requests.talent_access_files.forEach(item => {
                if (item.id) {
                    payloadData.append('requests[file_ids][]', item.id);
                } else {
                    payloadData.append('requests[talent_access_files][]', item);
                }
            })
        }

        if (resetOverridesData.requests.override_approvals) {
            payloadData.append('requests[approvals]', resetOverridesData.requests.approvals);

            resetOverridesData.requests.approvals_files.forEach(item => {
                if (item.id) {
                    payloadData.append('requests[file_ids][]', item.id);
                } else {
                    payloadData.append('requests[approvals_files][]', item);
                }
            })
        }

        props.dispatch(saveDataContentManagement(payloadData)).then(res => {
            props.dispatch(setActivePartnerData(partner));
        });
    }

    // CHANGE PARTNER HANDLER
    const hangleChangePartner = (event) => {
        setPartner(event.target.value);
        //setActiveTab(null);
        history.push('/admin/content-management/' + event.target.value);
        props.dispatch(setActivePartnerData(event.target.value))
            .then((data) => {
                setFieldData(data);
            });
    };

    // INITIAL VALUES
    let initialValues = {
        partner_id: partner,
        lions_tour: {
            partner_workshops: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content.lions_tour_partner_workshops && (active_partner_data.cms_content.lions_tour_partner_workshops.length > 0)) ? active_partner_data.cms_content?.lions_tour_partner_workshops[0]?.content?.html : '',
            partner_workshops_files: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content.lions_tour_partner_workshops && (active_partner_data.cms_content.lions_tour_partner_workshops.length > 0)) ? active_partner_data.cms_content?.lions_tour_partner_workshops[0]?.partner_cms_content_files : [],
            right_protection: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.lions_tour_right_protection[0]?.content?.html : '',
            right_protection_files: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.lions_tour_right_protection[0]?.partner_cms_content_files : [],
            documents: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.lions_tour_documents[0]?.content?.html : '',
            documents_files: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.lions_tour_documents[0]?.partner_cms_content_files : [],
        },
        brand: {
            brand: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.brand[0]?.content?.html : '',
            brand_files: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.brand[0]?.partner_cms_content_files : [],
        },
        media_library: {
            media_library: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content?.media_library) ? active_partner_data.cms_content?.media_library[0]?.content?.html : '',
            british_lions_imagery: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content?.media_library) ? active_partner_data.cms_content?.media_library[0]?.content.british_lions_imagery : '',
            british_lions_footage: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content?.media_library) ? active_partner_data.cms_content?.media_library[0]?.content.british_lions_footage : '',
            springboks_imagery: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content?.media_library) ? active_partner_data.cms_content?.media_library[0]?.content.springboks_imagery : '',
            springboks_footage: (active_partner_data && active_partner_data.cms_content && active_partner_data.cms_content?.media_library) ? active_partner_data.cms_content?.media_library[0]?.content.springboks_footage : ''
        },
        requests: {
            override_talent_access: (active_partner_data && active_partner_data.cms_content) && (active_partner_data.cms_content?.requests_talent_access[0]?.content?.override_talent_access === "1" ? true : false),
            talent_access: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.requests_talent_access[0]?.content?.html : '',
            talent_access_files: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.requests_talent_access[0]?.partner_cms_content_files : [],
            override_approvals: (active_partner_data && active_partner_data.cms_content) && (active_partner_data.cms_content?.requests_approvals[0]?.content?.override_approvals === "1" ? true : false),
            approvals: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.requests_approvals[0]?.content?.html : '',
            approvals_files: (active_partner_data && active_partner_data.cms_content) ? active_partner_data.cms_content?.requests_approvals[0]?.partner_cms_content_files : []
        }
    };

    // SAVE ALL FORM TO DB
    const handleSubmit = (submitData) => {

        const payloadData = new FormData();

        // PARTNER ID
        payloadData.append('partner_id', submitData.partner_id);

        // LIONS TOUR 25
        payloadData.append('lions_tour[partner_workshops]', submitData.lions_tour.partner_workshops);

        submitData.lions_tour.partner_workshops_files.forEach(item => {
            if (item.id) {
                payloadData.append(`lions_tour[file_ids][${item.id}]`, item.name);
            } else {
                payloadData.append('lions_tour[partner_workshops_files][]', item);
            }
        })

        payloadData.append('lions_tour[right_protection]', submitData.lions_tour.right_protection);

        submitData.lions_tour.right_protection_files.forEach(item => {
            if (item.id) {
                payloadData.append(`lions_tour[file_ids][${item.id}]`, item.name);
            } else {
                payloadData.append('lions_tour[right_protection_files][]', item);
            }
        })

        payloadData.append('lions_tour[documents]', submitData.lions_tour.documents);

        submitData.lions_tour.documents_files.forEach(item => {
            if (item.id) {
                payloadData.append(`lions_tour[file_ids][${item.id}]`, item.name);
            } else {
                payloadData.append('lions_tour[documents_files][]', item);
            }
        })

        // BRAND
        payloadData.append('brand[brand]', submitData.brand.brand);

        submitData.brand.brand_files.forEach(item => {
            if (item.id) {
                payloadData.append(`brand[file_ids][${item.id}]`, item.name);
            } else {
                payloadData.append('brand[brand_files][]', item);
            }
        })

        // MEDIA LIBRARY
        payloadData.append('media_library[media_library]', submitData.media_library.media_library);
        payloadData.append('media_library[british_lions_imagery]', submitData.media_library.british_lions_imagery);
        payloadData.append('media_library[british_lions_footage]', submitData.media_library.british_lions_footage);
        payloadData.append('media_library[springboks_imagery]', submitData.media_library.springboks_imagery);
        payloadData.append('media_library[springboks_footage]', submitData.media_library.springboks_footage);


        // REQUESTS
        payloadData.append('requests[override_talent_access]', submitData.requests.override_talent_access ? 1 : 0);
        payloadData.append('requests[override_approvals]', submitData.requests.override_approvals ? 1 : 0);

        if (submitData.requests.override_talent_access) {
            payloadData.append('requests[talent_access]', submitData.requests.talent_access);

            submitData.requests.talent_access_files.forEach(item => {
                if (item.id) {
                    payloadData.append(`requests[file_ids][${item.id}]`, item.name);
                } else {
                    payloadData.append('requests[talent_access_files][]', item);
                }
            })
        }

        if (submitData.requests.override_approvals) {
            payloadData.append('requests[approvals]', submitData.requests.approvals);

            submitData.requests.approvals_files.forEach(item => {
                if (item.id) {
                    payloadData.append(`requests[file_ids][${item.id}]`, item.name);
                } else {
                    payloadData.append('requests[approvals_files][]', item);
                }
            })
        }

        props.dispatch(saveDataContentManagement(payloadData))
            .then(res => {
                props.dispatch(setActivePartnerData(partner));
            });
    };

    // RESET FORM DATA
    const handleResetForm = () => {
        props.dispatch(setActivePartnerData(partner))
            .then((data) => {
                setFieldData(data);

                initialValues = {
                    partner_id: partner,
                    lions_tour: {
                        partner_workshops: (data && data.cms_content && data.cms_content?.lions_tour_partner_workshops && (data.cms_content?.lions_tour_partner_workshops.length > 0)) ? data.cms_content?.lions_tour_partner_workshops[0]?.content?.html : '',
                        partner_workshops_files: (data && data.cms_content && data.cms_content?.lions_tour_partner_workshops && (data.cms_content?.lions_tour_partner_workshops.length > 0)) ? data.cms_content?.lions_tour_partner_workshops[0]?.partner_cms_content_files : [],
                        right_protection: (data && data.cms_content) ? data.cms_content?.lions_tour_right_protection[0]?.content?.html : '',
                        right_protection_files: (data && data.cms_content) ? data.cms_content?.lions_tour_right_protection[0]?.partner_cms_content_files : [],
                        documents: (data && data.cms_content) ? data.cms_content?.lions_tour_documents[0]?.content?.html : '',
                        documents_files: (data && data.cms_content) ? data.cms_content?.lions_tour_documents[0]?.partner_cms_content_files : [],
                    },
                    brand: {
                        brand: (data && data.cms_content) ? data.cms_content?.brand[0]?.content?.html : '',
                        brand_files: (data && data.cms_content) ? data.cms_content?.brand[0]?.partner_cms_content_files : [],
                    },
                    media_library: {
                        media_library: (data && data.cms_content && data.cms_content?.media_library) ? data.cms_content?.media_library[0]?.content?.html : '',
                        british_lions_imagery: (data && data.cms_content && data.cms_content?.media_library) ? data.cms_content?.media_library[0]?.content.british_lions_imagery : '',
                        british_lions_footage: (data && data.cms_content && data.cms_content?.media_library) ? data.cms_content?.media_library[0]?.content.british_lions_footage : '',
                        springboks_imagery: (data && data.cms_content && data.cms_content?.media_library) ? data.cms_content?.media_library[0]?.content.springboks_imagery : '',
                        springboks_footage: (data && data.cms_content && data.cms_content?.media_library) ? data.cms_content?.media_library[0]?.content.springboks_footage : ''
                    },
                    requests: {
                        override_talent_access: (data && data.cms_content) && Number(data.cms_content?.requests_talent_access[0]?.content?.override_talent_access),
                        talent_access: (data && data.cms_content) ? data.cms_content?.requests_talent_access[0]?.content?.html : '',
                        talent_access_files: (data && data.cms_content) ? data.cms_content?.requests_talent_access[0]?.partner_cms_content_files : [],
                        override_approvals: (data && data.cms_content) && Number(data.cms_content?.requests_approvals[0]?.content?.override_approvals),
                        approvals: (data && data.cms_content) ? data.cms_content?.requests_approvals[0]?.content?.html : '',
                        approvals_files: (data && data.cms_content) ? data.cms_content?.requests_approvals[0]?.partner_cms_content_files : []
                    }
                }
                // setActiveTab(null);
            });
    }

    // IMAGES PREVIEW
    const previewUpload = (file) => {
        let name, path;

        if (file.original_name) {
            name = file.original_name;
            path = file.path;
        } else {
            name = file.name;
            path = URL.createObjectURL(file)
        }

        setPreviewFile({ name: name, path: path, media_type: (file.media_type.length > 0 ? file.media_type : (file.type ? file.type : file.ext)) });
        setShowPreview(true);
    }

    // UPDATE IMAGE NAME
    const updateImageName = (section, imageIndex, newName, setFieldValue) => {
        let newArray = [];
        let file = null;
        let ext = '';
        switch (section) {
            case "partner_workshops_files":
                newArray = JSON.parse(JSON.stringify(partnerWorkshopsFiles));
                file = newArray[imageIndex];
                ext = file.name.split('.').pop();
                if (file.constructor.name === 'File') {
                    newArray[imageIndex] = new File([file],
                        `${newName}.${ext}`,
                        {
                            type: file.type,
                            path: file.path
                        }
                    );
                }
                if (file.constructor.name === 'Object') {
                    newArray[imageIndex].name = newName;
                }
                setPartnerWorkshopsFiles(newArray);
                setFieldValue('lions_tour.partner_workshops_files', newArray);
                break;
            case "right_protection_files":
                newArray = JSON.parse(JSON.stringify(rightProtectionFiles));
                file = newArray[imageIndex];
                ext = file.name.split('.').pop();
                if (file.constructor.name === 'File') {
                    newArray[imageIndex] = new File([file],
                        `${newName}.${ext}`,
                        {
                            type: file.type,
                            path: file.path
                        }
                    );
                }
                if (file.constructor.name === 'Object') {
                    newArray[imageIndex].name = newName;
                }
                setRightProtectionFiles(newArray);
                setFieldValue('lions_tour.right_protection_files', newArray);
                break;
            case "documents_files":
                newArray = JSON.parse(JSON.stringify(documentsFiles));
                file = newArray[imageIndex];
                ext = file.name.split('.').pop();
                if (file.constructor.name === 'File') {
                    newArray[imageIndex] = new File(file,
                        `${newName}.${ext}`,
                        {
                            type: file.type,
                            path: file.path
                        }
                    );
                }
                if (file.constructor.name === 'Object') {
                    newArray[imageIndex].name = newName;
                }
                setDocumentsFiles(newArray);
                setFieldValue('lions_tour.documents_files', newArray);
                break;
            case "brand_files":
                newArray = JSON.parse(JSON.stringify(brandFiles));
                file = newArray[imageIndex];
                ext = file.name.split('.').pop();
                if (file.constructor.name === 'File') {
                    newArray[imageIndex] = new File([file],
                        `${newName}.${ext}`,
                        {
                            type: file.type,
                            path: file.path
                        }
                    );
                }
                if (file.constructor.name === 'Object') {
                    newArray[imageIndex].name = newName;
                }
                setBrandFiles(newArray);
                setFieldValue('brand.brand_files', newArray);
                break;
            case "talent_access_files":
                newArray = JSON.parse(JSON.stringify(talentAccessFiles));
                file = newArray[imageIndex];
                ext = file.name.split('.').pop();
                if (file.constructor.name === 'File') {
                    newArray[imageIndex] = new File([file],
                        `${newName}.${ext}`,
                        {
                            type: file.type,
                            path: file.path
                        }
                    );
                }
                if (file.constructor.name === 'Object') {
                    newArray[imageIndex].name = newName;
                }
                setTalentAccessFiles(newArray);
                setFieldValue('request.talent_access_files', newArray);
                break;
            case "approvals_files":
                newArray = JSON.parse(JSON.stringify(approvalsFiles));
                file = newArray[imageIndex];
                ext = file.name.split('.').pop();
                if (file.constructor.name === 'File') {
                    newArray[imageIndex] = new File([file],
                        `${newName}.${ext}`,
                        {
                            type: file.type,
                            path: file.path
                        }
                    );
                }
                if (file.constructor.name === 'Object') {
                    newArray[imageIndex].name = newName;
                }
                setApprovalsFiles(newArray);
                setFieldValue('request.approvals_files', newArray);
                break;
        }
        setRenameImage(null);
        setRenameSection(null);
        setRenameRenameFileIndex(null);
    }

    return (
        <section className={classes.section}>
            <Grid container>
                <Grid item>
                    <h1 className="content-title">Admin</h1>
                </Grid>
            </Grid>
            <Grid container>
                <AdminMenu />
            </Grid>
            <Divider />
            <div className={classes.titlePadding}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Button className={classes.backButton} onClick={() => history.goBack()}>
                                    <Grid container direction="row" alignItems="center" justify="space-between">
                                        <Grid item>
                                            <img style={{ width: 22, height: 22 }} src="/images/icons/SVG/Arrows/Left.svg" alt="back arrow" />
                                        </Grid>
                                        <Grid item>
                                            <span style={{ color: '#3e3f60', fontSize: 16, fontWeight: 600 }}>Back</span>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Formik
                            onSubmit={handleSubmit}
                            onReset={handleResetForm}
                            initialValues={initialValues}
                            enableReinitialize>
                            {({ values, setFieldValue, dirty, handleReset, initialValues }) => {
                                const menuItems = [
                                    checker.some([
                                        // admin content management Lions Tour 25
                                        "admin_view_ticket_hospitality",
                                        "admin_view_appearances",
                                        "admin_view_activation",
                                        "admin_view_partner_workshops",
                                        "admin_view_right_protection",
                                        "admin_view_documents",

                                        'edit_ticket_content',
                                        'upload_delete_ticket_files',
                                        'edit_appearances_content',
                                        'upload_delete_appearances_files',
                                        'edit_activation_content',
                                        'upload_delete_activation_files',
                                        'edit_partner_workshops_content',
                                        'upload_delete_partner_workshops_files',
                                        'edit_rights_protection_content',
                                        'upload_delete_rights_protection_files',
                                        'edit_documents_content',
                                        'upload_delete_documentations_files',
                                    ]) && {
                                        name: 'Partner information',
                                        component: <PartnerInformation
                                            {...{
                                                user,
                                                setFieldValue,
                                                setPartnerWorkshopsFiles,
                                                partnerWorkshopsFiles,
                                                setRightProtectionFiles,
                                                rightProtectionFiles,
                                                documentsFiles,
                                                setDocumentsFiles,
                                                previewUpload,
                                                partnerWorkshopsEditorState,
                                                setPartnerWorkshopsEditorState,
                                                rightProtectionEditorState,
                                                setRightProtectionEditorState,
                                                documentsEditorState,
                                                setDocumentsEditorState,
                                                resetOverrides,
                                                resetOverridesData,
                                                updateImageName,
                                                setRenameImage,
                                                handleOpenImageDialog,
                                                subTab,
                                                setRenameSection,
                                                setRenameRenameFileIndex
                                            }} />
                                    },
                                    checker.some([
                                        // admin content management Brand
                                        "admin_view_brand_guidelines",

                                        'edit_brand_guidelines_content',
                                        'upload_delete_brand_guidelines_files'
                                    ]) && {
                                        name: 'Brand & Documents',
                                        component: <Brand
                                            {...{
                                                setFieldValue,
                                                brandFiles,
                                                setBrandFiles,
                                                previewUpload,
                                                brandEditorState,
                                                setRenameImage,
                                                handleOpenImageDialog,
                                                setBrandEditorState,
                                                setRenameSection,
                                                setRenameRenameFileIndex
                                            }} />
                                    },
                                    checker.some([
                                        // admin content management Media Library
                                        "admin_view_logo_assets",
                                        "admin_view_brand_documents",
                                        "admin_view_imagery_footage",
                                        "admin_view_archive",

                                        'edit_archive_content',
                                        'edit_archive_links'
                                    ]) && {
                                        name: 'Media Library',
                                        component: <MediaLibrary
                                            {...{
                                                british_lions_imagery,
                                                setBritish_lions_imagery,
                                                british_lions_footage,
                                                setBritish_lions_footage,
                                                springboks_imagery,
                                                setSpringboks_imagery,
                                                springboks_footage,
                                                setSpringboks_footage,
                                                setFieldValue,
                                                mediaLibraryEditorState,
                                                setRenameImage,
                                                handleOpenImageDialog,
                                                setMediaLibraryEditorState,
                                                setRenameSection,
                                                setRenameRenameFileIndex
                                            }} />
                                    },
                                    checker.some([
                                        // admin content management Requests
                                        "admin_view_talent_access",

                                        'edit_talent_access',
                                        'upload_delete_talent_access_files',
                                        'edit_approvals',
                                        'upload_delete_approvals_files',
                                    ]) && {
                                        name: 'Requests',
                                        component: <Requests
                                            {...{
                                                setFieldValue,
                                                talentAccessFiles,
                                                setTalentAccessFiles,
                                                approvalsFiles,
                                                setApprovalsFiles,
                                                approvalsSwitchState,
                                                setApprovalsSwitchState,
                                                talentAccessSwitchState,
                                                setTalentAccessSwitchState,
                                                talentAccessEditorState,
                                                setTalentAccessEditorState,
                                                approvalsEditorState,
                                                setApprovalsEditorState,
                                                previewUpload,
                                                setRenameImage,
                                                handleOpenImageDialog,
                                                subTab,
                                                setRenameSection,
                                                setRenameRenameFileIndex,
                                                user
                                            }} />
                                    }
                                ];

                                return (
                                    <Form>
                                        <Grid item>
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item>
                                                    { partners && (
                                                        <Select 
                                                            defaultValue={match.params.partnerId}
                                                            value={partner}
                                                            className={classes.partnerSelect}
                                                            disableUnderline
                                                            onChange={hangleChangePartner}
                                                            displayEmpty>
                                                            {
                                                                partners && partners.map((partner, key) => (
                                                                    <MenuItem value={partner.id} key={key}>{partner.name}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>)
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row">
                                                        <Grid item xs sm md lg xl>
                                                            <Typography style={{ color: '#3e3f60', fontSize: 30, fontWeight: 500 }}>Content Management</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            {
                                                                dirty &&
                                                                <Grid container direction="row" justify="space-between" spacing={2}>
                                                                    <Grid item>
                                                                        <Button className={classes.cancelButton} onClick={handleReset}>CANCEL</Button>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Button className={classes.saveChanges} type="submit">SAVE CHANGES</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item xs sm md lg xl>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item md={2}>
                                                            <Grid container direction="column" spacing={2}>
                                                                <Grid item xs sm md lg xl>
                                                                    <Card className="request-types" style={{ borderRadius: 0 }}>
                                                                        <Tabs
                                                                            orientation="vertical"
                                                                            variant="scrollable"
                                                                            value={activeTab}
                                                                            onChange={handleChange}
                                                                            classes={{
                                                                                indicator: classes.tabs,
                                                                            }}>
                                                                            {
                                                                                menuItems
                                                                                    .filter(x => x !== false)
                                                                                    .map((item, index) => (
                                                                                        <Tab
                                                                                            key={index}
                                                                                            label={item.name}
                                                                                            style={{outline: 'none'}}
                                                                                            classes={{
                                                                                                wrapper: activeTab === index
                                                                                                    ? classes.activeTab
                                                                                                    : classes.tab
                                                                                            }}
                                                                                            className={
                                                                                                activeTab === index
                                                                                                    ? classes.tabItem
                                                                                                    : classes.tabItemBlack
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setActiveTab(index);
                                                                                            }}
                                                                                        />
                                                                                    ))
                                                                            }
                                                                        </Tabs>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item md={10}>
                                                            {
                                                                menuItems
                                                                    .filter(x => x != false)
                                                                    .map((item, index) => (
                                                                        activeTab === index &&
                                                                        cloneElement(item.component, { key: index })
                                                                    ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <RenameImageDialog
                                            handleCloseImageDialog={handleCloseImageDialog}
                                            handleSaveImageDialog={handleSaveImageDialog}
                                            imageName={renameImage}
                                            open={openRenameDialog}
                                            setFieldValue={setFieldValue}
                                            formValues={values}
                                        />
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </div>

            <PreviewFile
                previewFile={previewFile}
                showPreview={showPreview}
                setLoaded={() => {
                }}
                setShowPreview={() => setShowPreview(!showPreview)}
            />

        </section>
    );
};

const mapStateToProps = (state) => ({
    partners: state.partner.partners.data,
    active_partner_data: state.partner.active_partner_data,
    user: state.user.user
});

export default connect(mapStateToProps)(withRouter(ContentManagement));
