import React, { useEffect, useState, cloneElement } from 'react';
import { connect } from "react-redux";
import {
    makeStyles,
    Tabs,
    Tab,
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    Paper,
    Button,
    Divider,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Search } from '@material-ui/icons';
import { setSelectedUsers, filterUsers, fetchUserList, getRoles } from './userActions';
import { getPartners } from '../PartnerManagement/partnerActions';
import AdminMenu from "../AdminMenu";
import __ from 'lodash';
import RoleManagementTab from "./RoleManagementTab/RoleManagementTab";
import UsersFilter from "./UserManagementTab/UsersFilter/UsersFilter";
import RolesFilter from "./RoleManagementTab/RolesFilter/RolesFilter";
import FiltersIconWhite from "~/Components/Icons/FiltersIconWhite";
import FiltersIconBlack from "~/Components/Icons/FiltersIconBlack";
import UserManagementTab from './UserManagementTab/UserManagementTab';
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";

const TabPanel = (props) => {

    const { children, value, index, ...other } = props;

    return (
            value === index && (
                children
            )
    );
}

const UserManagement = (props) => {

    const useStyles = makeStyles((theme) => ({
        section: {
            // paddingLeft: 43,
        },
        indicator: {
            color: '#af001e'
        },
        tourimg: {
            maxWidth: '-webkit-fill-available'
        },
        table: {
            minWidth: 650,
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60
        },
        textPadding: {
            paddingTop: 20
        },
        arrowIcon: {
            width: 20,
            height: 20,
            filter: 'brightness(0) invert(1)'
        },
        editButtonIcon: {
            width: 20,
            height: 20
        },
        tabItem: {
            color: '#af001e',
            fontWeight: 'bold'
        },
        tabLink: {
            borderBottom: '2px solid #af001e',
        },
        tabItemBlack: {
            color: 'black'
        },
        newUserButton: {
            borderRadius: 0,
            backgroundColor: '#af001e',
            color: 'white',
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        tabs: {
            display: 'block',
            left: 0,
            backgroundColor: '#af001e'
        },
        tab: {
            alignItems: 'flex-start',
            textTransform: 'capitalize'
        },
        activeTab: {
            alignItems: 'flex-start',
            textTransform: 'capitalize',
            color: '#af001e',
            fontWeight: 'bold'
        },
        titlePadding: {
            paddingTop: '4rem'
        },
        test: {
            width: 500
        },
        createUserPadding: {
            padding: 20,
            minWidth: 400
        },
        closeButton: {
            cursor: 'pointer'
        },
        createUserButton: {
            backgroundColor: '#af001e',
            color: 'white',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: '#af001eA5',
            }
        },
        cancelUserButton: {
            backgroundColor: '#d8dae6',
            color: '#3e3f60',
            borderRadius: 0
        },
        subtitle: {
            color: '#af001e'
        },
        dividerLink: {
            backgroundColor: '#af001e'
        },
        dialog: {
            borderRadius: 0,
            maxWidth: 490
        },
        timelineItem: {
            '&&&:before': {
                display: 'none',
            },
        },
        activityLabel: {
            fontSize: '14px',
            fontWeight: '600',
            color: '#999bb5',
        },
        activityTime: {
            fontSize: '10px',
            color: '#3e3f60',
        },
        activityText: {
            fontSize: '14px',
            color: '#3e3f60',
        },
        paginationWrapper: {
            marginTop: '20px',
        },
        animatedWrapper: {
            transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            width: '100%',
        },
        tableRowCell: {
            fontSize: 20,
            color: '#3e3f60',
            fontFamily: 'AvenirNext-Regular'
        },
        tableRowHover: {
            '&:hover': {
                backgroundColor: '#ecedf3'
            }
        },
        tableHeadCell: {
            color: '#999bb5',
            fontSize: 16,
            fontWeight: 600,
            textTransform: 'uppercase'
        },
        filterIconsWrapper: {
            padding: '9px 10px',
            backgroundColor: 'white',
            cursor: 'pointer'
        },
        filterIconsWrapperActive: {
            padding: '9px 10px',
            backgroundColor: '#af001e',
            cursor: 'pointer'
        },
        maxWidth: {
            width: '100%',
            maxWidth: '100%'
        },
        typeTitle: {
            fontFamily: theme.demiBold,
            color: '#af001e', 
            textTransform: 'uppercase', 
            fontSize: 20, 
            fontWeight: 600,
            paddingBottom: '1em'
        }
    }));

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [createUser, setCreateUser] = useState(false);
    const [createRole, setCreateRole] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
    const [sidebarElement, setSidebarElement] = useState({});
    const [filterCriteria, setFilterCriteria] = useState('first_name');
    const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [input, setInput] = useState('');
    const checker = new PermissionChecker(props.user);

    // INITIAL LOAD
    useEffect(() => {
        props.dispatch(fetchUserList());
        props.dispatch(getPartners());
        props.dispatch(getRoles());
    }, [])

    // CLEANUP ON DESTORY
    useEffect(() => {
        return () => {
            props.dispatch(setSelectedUsers([]));
        }
    }, [])

    // TOGGLE CREATE USER
    const toggleCreateUser = () => {
        props.dispatch(setSelectedUsers([]));
        setSelectAllCheckbox(false);
        setCreateUser(!createUser);
    };

    const createItem = (num) => {
        if (num === 0) {
            toggleCreateUser();
        } else {
            setCreateRole(!createRole);
        }
    }

    // HANDLE TAB CHANGE
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue) {
            setFilterCriteria('id')
        } else {
            setFilterCriteria('first_name')
        }
        props.dispatch(setSelectedUsers([]));
        setSelectAllCheckbox(false)
    };

    // HANDLE CHANGE PER PAGE COUNT
    const handleChangePerPage = (value) => {
        setPerPage(value);
    }

    // INPUT FILTER
    const inputFilter = __.debounce((fInput = input, fFilterCriteria = filterCriteria, fperPage = perPage, fpage = page) => {
        if (value === 0) {
            props.dispatch(filterUsers(fInput, fFilterCriteria, fperPage, fpage));
        } else {
            props.dispatch(getRoles({ criteria: fFilterCriteria, input: fInput }, fperPage, fpage));
        }
    }, 700);

    // FILTER USERS

    // HANDLE INPUT
    const handleInput = (value) => {
        setInput(value);
    }

    const menuItems = [
        checker.some([
            "view_user_list"
        ]) && {
            name: 'Users',
            component:
                <UserManagementTab
                    {...{
                        createUser,
                        toggleCreateUser,
                        setSidebarElement,
                        setCreateUser,
                        selectAllCheckbox,
                        setSelectAllCheckbox,
                        showFilter
                    }}
                    classes={classes} />,
            createButton:
                <Permitted one="create_new_user">
                    <Button
                        fullWidth
                        className={classes.newUserButton}
                        onClick={() => createItem(0)}>
                        <img src="/images/icons/SVG/Add.svg"
                            style={{
                                width: 25,
                                paddingRight: 10,
                                fill: 'white',
                                filter: 'brightness(0) invert(1)'
                            }} />
                        New User
                    </Button>
                </Permitted>
        },
        checker.some([
            "view_role_list"
        ]) && {
            name: 'User Roles',
            component:
                <RoleManagementTab
                    {...{
                        createRole,
                        setCreateRole
                    }}
                    classes={classes} />,
            createButton:
                <Permitted one="create_new_role">
                    <Button
                        fullWidth
                        className={classes.newUserButton}
                        onClick={() => createItem(1)}>
                        <img src="/images/icons/SVG/Add.svg"
                            style={{
                                width: 25,
                                paddingRight: 10,
                                fill: 'white',
                                filter: 'brightness(0) invert(1)'
                            }} />
                        New role
                    </Button>
                </Permitted>
        }
    ];

    const createButtons = [

    ];

    return (
        <section className={classes.section}>
            <Grid container>
                <Grid item>
                    <h1 className="content-title">Admin</h1>
                </Grid>
            </Grid>
            <Grid container>
                <AdminMenu />
            </Grid>
            <Divider />
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.titlePadding + ' ' + classes.animatedWrapper}
                style={{
                    width: activeUser || createUser ? `calc(100% - ${sidebarElement.offsetWidth + 20}px)` : ''
                }}
                spacing={2}>
                <Grid item>
                    <Typography className={classes.typeTitle}>User Management</Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={4}>
                        {
                            showFilter &&
                            (<>
                                <Grid item>
                                    <TextField
                                        placeholder="Search"
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{
                                            style: {
                                                borderRadius: 0
                                            },
                                            endAdornment:
                                                <Search />
                                        }}
                                        onChange={(event) => {
                                            inputFilter(event.target.value);
                                            handleInput(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography style={{ fontSize: 14, color: '#999bb5', fontWeight: 600, textTransform: 'uppercase' }}>Sort:</Typography>
                                        </Grid>
                                        <Grid item>
                                            {
                                                value === 0
                                                    ?
                                                    <UsersFilter filterCriteria={filterCriteria} inputFilter={inputFilter}
                                                        setFilterCriteria={setFilterCriteria} />
                                                    :
                                                    <RolesFilter inputFilter={inputFilter}
                                                        filterCriteria={filterCriteria}
                                                        setFilterCriteria={setFilterCriteria} />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography style={{ fontSize: 14, color: '#999bb5', fontWeight: 600, textTransform: 'uppercase' }}>Show:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                value={perPage}
                                                style={{
                                                    boxShadow: '0 0 4px 0 rgba(62, 63, 96, 0.15)',
                                                    borderRadius: 0,
                                                    backgroundColor: 'white',
                                                    border: 'none'
                                                }}
                                                onChange={(event) => {
                                                    inputFilter(undefined, undefined, event.target.value);
                                                    handleChangePerPage(event.target.value);
                                                }}
                                                placeholder="Name"
                                                variant="outlined"
                                                margin="dense">
                                                <MenuItem value={5} selected>5</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>)
                        }
                        <Grid item>
                            {
                                !showFilter && (
                                    <div
                                        className={classes.filterIconsWrapper}
                                        onClick={() => setShowFilter(true)}>
                                        <FiltersIconBlack />
                                    </div>
                                )
                            }
                            {
                                showFilter && (
                                    <div
                                        className={classes.filterIconsWrapperActive}
                                        onClick={() => setShowFilter(false)}>
                                        <FiltersIconWhite />
                                    </div>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Paper className={classes.paperBorder} style={{ borderRadius: 0 }}>
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    classes={{
                                        indicator: classes.tabs,
                                    }}>
                                    {
                                        checker.some([
                                            "view_user_list"
                                        ]) &&
                                        <Tab
                                            className={classes.maxWidth}
                                            style={{outline: 'none'}}
                                            label={`Users (${props.users ? props.meta.total : '0'})`}
                                            classes={{
                                                wrapper: value === 0
                                                    ? classes.activeTab
                                                    : classes.tab
                                            }} />
                                    }
                                    {
                                        checker.some([
                                            "view_role_list"
                                        ]) &&
                                        <Tab
                                            className={classes.maxWidth}
                                            label={`User Roles (${props.rolesMeta ? props.rolesMeta.total : '0'})`}
                                            style={{outline: 'none'}}
                                            classes={{
                                                wrapper: value === 1
                                                    ? classes.activeTab
                                                    : classes.tab
                                            }} />
                                    }
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid item>
                            {
                                menuItems
                                    .filter(x => x != false)
                                    .map((item, index) => (
                                        value === index &&
                                        cloneElement(item.createButton, { key: index })
                                    ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                    {
                        menuItems
                            .filter(x => x != false)
                            .map((item, index) => (
                                <TabPanel key={index} value={value} index={index}>
                                    {item.component}
                                </TabPanel>
                            ))
                    }
                </Grid>
            </Grid>
        </section>
    );
}

const mapStateToProps = (state) => ({
    users: state.user.users.data,
    meta: state.user.users.meta,
    roles: state.user.roles.data,
    rolesMeta: state.user.roles.meta,
    activityLogs: state.user.activityLogs.data,
    lastActivity: state.user.activityLogs.last_activity,
    partners: state.partner.partners.data,
    selectedUsers: state.user.selectedUsers,
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(UserManagement));
