import React, {useEffect, useState} from 'react';
import CheckboxTree from 'react-checkbox-tree';
import './PermissionsTree.scss';
import RadioActive from "~/Components/Icons/RadioActive";
import RadioInactive from "~/Components/Icons/RadioInactive";
import RadioSemiActive from "~/Components/Icons/RadioSemiActive";

const PermissionsTree = (props) => {
    const {
        children,
        hasPermissions,
        setSelectedPermissions,
        expandedAll,
        title,
        disabled
    } = props;

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState(expandedAll || []);

    useEffect(() => {
        if (hasPermissions.length > 0) {
            setChecked(hasPermissions);
        }

    }, [hasPermissions]);

    const handleChecked = (checked) => {
        setChecked(checked);
        setSelectedPermissions(checked);
    }

    if (!children) {
        return (
            <div>...</div>
        )
    }

    return (
        <div className="permission-tree">
            <div className="permissions-title">{title || "Permissions"}</div>
            <CheckboxTree
                checked={checked}
                expanded={expanded}
                icons={{
                    check: <RadioActive />,
                    uncheck: <RadioInactive />,
                    halfCheck: <RadioSemiActive/>,
                    expandClose: "SHOW ALL",
                    expandOpen: "SHOW ALL",
                }}
                disabled={disabled}
                nodes={children}
                onCheck={handleChecked}
                onExpand={setExpanded}
            />
        </div>

    );
}

export default PermissionsTree;
