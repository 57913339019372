import React from "react";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Formik } from "formik";
import { TextField, Typography } from "@material-ui/core";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Dropzone from "react-dropzone";
import Button from "../Components/Button";
import './brandForm.scss';
import CalendarIcon from "~/Components/Icons/CalendarIcon";
import PreviewFile from "~/Components/PreviewFile";

export const BrandForm = ({ onSubmit, onBack, defaultRequest, submitButtonLabel, ...values }) => {

    const [types, setTypes] = React.useState([]);
    const [otherId, setOtherId] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [showPreview, setShowPreview] = React.useState(false);
    const [previewFile, setPreviewFile] = React.useState(null);

    submitButtonLabel = submitButtonLabel || 'SUBMIT';

    React.useEffect(() => {
        axios
            .get("/api/request-type")
            .then(res => {
                setTypes(res.data.requestTypes.data);
                setOtherId(parseInt(res.data.other));
            });
    }, []);

    let defaultFiles = [];

    if (defaultRequest && defaultRequest.files && defaultRequest.files.length) {
        defaultFiles = defaultRequest.files;
    }

    const initialValues = {
        request_type_id: defaultRequest.request_type_id || "",
        subtype: defaultRequest.subtype || "partner",
        name: defaultRequest.name || "",
        location: defaultRequest.location || "",
        date: defaultRequest.date || "",
        deadline: defaultRequest.deadline ? moment(defaultRequest.deadline, 'DD-MM-YYYY').format('YYYY-MM-DD') : "",
        description: defaultRequest.description || "",
        files: defaultFiles,
        other_request_type: defaultRequest.other_request_type || ""
    };

    const RequestSchema = Yup.object().shape({
        name: Yup.string().max(255).required("Required"),
        request_type_id: Yup.string().required("Required"),
        description: Yup.string()
            .max(300)
            .required("Required"),
        location: Yup.string().max(255).required("Required"),
        date: Yup.string().max(255).required("Required"),
        deadline: Yup.string().max(255).required("Required"),
        files: Yup.array()
            .min(1)
            .required("Required"),
        other_request_type: Yup.string().when("request_type_id", {
            is: (value) => {
                return value == otherId;
            },
            then: Yup.string().max(255).required("Required"),
            otherwise: Yup.string().max(255)
        })
    });

    const handleBack = () => {
        if (onBack) {
            onBack();
        }
    };

    const setStartDate = () => {
        let currentDate = new Date();

        currentDate.setDate(currentDate.getDate() + 2);

        if (currentDate.getDay() === 0) {
            currentDate.setDate(currentDate.getDate() + 1);
        } else if(currentDate.getDay() === 6) {
            currentDate.setDate(currentDate.getDate() + 2);
        }

        return currentDate.getFullYear() + '-' + Number(currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    }

    const handleSubmit = (values) => {

        setLoading(true);

        const payload = new FormData();

        for (const field in values) {
            if (field === "deadline") {
                payload.append(field, moment(values[field]).format('YYYY-MM-DD'));
            } else {
                payload.append(field, values[field]);
            }
        }

        for (let i = 0; i < values.files.length; i++) {
            if (values.files[i].id) {
                payload.append(values.files[i].id, '1');
            } else {
                payload.append("files[]", values.files[i]);
            }
        }

        if (onSubmit) {

            onSubmit(payload).finally(() => {
                setLoading(false);
            });
        }
    };

    const previewUpload = (file) => {
        let name, path;

        if (file.original_name) {
            name = file.original_name;
            path = file.download;
        } else {
            name = file.name;
            path = URL.createObjectURL(file)
        }

        setPreviewFile({ name: name, path: path, media_type: file.media_type });
        setShowPreview(true);
    }

    const downloadFile = (file) => {
        let link = document.createElement('a');
        link.href = file.url;
        link.href = URL.createObjectURL(file);

        link.download = file.name;
        document.body.appendChild(link);

        link.click();
    }

    const checkFileType = (file) => {

        let imageRegex = new RegExp(/image\//);
        let videoRegex = new RegExp(/video\//);

        if (imageRegex.test(file.type)) {
            return 'photos'
        }
        if (videoRegex.test(file.type)) {
            return 'videos';
        }

        return false;
    }
    const printName = (file) => {
        let name = file.name || file.original_name;

        if (name.length > 34) {
            return name.substr(0, 35) + '...';
        }

        return name;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            margin: 0
        },
        paper: {
            position: 'relative',
            marginLeft: '21px',
            minWidth: '322px',
            width: 'auto',
            marginTop: '9px',
            display: 'inline-block',
        },
        calendarButton: {
            height: '32px',
            padding: '4px 11px',
            position: 'relative',
            right: '-10px',
            '&:focus': {
                outline: 'none'
            }
        },
        calendarIcon: {
            position: 'relative',
            top: '-2px',
        },
        input: {
            border: '1px solid #c6c9db',
            padding: '8px 5px',
            borderRadius: 0,
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        },
        dateError: {
            border: '1px solid #F44336',
            padding: '8px 15px',
            borderRadius: 0,

            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        },
    }));

    const classes = useStyles();

    return (
        <div className="approvals-form">
            <Formik
                validationSchema={RequestSchema}
                onSubmit={handleSubmit}
                initialValues={initialValues}
            >
                {({ setFieldValue, submitForm, values, touched, errors }) => (
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            {
                                !defaultRequest.id &&
                                <h3>SUBMIT YOUR CREATIVE</h3>
                            }
                        </Grid>
                        <Grid item>
                            <Grid container spacing={4} className="form-wrapper">
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="left-form">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                lineHeight: 1.57,
                                                color: '#af001e'
                                            }}>Project type</Typography>
                                        </Grid>
                                        <Grid item>

                                            <div className="field-wrapper">

                                                <label>Reference name</label>

                                                <Field
                                                    name="name"
                                                    type="text">
                                                    {({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            value={values.name || ''}
                                                            placeholder="Reference Name"
                                                            margin="normal"
                                                            InputProps={{ className: classes.input }}
                                                            classes={{ root: classes.root }}
                                                            fullWidth
                                                            variant="outlined"
                                                            error={touched.name && !!errors.name}
                                                            helperText={touched.name && !!errors.name ? errors.name : ''}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="field-wrapper">

                                                <label>Creative Type</label>

                                                {types.length > 0 ? (
                                                    <Field
                                                        name="request_type_id"
                                                        as="select"
                                                        type="select">
                                                        {({ field }) => (
                                                            <DropdownButton
                                                                title={types.find((item) => item.id === values.request_type_id)?.name || 'Select creative type'}
                                                                className={touched.request_type_id && !!errors.request_type_id ? 'error' : ''}>
                                                                {types.map(type => (
                                                                    <Dropdown.Item
                                                                        key={type.id}
                                                                        active={values.request_type_id === type.id}
                                                                        onClick={e => {
                                                                            setFieldValue("request_type_id", type.id);
                                                                        }}
                                                                        value={type}>
                                                                        {type.name}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </DropdownButton>
                                                        )}
                                                    </Field>
                                                ) : (
                                                    <p>Loading request types...</p>
                                                )}

                                                {touched.creative_type && !!errors.creative_type &&
                                                    <p className="input-error">{errors.creative_type}</p>
                                                }

                                            </div>

                                            {values.request_type_id === otherId && (
                                                <div className="field-wrapper">

                                                    <label>Other request type:</label>

                                                    <Field
                                                        name="other_request_type"
                                                        type="text">
                                                        {({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                value={values.other_request_type || ''}
                                                                placeholder="Other request type"
                                                                margin="normal"
                                                                InputProps={{ className: classes.input }}
                                                                classes={{ root: classes.root }}
                                                                fullWidth
                                                                variant="outlined"
                                                                error={touched.other_request_type && !!errors.other_request_type}
                                                                helperText={touched.other_request_type && !!errors.other_request_type ? errors.other_request_type : ''}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            )}

                                            <div className="field-wrapper">
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Grid container direction="column">
                                                            <Grid item>
                                                                <label>Approved Deadline</label>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography style={{ color: '#999bb5', fontSize: 10 }}>Please select a date at least two days from today</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container justify="space-around">
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="inline"
                                                                autoOk
                                                                inputVariant="standard"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                minDate={setStartDate()}
                                                                value={values.deadline ? values.deadline : null}
                                                                onChange={(date) => {
                                                                    setFieldValue('deadline', moment(date).format(moment.HTML5_FMT.DATE))
                                                                }}
                                                                placeholder="DD/MM/YYYY"
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                    className: classes.calendarButton,
                                                                    // size: 'small'
                                                                }}
                                                                fullWidth
                                                                keyboardIcon={<CalendarIcon width="12px" height="22px"
                                                                    className={classes.calendarIcon} />}
                                                                classes={{ root: classes.root }}
                                                                InputProps={{
                                                                    className: touched.approval_deadline && !!errors.approval_deadline
                                                                        ?
                                                                        classes.dateError
                                                                        :
                                                                        classes.input

                                                                }}
                                                                PopoverProps={{
                                                                    classes: {
                                                                        paper: classes.paper
                                                                    }
                                                                }}

                                                            />
                                                        </Grid>
                                                        {touched.deadline && !!errors.deadline &&
                                                            <p className="input-error">{errors.deadline}</p>
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="right-form">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                lineHeight: 1.57,
                                                color: '#af001e'
                                            }}>Project Description</Typography>
                                        </Grid>
                                        <Grid item>

                                            <div className="field-wrapper">

                                                <label>Where will it be used?</label>

                                                <Field
                                                    name="location"
                                                    type="text">
                                                    {({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            value={values.location || ''}
                                                            placeholder="Social media, billboard, tv adverts, etc."
                                                            margin="normal"
                                                            InputProps={{ className: classes.input }}
                                                            classes={{ root: classes.root }}
                                                            fullWidth
                                                            variant="outlined"
                                                            error={touched.location && !!errors.location}
                                                            helperText={touched.location && !!errors.location ? errors.location : ''}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="field-wrapper">
                                                <label htmlFor="date">When will it start to be used?</label>
                                                <Field
                                                    name="date"
                                                    type="text"
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Lorem, ipsum dolor, atec dego, etc."
                                                            margin="normal"
                                                            fullWidth
                                                            value={values.date || ''}
                                                            InputProps={{ className: classes.input }}
                                                            classes={{ root: classes.root }}
                                                            variant="outlined"
                                                            error={touched.date && !!errors.date}
                                                            helperText={touched.date && !!errors.date ? errors.date : ''}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="field-wrapper">
                                                <label htmlFor="description">Project description</label>
                                                <Field
                                                    name="description"
                                                    type="text"
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Please tell us more about your project."
                                                            margin="normal"
                                                            fullWidth
                                                            multiline={true}
                                                            rows={6}
                                                            value={values.description || ''}
                                                            InputProps={{ className: classes.input }}
                                                            classes={{ root: classes.root }}
                                                            variant="outlined"
                                                            error={touched.description && !!errors.description}
                                                            helperText={touched.description && !!errors.description ? errors.description : ''}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="supporting-docu">
                                                <Typography style={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    lineHeight: 1.57,
                                                    color: '#af001e'
                                                }}>Upload Creative:</Typography>

                                                <div className="fileupload">
                                                    <div className="upload-icon">
                                                        <img src="/images/icons/SVG/Upload-red.svg" alt="upload" />
                                                    </div>
                                                    <h4 className="upload-title">
                                                        Drag & Drop
</h4>
                                                    <div className="form-group">
                                                        <Dropzone
                                                            accept=".ai,.esp,.otf,.eps,.jpg,.jpeg,.png,.gif,.pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx,.mp3,.m4a,.wav,.mp3,.m4a,.wav,.mp4, .m4v,.mpg,.wmv,.mov,.avi,.swf,.zip,.epub"
                                                            minSize={0}
                                                            onDrop={x => {
                                                                let newFiles = [...values.files, ...x];
                                                                setFieldValue("files", newFiles);
                                                            }}
                                                        >
                                                            {({
                                                                getRootProps,
                                                                getInputProps,
                                                                isDragActive,
                                                                isDragReject
                                                            }) => {
                                                                return (
                                                                    <div {...getRootProps()} className="dropZone">
                                                                        <input {...getInputProps()} />
                                                                        {!isDragActive &&
                                                                            <span>Or you can <u>browse</u> your files</span>}
                                                                        {isDragActive &&
                                                                            !isDragReject &&
                                                                            "Drop it like it's hot!"}
                                                                        {isDragReject &&
                                                                            "File type not accepted, sorry!"}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                                <div className="accepted-formats">
                                                    Accepted Formats: ai, esp, otf, eps, jpg, jpeg, png, gif, pdf, doc, docx, ppt,  pptx, xls,  xlsx, mp3, m4a, wav, mp3, m4a, wav, mp4,  m4v, mpg, wmv, mov, avi, swf, zip, epub
</div>
                                                <div className="input-error">
                                                    {touched.files && errors.files}
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 text-left pt-4">
                                                    <span className="uploads-title">YOUR UPLOADS</span>
                                                </div>
                                            </div>

                                            <div className="row uploads-wrapper">
                                                <div className="col-md-12">
                                                    <ul className="uploads">
                                                        {values.files.length > 0
                                                            ? values.files.map((uploaded_image, index) => {
                                                                let fileType = checkFileType(uploaded_image);
                                                                if (!uploaded_image.media_type) {
                                                                    uploaded_image['media_type'] = fileType;
                                                                }
                                                                return (
                                                                    <li key={index} className="upload row">
                                                                        <div className="upload__title col-9">
                                                                            <span className="icons">
                                                                                <img src="/images/icons/SVG/File Type/File.svg"
                                                                                    alt="file" />
                                                                            </span>
                                                                            <span className="filename" title={uploaded_image.name || uploaded_image.original_name}>{printName(uploaded_image)}</span>{" "}
                                                                        </div>

                                                                        {(uploaded_image.name || uploaded_image.original_name) && (
                                                                            <div className="right-buttons col-3 text-right">
                                                                                <div className="row">
                                                                                    <div className="col">{uploaded_image.media_type &&
                                                                                        <img src="/images/icons/SVG/View.svg" alt="file"
                                                                                            onClick={() => previewUpload(uploaded_image)} />
                                                                                    }
                                                                                    </div>
                                                                                    <div className="col"
                                                                                    >
                                                                                        {uploaded_image.original_name
                                                                                            ?
                                                                                            <a href={uploaded_image.download} download>
                                                                                                <img src="/images/icons/SVG/Download.svg"
                                                                                                    alt="file" />
                                                                                            </a>
                                                                                            :
                                                                                            <img src="/images/icons/SVG/Download.svg"
                                                                                                alt="file"
                                                                                                onClick={() => downloadFile(uploaded_image)} />
                                                                                        }

                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <img src="/images/icons/SVG/Close.svg"
                                                                                            alt="close icon" onClick={() =>
                                                                                                setFieldValue(
                                                                                                    "files",
                                                                                                    values.files.filter(f => {
                                                                                                        let nameProperty = f.id ? 'original_name' : 'name';
                                                                                                        return f[nameProperty] !== uploaded_image[nameProperty]
                                                                                                    }
                                                                                                    )
                                                                                                )
                                                                                            } />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </li>
                                                                )
                                                            })
                                                            : ""}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className="submit-wrapper">
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                {onSubmit && (
                                    <Grid item className="pr-0">
                                        <Button
                                            text={loading ? 'Working...' : submitButtonLabel}
                                            disabled={loading}
                                            class="color-red"
                                            onClick={submitForm}>
                                        </Button>
                                    </Grid>
                                )}

                                {onBack && (
                                    <Grid item className="pr-0">
                                        <Button
                                            text="BACK"
                                            disabled={loading}
                                            class="btn-grey"
                                            onClick={handleBack}>
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </Grid>
                )}
            </Formik>
            <PreviewFile
                previewFile={previewFile}
                showPreview={showPreview}
                setLoaded={() => {
                }}
                setShowPreview={() => setShowPreview(!showPreview)}
            />
        </div >
    );
};

export default BrandForm;
