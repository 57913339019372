import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import { setActionPermissions } from "../roleActions";
import Button from "~/Components/Button";
import PermissionsTree from "~/Components/PermissionsTree/PermissionsTree";
import Dialog from "@material-ui/core/Dialog";
import './ExpandedPermissions.scss';


const useStyles = makeStyles((theme) => ({
    closeButton: {
        cursor: 'pointer'
    },
    subtitle: {
        color: theme.primaryColor,
    },
    dividerLink: {
        backgroundColor: theme.primaryColor,
    },
}));
const ExpandedPermissions = (props) => {
    const classes = useStyles();
    const {
        createRole,
        activeRole,
        expandPermissions,
        actionPermissions,
        dispatch,
        allPermissions,
        read_only,
        roleType
    } = props;

    const [selectedPermissions, setSelectedPermissions] = useState([]);

    useEffect(() => {
        if (activeRole) {
            setSelectedPermissions(activeRole.permissions);
        }
    }, [activeRole]);

    useEffect(() => {
        if (allPermissions) {
            dispatch({ type: 'roles/SAVE_ROLE_PERMISSIONS', payload: allPermissions });
        }
    }, [allPermissions]);

    const handleSelectPermissions = (checked) => {
        setSelectedPermissions(checked);

        if (createRole) {
            dispatch({ type: 'roles/SAVE_ROLE_PERMISSIONS', payload: checked });
        }
    }

    const handleSaveChanges = () => {
        dispatch(setActionPermissions(activeRole.id, selectedPermissions))
            .then(() => {
                setSelectedPermissions([]);
                dispatch({ type: 'roles/EXPAND_ALL_PERMISSIONS', payload: { dialog: false } });
            });
    }

    const handleCancel = () => {
        setSelectedPermissions([]);
        dispatch({ type: 'roles/EXPAND_ALL_PERMISSIONS', payload: { dialog: false } });
        dispatch({ type: 'roles/SET_READ_ONLY', payload: false });
    }

    return (
        <Dialog
            disableBackdropClick
            BackdropProps={{ invisible: true }}
            anchor="right"
            fullWidth
            open={expandPermissions}
            onClose={() => { }}
            maxWidth={'xs'}
            PaperProps={{ style: { borderRadius: 0, padding: 20 } }}
            classes={{ paper: classes.createUserPadding }}>
            <DialogContent>
                <Grid container direction="column" spacing={3} className="expand-all">
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <Close onClick={() => dispatch({ type: 'roles/EXPAND_ALL_PERMISSIONS', payload: { dialog: false } })} className={classes.closeButton} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className="role-name">{activeRole && activeRole.display_name}</Typography>
                        <Typography className="main-title">Action Permissions</Typography>
                    </Grid>
                    {
                        activeRole
                            ?
                            <Grid item>
                                {
                                    actionPermissions && actionPermissions.length > 0 &&
                                    <PermissionsTree
                                        title={"Action Permissions"}
                                        children={actionPermissions}
                                        expandedAll={allPermissions}
                                        hasPermissions={activeRole && activeRole.permissions ? activeRole.permissions : []}
                                        setSelectedPermissions={(checked) => handleSelectPermissions(checked)}
                                    />
                                }
                            </Grid>
                            :
                            <Grid item>
                                {
                                    actionPermissions && actionPermissions.length > 0 &&
                                    <PermissionsTree
                                        title={"Action Permissions"}
                                        children={actionPermissions}
                                        expandedAll={allPermissions}
                                        hasPermissions={allPermissions}
                                        setSelectedPermissions={(checked) => handleSelectPermissions(checked)}
                                    />
                                }
                            </Grid>
                    }
                    {
                        activeRole &&
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                {
                                    !read_only &&
                                    <Grid item>
                                        <Button class="noMarg noRadius permissions color-red" onClick={handleSaveChanges}
                                            text="Save Changes" />
                                    </Grid>
                                }
                                <Grid item>
                                    <Button class="noMarg noRadius permissions btn-grey" onClick={handleCancel}
                                        text="Cancel" />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>

    );
}

const mapStateToProps = ((state) => ({
    actionPermissions: state.role.actionPermissions.data,
    expandPermissions: state.role.expandPermissions,
    allPermissions: state.role.allPermissions,
    read_only: state.role.read_only,
    roleType: state.role.roleType
}));

export default connect(mapStateToProps)(ExpandedPermissions);
