import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import './Matched.scss';
import {Check} from "@material-ui/icons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Button from "~/Components/Button";
import {withRouter} from "react-router";

const Matched = ({members, memberIds, partner, request, history, accessType}) => {

    const goToRequest = () => {
        history.push('/admin/talent-request-management/' + request)
    }
    return (
        <Grid container direction="column" className="group-allocation matched">
            <Grid item className="partner">
                {partner?.name}
            </Grid>
            <Grid item>
                <Typography className="player-title">Requested {accessType === 'players' ? 'Players' : 'Staff'}</Typography>
            </Grid>
            <Grid item className="player-names">
                <Grid container direction="column">
                    {members.map((member, key) => {
                        if (typeof memberIds[member.id] === 'undefined') {
                            return null;
                        }
                        return (
                            <Grid item key={key}>
                                <Grid container direction="row">
                                    <Grid item className="checkmark-wrap">
                                        <ToggleButton
                                            value="check"
                                            style={{
                                                backgroundColor: memberIds[member.id] ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                borderRadius: '50%',
                                                padding: 0,
                                                border: 'none',
                                                height: 18,
                                                width: 18,
                                            }}>
                                            <Check style={{
                                                color: 'white',
                                                width: 14,
                                                height: 14
                                            }}/>
                                        </ToggleButton>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" className="player-wrap">
                                            <Grid item>
                                                <Typography className="player-name">{member.name}</Typography>
                                            </Grid>
                                            {accessType === 'players' &&
                                                <Grid item className="tier">Tier {key + 1} | {member.nationality}</Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item className="view-request">
                    <Button onClick={goToRequest} class="noMarg view-request-btn no-radius btn-grey"
                            text="View Request"/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(Matched);
