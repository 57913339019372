import React from 'react';
import LibraryApi from "~/services/api/library";

const DownloadIcon = ({file, setLoaded, disableDownload}) => {
    return (
        <div onClick={() => {
            if (file && !disableDownload) {
                LibraryApi.downloadImage(file, setLoaded);
            }
        }}>
            <span className="download-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
                    <path fill="#3E3F60" fillRule="evenodd" d="M2.75 14.422v4.125h16.5v-4.125h1.375v5.5H1.375v-5.5H2.75zm8.938-11l-.001 9.743 3.202-3.202.972.973L11 15.797l-4.861-4.861.972-.973 3.2 3.201.002-9.742h1.374z"/>
                </svg>
            </span>
        </div>
    );
};

export default DownloadIcon;
