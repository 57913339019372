import React, {useEffect, useState} from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
import Permitted from "~/Permissions/Permitted";

const HeaderInnerNavigation = ({items, basePath, navigationCurrent}) => {

    const getPermission = (slug) => {

        switch(slug) {
            case 'brand-guidelines':
                return ['view_brand_guidelines'];
            case 'logo-assets':
                return ['view_logo_assets', 'admin_view_logo_assets'];
            case 'brand-documents':
                return ['view_brand_documents', 'admin_view_brand_documents'];
            case 'imagery-footage':
                return ['view_imagery_footage', 'admin_view_imagery_footage'];
            case 'archive':
                return ['view_archive'];
        }
        return false;
    }
    if (basePath && items[0]) {
        return  (
            <Container className="navInnerContainer d-none d-md-block col-lg-11 col-md-10">
                    <ul className="navInner">
                        {items.map(function (item, index) {
                            return (
                                <Permitted some={getPermission(item.slug)} key={index}>
                                    <li key={index}
                                        className={`navInner__item d-none d-md-block ${navigationCurrent == item.id ? ' active' : ' '}`}
                                    >
                                        <Link to={item.id ? `${basePath}/${item.id}` : basePath}>{item.title}</Link>
                                    </li>
                                </Permitted>
                            );
                        })}
                    </ul>

            </Container>
        );
    }

}

export default HeaderInnerNavigation;
