import update from 'immutability-helper';

const initialState = {
    manualDialogShow: false,
    deleteMemberExecute: false,
    manualMode: false,
    deleteMode: false,
    memberToDelete: null,
};

const talentAccessAdminRequestReducer = (state = initialState, action) =>
{
    if (action.type === 'talent_access_admin/SET_MANUAL_DIALOG_SHOW') {
        return update(state, {
            manualDialogShow: {$set: action.payload},
        });
    }

    if (action.type === 'talent_access_admin/SET_MEMBER_DELETE_EXECUTE') {
        return update(state, {
            deleteMemberExecute: {$set: action.payload},
        });
    }

    if (action.type === 'talent_access_admin/SET_MEMBER_TO_DELETE') {
        return update(state, {
            memberToDelete: {$set: action.payload},
        });
    }

    if (action.type === 'talent_access_admin/SET_MANUAL_MODE') {
        return update(state, {
            manualMode: {$set: action.payload},
        });
    }

    if (action.type === 'talent_access_admin/SET_DELETE_MODE') {
        return update(state, {
            deleteMode: {$set: action.payload},
        });
    }

    return state;
};

export default talentAccessAdminRequestReducer;
