import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Card,
    List,
    ListItem,
    Grid,
    Typography,
    Tabs,
    Tab,
    Box
} from '@material-ui/core';
import {
    getKeyDeadlines,
    getAllNoticeboard
} from '../dashboardActions';
//import PermissionChecker from "~/Permissions/PermissionChecker";
import Moment from "react-moment";
import { withRouter } from 'react-router-dom';
//import moment from "moment";
import Permitted from '~/Permissions/Permitted';

const NoticesDeadlines = (props) => {

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            value === index && (
                <Box>
                    {children}
                </Box>
            )
        );
    }

    const useStyles = makeStyles((theme) => ({
        title: {
            fontSize: 26,
        },
        sideCard: {
            borderRadius: 0,
            maxHeight: '75vh'
        },
        dateText: {
            fontSize: 12,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#c6c9db',
        },
        cardItemText: {
            color: '#3e3f60',
            fontSize: 12
        },
        noteCreate: {
            color: '#af001e',
            cursor: 'pointer'
        },
        navIcons: {
            width: '1em'
        },
        activeTab: {
            backgroundColor: '#ffffff',
            color: '#3e3f60'
        },
        inactiveTab: {
            backgroundColor: '#ecedf3',
            color: '#3e3f60'
        },
        dateNum: {
            fontSize: 24,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#c6c9db',
        }
    }));

    const classes = useStyles();
    const [tabIndex, settabIndex] = useState(0);

    useEffect(() => {
        props.dispatch(getKeyDeadlines());
        props.dispatch(getAllNoticeboard());
    }, []);

    const handleTabChange = (event, newValue) => {
        settabIndex(newValue);
    };

   
    const menuItems = [
        {
            name: 'Noticeboard',
            component: <Card xs={12} sm={12} md={4} className={classes.sideCard}>
                <Grid container alignItems="center" justify="space-between" style={{ paddingRight: 13, paddingLeft: 13 }}>
                    <Grid item>
                        <Typography className={classes.title}>Noticeboard</Typography>
                    </Grid>
                    <Permitted one="create_notes">
                        <Grid item>
                            <Typography className={classes.noteCreate} onClick={() => props.setOpenAddNoteDialog(true)}>Create note</Typography>
                        </Grid>
                    </Permitted>
                </Grid>

                <List style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                    {
                        props.noticeboard && props.noticeboard.length > 0
                            ? props.noticeboard.map(item => {
                                return (
                                    <Fragment key={item.id}>
                                        <ListItem dense>
                                            <Grid container direction="column">
                                                <Grid item md={12} style={{ color: '#3e3f60' }}>
                                                    <Typography style={{ whiteSpace: 'pre-wrap' }}>{item.notice}</Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Grid container justify="space-between" alignItems="center" alignContent="center">
                                                        <Grid item>
                                                            <Moment style={{ color: '#c6c9db' }} fromNow ago className="moment-date">{item.created_at}</Moment>
                                                        </Grid>
                                                        <Permitted one="delete_existing_note">
                                                            <Grid item >
                                                                <Grid container alignItems="center" style={{ cursor: 'pointer' }} onClick={() => props.handleOpenDeleteDialog(item.id)}>
                                                                    <Typography style={{ fontSize: 14 }}><img className={classes.navIcons} src="/images/icons/SVG/Delete.svg" />  Delete</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Permitted>
                                                        <Permitted one="edit_existing_note">
                                                            <Grid item style={{ cursor: 'pointer' }} onClick={() => props.handleOpenEditDialog(item)}>
                                                                <Typography style={{ fontSize: 14 }}><img className={classes.navIcons} src="/images/icons/SVG/Edit.svg" /> Edit</Typography>
                                                            </Grid>
                                                        </Permitted>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <hr />
                                    </Fragment>
                                );
                            })
                            : <Typography>No notices yet!</Typography>
                    }
                </List>
            </Card>,
        },
    ];

    return (
        <Grid item xs={12} sm={12} md={4}>
            <Grid container direction="column">
                <div className={classes.root}>
                    <Tabs
                        component={Box}
                        boxShadow={1}
                        value={tabIndex}
                        variant="fullWidth"
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                height: 0,
                            }
                        }}>
                        {
                            menuItems
                                .filter(x => x != false)
                                .map((item, index) => (
                                    <Tab
                                        key={index}
                                        label={item.name}
                                        className={
                                            tabIndex === index
                                                ? classes.activeTab
                                                : classes.inactiveTab
                                        } />

                                ))
                        }
                    </Tabs>
                    {
                        menuItems
                            .filter(x => x != false)
                            .map((item, index) => (
                                <TabPanel
                                    key={index}
                                    value={tabIndex}
                                    index={index}>
                                    {item.component}
                                </TabPanel>

                            ))
                    }
                </div>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    noticeboard: state.homepage.noticeboard.data,
    keyDeadlines: state.user.keyDeadlines.data
});

export default connect(mapStateToProps)(withRouter(NoticesDeadlines));
/* Implement this menuItems if want to show KEY DEADLINES TAB


    let checker = new PermissionChecker(props.user);
        
    const passedDate = (item) => {
        let deadlineDate = moment(item.deadline_date)
        let now = moment();

        return now.diff(deadlineDate, 'days') > 0;
    }

    const menuItems = [
        {
            name: 'Noticeboard',
            component: <Card xs={12} sm={12} md={4} className={classes.sideCard}>
                <Grid container alignItems="center" justify="space-between" style={{ paddingRight: 13, paddingLeft: 13 }}>
                    <Grid item>
                        <Typography className={classes.title}>Noticeboard</Typography>
                    </Grid>
                    <Permitted one="create_notes">
                        <Grid item>
                            <Typography className={classes.noteCreate} onClick={() => props.setOpenAddNoteDialog(true)}>Create note</Typography>
                        </Grid>
                    </Permitted>
                </Grid>

                <List style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                    {
                        props.noticeboard && props.noticeboard.length > 0
                            ? props.noticeboard.map(item => {
                                return (
                                    <Fragment key={item.id}>
                                        <ListItem dense>
                                            <Grid container direction="column">
                                                <Grid item md={12} style={{ color: '#3e3f60' }}>
                                                    <Typography style={{ whiteSpace: 'pre-wrap' }}>{item.notice}</Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Grid container justify="space-between" alignItems="center" alignContent="center">
                                                        <Grid item>
                                                            <Moment style={{ color: '#c6c9db' }} fromNow ago className="moment-date">{item.created_at}</Moment>
                                                        </Grid>
                                                        <Permitted one="delete_existing_note">
                                                            <Grid item >
                                                                <Grid container alignItems="center" style={{ cursor: 'pointer' }} onClick={() => props.handleOpenDeleteDialog(item.id)}>
                                                                    <Typography style={{ fontSize: 14 }}><img className={classes.navIcons} src="/images/icons/SVG/Delete.svg" />  Delete</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Permitted>
                                                        <Permitted one="edit_existing_note">
                                                            <Grid item style={{ cursor: 'pointer' }} onClick={() => props.handleOpenEditDialog(item)}>
                                                                <Typography style={{ fontSize: 14 }}><img className={classes.navIcons} src="/images/icons/SVG/Edit.svg" /> Edit</Typography>
                                                            </Grid>
                                                        </Permitted>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <hr />
                                    </Fragment>
                                );
                            })
                            : <Typography>No notices yet!</Typography>
                    }
                </List>
            </Card>,
        },
        checker.some(["admin_view_key_deadlines", 'view_key_deadlines']) && {
            name: 'Key Deadlines',
            component: <Card xs={12} sm={12} md={4} className={classes.sideCard}>
                <Grid container alignItems="center" justify="space-between" style={{ paddingRight: 13, paddingLeft: 13 }}>
                    <Grid item>
                        <Typography className={classes.title}>Key Deadlines</Typography>
                    </Grid>
                    <Permitted one="create_new_deadlines">
                        <Grid item>
                            <Typography className={classes.noteCreate} onClick={() => props.setOpenAddKeyDeadlineDialog(true)}>Create date</Typography>
                        </Grid>
                    </Permitted>
                </Grid>

                <List style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                    {
                        props.keyDeadlines && props.keyDeadlines.length > 0
                            ? props.keyDeadlines.map(item => {
                                if (passedDate(item)) {
                                    return null;
                                }
                                return (
                                    <Fragment key={item.id}>
                                        <ListItem dense>
                                            <Grid container direction="column">
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item md>
                                                        <Grid container direction="column">
                                                            <Grid item>
                                                                <Typography className={classes.dateNum}>
                                                                    <Moment format="DD" className="moment-date">{item.deadline_date}</Moment>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.dateText}>
                                                                    <Moment format="MMM" className="moment-date">{item.deadline_date}</Moment>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={10}>
                                                        <Typography style={{ fontSize: 16 }}>{item.title}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid item>
                                                        <Typography className={classes.cardItemText}>{item.description}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Grid container justify="space-between" alignItems="center" alignContent="center">
                                                        <Permitted one="delete_existing_deadlines">
                                                            <Grid item>
                                                                <Grid container alignItems="center" style={{ cursor: 'pointer' }} onClick={() => props.handleOpenDeleteDeadlineDialog(item.id)}>
                                                                    <Typography style={{ fontSize: 14 }}><img className={classes.navIcons} src="/images/icons/SVG/Delete.svg" />  Delete</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Permitted>

                                                        <Permitted one="edit_existing_deadlines">
                                                            <Grid item style={{ cursor: 'pointer' }} onClick={() => props.handleOpenEditDeadlineDialog(item)}>
                                                                <Typography style={{ fontSize: 14 }}><img className={classes.navIcons} src="/images/icons/SVG/Edit.svg" /> Edit</Typography>
                                                            </Grid>
                                                        </Permitted>
                                                        <Grid item>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <hr />
                                    </Fragment>
                                );
                            })

                            : <ListItem dense>
                                <Typography>No Key Deadlines!</Typography>
                            </ListItem>
                    }
                    <Permitted one="view_key_deadlines">
                        <ListItem dense>
                            <Typography className={classes.noteCreate} onClick={() => props.history.push('/lions-series-2021/key-deadlines')}>View all deadlines</Typography>
                        </ListItem>
                    </Permitted>
                </List>
            </Card>,
        },
];
*/