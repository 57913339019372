import React from 'react';

const PdfIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
                <path fill="#3E3F60" fillRule="evenodd" d="M16.503.672l5.247 5.366v18.634h-18v-3h1.5v1.5h15V7.421L15 7.422v-5.25H5.25v12h-1.5V.672h12.753zm-3.753 14.25v6H2.25v-6h10.5zm-8.117 1.49H3.75v3.01h.6v-1.07h.408c.365 0 .644-.084.838-.25.193-.166.29-.406.29-.72 0-.17-.034-.32-.103-.454-.069-.132-.166-.24-.29-.324-.103-.067-.221-.116-.356-.146-.1-.023-.22-.037-.359-.043l-.145-.003zm2.525 0h-.82v3.01h.94c.368 0 .67-.069.907-.206.215-.122.378-.294.49-.516.112-.223.168-.484.168-.785 0-.301-.055-.561-.166-.78-.11-.22-.271-.393-.482-.521-.119-.07-.256-.122-.413-.154-.125-.025-.284-.04-.475-.046l-.149-.002zm4.008 0H9.318v3.01h.6v-1.234h.902l.11-.475H9.919v-.825h1.104l.144-.476zm-3.931.476c.333 0 .573.09.72.268.077.09.136.202.177.336.042.135.063.27.063.408 0 .196-.019.358-.055.488-.037.13-.099.236-.185.319-.093.09-.196.152-.31.187-.113.035-.245.053-.396.053h-.312v-2.06h.298zm-2.549 0c.189 0 .33.04.425.12.094.08.141.208.141.384 0 .176-.048.304-.144.386-.096.082-.241.122-.436.122H4.35v-1.012h.336zM16.5 2.815v3.107l3.037-.001L16.5 2.815z"/>
            </svg>
        </>
    );
};

export default PdfIcon;
