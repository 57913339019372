import React from "react";
import BrandApprovalRequestsList from "./BrandApprovalRequestsList";
import './index.scss';
import {
    Grid,
} from "@material-ui/core";

const BrandApprovalRequests = (props) => {

    return (

        <Grid container className="brand-approval brand-approval-requests sticky-footer brand-approval-requests-title">
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <h1>My Requests</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <BrandApprovalRequestsList adminMode={false} />
            </Grid>
        </Grid>
    );
};

export default BrandApprovalRequests;