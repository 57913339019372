import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "~/Components/Button";
import TextField from "@material-ui/core/TextField";
import { Field, Formik } from "formik";
import PlayersList from "~/Admin/InventoryManagement/MembersForm/PlayersList";
import StaffList from "~/Admin/InventoryManagement/MembersForm/StaffList";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import { connect } from "react-redux";
import InventoryUpload from "~/Admin/InventoryManagement/InventoryUpload/InventoryUpload";
import { getTeams } from "~/Admin/InventoryManagement/inventoryActions";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-loader";
import { withRouter } from "react-router";
import Permitted from '~/Permissions/Permitted';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyle = makeStyles((theme) => ({
    inputBorder: {
        borderRadius: 0,
    }
}));

const MembersForm = (props) => {

    const classes = useStyle();

    const {
        dispatch,
        activeTeam,
        players,
        staff,
        history,
    } = props;

    const [automatic, setAutomatic] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [manualType, setManualType] = useState('players');
    const [loaded, setLoaded] = useState(true);

    const setStaff = (staff) => {
        dispatch({ type: 'inventory/SET_STAFF', payload: staff });
    }

    const setPlayers = (players) => {
        dispatch({ type: 'inventory/SET_PLAYERS', payload: players });
    }

    const setTeam = (name) => {
        let team = { ...activeTeam, name };
        dispatch({ type: 'inventory/SET_TEAM', payload: team });
    }

    const fileDropped = (data) => {
        let playersTmp = [];
        let staffTmp = [];

        data.forEach(row => {
            if (row.errors.length > 0) {
                return;
            }
            if (!row.data.name) {
                return;
            }
            if (!row.data.category) {
                row.data.captain = row.data.captain || false;
                playersTmp = [...playersTmp, row.data];
            } else {
                staffTmp = [...staffTmp, row.data];
            }
        });

        setPlayers(playersTmp)
        setStaff(staffTmp)
    }
    const handleTeamSubmit = () => {
        setLoaded(false);
        if (activeTeam.id) {
            axios.put('/api/teams/' + activeTeam.id, { name: activeTeam.name, players, staff }).then(handleResponse).catch(errorHandler);
        } else {
            axios.post('/api/teams', { name: activeTeam.name, players, staff }).then(handleResponse).catch(errorHandler);
        }

    }

    const errorHandler = (errors) => {
        if (errors.response && errors.response.data) {
            dispatch({ type: 'inventory/SET_ERRORS', payload: errors.response.data.errors });
        }
        setLoaded(true);
    }

    const handleResponse = (resp) => {
        dispatch({ type: 'inventory/SET_TEAM', payload: resp.data.data });
        setLoaded(true);
        history.push({ pathname: '/admin/inventory-management/assets-form', state: { createInventory: true } })
    }

    const cancelTeamCreation = () => {
        history.push('/admin/inventory-management');
    }

    const resetForm = () => {
        dispatch({ type: 'inventory/SET_TEAM', payload: { name: '' } });
        dispatch({ type: 'inventory/SET_PLAYERS', payload: [] });
        dispatch({ type: 'inventory/SET_STAFF', payload: [] });
    }

    const deleteTeam = () => {
        if (activeTeam.id) {
            axios.delete('/api/teams/' + activeTeam.id).then(() => {
                props.dispatch(getTeams());
                resetForm();
            });
        } else {
            resetForm();
        }

    }

    const disabled = players.length === 0 || staff.length === 0 || !activeTeam.name;

    return (
        <div>
            {!loaded &&
                <div className="media-loader-container">
                    <Loader loaded={loaded} loadedClassName="loaded-content h-100" />
                </div>
            }
            <div className="members-form">
                <Grid container direction="row" justify="space-between">
                    <Grid item>
                        <Typography className="step-header">Team Management</Typography>
                    </Grid>
                    <Grid item className="btn-delete-wrapper">
                        <Permitted one="delete_talent_access_existing_assets">
                            <Button
                                class={"fullWidth" + (activeTeam.hasRequest ? " disabled" : " delete")}
                                text="DELETE TEAM"
                                disabled={activeTeam.hasRequest}
                                onClick={deleteTeam} />
                        </Permitted>
                    </Grid>
                </Grid>
                <Formik>
                    <Grid container direction="row" justify="space-between">
                        <Grid item md={12}>
                            <Grid container direction="column">
                                <Grid item md={5}>
                                    <label className="action-title">Team Name</label>

                                    <Field
                                        name="team_name"
                                        type="text">
                                        {({ field }) => (
                                            <TextField
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputBorder
                                                    }
                                                }}
                                                {...field}
                                                fullWidth
                                                value={activeTeam.name}
                                                onChange={(event) => {
                                                    setTeam(event.target.value)
                                                }}
                                                placeholder="Team Name"
                                                variant="outlined"
                                                error={false}
                                                helperText={''}
                                            />
                                        )}

                                    </Field>
                                </Grid>

                            </Grid>

                            <Grid container direction="column">
                                <Grid item className="action-title">New Members</Grid>
                                <Grid item className="new-members-note">Add new members automatically by uploading a csv
                                    file. Turn off the below switch to manually add new members through a form</Grid>
                                <Grid item>
                                    <Switch
                                        color="primary"
                                        checked={automatic}
                                        onChange={() => setAutomatic(!automatic)}
                                        name="automatic"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    {automatic ? 'Automatic upload' : 'Manual Entry'}
                                </Grid>
                                {!automatic &&
                                    <Grid item>

                                        <ToggleButtonGroup
                                            value={manualType}
                                            exclusive
                                            onChange={(event, active) => {
                                                if (active) {
                                                    setManualType(active)
                                                }
                                            }}
                                            aria-label="text alignment"
                                        >
                                            <ToggleButton value="players" aria-label="left aligned">
                                                Players
                                        </ToggleButton>
                                            <ToggleButton value="staff" aria-label="centered">
                                                Staff
                                        </ToggleButton>
                                        </ToggleButtonGroup>

                                    </Grid>
                                }
                            </Grid>
                            {automatic &&
                                <InventoryUpload fileDropped={fileDropped} step={1} />
                            }
                            {automatic &&
                                <Grid container>
                                    <Grid item md={12}>
                                        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}
                                            aria-label="simple tabs example">
                                            <Tab label="Player List" {...a11yProps(0)} />
                                            <Tab label="Staff List" {...a11yProps(1)} />
                                        </Tabs>
                                        <TabPanel value={activeTab} index={0}>
                                            {players.length > 0 &&
                                                <PlayersList players={players} setPlayers={setPlayers} classes={classes} />
                                            }

                                        </TabPanel>
                                        <TabPanel value={activeTab} index={1}>
                                            {staff.length > 0 &&
                                                <StaffList staff={staff} setStaff={setStaff} classes={classes} />
                                            }
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            }
                            {!automatic &&
                                <Grid container>
                                    <Grid item md={12}>
                                        {manualType === 'players'
                                            ?
                                            <PlayersList players={players} setPlayers={setPlayers} manual={!automatic} classes={classes} />
                                            :
                                            <StaffList staff={staff} setStaff={setStaff} manual={!automatic} classes={classes} />
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Formik>
            </div>
            <Grid container justify="flex-end">
                <Grid item className="footer-wrapper">
                    <Button class="footer-buttons cancel" text="CANCEL"
                        onClick={() => {
                            cancelTeamCreation();
                        }} />
                    <Permitted one="create_talent_access_assets">
                        {
                            !activeTeam.id &&
                            <Button
                                class={"footer-buttons next" + (disabled ? ' disabled' : '')}
                                disabled={disabled}
                                text="NEXT STEP" onClick={handleTeamSubmit} />
                        }
                    </Permitted>
                    <Permitted one="edit_existing_talent_access_assets">
                        {
                            activeTeam.id &&
                            <Button
                                class={"footer-buttons next" + (disabled ? ' disabled' : '')}
                                disabled={disabled}
                                text="SAVE CHANGES" onClick={handleTeamSubmit} />
                        }
                    </Permitted>
                </Grid>
            </Grid>

        </div>

    );
};

const mapStateToProps = (state => ({
    players: state.inventory.players,
    staff: state.inventory.staff,
    activeTeam: state.inventory.team,
}));

export default connect(mapStateToProps)(withRouter(MembersForm));
