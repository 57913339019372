import React from 'react';
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';


const DeleteUserDialog = (props) => {

    const useStyles = makeStyles((theme) => ({
        createUserButton: {
            width: 165,
            fontSize: 16,
            fontWeight: 600,
            backgroundColor: '#af001e',
            color: 'white',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: '#af001eA5',
            }
        },
    }));

    const classes = useStyles();
    const {
        openDeleteDialog,
        handleCloseDeleteDialog,
        handleDeleteUser
    } = props;

    return (
        <Dialog
            PaperProps={{
                style: {
                    maxWidth: 490,
                    borderRadius: 0,
                    padding: 20
                }
            }}
            open={openDeleteDialog}
            disableBackdropClick
            keepMounted
            onClose={handleCloseDeleteDialog}>
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography style={{ color: '#3e3f60', fontSize: 30, fontWeight: 600 }}>Delete User?</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ color: '#3e3f60', fontSize: 18 }}>
                            Are you sure you want to delete this user? This action will not be reversible.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="flex-start">
                            <Grid item>
                                <Button fullWidth size="large" className={classes.createUserButton} onClick={() => handleDeleteUser(true)} color="primary">
                                    Yes, delete
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button fullWidth style={{ color: '#3e3f60', fontSize: 16, fontWeight: 600, width: 168 }} size="large" onClick={() => handleDeleteUser(false)} color="primary">
                                    No, cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteUserDialog;
