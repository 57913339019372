import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSinceLastVisited } from '../dashboardActions';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Card,
    Typography,
    Chip,
    Grid
} from '@material-ui/core';
import Moment from "react-moment";
import { Link } from 'react-router-dom';

const SinceLastVisit = (props) => {

    const useStyles = makeStyles((theme) => ({
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 26,
        },
        section: {
            paddingLeft: 43,
            width: '100%'
        },
        cardCol: {
            padding: 5,
        },
        tableCard: {
            marginTop: 10,
        },
        borderColorGray: {
            borderTop: '3px solid #f3f4f8',
            height: 120,
            borderRadius: 0,
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorOrange: {
            borderTop: '3px solid #ebae45',
            height: 120,
            borderRadius: 0,
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorGreen: {
            borderTop: '3px solid #44de6f',
            height: 120,
            borderRadius: 0,
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorRed: {
            borderTop: '3px solid #de5644',
            height: 120,
            borderRadius: 0,
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        borderColorBlack: {
            borderTop: '3px solid #3e3f60',
            minHeight: 120,
            borderRadius: 0,
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        sideCard: {
            width: '100%',
            padding: 30,
            borderRadius: 0,
            maxHeight: '75vh'
        },
        sideCardItem: {
            borderBottom: '1px solid #f3f4f8',
            padding: '1em 0 1em 0',
            display: 'flex',
            alignItems: 'center',
        },
        cardItemDate: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        dateNum: {
            fontSize: 24,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#c6c9db',
        },
        dateText: {
            fontSize: 16,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#c6c9db',
        },
        cardItemText: {
            color: '#3e3f60',
        },
        bottomCard: {
            padding: 30,
            borderRadius: 0
        },
        pendingChip: {
            backgroundColor: '#d8dae64D',
            color: '#3e3f60',
            textTransform: 'capitalize',
        },
        awaitChip: {
            backgroundColor: '#ebae451A',
            color: '#ebae45',
            textTransform: 'capitalize',
        },
        approvedChip: {
            backgroundColor: '#44de6f1A',
            color: '#44de6f',
            textTransform: 'capitalize',
        },
        rejectedChip: {
            backgroundColor: '#af001e1A',
            color: '#af001e',
            textTransform: 'capitalize',
        },
        tableHeaderBorder: {
            borderBottom: '2px solid #af001e',
        },
        right: {
            textAlign: 'right',
        },
        center: {
            alignItems: 'baseline',
        },
        viewDeadlines: {
            color: '#af001e',
            '&:hover': {
                color: '#af001e',
                textDecoration: 'none',
                fontWeight: 'bold'
            }
        },
        tableCell: {
            padding: '16px 16px 16px 0px'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // padding: '50px 160px 50px 0px',
            borderRadius: 0,
            // height: '70vh',
            // width: '70vw'
        },
        modalColPadding: {
            margin: '10em 1em 0 1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'inherit',
            position: 'relative'
        },
        rowHeight: {
            height: 'inherit',
            flexDirection: 'column'
        },
        closeButtonRow: {
            marginRight: 40,
            textAlign: 'right'
        },
        closeButton: {
            position: 'absolute',
            zIndex: 99999,
            fill: '#c6c9db',
            width: 40,
            height: 40,
            cursor: 'pointer'
        },
        dontShow: {
            alignItems: 'center'
        },
        nextButton: {
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            color: 'white',
            borderRadius: 0,
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        noteModal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        notePaper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // padding: '50px 160px 50px 0px',
            borderRadius: 0,
            padding: 40
        },
        noticeAlign: {
            alignItems: 'flex-end',
            textAlign: 'end'
        },
        noteCreate: {
            color: '#af001e',
            cursor: 'pointer'
        },
        dotsColor: {
            backgroundColor: '#af001e',
        },
        secondaryCheckboxColor: {
            color: '#af001e',
        },
        createNoteCard: {
            minWidth: 400,
            minHeight: 150,
            borderRadius: 0
        },
        navIcons: {
            width: '1em'
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        props.dispatch(getSinceLastVisited());
    }, []);

    return (
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Card className={classes.bottomCard}>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography className={classes.title}>Since you last visited</Typography>
                    </Grid>
                    <Grid item className={classes.right}>
                        <Typography component={Link} className={classes.viewDeadlines} to="my-requests">View all</Typography>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHeaderBorder}>
                                <TableCell className={classes.tableCell}>Request Name</TableCell>
                                <TableCell className={classes.tableCell} align="left">Request Type</TableCell>
                                <TableCell className={classes.tableCell} align="left">Submission Date</TableCell>
                                <TableCell className={classes.tableCell} align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.since_last_visit ? props.since_last_visit.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell className={classes.tableCell} component="th" scope="row" style={{ textTransform: 'capitalize' }}>{item.name}</TableCell>
                                    <TableCell className={classes.tableCell} align="left" style={{ textTransform: 'capitalize' }}>{item.type}</TableCell>
                                    <TableCell className={classes.tableCell} align="left"><Moment format="DD/MM/YYYY" className="moment-date">{item.created_at}</Moment></TableCell>
                                    <TableCell className={classes.tableCell} align="left"><Chip label={item.status === 'change' ? 'Awaiting Changes' : item.status} className={[item.status === 'pending' && classes.pendingChip, item.status === 'change' && classes.awaitChip, item.status === 'approved' && classes.approvedChip, item.status === 'rejected' && classes.rejectedChip]} /></TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    since_last_visit: state.homepage.since_last_visit.data
});

export default connect(mapStateToProps)(SinceLastVisit);
