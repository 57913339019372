import React from 'react'
import { connect } from "react-redux";
import AuthContainer from './AuthContainer';
import { Link } from 'react-router-dom';
import { Card } from '@material-ui/core';
import ValidationErrors from '../Components/Utils/validationErrors';
import Button from "../Components/Button";
import BeatLoader from 'react-spinners/BeatLoader';

import './index.scss';
import { authHeader } from "~/helpers/authHeader";

class Reset extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            message: '',
            loading: false,
            email: '',
            status: false,
        };
    }

    sendReset() {

        this.setState({
            loading: true
        });

        fetch('/api/users/send-reset', {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({
                email: this.state.email,
            })
        }).then((response) => {

            this.setState({
                loading: false,
                status: response.ok
            });

            response.json().then((data) => {
                this.setState({
                    errors: data || {},
                    message: data.message || ''
                });
            });
        });
    }

    handleKeyPress(e) {
        if (e.keyCode === 13) {
            this.loginUser();
        }
    }

    render() {
        return (
            <AuthContainer>
                {!this.state.status && (
                    <Card
                        raised={true}
                        className="card">

                        <div className="row">
                            <div className="col-12 text-center">
                                <img height="100" alt="Lions Logo" src="/images/logo/LionsSeries-Logo-Portrait-RGB-POS_AW.svg" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>Forgot Your Password?</h1>
                                <p>Enter the email address used for your account below and we will send you a password
                                    reset link.</p>
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className="col-12">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="example@lionstour.com"
                                    className="form-control"
                                    onChange={e => this.setState({ email: e.target.value })}
                                    onKeyDown={e => this.handleKeyPress(e)}
                                />
                                <ValidationErrors errors={this.state.errors.email || [this.state.message]} />
                            </div>
                        </div>

                        <div className="row pt-5">
                            <div className="col-12 text-center">

                                {!this.state.loading && (
                                    <Button class="color-red fullWidth" onClick={() => this.sendReset()} text="Send Reset Link" />
                                )}

                                <BeatLoader
                                    css={{ marginTop: '20px' }}
                                    color='#af001e'
                                    loading={this.state.loading}
                                />
                                <Link className="link" to="/">Back to Login</Link>
                            </div>
                        </div>
                    </Card>
                )}

                {this.state.status && (
                    <Card
                        raised={true}
                        className="card">

                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>check your inbox!</h1>
                                <p><strong>A password reset link has been sent.</strong></p>
                                <p>Check your email inbox and click the link in the email you received to reset your password.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-center">
                                <Link className="link" to="/">Back to Login</Link>
                            </div>
                        </div>

                    </Card>
                )}

            </AuthContainer>
        );
    }
}

const mapStateToProps = (state) => ({});

const reducerToProp = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, reducerToProp)(Reset);
