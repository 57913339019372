import update from 'immutability-helper';

const initialState = {
    role: null,
    actionPermissions: [],
    expandPermissions: false,
    allPermissions: [],
    criteria: 'id',
    read_only: false,
    editRoleType: null,
    users: [],
    saveRolePermissions: [],
    openEditRoleDialog: false
};

const roleReducer = (state = initialState, action) =>
{
    if (action.type === 'roles/SET_ROLE') {
        return update(state, {
            role: {$set: action.payload},
        });
    }

    if (action.type === 'roles/SET_EDIT_ROLE_TYPE') {
        return update(state, {
            editRoleType: {$set: action.payload},
        });
    }

    if (action.type === 'roles/SET_ACTION_PERMISSIONS') {
        return update(state, {
            actionPermissions: {$set: action.payload},
        });
    }

    if (action.type === 'roles/SET_SELECT_ALL') {
        return update(state, {
            allPermissions: {$set: action.payload},
        });
    }

    if (action.type === 'roles/SET_OPEN_EDIT_ROLE_DIALOG') {
        return update(state, {
            openEditRoleDialog: {$set: action.payload},
        });
    }

    if (action.type === 'roles/SET_CRITERIA') {
        return update(state, {
            criteria: {$set: action.payload},
        });
    }

    if (action.type === 'roles/SET_READ_ONLY') {
        return update(state, {
            read_only: {$set: action.payload},
        });
    }

    if (action.type === 'roles/EXPAND_ALL_PERMISSIONS') {
        return update(state, {
            expandPermissions: {$set: action.payload.dialog},
        });
    }

    if (action.type === 'roles/SAVE_ROLE_PERMISSIONS') {
        return update(state, {
           saveRolePermissions: {$set:action.payload}
        });
    }

    if (action.type === 'roles/SET_USERS') {
        return update(state, {
            users: {$set: action.payload}
        });
    }

    return state;
};

export default roleReducer;
