import update from 'immutability-helper';

const initialState = {
    partners: [],
    permissions: [],
    partner_permissions: [],
    active_partner_data: null,
    users_dropdowns: [],
    users: [],
    partner_tiers: [],
    partner_cms: {}
};

const partnerReducer = (state = initialState, action) => {
    if (action.type === 'partners/DESTROY') {
        return initialState;
    }

    if (action.type === 'partners/SET_PARTNERS') {
        return update(state, {
            partners: { $set: action.payload },
        });
    }

    if (action.type === 'partners/SET_PARTNER_PERMISSIONS') {
        return update(state, {
            partner_permissions: { $set: action.payload },
        });
    }

    if (action.type === 'partners/SET_ACTIVE_PARTNER_DATA') {
        return update(state, {
            active_partner_data: { $set: action.payload },
        });
    }

    if (action.type === 'partners/SET_USERS_DROPDOWNS') {
        return update(state, {
            users_dropdowns: { $set: action.payload },
        });
    }

    if (action.type === 'partners/SET_USERS') {
        return update(state, {
            users: { $set: action.payload },
        });
    }

    if (action.type === 'partners/SET_PARTNER_TIERS') {
        return update(state, {
            partner_tiers: { $set: action.payload },
        });
    }

    if (action.type === 'partners/SET_PARTNER_CMS') {
        return update(state, {
            partner_cms: { $set: action.payload },
        });
    }

    return state;
};

export default partnerReducer;
