import React from 'react';
import { connect } from "react-redux";
import {
    makeStyles, Tab, Tabs,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Permitted from "../Permissions/Permitted";

export const menuItems = [
    {
        link: '/admin/request-management',
        label: 'Request Management',
        permission: [
            'admin_review_talent_request',
            'admin_review_approvals_request'
        ]
    },
    {
        link: '/admin/partner-management',
        label: 'Partner Management',
        permission: [
            //admin content management Lions Tour 25
            "admin_view_ticket_hospitality",

            // admin content management Brand
            "admin_view_brand_guidelines",

            // admin content mangagement Media Librady
            "admin_view_archive",

            // admin content management Rights and Documentation
            "admin_view_right_documentations",

            // admin content management Requests
            "admin_view_talent_access",
            "edit_approvals",
            "upload_delete_approvals_files",


            "view_partner_list",
            "create_new_partner",
            "edit_partner",
            "edit_partner_user_list",
            "edit_partner_permissions",
            "delete_existing_partner"
        ]
    },
    {
        link: '/admin/inventory-management',
        label: 'Inventory Management',
        permission: [
            "submit_talent_request",
            "edit_talent_access",
            "upload_delete_talent_access_files",
            "view_talent_access_inventory",
            "create_talent_access_assets",
            "edit_existing_talent_access",
            "delete_talent_access_existing"
        ]
    },
    {
        link: '/admin/user-management',
        label: 'User Management',
        permission: [
            "view_user_list",
            "create_new_user",
            "edit_existing_user",
            "delete_existing_user",
            "create_new_admin",
            "edit_existing_admin",
            "delete_existing_admin",
            "view_role_list",
            "create_new_role",
            "edit_existing_role",
            "delete_existing_role",
            "create_new_admin_role",
            "edit_existing_admin_role",
            "delete_existing_admin_role",
            "mass_delete_users"
        ]
    },
];

const AdminMenu = (props) => {

    const [currentTab, setCurrentTab] = React.useState(0);


    const useStyles = makeStyles((theme) => ({
        activeTabItem: {
            color: '#af001e',
            fontWeight: 'bold',
            fontFamily: theme.demiBold
        },
        activeTabLink: {
            borderBottom: '2px solid #af001e',
            '&:hover': {
                textDecoration: 'none'
            }
        },
        nonActiveTabItem: {
            color: 'black',
            fontFamily: theme.demiBold,
            '&:hover': {
                fontWeight: 'bold',
                color: '#af001e',
                textDecoration: 'none',
                fontFamily: theme.demiBold
            }
        },
        indicator: {
            backgroundColor: "white",
            display: 'none'
        },
        fullWidth: {
            width: '100%'
        },
        responsivDirection: {
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row'
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        }
    }));

    const classes = useStyles();

    const pathName = props.location.pathname;

    const getChildren = (item, key) => {
        return (
            <Link key={key} to={item.link}
                className={pathName === item.link ? classes.activeTabLink : classes.nonActiveTabItem}>
                <Tab value={key} index={key} label={item.label}
                    className={pathName === item.link ? classes.activeTabItem : classes.nonActiveTabItem} />
            </Link>
        )
    }

    return (
        <Tabs classes={{ indicator: classes.indicator, root: classes.fullWidth, flexContainer: classes.responsivDirection }} value={currentTab}>

            {menuItems.map((item, key) => {
                if (Array.isArray(item.permission)) {
                    return (
                        <Permitted key={key} some={item.permission}>
                            {getChildren(item, key)}
                        </Permitted>
                    );
                } else {
                    return (
                        <Permitted key={key} one={item.permission}>
                            {getChildren(item, key)}
                        </Permitted>
                    );
                }

            })}

            {
                //<Link to="/admin/user-management"
                //    className={props.location.pathname === '/admin/user-management' || props.location.pathname === '/admin' ? classes.tabLink : null}>
                //  <Tab value={0}
                //      label="User Management"
                //      className={props.location.pathname === '/admin/user-management' || props.location.pathname === '/admin' ? classes.tabItem : classes.tabItemBlack} />
                //</Link>
            }

            {/* <Link to="/admin/#" className={props.location.pathname === '/admin/#' ? classes.tabLink : null}><Tab value={1} label="Partner Tier Management" className={props.location.pathname === '/admin/#' ? classes.tabItem : classes.tabItemBlack} /></Link> */}
            {/* <Link to="/admin/#" className={props.location.pathname === '/admin/#' ? classes.tabLink : null}><Tab value={2} label="Partner Management" className={props.location.pathname === '/admin/#' ? classes.tabItem : classes.tabItemBlack} /></Link> */}
            {/* <Link to="/admin/#" className={props.`location.pathname` === '/admin/#' ? classes.tabLink : null}><Tab value={3} label="Inventory Management" className={props.location.pathname === '/admin/#' ? classes.tabItem : classes.tabItemBlack} /></Link> */}

            {
                //<Link to="/admin/request-management"
                //      className={props.location.pathname === '/admin/request-management' ? classes.tabLink : null}>
                //    <Tab value={4} label="Request Management" className={props.location.pathname === '/admin/request-management' ? classes.tabItem : classes.tabItemBlack} />
                //</Link>
            }

            {/* <Link to="/admin/#" className={props.location.pathname === '/admin/#' ? classes.tabLink : null}><Tab value={5} label="Reports" className={props.location.pathname === '/admin/#' ? classes.tabItem : classes.tabItemBlack} /></Link> */}
        </Tabs>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(withRouter(AdminMenu));
