import update from 'immutability-helper';

const initialState = {
    activeAsset: null,
    players: [],
    staff: [],
    automaticAllocation: false,
};

const talentAccessReducer = (state = initialState, action) =>
{
    if (action.type === 'talent_access/SET_ACTIVE_ASSET') {
        return update(state, {
            activeAsset: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_TEAM_PLAYERS') {
        return update(state, {
            players: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_TEAM_STAFF') {
        return update(state, {
            staff: {$set: action.payload},
        });
    }

    if (action.type === 'inventory/SET_AUTOMATIC_ALLOCATION') {
        return update(state, {
            automaticAllocation: {$set: action.payload},
        });
    }

    return state;
};

export default talentAccessReducer;
