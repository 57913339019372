export const resetOverridesData = {    
    lions_tour: {
        partner_workshops: `
        <p style="text-align:left;">
            <span style="color:rgba(0,0,0,0.87);background-color:rgb(243,244,248);font-size:1rem;font-family:'AvenirNext-Regular';">
                Details of scheduled Partner Workshops will be communicated in due course.
            </span>
        </p>
        `,
        partner_workshops_files: [],
        right_protection: `
            <p style="text-align:left;">
                <span style="color:rgba(0,0,0,0.87);background-color:rgb(243,244,248);font-size:1rem;font-family:'AvenirNext-Regular';">
                    <strong>
                        LTAU take instances of ambush marketing seriously and acknowledge that these 
                        incidents require immediate action.
                    </strong>
                </span>
            </p>
            <p style="text-align:left;">
                <span style="color:rgba(0,0,0,0.87);background-color:rgb(243,244,248);font-size:1rem;font-family:'AvenirNext-Regular';">
                    Ambush Marketing refers to activity such as advertising and communications which 
                    implies that an individual or organisation is associated with an entity and an event 
                    or product, where no association exists.
                </span>
            </p>
            <p style="text-align:left;">
                <span style="color:rgba(0,0,0,0.87);background-color:rgb(243,244,248);font-size:1rem;font-family:'AvenirNext-Regular';">
                    Entities who engage in Ambush Marketing often attempt to profit from an 
                    association with an event without spending time or money to become officially 
                    associated via being an official Partner, broadcaster or supplier.
                </span>
            </p>
            <p style="text-align:left;">
                <span style="color:rgba(0,0,0,0.87);background-color:rgb(243,244,248);font-size:1rem;font-family:'AvenirNext-Regular';">
                    LTAU and all associated stakeholders of the Tour will endeavour to prevent, 
                    minimise and reduce any infringements in order to protect and enhance the 
                    intellectual property, commercial rights and investment made by sanctioned 
                    stakeholders, as well as protecting the Tour via a commercially controlled and 
                    balanced environment.
                </span>
            </p>
        `,
        right_protection_files: [],
        documents: ``,
        documents_files: [],
    },
    brand: {
        brand: `
            <p style="text-align:left;">
                <span style="color:rgb(62,63,96);background-color:rgb(243,244,248);font-size:17px;font-family:'AvenirNext-Regular';">
                    Every Lions Tour has its own character. But it is never 
                    anything short of World Class. Every word and every 
                    image should be measured against this standard. These 
                    documents provide the tools to help you meet this 
                    standard and ensure consistent excellence across all 
                    Partner communications.
                </span>
            </p>
        `,
        brand_files: [],
    },
    media_library: {
        media_library: `
        <p style="text-align:left;">
            <span style="color:rgb(62,63,96);background-color:rgb(243,244,248);font-size:16px;font-family:'AvenirNext-Regular';">
                In this section you will find a series of external links to access imagery &amp; footage. Your Partner Manager will be set up with access details where applicable.
            </span>
        </p>
        <p style="text-align:left;">
            <span style="color:rgb(62,63,96);background-color:rgb(243,244,248);font-size:16px;font-family:'AvenirNext-Regular';">
                Please note that access is dependent on individual agreements and all use of any associated imagery or footage is subject to approval by LTAU.                
            </span>
        </p>`,
        british_lions_imagery: '',
        british_lions_footage: '',
        springboks_imagery: '', 
        springboks_footage: ''
    },
    requests: {
        override_talent_access: false,
        talent_access: `
            <p>
                All talent and staff access requests should be submitted 
                via the portal. Please note, requests should be submitted at 
                least two months prior to the date you are planning for the 
                appearance to take place.
            </p>
            <p>There are two types of appearance request:</p>
            <ul>
                <li>Partner events – time requested by the partner but agreed between parties</li>
                <li>Commercial Appearance – time dictated by LTSA</li>
            </ul>
            <p>
                Please note, player access requests are to be used as an 
                indication of partner preferences and are subject to confirmation 
                from LTSA. Players assigned to each Partner will not be confirmed until 
                closer to the appearance.
            </p>
            <p>
                In order to process your request as quickly as possible, we ask 
                that you include alternative dates, should your preferred date not 
                be logistically possible.
            </p>
            <p>
                All staff and player briefs should be submitted via the portal. The 
                ‘Partner Appearance Brief’ form can be found in the ‘Documents’ folder 
                of the ‘Brand’ tab.
            </p>
            <p>
                Rights to talent and staff access are subject to the terms agreed in 
                your contract.
            </p>`,
        talent_access_files: [],
        override_approvals: false,
        approvals: `
            <ul>
                <li>
                    As with all brands, LTAU have guidelines that need to be
                    adhered to in all executions
                </li>
                <li>
                    Partner guidelines centre around logos, brand and player
                    imagery, footage, and partner assets
                </li>
                <li>
                    Any content featuring Tour or Team IP will need to be
                    approved by the LTAU Commercial Team
                </li>
                <li>
                    Should any execution not be approved by LTAU, Partners
                    will be in breach of contract and liable to legal action
                </li>
                <li>
                    All executions must be shared via the approvals portal
                    before being approved and going live
                </li>
            </ul>`,
        approvals_files: []
    }
}
