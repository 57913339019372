import React, { useEffect, useState } from 'react'
import { Switch } from 'react-router-dom';
import './index.scss';
import ProtectedRoute from "../ProtectedRoute";
import Item from "~/MediaLibrary/Item";
import ManageItem from "~/MediaLibrary/Manage";
import HeaderInnerNavigation from "../Components/HeaderInnerNavigation";
import axios from "axios";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import AdminPartnersDropdown from "~/Components/AdminPartnersDropdown/AdminPartnersDropdown";

const Media = (props) => {
    const [navigationCurrent, setNavigationCurrent] = useState(null);
    const {
        match,
    } = props;

    const [mediaLibraries, setMediaLibraries] = useState(null);

    useEffect(() => {
        axios.get('/api/media-categories').then((response) => {
            let libraries = response.data.data
            if (libraries.length > 0) {
                setMediaLibraries(libraries);
            }

        });
    }, []);

    useEffect(() => {
        if (props.location.pathname === '/media' && mediaLibraries) {
            props.history.push('/media/category/' + mediaLibraries[0].id);
        }

        let regex = new RegExp(/^\/media\/category|manage\//)
        if (!regex.test(props.location.pathname) && props.location.pathname !== '/media') {
            props.history.push('/404');
        }
    }, [props.location.pathname, mediaLibraries]);

    return (
        <div  className={props.showInfo && props.refInfoElement ? 'media-wrapper resized' : 'media-wrapper'} style={{
            width: props.showInfo && props.refInfoElement ? `calc(100% - ${props.refInfoElement.offsetWidth}px)` : '100%',
        }}>
            <section className="bodyContent media-library bottom-line">
                <section className="library-header">
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <h1 className="content-title">Media Library</h1>
                        </Grid>
                        <Grid item>
                            <AdminPartnersDropdown tabId="2" />
                        </Grid>
                    </Grid>

                    <div>
                        {mediaLibraries && mediaLibraries.length > 0 &&
                            <HeaderInnerNavigation
                                items={mediaLibraries}
                                basePath="/media/category"
                                navigationCurrent={navigationCurrent} />
                        }
                    </div>
                </section>
            </section>

            <section className="library-content">
                <Switch>
                    <ProtectedRoute exact path={`${match.path}/category/:id`}
                        setNavigationCurrent={setNavigationCurrent}
                        libraryScope="media"
                        component={Item} />
                    <ProtectedRoute exact path={`${match.path}/category/:id/:folderId`}
                        setNavigationCurrent={setNavigationCurrent}
                        libraryScope="media"
                        component={Item} />
                    <ProtectedRoute exact path={`${match.path}/manage/:id/:folderId?`}
                        setNavigationCurrent={setNavigationCurrent}
                        component={ManageItem} />
                </Switch>
            </section>
        </div>
    );
}

const mapStateToProps = (state) => ({
    showInfo: state.media.showInfo,
    refInfoElement: state.media.refInfoElement,
});
export default connect(mapStateToProps)(Media);

