const setImagePathByExtension = (ext) => {
    let path = '/images/icons/SVG/File Type/';
    let alt = '';

    switch (ext) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/eps':
        case 'jpg':
        case 'png':
        case 'eps':
        case 'ai':    
        case 'gif':    
            path += 'Image.svg';
            alt = 'image';
            break;
        case 'svg':
            path += 'SVG.svg';
            alt = 'svg image';
            break;
        case 'video/mp4':
        case 'video/mov':
        case 'mp4':
        case 'mp3':
        case 'm4v':
        case 'mov':
        case 'mpeg':
        case 'wmv':
        case 'avi':
        case 'swf':
            path += 'Video.svg';
            alt = 'video';
            break;
        case 'application/pdf':
        case 'pdf':
            path += 'PDF.svg';
            alt = 'pdf';
            break;
        case 'application/zip':
        case 'zip':
            path += 'Zip.svg';
            alt = 'zip';
            break;
        case 'application/msword':
        case 'application/vnd.ms-excel':
        case 'application/xslt+xml':
        case 'xsl':
        case 'doc':
        case 'docx':
            path += 'File.svg';
            alt = 'file';
            break;
        default:
            path += 'File.svg';
            alt = 'file';
    };
    
    return {path: path, alt: alt};          
};

export default setImagePathByExtension;