import React from 'react';

const ListIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <path fill="#3E3F60" fillRule="evenodd" d="M2.469 17.953v1.969H.5v-1.969h1.969zm19.031.328v1.313H3.781V18.28H21.5zM2.469 12.703v1.969H.5v-1.969h1.969zm19.031.328v1.313H3.781V13.03H21.5zM2.469 7.453v1.969H.5V7.453h1.969zm19.031.328v1.313H3.781V7.78H21.5zM2.469 2.203v1.969H.5V2.203h1.969zm19.031.328v1.313H3.781V2.53H21.5z"/>
            </svg>
        </>
    );
};

export default ListIcon;
