import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from "@material-ui/core";
import LibraryApi from "~/services/api/library";
import { connect } from "react-redux";
import { resetOverridesData } from "~/Admin/PartnerManagement/ContentManagement/cmsInitialValues";

const Description = ({ talentAccessCms }) => {

    const useStyles = makeStyles((theme) => ({
        icons: {
            width: 22,
            height: 22
        },
        documentPaper: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            minHeight: 46,
            height: 'auto',
            display: 'flex',
            alignItems: 'left',
            width: '100%',
            minWidth: '200px',
            [theme.breakpoints.down(700)]: {
                width: 'max-content'
            },
        },
        downloadIcon: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            textAlign: 'center',
            cursor: "pointer",
        },
        fileNameText: {
            paddingLeft: '.25rem',
            [theme.breakpoints.between(960, 1600)]: {
                visibility: 'hidden',
                position: 'absolute',
                paddingLeft: '1.25rem',
                minWidth: '190px',
                "&::before": {
                    visibility: 'visible',
                    content: '"Approvals Flow Chart"',
                    paddingLeft: '.25rem',
                    float: 'left',
                },
            },
        },
        documentsTitle: {
            fontSize: 18,
            fontFamily: theme.bold,
            color: theme.primaryColor,
        }
    }));

    const classes = useStyles();
    const [approvalDocuments, setApprovalDocuments] = useState(null);

    const talentDescription = talentAccessCms?.content?.html;
    const overrideTalentAccess = talentAccessCms?.content?.override_talent_access;

    useEffect(() => {
        if (talentAccessCms && talentAccessCms.files && overrideTalentAccess == "1") {
            setApprovalDocuments(talentAccessCms?.files);
        } else {
            axios.get('/api/media-categories/manuals?slug=talent-documents').then((response) => {
                setApprovalDocuments(response.data.data);
            })
        }
    }, [talentAccessCms]);

    return (
        <div className="approvals-description">
            <div className="instructions">
                <div className="pb-1">
                    {
                        talentDescription && overrideTalentAccess == "1"
                            ?
                            <div dangerouslySetInnerHTML={{ __html: talentDescription }} />
                            :
                            <div dangerouslySetInnerHTML={{ __html: resetOverridesData.requests.talent_access }} />
                    }
                </div>
                <div className={classes.documentsTitle}>
                    Resources
                </div>
                {
                    approvalDocuments &&

                    approvalDocuments.map((document, key) => {
                        return (
                            <Grid container alignContent="center" key={key} spacing={1} justify="flex-start">
                                <Grid item md>
                                    <Paper className={classes.documentPaper}>
                                        <span>
                                            <img className={classes.icons} src="/images/icons/SVG/File Type/File.svg" />
                                        </span>
                                        <span className={classes.fileNameText}>
                                            {document.name.length > 20 ? document.name.substring(0, 20) + '...' : document.name}
                                        </span>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <a href={document.full_path || document.path} download={`${document.name}.${document.ext}`}>
                                        <Paper className={classes.downloadIcon}>
                                            <img className={classes.icons} src="/images/icons/SVG/Download.svg" />
                                        </Paper>
                                    </a>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </div>
        </div>
    );
};

const mapStateToProps = ((state) => ({
    talentAccessCms: state.user.cmsBlocks?.requests_talent_access,
}));

export default connect(mapStateToProps)(Description);
