// import { getCommunications } from './commActions';
import { authHeader } from "~/helpers/authHeader";
import axios from 'axios';
import { fetchMe } from "~/appLoaderActions";

export const getPartners = () => {

    return (dispatch) => {

        return fetch('/api/partners', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_PARTNERS',
                    payload: data,
                });
            });
    };
};

export const getPartnerPermissions = () => {

    return (dispatch) => {

        return fetch('/api/permissions?partnerships', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_PARTNER_PERMISSIONS',
                    payload: data,
                });
            });
    };
};


export const setActivePartnerData = (activePartner) => {

    return (dispatch) => {

        return fetch('/api/partners/' + activePartner, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_ACTIVE_PARTNER_DATA',
                    payload: data.data,
                });
                return data.data;
            });
    };
};


export const addPartner = (data) => {

    return (dispatch) => {


        return axios.post('/api/partners', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {

                if (response.status === 201) {
                    return dispatch(getPartners());
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};


export const updatePartner = (activePartner, data) => {
    data.append('_method', 'put');
    return (dispatch) => {

        return axios.post('/api/partners/' + activePartner, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
        ).then(response => {

            if (response.status === 200) {
                return dispatch(getPartners());
            };

            if (response.status === 422) {
                return response.json()
            };

            throw Error('Unknown error!');
        });
    };
};


export const setPartnerPermission = (activePartner, selectedPermissions) => {

    return (dispatch) => {

        return fetch('/api/partners/' + activePartner, {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({ permission_ids: selectedPermissions })
        })
            .then((response) => {

                if (response.ok) {
                    return dispatch(getPartners())
                        .then(() => {
                            return true;
                        });
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};


export const getUserDropdowns = (data) => {

    return (dispatch) => {

        return fetch('/api/users/users-dropdowns', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_USERS_DROPDOWNS',
                    payload: data.data,
                });
            });
    };
};


export const fetchUsersList = (name) => {
    let nameParam = name ? '?name=' + name : '';
    return (dispatch) => {
        return fetch('/api/users' + nameParam, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_USERS',
                    payload: data,
                });
            });
    };
};

export const getPartnerTiers = () => {

    return (dispatch) => {
        return fetch('/api/partner-tiers', {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_PARTNER_TIERS',
                    payload: data.data,
                });
            });
    };
};


export const deletePartner = (partnerId, reassignPartnerId) => {

    return (dispatch) => {
        return fetch('/api/partners/' + partnerId, {
            method: 'DELETE',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({
                "new_partner_id": reassignPartnerId
            })
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch(fetchMe());
                return dispatch({
                    type: 'partners/SET_ACTIVE_PARTNER_DATA',
                    payload: null,
                });
            });
    };
};


export const clearData = () => {

    return (dispatch) => {
        dispatch({
            type: 'partners/DESTROY'
        });
    }
};


export const clearActivePartnerData = () => {

    return (dispatch) => {
        dispatch({
            type: 'partners/SET_ACTIVE_PARTNER_DATA',
            payload: null,
        });
    }
};


export const assignUsersToPartner = (partnerId, userList) => {

    return (dispatch) => {

        return fetch(`/api/partners/${partnerId}/users-sync`, {
            method: 'POST',
            credentials: 'include',
            headers: authHeader(),
            body: JSON.stringify({ "user_ids": userList })
        })
            .then((response) => {

                if (response.ok) {
                    dispatch(getPartners());
                    dispatch(fetchUsersList());
                    dispatch(fetchMe());
                    return dispatch(setActivePartnerData(partnerId))
                };

                if (response.status === 422) {
                    return response.json()
                };

                throw Error('Unknown error!');
            });
    };
};


export const filterUsers = (filterParams, page, perPage) => {
    return (dispatch) => {
        return fetch(`/api/users?first_name=${filterParams.fName}&partner=${filterParams.partner}&role=${filterParams.role}&email=${filterParams.email}&last_name=${filterParams.lName}&user_id=${filterParams.uid}&perPage=${perPage}&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: authHeader()
        })
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : [])
            .then((data) => {
                dispatch({
                    type: 'partners/SET_USERS',
                    payload: data,
                });
            });
    };
};

export const destroyPartnersManagement = () => {
    return (dispatch) => {
        return dispatch({
            type: 'partners/DESTROY'
        });
    }
}

export const saveDataContentManagement = (data) => {
    return (dispatch) => {
        return axios.post(`/api/partner-cms-content`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(res => {
                dispatch(fetchMe());
            })
    }
}
