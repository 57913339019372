export const PARTNER_INFORMATION_MENU = [
    {
        link: "/partner-information",
        label: "Partner Handbook",
        permission: ["view_brand_guidelines"],
    },
    {
        link: "/partner-information/partner-workshops",
        label: "Partner Workshops",
        permission: ["view_brand_guidelines"],
    },
    {
        link: "/partner-information/tickets-and-hospitality",
        label: "Tickets & Hospitality",
        permission: ["view_brand_guidelines"],
    },
    {
        link: "/partner-information/appearances",
        label: "Appearances",
        permission: ["view_brand_guidelines"],
    },
    {
        link: "/partner-information/activation",
        label: "Activation",
        permission: ["view_brand_guidelines"],
    },
    {
        link: "/partner-information/rights-protection",
        label: "Rights Protection",
        permission: ["view_brand_guidelines"],
    },
];
