import React from 'react';

const RadioActive = () => {
    return (
        <>
            <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Active</title>
                <g id="Portal-Design---Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Active" transform="translate(1.100000, 1.400000)">
                        <circle id="Oval" fill="#AF001E" cx="5.6" cy="5.6" r="5.6"></circle>
                        <path d="M5.6,-1 C1.95492065,-1 -1,1.95492065 -1,5.6 C-1,9.24507935 1.95492065,12.2 5.6,12.2 C9.24507935,12.2 12.2,9.24507935 12.2,5.6 C12.2,1.95492065 9.24507935,-1 5.6,-1 Z M5.6,1 C8.14050985,1 10.2,3.05949015 10.2,5.6 C10.2,8.14050985 8.14050985,10.2 5.6,10.2 C3.05949015,10.2 1,8.14050985 1,5.6 C1,3.05949015 3.05949015,1 5.6,1 Z" id="Oval" fill="#D8DAE6" fillRule="nonzero"></path>
                    </g>
                </g>
            </svg>
        </>
    );
};

export default RadioActive;
