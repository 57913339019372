import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import Matched from "~/Admin/RequestManagement/GroupAllocation/Matched/Matched";
import Typography from "@material-ui/core/Typography";

const TimeColumn = ({index, partner, members, column, setMatchedGroup, currentGroup, captain, matchedGroup}) => {

    const getSelected = () => {
        let i = 0;

        members.group.forEach(player => {
            if (typeof column.member_ids[player.id] !== 'undefined') {
                i++;
            }
        })

        return i;
    }

    return (
        <Grid container direction="row" key={index}>
            <Grid item md={4}>
                <img className="partner-image"
                     style={{cursor: !captain ? 'pointer' : 'default', maxWidth: 83}}
                     src={"/storage/" + partner?.logo} alt={partner?.name}
                     onClick={() => {
                         if (!captain) {
                             setMatchedGroup(`${currentGroup}-${column.request}-${partner?.id}`);
                         }
                     }}
                />
                {`${currentGroup}-${column.request}-${partner?.id}` === matchedGroup && !captain &&
                <Matched
                    partner={partner}
                    members={members.group}
                    memberIds={column.member_ids}
                    accessType={column.access_type}
                    request={column.request}
                />
                }
            </Grid>
            <Grid item md={8} style={{display: 'flex'}}>
                <Grid container direction="column" justify="center">
                    <Grid item>
                        <Typography className="partner">
                            {partner?.name}
                        </Typography>
                    </Grid>
                    {!captain &&
                    <Grid item>
                        <Typography className="matched">
                            MATCHED: <span className="matched-players">{getSelected()}/{members.group.length}
                            {column.access_type === 'players' ? ' Players' : ' Staff'}</span>
                        </Typography>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TimeColumn;
