import React, { useState } from 'react';
import { connect } from "react-redux";
import { Grid, Typography } from '@material-ui/core';
import { changePassword } from './loginActions';
import Button from '~/Components/Button';
import classNames from 'classnames';

const ChangePassword = (props) => {

    const [password, setPassword] = useState('');
    const [new_password, setNew_password] = useState('');
    const [confirm_password, setConfirm_password] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const onFormSubmit = (e) => {
        setErrors({});
        setSuccess(false);

        e.preventDefault();

        let form = { password, new_password, confirm_password }

        props.dispatch(changePassword(form))
            .then((res) => {
                if (res === true) {
                    setPassword('');
                    setNew_password('');
                    setConfirm_password('');
                    return setSuccess(true);
                }

                return setErrors(res.errors);
            });
    }

    return (
        <section className="requests sticky-footer">
            <Grid container style={{ paddingLeft: 43 }}>
                <Grid item>
                    <section>
                        <Typography variant="h3" style={{
                            textTransform: 'capitalize',
                            fontWeight: 600,
                            color: '#3e3f60',
                            fontSize: 40,
                        }}>Change Password</Typography>


                        <form onSubmit={(e) => {
                            onFormSubmit(e)
                        }}>

                            <div className="form-group">
                                <label>Old Password</label>
                                <input type="password"
                                    value={password}
                                    style={{ borderRadius: 0 }}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                    className={classNames('form-control', { 'is-invalid': errors.password })} />
                                <div className="invalid-feedback">{errors.password}</div>
                            </div>

                            <div className="form-group">
                                <label>New Password</label>
                                <input type="password"
                                    value={new_password}
                                    style={{ borderRadius: 0 }}
                                    onChange={(e) => {
                                        setNew_password(e.target.value)
                                    }}
                                    className={classNames('form-control', { 'is-invalid': errors.new_password })} />
                                <div className="invalid-feedback">{errors.new_password}</div>
                            </div>

                            <div className="form-group">
                                <label>Password Confirmation</label>
                                <input type="password"
                                    value={confirm_password}
                                    style={{ borderRadius: 0 }}
                                    onChange={(e) => {
                                        setConfirm_password(e.target.value)
                                    }}
                                    className={classNames('form-control', { 'is-invalid': errors.confirm_password })} />
                                <div className="invalid-feedback">{errors.confirm_password}</div>
                            </div>

                            {success && (
                                <div className="alert alert-success">
                                    <strong>Success!</strong> Password has been changed!&nbsp;
                                </div>
                            )}

                            <Button type="submit" class="btn-bloody footer-downloads noMarg" text="Submit" />
                        </form>

                    </section>
                </Grid>
            </Grid>
        </section>
    );
}


export default connect()(ChangePassword);
