import React, { useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import { PageMenu, LIONS_TOUR_2025_MENU } from "../Components/PageMenu";

const LionsTour2025 = (props) => {
    const useStyles = makeStyles((theme) => ({
        section: {},
        sectionTitle: {
            color: "#af001e",
            textTransform: "uppercase",
            fontSize: 20,
            fontWeight: 600,
            paddingBottom: "1em",
        },
        indicator: {
            color: "#af001e",
        },
        imgContainer: {
            overflow: "hidden",
        },
        tourimg: {
            width: "100%",
            marginTop: "60px",
            objectFit: "contain",
        },
        table: {
            borderRadius: 0,
        },
        tableRow: {
            borderBottom: "2px solid #af001e",
            textTransform: "uppercase",
        },
        tableCell: {
            color: "#999bb5",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: "bolder",
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60,
        },
        textPadding: {
            paddingTop: 20,
        },
        arrowIcon: {
            width: 29,
            height: 29,
        },
    }));

    const classes = useStyles();
    const [value, setValue] = useState(0);

    const createData = (id, date, time, match, location, stadion) => {
        return { id, date, time, match, location, stadion };
    };

    const rows = [
        createData(
            1,
            "20 June",
            "20:00",
            "British & Irish Lions v Argentina",
            "Dublin",
            "Aviva Stadium"
        ),
        createData(
            2,
            "28 June",
            "TBC",
            "Western Force v British & Irish Lions",
            "Perth",
            "Optus Stadium"
        ),
        createData(
            3,
            "2 July",
            "TBC",
            "Queensland Reds v British & Irish Lions",
            "Brisbane",
            "Suncorp Stadium"
        ),
        createData(
            4,
            "5 July",
            "TBC",
            "NSW Waratahs v British & Irish Lions",
            "Sydney",
            "Allianz Stadium"
        ),
        createData(
            5,
            "5 / 6 July",
            "TBC",
            "Wallabies v Fiji",
            "TBC",
            "Stadium: TBC"
        ),
        createData(
            6,
            "9 July",
            "TBC",
            "ACT Brumbies v British & Irish Lions",
            "Canberra",
            "GIO Stadium"
        ),
        createData(
            7,
            "12 July",
            "TBC",
            "Invitational AU & NZ v British & Irish Lions",
            "Adelaide",
            "Adelaide Oval"
        ),
        createData(
            8,
            "19 July",
            "TBC",
            "Test 1: Wallabies v British & Irish Lions",
            "Brisbane",
            "Suncorp Stadium"
        ),
        createData(
            9,
            "22 July",
            "TBC",
            "TBC v British & Irish Lions",
            "Melbourne",
            "Marvel Stadium"
        ),
        createData(
            10,
            "26 July",
            "TBC",
            "Test 2: Wallabies v British & Irish Lions",
            "Melbourne",
            "Melbourne Cricket Ground / MCG"
        ),
        createData(
            11,
            "2 August",
            "TBC",
            "Test 3: Wallabies v British & Irish Lions",
            "Sydney",
            "Accor Stadium"
        ),
    ];

    return (
        <section className={classes.section}>
            <Grid container direction="column">
                <Grid item>
                    <h1 className="content-title">Lions Series 2025</h1>
                </Grid>
            </Grid>
            <PageMenu menuItems={LIONS_TOUR_2025_MENU} />
            <Divider />
            <Grid container className={classes.gridPadding}>
                <Grid item md={6}>
                    <Typography variant="h5" className={classes.sectionTitle}>
                        Series Information
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.textPadding}
                        style={{ fontWeight: "bold" }}
                    >
                        The British & Irish Lions is an iconic sporting brand in
                        the world of rugby. Every four years, the Lions – the
                        best of the best selected from England, Ireland,
                        Scotland and Wales – travel to the southern hemisphere
                        to take on Australia, New Zealand, or South Africa on
                        landmark tours.
                    </Typography>
                    <Typography variant="body1" className={classes.textPadding}>
                        The Lions is the pinnacle of players’ careers, a once in
                        a lifetime experience for fans and leaves an enduring
                        legacy wherever the team visit.
                    </Typography>
                    <Typography variant="body1" className={classes.textPadding}>
                        The Lions foundations are strong, built upon 130 years
                        of history and tradition with tours taking place
                        regularly since 1888.
                    </Typography>
                    <Typography variant="body1" className={classes.textPadding}>
                        The 2025 Tour to Australia promises to be one of the
                        most eagerly anticipated tours in history considering
                        the absence of fans for the 2021 Tour of South Africa.
                        The Lions will travel throughout Australia with games
                        taking place in some of the most impressive stadiums in
                        world sport.
                    </Typography>
                    <Typography variant="body1" className={classes.textPadding}>
                        Fans will also have the opportunity to see the Lions in
                        action and send off the squad on a high before they
                        embark on their journey to Australia when they take on
                        Argentina in Dublin ahead of the Tour.
                    </Typography>
                    <Typography variant="body1" className={classes.textPadding}>
                        Please note, Lions v Argentina and Wallabies v Fiji are
                        Warm-up Fixtures and not part of the Qatar Airways Lions
                        Men’s Series 2025.
                    </Typography>
                </Grid>
                <Grid item md={6} className={classes.imgContainer}>
                    <img
                        className={classes.tourimg}
                        src="/images/lions-tour/map_2025.png"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Typography variant="body1" className={classes.sectionTitle}>
                    Match Schedule
                </Typography>
                <TableContainer component={Paper} className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    Date
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Kick-off Time
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                >
                                    Match
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                >
                                    Location
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                >
                                    Stadium
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.date}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.time}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.match}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.location}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.stadion}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </section>
    );
};

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
});

export default connect(mapStateToProps)(LionsTour2025);
