import React, {useEffect, useState} from 'react';
import CheckBoxIcon from "~/Components/Icons/CheckBoxIcon";
import ExtensionIcon from "~/Components/ExtensionIcon";
import InfoIcon from "~/Components/Icons/InfoIcon";
import LibraryApi from "~/services/api/library";
import {connect} from "react-redux";
import {setShowInfo} from "~/Media/actions";

const ListItem = (
    {
        setPreviewFile,
        item,
        selectedDownloadItem,
        getMonths,
        setSelectedDownloadItems,
        setLoaded,
        dispatch,
        showInfo,
}) => {
    const [showCommands, setShowCommands] = useState(false);

    const showInfoBar = (id) => {
        dispatch(setShowInfo(id));
        document.getElementsByTagName('body')[0].classList.add('no-scroll');
    }
    return (
        <tr onMouseEnter={() => setShowCommands(true)} onMouseLeave={() => setShowCommands(false)}>
            <td>
                <CheckBoxIcon setSelectedDownloadItems={setSelectedDownloadItems} selectedDownloadItem={selectedDownloadItem}
                              smallIcons={false} item={item}/>
            </td>
            <td onClick={() => {
                setPreviewFile(item)
            }}
                style={item.media_type ? {cursor: 'pointer'} : {cursor: ''}}
            >
                <div className="d-flex">
                {
                    item.media_type
                        ?
                        <img src="/images/icons/SVG/image_list.svg"/>
                        :
                        <ExtensionIcon text={item.ext} width="30px" height="30px"/>
                }
                <span title={item.name} className="image-name">{item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}</span>
                </div>

            </td>
            <td className="text-uppercase">{item.size > 0 ? (item.size / 1000) : 0 } MB</td>
            <td className="text-uppercase">{item.ext}</td>
            <td>{getMonths(item.updated_at)}</td>
            <td className="download-container d-flex">
                {showCommands &&
                <>
                    <span className="file-properties" onClick={() => showInfoBar(item.id)}>
                        <span className="info-icon" ><InfoIcon /></span>
                        {!showInfo &&
                            <span className="property-text">FILE PROPERTIES</span>
                        }
                    </span>
                    <span className={`button fullWidth color-red ${showInfo ? 'ml-2' : ''}`} onClick={() => {
                        LibraryApi.downloadImage(item, setLoaded)
                    }}>
                        {showInfo
                            ?
                            <span>
                                <img src="/images/icons/SVG/download_list.svg"/>
                            </span>
                        :
                            <>
                                <span className="pr-2">
                                    <img src="/images/icons/SVG/download_list.svg"/>
                                </span>
                                <span>DOWNLOAD</span>
                            </>
                        }
                    </span>
                </>
                }
            </td>
        </tr>
    );
};

const mapStateToProps = (state) => ({
    showInfo: state.media.showInfo
});

export default connect(mapStateToProps)(ListItem);
